import React from 'react';

import { Body, Container, Footer, Header } from './Modal.styled';
import { Button } from '../index';
import { BUTTON_TYPE } from '../Button/Button.component';

interface IModal {
	show?: boolean;
	onHide?: () => void;
	onCancel?: () => void;
	onConfirm?: () => void;
	centered?: boolean;
	children?: React.JSX.Element;
	title?: string;
	buttonActionType?: string;
	buttonActionLabel?: string;
	buttonCancelLabel?: string;
}

const ModalComponent = (props: IModal): React.JSX.Element => {
	const {
		show = false,
		onHide = () => {},
		onCancel = () => {},
		onConfirm = () => {},
		centered = true,
		buttonActionType = BUTTON_TYPE.PRIMARY,
		buttonActionLabel = 'Click Me',
		buttonCancelLabel = 'Cancel',
	} = props;

	const modalProps = () => ({
		show,
		onHide,
		centered,
	});

	const renderHeader = (): React.JSX.Element => {
		return <Header>{props?.title}</Header>;
	};

	const renderBody = (): React.JSX.Element => {
		return <Body>{props?.children}</Body>;
	};

	const renderFooter = (): React.JSX.Element => {
		return (
			<Footer>
				<Button
					onClick={onCancel}
					label={buttonCancelLabel}
					width="20%"
					kind={BUTTON_TYPE.SECONDARY}
				/>
				<Button
					onClick={onConfirm}
					label={buttonActionLabel}
					width="20%"
					kind={buttonActionType}
				/>
			</Footer>
		);
	};

	return (
		<Container {...modalProps()}>
			{props?.title && renderHeader()}
			{props?.children && renderBody()}
			{renderFooter()}
		</Container>
	);
};

export default ModalComponent;
