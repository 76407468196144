// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect, useState } from 'react';

import { WhiteContainer } from './Company.styled';
import {
	CompanyName,
	ShowMobileOnly,
	TopSectionMobile,
} from '../SingleReport/SingleReport.styled';
import RightTabComponent from '../../Components/SingleReport/RightTab.component';
import { RIGHT_TAB } from '../SingleReport/SingleReport.screen';
import {
	renderFloatingComponent,
	ScrollAppearComponent,
} from '../SingleReport/SingleReport.mobile.screen';
import { ICompanyDesktop } from './Company.types';
import CompanyList from '../../Components/Company/SentimentList.component';
import Graph from '../../Components/Company/Graph.component';
import YearFooter from '../../Components/Company/YearFooter.component';
import EarningCallTable from '../../Components/Company/EarningTable.component';
import KeywordTable from '../../Components/Company/KeywordTable.component';
import { SCREEN_SIZE } from '../../Constants';
import useResizeScreen from '../../Hooks/useResizeScreen';
import { getCallsLabel } from '../../Utils';
import { renderDisclaimer } from '../../Components/SingleReport';

const CompanyMobile = (props: ICompanyDesktop) => {
	const [selectedRightTab, setSelectedRightTab] = useState(RIGHT_TAB.OVERVIEW);
	const [isCompanyListOpen, setIsCompanyListOpen] = useState(false);
	const [isVisible, setIsVisible] = useState(false);
	const [xCoordinate, setXCoordinate] = useState(0);
	const [date, setDate] = useState<Date | undefined>();
	const screenType = useResizeScreen();

	const isSmallerScreen = screenType === SCREEN_SIZE.MOBILE;

	const {
		keywordList,
		tabType: companyListType,
		list: companyList,
		paginationData,
		data: company,
		setCompany,
		documentList,
		setDocumentList,
		tablePaginationData,
		setTablePaginationData,
		setDocument,
		document,
		documentId,
		setDocumentId,
		scoresAverage,
		onDocumentUrlClicked,
		startDate,
		setStartDate,
		finishDate,
		setFinishDate,
		onChangeDate,
		onChangeCompanyId,
		companyData,
		scoresList,
		setScoresList,
		stockPrices,
		setStockPrices,
		getList,
		getDocumentList,
		setTabType: setCompanyListType,
		documentType,
		setFilter,
		filter,
		navigateToCompare,
		companyReturn
	} = props;

	const onChangeRightTab = (selectedTab: string): void => {
		setSelectedRightTab(selectedTab);
	};

	const onClickIcon = () => {
		setIsCompanyListOpen(true);
	};

	const onCloseIcon = () => {
		setIsCompanyListOpen(false);
	};

	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY * window.innerWidth) {
				setIsVisible(true);
			} else {
				setIsVisible(false);
			}
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	const renderGraphProps = () => ({
		xCoordinate,
		setXCoordinate,
		setDate,
		isSmallerScreen,
		date,
		scoresList,
		setScoresList,
		stockPrices,
		setStockPrices,
		navigateToCompare,
		finishDate,
		startDate,
	});

	if (isCompanyListOpen) {
		return (
			<CompanyList
				isSmallerScreen={true}
				onCloseIcon={onCloseIcon}
				onChangeCompanyId={onChangeCompanyId}
				companyList={companyList}
				setCompanyListType={setCompanyListType}
				companyListType={companyListType}
				paginationData={paginationData}
				onChangePagination={getList}
				company={company}
				setCompany={setCompany}
			/>
		);
	}
	return (
		<React.Fragment>
			<ShowMobileOnly>
				<div className="bg-grey">
					<WhiteContainer className="px-3 py-3">
						<RightTabComponent
							finishDate={finishDate}
							startDate={startDate}
							documents={document}
							selectedRightTab={selectedRightTab}
							onChangeRightTab={onChangeRightTab}
							isCompanyPage
							scoreType='COMPANY'
							onClickIcon={onClickIcon}
							companies={companyData}
							scoresAverage={scoresAverage}
							navigateToCompare={navigateToCompare}
							documentType={documentType}
							companyReturn={companyReturn}
						/>
						<ScrollAppearComponent style={{ top: '40px' }} isVisible={isVisible}>
							{renderFloatingComponent({
								documents: document,
								selectedRightTab,
								setSelectedRightTab,
								scoresAverage,
								companies: companyData,
								documentType
							})}
						</ScrollAppearComponent>
					</WhiteContainer>

					<WhiteContainer className="mt-2 py-3 px-3">
						<CompanyName className="m-0">
							Sentiment and Return by&nbsp;
							{companyData?.name}
						</CompanyName>
						<Graph {...renderGraphProps()} />
					</WhiteContainer>
					<div className="px-3 bg-white">
						<YearFooter
							startDate={startDate}
							setStartDate={setStartDate}
							finishDate={finishDate}
							setFinishDate={setFinishDate}
							onChangeDate={onChangeDate}
						/>
					</div>
					<div className="mt-2 px-3 py-2 bg-white">
						<EarningCallTable
							pageType="Company"
							documentList={documentList}
							setDocumentList={setDocumentList}
							tablePaginationData={tablePaginationData}
							setTablePaginationData={setTablePaginationData}
							onChangePagination={value => {
								getDocumentList(`/?page=${value}`)();
							}}
							document={document}
							setDocument={setDocument}
							documentId={documentId}
							setDocumentId={setDocumentId}
							onDocumentUrlClicked={onDocumentUrlClicked}
							isMobileVersion={true}
							documentType={documentType}
							setFilter={setFilter}
							filter={filter}
							componentTitle={getCallsLabel(documentType)}
						/>
					</div>
					<WhiteContainer className="mt-2 py-2">
						<KeywordTable
							navigateToCompare={navigateToCompare}
							keywordList={keywordList}
							keywordPagination={{ current_page: 1 }}
						/>
					</WhiteContainer>
					<div className="mt-2 bg-white">
						{renderDisclaimer()}
					</div>
				</div>
			</ShowMobileOnly>
		</React.Fragment>
	);
};

export default CompanyMobile;
