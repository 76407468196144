import styled from 'styled-components';
import { Modal } from 'react-bootstrap';

import { FlexDisplay } from '../../Screens/Preferences/Preferences.styled';

export const Container = styled(Modal)`
	border-radius: 4px;
	border: none;
`;

export const Header = styled.div`
	padding: 2vh 2vw;
	border-radius: 4px;
	background: #f0f0f0;
	color: #000;
	font-size: 22px;
	font-style: normal;
	font-weight: 600;
	line-height: 150%;
	letter-spacing: -0.24px;
`;

export const Body = styled.div`
	padding: 2vw;
`;

export const Footer = styled(FlexDisplay)`
	justify-content: flex-end;
	padding: 1vh 1vh 1vh 0;
	gap: 0.5vw;
	border-top: 1px solid #969696;
`;
