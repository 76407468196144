const firstRawData = [
	{
		date: '2018-11-02',
		score: {
			positive: '0.26562205142804',
			negative: '0.12949322698007',
			neutral: '0.60488472288118',
		},
	},
	{
		date: '2019-02-19',
		score: {
			positive: '0.14973304740123',
			negative: '0.08173226772398',
			neutral: '0.76853468363852',
		},
	},
	{
		date: '2019-04-29',
		score: {
			positive: '0.23094229214554',
			negative: '0.15399071440000',
			neutral: '0.61506699472366',
		},
	},
	{
		date: '2019-07-29',
		score: {
			positive: '0.23813006042218',
			negative: '0.20537157700634',
			neutral: '0.55649836143063',
		},
	},
	{
		date: '2019-10-29',
		score: {
			positive: '0.24635635946090',
			negative: '0.17839887480277',
			neutral: '0.57524477057266',
		},
	},
	{
		date: '2020-02-13',
		score: {
			positive: '0.09832884413419',
			negative: '0.08166834073789',
			neutral: '0.82000282129801',
		},
	},
	{
		date: '2020-04-30',
		score: {
			positive: '0.23846392762105',
			negative: '0.19237547198129',
			neutral: '0.56916059600526',
		},
	},
	{
		date: '2020-07-28',
		score: {
			positive: '0.25824557731447',
			negative: '0.21099954533640',
			neutral: '0.53075487625614',
		},
	},
	{
		date: '2021-02-08',
		score: {
			positive: '0.15141144091731',
			negative: '0.10477006972253',
			neutral: '0.74381848798297',
		},
	},
	{
		date: '2021-04-28',
		score: {
			positive: '0.24717290162753',
			negative: '0.13680153371629',
			neutral: '0.61602555784551',
		},
	},
	{
		date: '2021-07-27',
		score: {
			positive: '0.27172907163668',
			negative: '0.16115481662576',
			neutral: '0.56711611178166',
		},
	},
	{
		date: '2021-10-25',
		score: {
			positive: '0.26501802053981',
			negative: '0.13022310448398',
			neutral: '0.60475887422476',
		},
	},
	{
		date: '2022-02-07',
		score: {
			positive: '0.18602476068167',
			negative: '0.10098419937708',
			neutral: '0.71299103603000',
		},
	},
	{
		date: '2022-04-25',
		score: {
			positive: '0.26875827940898',
			negative: '0.14699668210659',
			neutral: '0.58424503759102',
		},
	},
	{
		date: '2022-07-25',
		score: {
			positive: '0.28936266187295',
			negative: '0.14221312802126',
			neutral: '0.56842421133575',
		},
	},
	{
		date: '2022-10-24',
		score: {
			positive: '0.30798883431550',
			negative: '0.14141691773100',
			neutral: '0.55059424336750',
		},
	},
	{
		date: '2023-07-24',
		score: {
			positive: '0.36621280279023',
			negative: '0.18779992321203',
			neutral: '0.44598727826316',
		},
	},
];

export const firstFinalData = firstRawData.map(item => {
	return {
		x: new Date(item?.date),
		y: Number(item.score.positive) - Number(item.score.negative),
		stockCode: 'TSLA',
		score: item.score,
	};
});

const secondRawData = [
	{
		date: '2018-10-25',
		score: {
			positive: '0.20865698031401',
			negative: '0.12776711110977',
			neutral: '0.66357591056287',
		},
	},
	{
		date: '2019-02-07',
		score: {
			positive: '0.19476100848506',
			negative: '0.08871862033210',
			neutral: '0.71652037021236',
		},
	},
	{
		date: '2019-04-26',
		score: {
			positive: '0.11605510600880',
			negative: '0.18640663337880',
			neutral: '0.69753826051440',
		},
	},
	{
		date: '2019-07-26',
		score: {
			positive: '0.10311321816137',
			negative: '0.21447209758224',
			neutral: '0.68241468720779',
		},
	},
	{
		date: '2019-10-25',
		score: {
			positive: '0.15192552142776',
			negative: '0.14845138236896',
			neutral: '0.69962309630716',
		},
	},
	{
		date: '2020-02-06',
		score: {
			positive: '0.13846427537091',
			negative: '0.10884726398882',
			neutral: '0.75268845869094',
		},
	},
	{
		date: '2020-04-28',
		score: {
			positive: '0.12814512524222',
			negative: '0.11732264685000',
			neutral: '0.75453222612037',
		},
	},
	{
		date: '2020-07-28',
		score: {
			positive: '0.10965709288294',
			negative: '0.18773836139324',
			neutral: '0.70260454370765',
		},
	},
	{
		date: '2020-10-27',
		score: {
			positive: '0.13799570352225',
			negative: '0.17484008335972',
			neutral: '0.68716421241070',
		},
	},
	{
		date: '2021-02-04',
		score: {
			positive: '0.12266979644873',
			negative: '0.12564747123569',
			neutral: '0.75168272952101',
		},
	},
	{
		date: '2021-04-27',
		score: {
			positive: '0.16002672603546',
			negative: '0.06699917577490',
			neutral: '0.77297409935100',
		},
	},
	{
		date: '2021-07-27',
		score: {
			positive: '0.21286182304429',
			negative: '0.04559052794321',
			neutral: '0.74154764747964',
		},
	},
	{
		date: '2021-10-26',
		score: {
			positive: '0.19375338183763',
			negative: '0.06878329347670',
			neutral: '0.73746332367133',
		},
	},
	{
		date: '2022-02-09',
		score: {
			positive: '0.09636316942035',
			negative: '0.07438988373274',
			neutral: '0.82924694289381',
		},
	},
	{
		date: '2022-04-26',
		score: {
			positive: '0.09120662462242',
			negative: '0.09487654894753',
			neutral: '0.81391682638251',
		},
	},
	{
		date: '2022-07-27',
		score: {
			positive: '0.09424714572481',
			negative: '0.11502013166589',
			neutral: '0.79073272168023',
		},
	},
	{
		date: '2022-10-25',
		score: {
			positive: '0.09085576296364',
			negative: '0.08362006696982',
			neutral: '0.82552417373527',
		},
	},
];

export const secondFinalData = secondRawData.map(item => {
	return {
		x: new Date(item?.date),
		y: Number(item.score.positive) - Number(item.score.negative),
		stockCode: 'MMM',
		score: item.score,
	};
});
