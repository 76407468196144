import styled from 'styled-components';

export const CompContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	row-gap: 8px;
`;

export const LeftContainer = styled(CompContainer)`
	row-gap: 8px;
`;
