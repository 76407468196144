import moment from 'moment';

const token = localStorage.getItem('token');

export const HOTJAR = {
    siteId: process.env.REACT_APP_HOTJAR_SITE_ID,
    hotjarVersion: process.env.REACT_APP_HOTJAR_SITE_VERSION
}

export const GA = {
    MEASUREMENT_ID: process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID
}

export const DATE_NOW = token ? moment() : moment('2015-12-31 23:59:59.000')

export const MARKET_DROPDOWN_SELECT = [
    {
        label: 'Market US',
        value: 1,
        id: 1,
    },
    {
        label: 'Market HK',
        value: 2,
        id: 2,
    },
];

export const DOCUMENT_TYPE = {
    CALL: 'call',
    FILING: 'filing',
};

export const CALL = [

    {
        label: 'Corporate Filings',
        value: DOCUMENT_TYPE.FILING,
    },
    // {
    // 	label: 'Earnings Calls',
    // 	value: DOCUMENT_TYPE.CALL,
    // }
];

export const COLOR = {
    PRIMARY: '#225FB0',
    SECONDARY: '#56783E',
    ERROR: '#A5492D',
    BORDER: '#DEE2E6',
};

export const AUTHORIZATION_STEP = {
    LOGIN: 'LOGIN',
    REGISTER: 'REGISTER',
    FIND_PASSWORD: 'FIND_PASSWORD',
};

export const PASSWORD_ERROR_CONDITION = {
    ERR1: 'At least 8 characters',
    ERR2: "Can't contain your name or email address",
    ERR3: 'Contains a number or symbol',
};

export const SUBSCRIPTION_TYPE = {
    DEMO: '_xst_u_d',
    BASIC: '_xst_u_b',
    STANDARD: '_xst_u_s',
    DEVELOPER: '_xst_u_de',
    ENTERPRISE: '_xst_u_e',
};

export const LOCAL_STORAGE_VARIABLE = {
    FIRST_TIME_USER: 'first_time_user',
    SUBSCRIPTION_TYPE: '_xst_u',
};

export const AVAILABILITY = {
    AVAILABLE: 'AVAILABLE',
    NOT_AVAILABLE: 'NOT_AVAILABLE',
};

export const SUBSCRIPTION = [
    {
        name: 'Demo',
        value: SUBSCRIPTION_TYPE.DEMO,
        isAvailable: true,
        features: [
            {
                type: AVAILABILITY.AVAILABLE,
                label: 'Registration is not required',
            },
            {
                type: AVAILABILITY.AVAILABLE,
                label: 'Display of limited data',
            },
            {
                type: AVAILABILITY.NOT_AVAILABLE,
                label: 'Full access to the current data',
            },
        ],
    },
    {
        name: 'Basic',
        value: SUBSCRIPTION_TYPE.BASIC,
        isAvailable: true,
        features: [
            {
                type: AVAILABILITY.AVAILABLE,
                label: 'Free 7 days portal access (extendable)',
            },
            {
                type: AVAILABILITY.AVAILABLE,
                label: 'Early access to our future services',
            },
            {
                type: AVAILABILITY.NOT_AVAILABLE,
                label: 'Data API',
            },
        ],
    },
    {
        name: 'Standard',
        value: SUBSCRIPTION_TYPE.STANDARD,
        isAvailable: false,
        features: [
            {
                type: AVAILABILITY.AVAILABLE,
                label: 'Lorem ipsum dolor sit',
            },
            {
                type: AVAILABILITY.NOT_AVAILABLE,
                label: 'Lorem ipsum dolor sit',
            },
        ],
    },
    {
        name: 'Developer',
        value: SUBSCRIPTION_TYPE.DEVELOPER,
        isAvailable: false,
        features: [
            {
                type: AVAILABILITY.AVAILABLE,
                label: 'Lorem ipsum dolor sit',
            },
            {
                type: AVAILABILITY.NOT_AVAILABLE,
                label: 'Lorem ipsum dolor sit',
            },
        ],
    },
    {
        name: 'Enterprise',
        value: SUBSCRIPTION_TYPE.ENTERPRISE,
        isAvailable: false,
        features: [
            {
                type: AVAILABILITY.AVAILABLE,
                label: 'Lorem ipsum dolor sit',
            },
            {
                type: AVAILABILITY.NOT_AVAILABLE,
                label: 'Lorem ipsum dolor sit',
            },
        ],
    },
];

export const ERROR_CODES = {
    VALIDATION_ERROR: 422,
    UNAUTHORIZED_ERROR: 401,
    VERIFICATION_ERROR: 403,
};

export const ROLE = {
    MEMBER: 'member',
};

export const SCREEN_SIZE = {
    MOBILE: 'MOBILE',
    TABLET: 'TABLET',
    LAPTOP: 'LAPTOP',
    DESKTOP: 'DESKTOP',
    LARGE_DESKTOP: 'LARGE_DESKTOP',
};

export const DATE_TAB = {
    ALL: 'All',
    TEN_Y: '10',
    FIVE_Y: '5',
    THREE_Y: '3',
    ONE_Y: '1',
    CUSTOM_DATE: 'custom',
};

export const DATA_TYPE = {
    COMPANY: {
        field: 'company_id',
        value: 'id',
    },
    INDUSTRIES: {
        field: 'industry_code',
        value: 'industry_code',
    },
};

export const REPORT_DATE_RANGE = {
    SIX_MONTH: { label: '6 Months' },
    YEAR: { label: '1 Year' },
    THREE_YEAR: { label: '3 Years' },
    FIVE_YEAR: { label: '5 Years' },
    TEN_YEAR: { label: '10 Years' },
    ALL: { label: 'All' },
    CUSTOM_DATE: { label: 'Custom' },
};

export const COMPARE_CARD_TAB_TYPE = {
    ALL: 'All',
    SECTOR: 'Sector',
    INDUSTRY_GROUP: 'Industry Group',
    INDUSTRY: 'Industry',
    SUB_INDUSTRY: 'Sub Industry',
};

export const COMPARE_TAB = {
    INDUSTRY: 'industries',
    COMPANY: 'company',
};

export const FILTER_TYPE = {
    TOP: 'desc',
    BOTTOM: 'asc',
};

export const SORT_DATE = {
    ALL: {
        value: 'All',
        gte: DATE_NOW.clone().subtract(20, 'years').format('YYYY-MM-DD'),
        lte: DATE_NOW.clone().format('YYYY-MM-DD'),
    },
    TEN_YEARS: {
        value: '10y',
        gte: DATE_NOW.clone().subtract(10, 'years').format('YYYY-MM-DD'),
        lte: DATE_NOW.clone().format('YYYY-MM-DD'),
    },
    FIVE_YEARS: {
        value: '5y',
        gte: DATE_NOW.clone().subtract(5, 'years').format('YYYY-MM-DD'),
        lte: DATE_NOW.clone().format('YYYY-MM-DD'),
    },
    THREE_YEARS: {
        value: '3y',
        gte: DATE_NOW.clone().subtract(3, 'years').format('YYYY-MM-DD'),
        lte: DATE_NOW.clone().format('YYYY-MM-DD'),
    },
    ONE_YEAR: {
        value: '1y',
        gte: DATE_NOW.clone().subtract(1, 'years').format('YYYY-MM-DD'),
        lte: DATE_NOW.clone().format('YYYY-MM-DD'),
    },
};

export const CHART_INTERVAL = {
    DAY: 'Day',
    WEEK: 'Week',
    MONTH: 'Month',
};
