// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useState } from 'react';
import _ from 'lodash';
import { CaretDownFilled, CaretUpFilled } from '@ant-design/icons';
import styled from 'styled-components';

import {
	RightTabContainer,
	RightTabItem,
	SentimentContainer,
	SentimentItem,
	SentimentLabel,
	SentimentPercentage,
} from '../../Screens/SingleReport/SingleReport.styled';
import { ISentencesMapping, ISingleReport } from '../../Screens/SingleReport/SingleReport.types';
import { scrollTo } from '../../Screens/SingleReport/SingleReport.desktop.screen';
import { renderNegativeBackgroundColor, renderPositiveBackgroundColor } from './LeftTab.component';
import { mappingSentences } from './index';
import { FlexDisplaySmallGap } from '../../Screens/Preferences/Preferences.styled';
import { Popover } from '..';


export const SENTIMENT = {
	POSITIVE: 'Positive',
	NEGATIVE: 'Negative',
	NEUTRAL: 'Neutral',
};

export const UpDownIconWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	& > * {
		margin: -2px;
	}
`;

export const renderPercentage = (
	item: ISentencesMapping,
	sentimentTab: string
) => {
	let percentage: number | undefined = 0;

	if (sentimentTab === SENTIMENT.POSITIVE) {
		percentage = item.scores?.positive;
	}

	if (sentimentTab === SENTIMENT.NEGATIVE) {
		percentage = item.scores?.negative;
	}

	if (sentimentTab === SENTIMENT.NEUTRAL) {
		percentage = item.scores?.neutral;
	}

	return (Number(percentage) * 100).toFixed(2);
};

type ISort = {
	asc: boolean;
	desc: boolean;
};

const renderSentimentTable = (
	sentimentTab: string,
	neutralSentiments,
	positiveSentiments,
	negativeSentiments,
	sortPositive: ISort,
	sortNegative: ISort,
	sortNeutral: ISort
) => {
	let itemToRender = neutralSentiments;

	if (sentimentTab === SENTIMENT.NEUTRAL) {
		if (sortNeutral.asc) {
			itemToRender = itemToRender.sort(
				(a, b) => a?.scores?.neutral - b?.scores?.neutral
			);
		}
		if (sortNeutral.desc) {
			itemToRender = itemToRender.sort(
				(a, b) => b?.scores?.neutral - a?.scores?.neutral
			);
		}
	}

	if (sentimentTab === SENTIMENT.POSITIVE) {
		itemToRender = positiveSentiments;

		if (sortPositive.asc) {
			itemToRender = itemToRender.sort(
				(a, b) => a?.scores?.positive - b?.scores?.positive
			);
		}
		if (sortPositive.desc) {
			itemToRender = itemToRender.sort(
				(a, b) => b?.scores?.positive - a?.scores?.positive
			);
		}
	}

	if (sentimentTab === SENTIMENT.NEGATIVE) {
		itemToRender = negativeSentiments;

		if (sortNegative.asc) {
			itemToRender = itemToRender.sort(
				(a, b) => a?.scores?.negative - b?.scores?.negative
			);
		}
		if (sortPositive.desc) {
			itemToRender = itemToRender.sort(
				(a, b) => b?.scores?.negative - a?.scores?.negative
			);
		}
	}

	return (
		<SentimentContainer>
			{itemToRender?.map((item: ISentencesMapping, index) => {
				let background = '#e8e8e8';
				if (sentimentTab === SENTIMENT.POSITIVE)
					background = renderPositiveBackgroundColor(item.scores);
				if (sentimentTab === SENTIMENT.NEGATIVE)
					background = renderNegativeBackgroundColor(item.scores);

				return (
					<SentimentItem key={index}>
						<SentimentPercentage
							width={3}
							color="#212529"
							backgroundColor={background}>
							{renderPercentage(item, sentimentTab)}%
						</SentimentPercentage>
						<SentimentLabel
							className="cursor-pinter trim-text"
							onClick={() => {
								scrollTo(item?.sentenceId);
							}}>
							{item?.sentence}
						</SentimentLabel>
					</SentimentItem>
				);
			})}
		</SentimentContainer>
	);
};

export const iconStyle = (isSelected: boolean) => {
	return {
		color: isSelected ? 'black' : 'grey',
	};
};

const SentimentComponent = (props: ISingleReport) => {
	const [sentimentTab, setSentimentTab] = useState(SENTIMENT.POSITIVE);
	const [sortPositive, setSortPositive] = useState({
		asc: false,
		desc: true,
	});
	const [sortNegative, setSortNegative] = useState({
		asc: false,
		desc: true,
	});
	const [sortNeutral, setSortNeutral] = useState({
		asc: false,
		desc: true,
	});

	const sentiments = mappingSentences(props?.documents);

	const neutralSentiments = _.filter(
		sentiments,
		(item: ISentencesMapping) => item?.scores?.sentiment === 'Neutral'
	);
	const positiveSentiments = _.filter(
		sentiments,
		(item: ISentencesMapping) => item?.scores?.sentiment === 'Positive'
	);
	const negativeSentiments = _.filter(
		sentiments,
		(item: ISentencesMapping) => item?.scores?.sentiment === 'Negative'
	);

	const onChangeTab = (value: string) => {
		setSentimentTab(value);
	};

	return (
		<div className="mt-4">
			<RightTabContainer className="position-relative">
				<div className={`position-absolute end-0 top-0 px-1`}>
					<Popover
						className='ps-1'
						popoverTextHeader="**Sentiment Detail View**"
						popoverTextBody={`Lists sentences sorted by their sentiment scores.`}
					/>
				</div>
				<RightTabItem
					onClick={() => {
						onChangeTab(SENTIMENT.POSITIVE);
					}}
					selected={sentimentTab === SENTIMENT.POSITIVE}>
					<FlexDisplaySmallGap>
						<div>Positive ({positiveSentiments?.length})</div>
						<UpDownIconWrapper>
							<CaretUpFilled
								style={iconStyle(sortPositive.asc)}
								onClick={() => {
									setSortPositive({
										asc: true,
										desc: false,
									});
								}}
							/>
							<CaretDownFilled
								style={iconStyle(sortPositive.desc)}
								onClick={() => {
									setSortPositive({
										asc: false,
										desc: true,
									});
								}}
							/>
						</UpDownIconWrapper>
					</FlexDisplaySmallGap>
				</RightTabItem>
				<RightTabItem
					onClick={() => {
						onChangeTab(SENTIMENT.NEGATIVE);
					}}
					selected={sentimentTab === SENTIMENT.NEGATIVE}>
					<FlexDisplaySmallGap>
						<div>Negative ({negativeSentiments?.length})</div>
						<UpDownIconWrapper>
							<CaretUpFilled
								style={iconStyle(sortNegative.asc)}
								onClick={() => {
									setSortNegative({
										asc: true,
										desc: false,
									});
								}}
							/>
							<CaretDownFilled
								style={iconStyle(sortNegative.desc)}
								onClick={() => {
									setSortNegative({
										asc: false,
										desc: true,
									});
								}}
							/>
						</UpDownIconWrapper>
					</FlexDisplaySmallGap>
				</RightTabItem>
				<RightTabItem
					onClick={() => {
						onChangeTab(SENTIMENT.NEUTRAL);
					}}
					selected={sentimentTab === SENTIMENT.NEUTRAL}>
					<FlexDisplaySmallGap>
						<div>Neutral ({neutralSentiments?.length})</div>
						<UpDownIconWrapper>
							<CaretUpFilled
								style={iconStyle(sortNeutral.asc)}
								onClick={() => {
									setSortNeutral({
										asc: true,
										desc: false,
									});
								}}
							/>
							<CaretDownFilled
								style={iconStyle(sortNeutral.desc)}
								onClick={() => {
									setSortNeutral({
										asc: false,
										desc: true,
									});
								}}
							/>
						</UpDownIconWrapper>
					</FlexDisplaySmallGap>
				</RightTabItem>
			</RightTabContainer>
			{renderSentimentTable(
				sentimentTab,
				neutralSentiments,
				positiveSentiments,
				negativeSentiments,
				sortPositive,
				sortNegative,
				sortNeutral
			)}
		</div>
	);
};

export default SentimentComponent;
