import { useState } from 'react';
import styled, { css } from 'styled-components';

import {
	FlexDisplayCenter,
	FlexDisplaySpaceBetween,
} from '../../Screens/Preferences/Preferences.styled';
import warningIcon from '../../Assets/Icon/warning-icon.svg';
import closeIcon from '../../Assets/Icon/close-icon.svg';
import { IAuthorizationProps } from '../Authorization/Authorization.types';
import { mobile, tablet } from '../../Utils/layout.utils';

const Container = styled.div`
	background: #091d42;
	
	${mobile(css`
		top: 40px;
		position: sticky;
	`)};

	${tablet(css`
		top: 40px;
		position: sticky;
	`)};
`;

const Caption = styled.p`
	color: #fff;
	font-size: 12px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	padding: 0;
	margin: 0;
	cursor: pointer;

	${tablet(css`
		font-size: 10px;
	`)};

	${mobile(css`
		font-size: 7px;
	`)};
`;

const Highlight = styled.span`
	color: #dbd31f;
`;

const WarningIcon = styled.img`
	width: 1.2vw;

	${mobile(css`
		width: 3vw;
	`)};
`;

const CloseIcon = styled.img`
	width: 0.8vw;

	${mobile(css`
		width: 1.5vw;
	`)};
`;

const DemoAccountAlertComponent = (props: IAuthorizationProps) => {
	const [isOpen, setIsOpen] = useState(true);

	const { setIsSubscriptionModal } = props;

	const isLoggedIn = !!localStorage.getItem('token');
	if (!isOpen || isLoggedIn) {
		return <></>;
	}

	return (
		<Container className="py-2 px-3" onClick={() => {
			setIsSubscriptionModal(true);
		}} style={{ cursor: 'pointer', zIndex: 500 }}
		>
			<FlexDisplaySpaceBetween>
				<FlexDisplayCenter>
					<WarningIcon src={warningIcon} alt="icon" />
					<Caption>
						You are using a limited <Highlight>DEMO</Highlight> version of Finsent.
						Click here to explore the available features in the demo version,
						or <Highlight>REGISTER</Highlight> now for <Highlight>FULL</Highlight> access!
					</Caption>
				</FlexDisplayCenter>
				{/* <CloseIcon
					src={closeIcon}
					alt="icon"
					className="cursor-pinter"
					onClick={() => setIsOpen(false)}
				/> */}
			</FlexDisplaySpaceBetween>
		</Container>
	);
};

export default DemoAccountAlertComponent;
