import React from 'react';
import { Col } from 'react-bootstrap';

import LeftTabComponent from '../../Components/SingleReport/LeftTab.component';
import RightTabComponent from '../../Components/SingleReport/RightTab.component';
import SentimentComponent from '../../Components/SingleReport/Sentiment.component';
import FilingsComponent from '../../Components/SingleReport/Filings.component';

import {
	BackIcon,
	BackIconContainer,
	Container,
	ReportTitle,
	ReportTitleContainer,
} from './SingleReport.styled';
import { FlexDisplaySmallGap } from '../Preferences/Preferences.styled';

import { ISingleReport } from './SingleReport.types';
import {
	renderDisclaimer,
	renderInformation,
} from '../../Components/SingleReport';

export const scrollTo = (id: string | undefined) => {
	const sentenceId = id || '';

	const element = document.getElementById(String(sentenceId));

	if (element) {
		element.scrollIntoView({
			behavior: 'smooth',
			block: 'nearest',
		});
		window.history.pushState(null, '', `#${sentenceId}`);
	}
};

const SingleReportDesktop = (props: ISingleReport) => {
	const { documents } = props;

	return (
		<React.Fragment>
			<div className="align-items-stretch d-flex">
				<Col
					className="rounded"
					style={{ position: 'relative' }}>
					<div className="bg-light rounded">
						<ReportTitleContainer>
							<FlexDisplaySmallGap>
								<BackIconContainer>
									{/* <BackIcon /> */}
								</BackIconContainer>
								<ReportTitle>{documents?.document?.title}</ReportTitle>
							</FlexDisplaySmallGap>
						</ReportTitleContainer>
						<LeftTabComponent {...props} />
					</div>
					<div className="margin-top-2">{renderInformation(props)}</div>
					<div className="margin-top-8">
						{renderDisclaimer(false)}
					</div>
				</Col>

				<Container
					md={3}
					className="rounded margin-left-8"
					style={{ position: 'sticky', top: '0', minHeight: '100%' }}>
					<div
						className="bg-light rounded px-3 py-2 h-100"
					>
						<RightTabComponent {...props} isCompanyPage={false} />
						<SentimentComponent {...props} isCompanyPage={false} />
						<FilingsComponent {...props} />
					</div>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default SingleReportDesktop;
