import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import _ from 'lodash';
import moment from 'moment';

import HomeDesktop from './Home.desktop.screen';
import HomeMobile from './Home.mobile.screen';
import servicesRequest, {
	COMPANY_URL,
	DOCUMENT_LIST_URL,
	INDUSTRY_LIST_URL,
	KEYWORD_FREQUENCIES_URL,
	KEYWORD_LIST_URL,
	SCORE_LIST_URL,
	WATCHLIST_URL,
} from '../../Config/services.config';
import { LIST_TYPE } from '../Industries/Industries.screen';
import { getDefaultCalls, getDefaultMarket } from '../../Utils';
import { DATE_NOW, SCREEN_SIZE } from '../../Constants';
import useResizeScreen from '../../Hooks/useResizeScreen';

export const LIST_TYPE_SECTOR = {
	COMPANIES: 'company-list',
	INDUSTRIES: 'industry-list',
};

const HomeScreen = () => {
	const [trendingReports, setTrendingReports] = useState(null);
	const [trendingData, setTrendingData] = useState(null);
	const [selectedType, setSelectedType] = useState(LIST_TYPE.TRENDING);
	const [selectedListType, setSelectedListType] = useState(
		LIST_TYPE_SECTOR.COMPANIES
	);
	const [watchlists, setWatchlists] = useState(null);
	const [selectedWatchlist, setSelectedWatchlist] = useState(null);
	const [watchlistDetails, setWatchlistDetails] = useState([]);
	const [selectedWatchlistDetails, setSelectedWatchlistDetails] =
		useState(null);
	const [keywords, setKeywords] = useState(null);
	const [isShowWatchlistModal, setIsShowWatchlistModal] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	const [filter, setFilter] = useState(null);

	const [searchParams] = useSearchParams();
	const documentType = searchParams.get('document_type') || getDefaultCalls();
	const trendingReportsPage = searchParams.get('trending-reports-page') || '1';
	const companyChartPage = searchParams.get('company-page') || '1';
	const watchlistPage = searchParams.get('watchlist-page') || '1';

	const [isLoadingWatchlist, setIsLoadingWatchlist] = useState(false);
	const [isLoadingKeywords, setIsLoadingKeywords] = useState(false);

	const token = localStorage.getItem('token');

	const screenType = useResizeScreen();

	const navigate = useNavigate();

	const fetchingTrendingReports = async () => {
		try {
			setTrendingReports(null);

			const request = servicesRequest();

			const url = !filter
				? DOCUMENT_LIST_URL +
				`/trending?document_type=${documentType}&country_id=${getDefaultMarket()}&page=${trendingReportsPage}`
				: DOCUMENT_LIST_URL +
				`/trending?document_type=${documentType}&country_id=${getDefaultMarket()}&page=${trendingReportsPage}&order_by=${filter}`;

			const {
				data: { data },
			} = await request.get(url);

			setTrendingReports(data);
		} catch (error) {
			console.error('Something went wrong. Please check and try again.');
		}
	};

	const fetchingScoreList = async (payload: any) => {

		const getTrending: any[] = _.get(payload, 'data', []);

		let companyId = '';

		getTrending?.forEach((item, index) => {
			if (index > 0) {
				companyId += ',';
			}
			companyId += item.id;
		});

		const idParams =
			selectedListType === LIST_TYPE_SECTOR.COMPANIES
				? 'company_id'
				: 'industry_id';

		try {
			const request = servicesRequest();

			let url =
				SCORE_LIST_URL +
				`?document_type=${documentType}&${idParams}=${companyId}`;
			url += `&document_date.gte=${DATE_NOW
				.clone()
				.subtract(5, 'years')
				.format('YYYY-MM-DD')}&document_date.lte=${DATE_NOW.clone().format('YYYY-MM-DD')}`;
			const {
				data: { data },
			} = await request.get(url);

			payload.data = getTrending?.map((item: { id: string }) => {
				const scores = data.filter(
					(x: { filter: { company_id: string, industry_id: string } }) =>
						(x.filter.company_id === item.id || x.filter.industry_id === item.id)
				);
				// if (token) {
				return {
					...item,
					scores: scores?.[0]?.scores,
				};
				// } else {
				// 	return {
				// 		...item,
				// 		scores: scores?.[0]?.scores[documentType].filter(
				// 			(score: { date: string }) =>
				// 				moment(score.date).isBetween('2010-01-01', '2015-12-31', undefined, '[]')),
				// 	};
				// }
			});

			setTrendingData(payload);
		} catch (error) {
			console.error('Something went wrong. Please check and try again.');
		}
	};

	const fetchingTrending = async () => {
		try {
			const url =
				selectedListType === LIST_TYPE_SECTOR.COMPANIES
					? COMPANY_URL
					: INDUSTRY_LIST_URL;

			const request = servicesRequest();
			const {
				data: { data },
			} = await request.get(
				url +
				`/${selectedType}?document_type=${documentType}&country_id=${getDefaultMarket()}&limit=10&page=${companyChartPage}`
			);

			await fetchingScoreList(data);
		} catch (error) {
			console.error('Something went wrong. Please check and try again.');
		}
	};

	const createParams = (payload: {
		trendingReportsParam?: string;
		companyPageParam?: string;
	}) => {
		const trendingReportsParam = _.get(
			payload,
			'trendingReportsParam',
			trendingReportsPage
		);
		const companyChartParam = _.get(
			payload,
			'companyPageParam',
			companyChartPage
		);

		return `?document_type=${documentType}&trending-reports-page=${trendingReportsParam}&company-page=${companyChartParam}`;
	};

	const onChangePage = (payload: any) => {
		navigate(createParams(payload));
	};

	const fetchingKeywords = async (detail: any, page = 1) => {
		const stockCode: string = _.get(detail, 'code', '');
		setIsLoadingKeywords(true);
		try {
			const request = servicesRequest();

			const {
				data: { data },
			} = await request.get(
				KEYWORD_LIST_URL +
				`?document_type=${documentType}&company_stock_code=${stockCode}&page=${page}`
			);
			setKeywords(data);
		} catch (error) {
			console.error('Something went wrong. Please check and try again.');
		} finally {
			setIsLoadingKeywords(false);
		}
	};

	const fetchingWatchlistDetail = async (detail: any, page = 1) => {
		const watchlistId: string = _.get(detail, 'id', '');
		try {
			const request = servicesRequest();
			const {
				data: { data },
			} = await request.get(
				WATCHLIST_URL + `/${watchlistId}/details?page=${page}&document_type=${documentType}`
			);

			setWatchlistDetails(data);

			if (data.data.length > 0) {
				const defaultSelectedWatchlistDetail = data.data[0];
				setSelectedWatchlistDetails(defaultSelectedWatchlistDetail);
				await fetchingKeywords(defaultSelectedWatchlistDetail);
			}
		} catch (error) {
			console.error('Something went wrong. Please check and try again.');
		}
	};

	const fetchingWatchlist = async () => {
		setIsLoadingWatchlist(true);
		try {
			const request = servicesRequest();
			const {
				data: { data },
			} = await request.get(
				WATCHLIST_URL +
				`?document_type=${documentType}&country_id=${getDefaultMarket()}&page=${watchlistPage}`
			);

			setWatchlists(data);

			if (data.data.length > 0) {
				const defaultSelectedWatchlist = data.data[0];

				setSelectedWatchlist(defaultSelectedWatchlist);
				await fetchingWatchlistDetail(defaultSelectedWatchlist);
			}
		} catch (error) {
			console.error('Something went wrong. Please check and try again.');
		} finally {
			setIsLoadingWatchlist(false);
		}
	};

	const addToWatchlists = async (data: any) => {
		const selectedWatchlistId = _.get(selectedWatchlist, 'id', '');
		const payload = {
			related_id: data?.id,
			type: data?.industry_code ? 'industry' : 'company',
		};

		try {
			const request = servicesRequest();
			await request.post(
				WATCHLIST_URL + `/${selectedWatchlistId}/details`,
				payload
			);

			toast.success(`${data?.name} is added to your watchlist.`);
			await fetchingWatchlistDetail(selectedWatchlist);
		} catch (error) {
			console.error('Something went wrong. Please check and try again.');
		}
	};

	const deleteFromWatchlists = async (data: any) => {
		const selectedWatchlistId = _.get(selectedWatchlist, 'id', '');

		try {
			const request = servicesRequest();
			await request.delete(
				WATCHLIST_URL + `/${selectedWatchlistId}/details/${data?.id}`
			);

			toast.success(`${data?.name} is deleted from your watchlist.`);
			await fetchingWatchlistDetail(selectedWatchlist);
		} catch (error) {
			console.error('Something went wrong. Please check and try again.');
		}
	};

	const deleteWatchlist = async (data: any) => {
		const watchlistId = _.get(data, 'id', '');

		try {
			const request = servicesRequest();
			await request.delete(WATCHLIST_URL + `/${watchlistId}`);

			toast.success(`${data?.name} is deleted.`);
			await fetchingWatchlist();
		} catch (error) {
			console.error('Something went wrong. Please check and try again.');
		}
	};

	const addWatchlist = async (data: any) => {
		try {
			const request = servicesRequest();
			await request.post(WATCHLIST_URL, data);

			toast.success(`${data?.name} is added.`);
			await fetchingWatchlist();
		} catch (error) {
			console.error('Something went wrong. Please check and try again.');
		}
	};

	const updateWatchlist = async (
		watchlistId: string,
		payload: { name: string }
	) => {
		try {
			const request = servicesRequest();
			await request.put(WATCHLIST_URL + `/${watchlistId}`, payload);

			toast.success(`Watchlist name is updated.`);
			await fetchingWatchlist();
		} catch (error) {
			console.error('Something went wrong. Please check and try again.');
		}
	};

	const getKeywordFrequencies = async (keyword: string) => {
		const code = _.get(selectedWatchlistDetails, 'code', '');
		try {
			const request = servicesRequest();
			const {
				data: { data },
			} = await request.get(
				KEYWORD_FREQUENCIES_URL +
				`?document_type=${documentType}&keyword=${keyword}&company_stock_code=${code}&document_date.gte=${DATE_NOW
					.clone()
					.subtract(5, 'years')
					.format('YYYY-MM-DD')}&document_date.lte=${DATE_NOW.clone().format('YYYY-MM-DD')}`
			);

			return data;
		} catch (error) {
			console.error('Something went wrong. Please check and try again.');
			return null;
		}
	};

	useEffect(() => {
		fetchingTrendingReports().catch(console.error);
	}, [documentType, trendingReportsPage, filter]);

	useEffect(() => {
		setTrendingData(null);
		fetchingTrending().catch(console.error);
	}, [documentType, selectedType, selectedListType, companyChartPage]);

	useEffect(() => {
		if (token) {
			fetchingWatchlist().catch(console.error);
		}
	}, [documentType, watchlistPage]);

	const onChangeWatchlistDetail = async (item: any) => {
		setSelectedWatchlistDetails(item);
		await fetchingKeywords(item);
	};

	const onChangeWatchlist = async (item: any) => {
		setSelectedWatchlist(item);
		await fetchingWatchlistDetail(item);
	};

	const onSearch = (value: string) => {
		setSearchValue(value);
	};

	const onClickCard = (isCompany: boolean, id: string | number) => {
		if (isCompany) {
			navigate(
				`/companies?document_type=${documentType}&country_id=${getDefaultMarket()}&company_id=${id}`
			);
		} else {
			navigate(
				`/industries?document_type=${documentType}&country_id=${getDefaultMarket()}&industry_code=${id}`
			);
		}
	};

	const props: any = () => ({
		trendingReports,
		onChangePage,
		trendingData,
		documentType,
		selectedType,
		setSelectedType,
		selectedListType,
		setSelectedListType,
		watchlists,
		selectedWatchlist,
		setSelectedWatchlist,
		watchlistDetails,
		fetchingWatchlistDetail,
		keywords,
		fetchingKeywords,
		selectedWatchlistDetails,
		setSelectedWatchlistDetails,
		onChangeWatchlistDetail,
		onChangeWatchlist,
		addToWatchlists,
		deleteFromWatchlists,
		deleteWatchlist,
		addWatchlist,
		updateWatchlist,
		getKeywordFrequencies,
		isShowWatchlistModal,
		setIsShowWatchlistModal,
		searchValue,
		setSearchValue,
		onSearch,
		filter,
		setFilter,
		onClickCard,
		isLoadingWatchlist,
		isLoadingKeywords,
	});

	return (
		<>
			{screenType === SCREEN_SIZE.TABLET ||
				screenType === SCREEN_SIZE.MOBILE ? (
				<HomeMobile {...props()} />
			) : (
				<HomeDesktop {...props()} />
			)}
		</>
	);
};

export default HomeScreen;
