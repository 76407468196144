// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useRef, useEffect } from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';

export interface ReactEChartsProps {
	sentiment?: any;
	returnData?: any;
	maxAxisValue?: any;
	selectedLegend?: any;
}

const SentimentChart = ({
	sentiment,
	returnData,
	maxAxisValue,
	selectedLegend,
}: ReactEChartsProps): JSX.Element => {
	const chart1Ref = useRef(null);
	const chart2Ref = useRef(null);

	useEffect(() => {
		if (chart1Ref.current && chart2Ref.current) {
			const chart1 = chart1Ref.current.chart;
			const chart2 = chart2Ref.current.chart;

			const syncCharts = e => {
				if (e.trigger !== 'syncCharts') {
					chart2.xAxis[0].setExtremes(e.min, e.max, undefined, false, {
						trigger: 'syncCharts',
					});
					chart1.xAxis[0].setExtremes(e.min, e.max, undefined, false, {
						trigger: 'syncCharts',
					});
				}
			};

			// Ensure events object is present in xAxis configuration
			chart1.update({
				xAxis: {
					events: {
						afterSetExtremes: syncCharts,
					},
				},
			});

			chart2.update({
				xAxis: {
					events: {
						afterSetExtremes: syncCharts,
					},
				},
			});
		}
	}, [chart1Ref, chart2Ref]);

	const generateSeries = data => {
		return data.map((d, index) => {
			const chartData = d.map(item => {
				return [item.x.getTime(), Number(item.y)];
			});

			return {
				name: d[0]?.stockCode,
				data: chartData || [[]],
				tooltip: {
					valueDecimals: 2,
				},
				visible: selectedLegend[`${d[0]?.stockCode}-${d[0]?.document}`],
				color: d[0]?.color,
			};
		});
	};

	const getOptions = (chartName, data) => {
		const defaultOptions = {
			chart: {
				height: ((sentiment.length === 0 || returnData.length === 0) || (sentiment.length === 1 && sentiment[0]?.length === 0) || (returnData.length === 1 && returnData[0]?.length === 0)) ? '600vh' : '300vh'
			},
			title: {
				text: chartName,
				align: 'left',
				float: true,
			},
			rangeSelector: {
				enabled: false,
			},
			navigator: {
				enabled: false, // Set to false to hide the navigator
			},
			xAxis: {
				ordinal: false,
				type: 'datetime',
				crosshair: true,
				showEmpty: true,
				max: Date.parse(maxAxisValue),
			},
			yAxis: {
				labels: {
					format: '{value}%'
				},
				crosshair: true,
				showEmpty: true,
				endOnTick: true,
				tickInterval: 30,
				opposite: false,
			},
			series: generateSeries(data),
			scrollbar: {
				enabled: false
			},
			credits: {
				enabled: false
			}
		};
		if (chartName === 'Sentiment') {
			return {
				...defaultOptions,
				tooltip: {
					pointFormat:
						'<span style="color:{series.color}">{series.name}</span>: <b>{point.y}%</b><br/>',
					valueDecimals: 2,
					split: true,
				},
			};
		}
		return {
			...defaultOptions,
			plotOptions: {
				series: {
					compare: 'percent',
					showInNavigator: true,
				},
			},
			tooltip: {
				pointFormat: `<span style="color:{series.color}">{series.name}</span>: <b>{point.y}%</b> ({point.change}%)<br/>`,
				valueDecimals: 2,
				split: true,
			},
		};
	};

	Highcharts.setOptions({
		lang: {
			thousandsSep: ','
		}
	})

	return (
		<div>
			{sentiment.length > 0 && sentiment[0].length > 0 && <HighchartsReact
				constructorType={'stockChart'}
				highcharts={Highcharts}
				options={getOptions(
					'Sentiment',
					sentiment
				)}
				ref={chart2Ref}
			/>}
			{returnData.length > 0 && returnData[0].length > 0 && <HighchartsReact
				constructorType={'stockChart'}
				highcharts={Highcharts}
				options={getOptions(
					'Returns',
					returnData
				)}
				ref={chart1Ref}
			/>}
		</div>
	);
};
export default SentimentChart;
