import styled, { css } from 'styled-components';
import React, { useState } from 'react';
import { Offcanvas } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { Select } from 'antd';
import { useLocation } from 'react-router-dom';
import { Button } from '../../../Components/index';

import { INavigationBar } from '../NavigationBar.types';
import {
	FlexDisplayMediumGap,
	FlexDisplaySmallGap,
	FlexDisplaySpaceBetween,
} from '../../../Screens/Preferences/Preferences.styled';
import {
	BarsIcon,
	CloseIcon,
	NavLink,
	RightArrowIcon,
} from '../NavigationBar.styled';
import { desktop, tablet } from '../../../Utils/layout.utils';
import { MENU } from '../Desktop';
import Logo from '../../../Assets/logo.png';
import { LogoContainer } from '../NavigationBar.component';
import { CALL, MARKET_DROPDOWN_SELECT } from '../../../Constants';
import USAFlag from '../../../Assets/usa-icon.svg';
import HKFlag from '../../../Assets/hk-icon.svg';
import CallIcon from '../../../Assets/call.svg';
import FilingIcon from '../../../Assets/filing.svg';
import { ROUTES } from '../../../Constants/routes';
import AutoCompleteComponent from '../../Compare/AutoComplete';

const { Option } = Select;

const Container = styled.div`
	padding: 1vh 2vw;

	${desktop(css`
		display: none;
	`)};

	${tablet(css`
		display: block;
	`)};
`;

const Title = styled.div`
	color: #4e4e4e;
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: 20px;
`;

const Menu = styled.div`
	color: #4e4e4e;
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
`;

const MenuContainer = styled(FlexDisplaySpaceBetween)`
	border-bottom: 1px solid #e0e0e0;
	padding: 2vh 3vw;
`;

const CallFilingContainer = styled(FlexDisplayMediumGap)`
	border-radius: 4px;
	background: #e0ecfc;
	padding: 0 6px;
	align-items: center;
`;

const NavigationMobileComponent = (props: INavigationBar) => {
	const navigate = useNavigate();

	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const {
		setIsOpenAuthorization,
		isLoggedIn,
		selectedMarket,
		handleChangeMarket,
		selectedCall,
		changeDocumentType,
		onClickConfirm,
		showDocumentDropdown,
		showMarketDropdown,
	} = props;

	const MENU_MOBILE = () => {
		if (isLoggedIn) {
			return [
				...MENU,
				{
					label: 'Preferences',
					value: 'preferences',
					route: ROUTES.PREFERENCES,
				},
			]
		} else {
			return MENU
		}
	}
	const renderNavigation = () => {
		return MENU_MOBILE().map((item, index) => (
			<NavLink href={`${item.route}`} key={index} selected={false}>
				<MenuContainer key={index}>
					<Menu>{item?.label}</Menu>
					<RightArrowIcon />
				</MenuContainer>
			</NavLink>
		));
	};

	const handleClick = () => {
		if (isLoggedIn) {
			handleShow();
		} else {
			setIsOpenAuthorization(true);
			return (
				<div className="mt-2">
					<Button
						label="Sign in / Register"
						onClick={() => {
							setIsOpenAuthorization(true);
						}}
						mobilePadding="1.5vw"
					/>
				</div>
			)
		}
	};

	return (
		<React.Fragment>
			<Container className="position-sticky bg-white w-100 top-0" style={{ boxShadow: '5px 5px 10px 0 rgba(0, 0, 0, 0.04)', height: '40px', zIndex: '500' }}>
				<FlexDisplaySpaceBetween>
					<FlexDisplaySmallGap>
						<FlexDisplaySmallGap>
							<LogoContainer
								src={Logo}
								alt="FS"
								className="cursor-pointer"
								onClick={() => {
									navigate('/');
								}}
							/>
							{props?.mobileTitle && <Title>{props?.mobileTitle}</Title>}
							<CallFilingContainer onClick={handleShow}>
								<img
									src={
										selectedMarket?.id === MARKET_DROPDOWN_SELECT[0].id
											? USAFlag
											: HKFlag
									}
									alt="market icon"
								/>
								<div>|</div>
								<img
									src={selectedCall === CALL[0] ? FilingIcon : CallIcon}
									alt="call icon"
								/>
							</CallFilingContainer>
						</FlexDisplaySmallGap>
					</FlexDisplaySmallGap>
					<div>
						<BarsIcon onClick={handleShow} />
					</div>
				</FlexDisplaySpaceBetween>
			</Container>
			<Offcanvas show={show} onHide={handleClose}>
				<Container>
					<FlexDisplaySpaceBetween>
						<LogoContainer
							src={Logo}
							alt="FS"
							className="cursor-pointer"
							onClick={() => {
								navigate('/');
							}}
						/>
						<Title>{props?.mobileTitle}</Title>
						<div>
							<CloseIcon onClick={handleClose} />
						</div>
					</FlexDisplaySpaceBetween>
				</Container>

				<div className="p-2">
					<FlexDisplaySmallGap>
						{showMarketDropdown && (
							<Select
								labelInValue
								defaultValue={selectedMarket}
								style={{ width: '100%' }}
								onSelect={handleChangeMarket}>
								{MARKET_DROPDOWN_SELECT.map(option => (
									<Option
										key={option.value}
										value={option.value}
										label={option.label}>
										<FlexDisplaySmallGap>
											<div>
												<img
													src={option.value === 1 ? USAFlag : HKFlag}
													alt={option.value.toString()}
												/>
											</div>
											<div>
												<span className="ant-select-selection-item">
													{option.label}
												</span>
											</div>
										</FlexDisplaySmallGap>
									</Option>
								))}
							</Select>
						)}

						{showDocumentDropdown && (
							<Select
								labelInValue
								defaultValue={selectedCall}
								style={{ width: '100%' }}
								options={CALL}
								onSelect={(e: any) => {
									changeDocumentType(e);
								}}
							/>
						)}
					</FlexDisplaySmallGap>
					<div className="mt-1">
						<AutoCompleteComponent
							onClickConfirm={onClickConfirm}
							additionalAction={handleClose}
						/>
					</div>
				</div>

				{renderNavigation()}
				{!isLoggedIn ?
					<div className="mx-2 my-2">
						<Button
							label="Sign in / Register"
							onClick={() => {
								setIsOpenAuthorization(true);
							}}
						/>
					</div>
					: null}
			</Offcanvas>
		</React.Fragment>
	);
};

export default NavigationMobileComponent;
