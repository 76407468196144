import React, { useState, useEffect, ChangeEvent } from 'react';
import styled from 'styled-components';
import { Controller, useForm } from 'react-hook-form';
import axios, { AxiosInstance } from 'axios';
import { toast } from 'react-toastify';
import _ from 'lodash';

import { ModalTitle } from '../SignIn/SignIn.styled';
import { DisclaimerLabel } from '../Authorization/Authorization.styled';
import { FlexDisplayCenter } from '../../Screens/Preferences/Preferences.styled';
import { Button } from '../index';
import emailImage from '../../Assets/email-confirmation.svg';
import { Image } from '../FirstTimeModal/FirstTimeModal.component';
import servicesRequest, {
	USER_VERIFICATION_URL,
	EMAIL_VERIFICATION_REQUEST_URL,
} from '../../Config/services.config';
import {
	ErrorResponse,
	IVerificationModalProps,
} from './VerificationModal.types';
import { ERROR_CODES } from '../../Constants';

const Label = styled(DisclaimerLabel)`
	font-size: 14px;
	margin-top: 2vh;
	font-weight: 500;
	color: #4e4e4e;
`;

const Input = styled.input`
	width: 100%;
	justify-content: center;
	align-items: center;
	border-radius: 4px;
	border: 2px solid #efefef;
	text-align: center;
	color: #a6a6a6;
	font-size: 36px;
	font-style: normal;
	font-weight: 600;
	margin-bottom: 2vh;

	&:focus {
		outline: none;
		box-shadow: none !important;
	}
`;

const VerificationModalComponent = (
	props: IVerificationModalProps
): React.JSX.Element => {
	const { email, showDiscoverTutorialModal, onHide, changeBackdropBehaviour } = props;

	const formContext = useForm({
		defaultValues: {
			otp: '',
		},
	});
	const { control } = formContext;

	const [countdown, setCountdown] = useState(0);
	const [eligibleRequestOTP, setEligibleRequestOTP] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isLoadingRequest, setIsLoadingRequest] = useState(false);

	const handleSubmitOTP = async () => {
		setIsLoading(true);
		try {
			const otp = formContext.getValues('otp');
			// const request: AxiosInstance = servicesRequest();
			await axios.post(USER_VERIFICATION_URL, {
				token: otp,
				email,
			});

			onHide();
			showDiscoverTutorialModal();
		} catch (error) {
			const err = error as ErrorResponse;
			const statusCode = _.get(err, 'response.data.status_code');
			const message = _.get(err, 'response.data.message');

			if (statusCode === ERROR_CODES.VERIFICATION_ERROR) {
				toast.error(message);
			} else {
				toast.error('SomSomething went wrong. Please try agin.');
			}
		} finally {
			setIsLoading(false);
			onHide();
			// showDiscoverTutorialModal(); // mock
		}
	};

	const handleRequestOTP = async () => {
		setCountdown(60);
		setEligibleRequestOTP(false);
		setIsLoadingRequest(true);

		try {
			const request: AxiosInstance = servicesRequest();
			await request.post(EMAIL_VERIFICATION_REQUEST_URL);
		} catch (error) {
			toast.error('Error requesting OTP. Please try again.');
		} finally {
			setIsLoadingRequest(false);
		}
	};

	const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
		const result = event?.target?.value?.replace(/[^0-9]/g, '');
		formContext.setValue('otp', result);
	};

	useEffect(() => {
		let intervalId: string | number | NodeJS.Timeout | undefined;

		if (countdown > 0) {
			intervalId = setInterval(() => {
				setCountdown(prevCountdown => prevCountdown - 1);
			}, 1000);
		} else {
			setEligibleRequestOTP(true);
		}

		return () => {
			clearInterval(intervalId);
		};
	}, [countdown]);

	const handleUnload = (event: any) => {
		// set a truthy value to property returnValue
		event.returnValue = true;
	}

	useEffect(() => {
		changeBackdropBehaviour('static')
		window.addEventListener("beforeunload", handleUnload);

		return () => {
			window.removeEventListener("beforeunload", handleUnload);
		}
	}, [])

	const label = () => (countdown > 0 ? `Resend in ${countdown}s` : 'Resend');

	const screenWidth = window.innerWidth;

	if (screenWidth <= 930) {
		return (
			<div className="py-4 px-3">
				<center>
					<center>
						<Image src={emailImage} alt="welcome" />
					</center>
					<ModalTitle>Email Confirmation</ModalTitle>
					<Label>
						We emailed you a six-digit code to your email. Enter your code below
						to confirm your email address.
					</Label>
					<Controller
						name="otp"
						control={control}
						rules={{
							required: true,
						}}
						render={({ field: { value } }) => (
							<Input
								placeholder="000000"
								value={value}
								onChange={handleOnChange}
								type="string"
								maxLength={6}
							/>
						)}
					/>
					<Button
						label={label()}
						isLoading={isLoadingRequest}
						onClick={handleRequestOTP}
						disabled={!eligibleRequestOTP}
						mobilePadding="1.5vw"
					/>
					<div className="mt-2">
						<Button
							label="Confirm"
							isLoading={isLoading}
							onClick={handleSubmitOTP}
							mobilePadding="1.5vw"
						/>
					</div>
				</center>
			</div>
		);
	}

	return (
		<div>
			<center>
				<center>
					<Image src={emailImage} alt="welcome" />
				</center>
				<ModalTitle>Email Confirmation</ModalTitle>
				<Label>
					We emailed you a six-digit code to your email. Enter your code below
					to confirm your email address.
				</Label>
				<Controller
					name="otp"
					control={control}
					rules={{
						required: true,
					}}
					render={({ field: { value } }) => (
						<Input
							placeholder="000000"
							value={value}
							onChange={handleOnChange}
							type="string"
							maxLength={6}
						/>
					)}
				/>
				<FlexDisplayCenter>
					<Button
						label={label()}
						isLoading={isLoadingRequest}
						onClick={handleRequestOTP}
						disabled={!eligibleRequestOTP}
					/>
					<Button
						label="Confirm"
						isLoading={isLoading}
						onClick={handleSubmitOTP}
					/>
				</FlexDisplayCenter>
			</center>
		</div>
	);
};

export default VerificationModalComponent;
