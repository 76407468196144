import React, { useEffect, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import {
	FaEye,
	FaEyeSlash,
	FaRegCheckCircle,
	FaRegTimesCircle,
} from 'react-icons/fa';
import { AxiosInstance } from 'axios';
import { toast } from 'react-toastify';
import _ from 'lodash';
import {
	CredentialResponse,
	GoogleLogin,
	GoogleOAuthProvider,
} from '@react-oauth/google';
// @ts-ignore
// eslint-disable-next-line import/no-extraneous-dependencies
import * as jwt_decode from 'jwt-decode';

import {
	BottomFormInput,
	FormControlStyled,
	FormInput,
	FormLabel,
	ModalTitle,
	StyledDivider,
	TopFormInput,
} from '../SignIn/SignIn.styled';
import {
	IAuthorizationElementProps,
	ISignInResponseType,
	SignUpDataRequest,
} from '../Authorization/Authorization.types';
import { AUTHORIZATION_STEP, ERROR_CODES } from '../../Constants';
import {
	BackButton,
	DisclaimerLabel,
	DisclaimerLink,
	PasswordChecker,
} from '../Authorization/Authorization.styled';
import { Button, VerificationModal } from '../index';
import { FlexDisplaySmallGap } from '../../Screens/Preferences/Preferences.styled';
import servicesRequest, {
	SIGNUP_SERVICE_URL,
	SIGNUP_SOCIAL_SERVICE_URL,
} from '../../Config/services.config';
import { CONFIG } from '../../Config';
import { ErrorResponse } from '../VerificationModal/VerificationModal.types';

const { VALIDATION_ERROR, UNAUTHORIZED_ERROR } = ERROR_CODES;

export const passwordValidationIcon = (value: boolean): React.JSX.Element => {
	if (value) {
		return <FaRegCheckCircle className="mb-05" />;
	}

	return <FaRegTimesCircle className="mb-05" />;
};

const SignUpComponent = (
	props: IAuthorizationElementProps
): React.JSX.Element => {
	const [showPassword, setShowPassword] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [stepOTP, setStepOTP] = useState(false);
	const [passwordValidation, setPasswordValidation] = useState({
		atLeastEightChar: false,
		cantContainPassword: false,
		mustContainNumberAndSymbol: false,
	});

	const togglePasswordVisibility = () => setShowPassword(prevShow => !prevShow);

	const { changeStep, showDiscoverTutorialModal, onHide, changeBackdropBehaviour } = props;

	const {
		handleSubmit,
		control,
		formState: { errors },
		getValues,
		reset,
		setValue,
	} = useForm({
		defaultValues: {
			firstName: '',
			lastName: '',
			email: '',
			password: '',
		},
	});

	const isPasswordValidated =
		!passwordValidation.atLeastEightChar ||
		!passwordValidation.cantContainPassword ||
		!passwordValidation.mustContainNumberAndSymbol;

	const { password } = getValues();

	const validatePassword = () => {
		const { firstName, lastName, email } = getValues();

		const atLeastEightChar = password.length >= 8;
		const cantContainPassword =
			!password.toLowerCase().includes(firstName.toLowerCase()) &&
			!password.toLowerCase().includes(lastName.toLowerCase()) &&
			!password.toLowerCase().includes(email.toLowerCase());
		const mustContainNumberAndSymbol =
			/[0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(password);

		setPasswordValidation({
			atLeastEightChar,
			cantContainPassword,
			mustContainNumberAndSymbol,
		});
	};

	const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setValue('password', e.target.value);
		validatePassword();
	};

	useEffect(() => {
		validatePassword();
	}, [password]);

	const onSubmit = async (data: SignUpDataRequest) => {
		setIsLoading(true);
		try {
			const mappedData = {
				email: data.email,
				password: data.password,
				last_name: data.lastName,
				first_name: data.firstName,
			};

			const request: AxiosInstance = servicesRequest();
			const response: ISignInResponseType = await request.post(
				SIGNUP_SERVICE_URL,
				mappedData
			);

			const token: string = _.get(response, 'data.data.token.token', '');
			const roleResponse: string = _.get(response, 'data.data.user.role', '');

			localStorage.setItem('role', roleResponse);
			localStorage.setItem('token', token);
			props.setIsLoggedIn(true);

			setStepOTP(true);
		} catch (error) {
			const err = error as ErrorResponse;
			const statusCode = _.get(err, 'response.data.status_code');
			const message = _.get(err, 'response.data.message');

			if (statusCode === VALIDATION_ERROR) {
				toast.error(
					'Something is wrong with your input. Please check and try again.'
				);
			} else if (statusCode === UNAUTHORIZED_ERROR) {
				toast.error(message);
			} else {
				toast.error('Something went wrong. Please try agin.');
			}
		} finally {
			setIsLoading(false);
			// setStepOTP(true); // mock
		}
	};

	const passwordRules1 = !passwordValidation.atLeastEightChar;
	const passwordRules2 = !passwordValidation.cantContainPassword;
	const passwordRules3 = !passwordValidation.mustContainNumberAndSymbol;

	const handleBack = (): void => {
		reset();
		changeStep(AUTHORIZATION_STEP.LOGIN);
	};

	const signUpProcessWithGoogle = async (payload: CredentialResponse) => {
		const { credential } = payload;

		let decoded: { email: string; name: string; family_name: string } = {
			email: '',
			name: '',
			family_name: '',
		};

		if (typeof credential === 'string') {
			// @ts-ignore
			decoded = jwt_decode(credential);
		}
		const email = decoded?.email;
		const firstName = decoded?.name;
		const lastName = decoded?.family_name;
		const provider = 'google';
		const token = credential;

		const data = {
			email,
			first_name: firstName,
			last_name: lastName,
			provider,
			token,
		};

		const request = servicesRequest();
		const response = await request.post(SIGNUP_SOCIAL_SERVICE_URL, data);

		const socialToken: string = _.get(response, 'data.data.token.token', '');
		const roleResponse: string = _.get(response, 'data.data.user.role', '');

		localStorage.setItem('role', roleResponse);
		localStorage.setItem('token', socialToken);
		props.setIsLoggedIn(true);
	};

	const errorMessage = () => {
		toast.warning(
			'Sorry, we unable to find your account, please check your email and password, and try again'
		);
	};

	if (stepOTP) {
		return (
			<VerificationModal
				email={getValues('email')}
				showDiscoverTutorialModal={showDiscoverTutorialModal}
				onHide={onHide}
				changeBackdropBehaviour={changeBackdropBehaviour}
			/>
		);
	}

	return (
		<React.Fragment>
			<ModalTitle>
				<FlexDisplaySmallGap>
					<BackButton onClick={() => handleBack()} />
					<div>Create your account</div>
				</FlexDisplaySmallGap>
			</ModalTitle>
			<div className="mt-4">
				<Form>
					<Form.Group className="mb-3">
						<Controller
							name="firstName"
							control={control}
							rules={{
								required: 'First name is required',
							}}
							render={({ field }) => (
								<TopFormInput
									{...field}
									type="text"
									placeholder="First Name"
									isError={!!errors?.firstName}
								/>
							)}
						/>
						<Controller
							name="lastName"
							control={control}
							rules={{
								required: 'Last name is required',
							}}
							render={({ field }) => (
								<BottomFormInput
									{...field}
									type="text"
									placeholder="Last Name"
									isError={!!errors?.lastName}
								/>
							)}
						/>
						<small className="text-red">
							{(errors?.firstName && errors.firstName?.message) ||
								(errors?.lastName && errors.lastName?.message)}
						</small>
					</Form.Group>

					<Form.Group className="mb-3">
						<FormLabel>Email</FormLabel>
						<Controller
							name="email"
							control={control}
							rules={{
								required: 'Email is required',
								pattern: {
									value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, // eslint-disable-line
									message: 'Invalid email address',
								},
							}}
							render={({ field }) => (
								<FormInput
									{...field}
									type="email"
									placeholder="mail@mail.com"
									isError={!!errors?.email}
								/>
							)}
						/>
						<small className="text-red">
							{errors?.email && errors.email?.message}
						</small>
					</Form.Group>

					<Form.Group className="mb-3">
						<FormLabel>Password</FormLabel>
						<InputGroup>
							<Controller
								name="password"
								control={control}
								rules={{
									required: 'Password is required',
								}}
								render={({ field }) => (
									<FormControlStyled
										{...field}
										type={showPassword ? 'text' : 'password'}
										placeholder="Min 8 characters"
										isError={!!errors?.password}
										onChange={handlePasswordChange}
									/>
								)}
							/>
							<span
								className="input-group-text cursor-pointer"
								onClick={togglePasswordVisibility}>
								{showPassword ? <FaEyeSlash /> : <FaEye />}
							</span>
						</InputGroup>
						{password !== '' && (
							<div className="mt-2">
								<PasswordChecker fulfilled={!passwordRules2}>
									{passwordValidationIcon(!passwordRules2)} Can’t contain your
									name or email address
								</PasswordChecker>
								<PasswordChecker fulfilled={!passwordRules1}>
									{passwordValidationIcon(!passwordRules1)} At least 8
									characters
								</PasswordChecker>
								<PasswordChecker fulfilled={!passwordRules3}>
									{passwordValidationIcon(!passwordRules3)} Contains a number or
									symbol
								</PasswordChecker>
							</div>
						)}
						{password === '' && (
							<small className="text-red">
								{errors?.password && errors.password?.message}
							</small>
						)}
					</Form.Group>

					<DisclaimerLabel>
						By registering, I accept and agree to the{' '}
						<DisclaimerLink href="https://cbsa.hkust.edu.hk/terms-and-conditions-finsent"
							target="_blank" rel="noopener noreferrer"
						>Terms and Conditions</DisclaimerLink>,{' '}
						<DisclaimerLink href="https://cbsa.hkust.edu.hk/privacy-policy-finsent"
							target="_blank" rel="noopener noreferrer"
						>Privacy Policy</DisclaimerLink>, and{' '}
						<DisclaimerLink href="https://cbsa.hkust.edu.hk/cookies-policy-finsent"
							target="_blank" rel="noopener noreferrer"
						>Cookies Policy</DisclaimerLink>.
					</DisclaimerLabel>
					<Button
						disabled={isPasswordValidated}
						isLoading={isLoading}
						label="Agree and continue"
						onClick={handleSubmit(onSubmit)}
						mobilePadding="1.5vw"
					/>
				</Form>

				{/* <StyledDivider className="mt-3">
					<span>or</span>
				</StyledDivider>

				<GoogleOAuthProvider clientId={CONFIG.GOOGLE_OAUTH_CLIENT_ID}>
					<div className="d-flex flex-row justify-content-center gap-3 mt-3 mb-2">
						<div>
							<GoogleLogin
								locale="en"
								shape="square"
								onSuccess={signUpProcessWithGoogle}
								onError={errorMessage}
								text="signup_with"
							/>
						</div>
					</div>
				</GoogleOAuthProvider> */}
			</div>
		</React.Fragment>
	);
};

export default SignUpComponent;
