import React from 'react';
import _ from 'lodash';

import {
	Container,
	RightTabContainer,
	RightTabItem,
	RootContainer,
} from '../SingleReport/SingleReport.styled';
import { WhiteContainer } from '../Company/Company.styled';
import { renderDisclaimer } from '../../Components/SingleReport';
import { IReport } from './Report.types';
import { LIST_TYPE } from '../Industries/Industries.screen';
import { ColoredLabel } from '../Industries/Industries.desktop.screen';
import SideTab from '../../Components/Compare/SideTab.component';
import EarningCallTable from '../../Components/Company/EarningTable.component';
import { Popover } from '../../Components';


const ReportDesktop = (props: IReport) => {
	const {
		documentList,
		industryCode,
		setIndustryCode,
		onClickConfirm,
		filtered,
		onDeleteList,
		data,
		scrollDirection,
		setScrollDirection,
		setStartDate,
		setFinishDate,
		selectedRange,
		setSelectedRange,
		setDocumentType,
		documentType,
		getAllDocument,
		selectedPaginationData,
		trendingPaginationData,
		tab,
		setTab,
		tempFilter,
		setIsSubmitted,
		getCompaniesNextPage,
		isLoadingScrollCompany,
		startDate,
		finishDate,
	} = props;

	const { SELECT_INDUSTRY, TRENDING } = LIST_TYPE;

	const documentListTab = _.get(documentList, tab, []);
	const paginationTab = tab === TRENDING ? trendingPaginationData : selectedPaginationData;

	const onClickConfirmDesktop = () => () => {
		setTab(LIST_TYPE.SELECT_INDUSTRY);
		setIsSubmitted(true);
	};

	const sideTabProps = {
		industryCode,
		setIndustryCode,
		onClickConfirm,
		filtered: tempFilter,
		onDeleteList,
		data,
		scrollDirection,
		setScrollDirection,
		setStartDate,
		setFinishDate,
		selectedRange,
		setSelectedRange,
		setDocumentType,
		documentType,
		onConfirm: onClickConfirmDesktop,
		getCompaniesNextPage,
		isLoadingScrollCompany,
		label: 'Confirm',
		startDate,
		finishDate,
		isRenderSentimentChart: !documentListTab
	};

	return (
		<React.Fragment>
			<RootContainer>
				<div className="align-items-stretch d-flex mb-2">
					<Container className="rounded bg-white margin-right-8">
						<WhiteContainer className="rounded px-3 pt-2 pb-4">
							<SideTab {...sideTabProps} />
						</WhiteContainer>
					</Container>
					<Container md={9} className="rounded bg-white py-2">
						<RightTabContainer className="d-flex px-3">
							<RightTabItem
								className="px-2"
								onClick={() => {
									setTab(TRENDING);
								}}
								selected={tab === TRENDING}>
								Trending Reports
								<Popover
									className='ps-1'
									popoverTextHeader="**Trending Reports**"
									popoverTextBody={`Displays reports that have garnered the most user clicks over the past 30 days.`}
								/>
							</RightTabItem>
							{filtered.length > 0 && (
								<RightTabItem
									className="pb-0"
									onClick={() => {
										setTab(SELECT_INDUSTRY);
									}}
									selected={tab === SELECT_INDUSTRY}>
									<div
										className={`${tab === SELECT_INDUSTRY
											? 'bg-lightgrey text-neutral'
											: 'bg-lightgrey'
											} rounded-top px-2 py-1 mx-2 d-flex gap-1 align-content-center`}>
										<p className="mb-0 align-self-center text-">Selected</p>
										{filtered.map((code, index) => {
											return (
												<div className="rounded px-1 py-1" key={index}>
													<ColoredLabel
														sector={code[1]}
														className="text-white company-stock-code fw-600 fs-10 mx-1">
														{code[1]}
													</ColoredLabel>
												</div>
											);
										})}
									</div>
								</RightTabItem>
							)}
						</RightTabContainer>
						<EarningCallTable
							documentList={documentListTab}
							tablePaginationData={paginationTab}
							onChangePagination={page => {
								getAllDocument(`/?page=${page}`)();
							}}
							isMobileVersion={false}
							isWithoutTabHead={true}
							isWithCompanyDetail={true}
							isReportOrder={true}
						/>
					</Container>
				</div>
				<div className="align-items-stretch d-flex">
					<Container className="rounded">
						{renderDisclaimer()}
					</Container>
				</div>
			</RootContainer>
		</React.Fragment>
	);
};

export default ReportDesktop;
