import React, { Dispatch, SetStateAction, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { BiFilter } from 'react-icons/bi';
import { Dropdown, Pagination } from 'antd';
import { FiMinimize2 } from 'react-icons/fi';
import { CaretDownFilled, CaretUpFilled } from '@ant-design/icons';
import { Popover } from '..';


import {
	COMPARE_CARD_TAB_TYPE,
	COMPARE_TAB,
	FILTER_TYPE,
} from '../../Constants';
import {
	InformationPercentageSpan,
	TableHead,
	WhiteContainer,
} from '../../Screens/Company/Company.styled';
import { ICompareGroupCard } from '../../Screens/Compare/Compare.types';
import {
	RightTabContainer,
	RightTabItem,
} from '../../Screens/SingleReport/SingleReport.styled';
import {
	CardContainer,
	CardPercentage,
	IndustryText,
	StyledCard,
} from '../../Screens/Compare/Compare.styled';
import { convertToPercentage, hexToRgbA } from '../../Utils';
import {
	ColoredLabel,
	LoadingComponent,
	getColorScheme,
} from '../../Screens/Industries/Industries.desktop.screen';
import {
	FilterProgressBarContainer,
	FilterProgressBarItem,
	TableMobile,
} from '../../Screens/Compare/Compare.desktop.screen';
import { convertValue } from '../Home/TrendingReportsTable.component';
import { GrowthText } from '../Company/SentimentList.component';
import {
	UpDownIconWrapper,
	iconStyle,
} from '../SingleReport/Sentiment.component';

const { TOP, BOTTOM } = FILTER_TYPE;
const { ALL, SECTOR, INDUSTRY_GROUP, INDUSTRY, SUB_INDUSTRY } =
	COMPARE_CARD_TAB_TYPE;
const { INDUSTRY: INDUSTRY_TAB, COMPANY } = COMPARE_TAB;

const getTrendingTabItemDropdown = (
	setGroupTab: Dispatch<SetStateAction<number>>,
	setLabel: Dispatch<SetStateAction<any[]>>,
	label: any[],
	setCompareTab: Dispatch<SetStateAction<string>>
) => ({
	SECTORS: [
		{
			label: (
				<h5
					className="fs-12 fw-600"
					onClick={() => {
						setCompareTab(INDUSTRY_TAB);
						setGroupTab(0);
						setLabel([{ label: 'Top Sectors', key: 0, filter: TOP }, label[1]]);
					}}>
					Top Sectors
				</h5>
			),
			key: 0,
		},
		{
			label: (
				<h5
					className="fs-12 fw-600"
					onClick={() => {
						setCompareTab(INDUSTRY_TAB);
						setGroupTab(1);
						setLabel([
							{ label: 'Bottom Sectors', key: 1, filter: BOTTOM },
							label[1],
						]);
					}}>
					Bottom Sectors
				</h5>
			),
			key: 1,
		},
	],
	COMPANIES: [
		{
			label: (
				<h5
					className="fs-12 fw-600"
					onClick={() => {
						setCompareTab(COMPANY);
						setGroupTab(2);
						setLabel([
							label[0],
							{ label: 'Top Companies', key: 0, filter: TOP },
						]);
					}}>
					Top Companies
				</h5>
			),
			key: 2,
		},
		{
			label: (
				<h5
					className="fs-12 fw-600"
					onClick={() => {
						setCompareTab(COMPANY);
						setGroupTab(3);
						setLabel([
							label[0],
							{
								label: 'Bottom Companies',
								key: 1,
								filter: BOTTOM,
							},
						]);
					}}>
					Bottom Companies
				</h5>
			),
			key: 3,
		},
	],
});

const CompareGroupCard = ({
	data,
	groupTab,
	setGroupTab,
	compareCardTab,
	setCompareCardTab,
	compareCardTableData,
	compareTab,
	setCompareTab,
	compareCardPagination,
	getCompareTabData,
	isMobile,
	setCompareDataOrder,
	compareDataOrder,
	onClickIndustryCompany,
}: ICompareGroupCard) => {
	const [expandTab, setExpandTab] = useState(false);
	const [label, setLabel] = useState([
		{ label: 'Top Sectors', key: 0, filter: TOP },
		{ label: 'Top Companies', key: 0, filter: TOP },
	]);

	const { SECTORS, COMPANIES } = getTrendingTabItemDropdown(
		setGroupTab,
		setLabel,
		label,
		setCompareTab
	);

	return (
		<WhiteContainer className="w-100 margin-top-8 rounded">
			{!expandTab && (
				<Row className="px-md-3 pt-md-3">
					<RightTabContainer className="w-100 d-flex justify-content-between col-9 col-md-10 scrolling-wrapper">
						<div className="d-flex gap-2">
							<div className="d-flex align-items-center">
								{/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
					@ts-ignore */}
								<RightTabItem
									onClick={() => {
										setGroupTab(SECTORS[label[0].key].key);
										setCompareTab(INDUSTRY_TAB);
									}}
									selected={
										groupTab === SECTORS[0].key || groupTab === SECTORS[1].key
									}>
									{label[0].label}
									{(groupTab === SECTORS[0].key ||
										groupTab === SECTORS[1].key) && (
											<>
												<Popover
													className='px-1'
													popoverTextHeader="**Sector Performance**"
													popoverTextBody={`Shows top and bottom sectors within the selected date range.`}
												/>
												<Dropdown menu={{ items: SECTORS }} trigger={['click']}>
													<BiFilter className="fs-12 px-1" style={{ width: '20px', height: '20px' }} color="#225FB0" />
												</Dropdown>
											</>
										)}

								</RightTabItem>

							</div>
							<div className="d-flex align-items-center">
								{/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
					@ts-ignore */}
								<RightTabItem
									onClick={() => {
										setGroupTab(COMPANIES[label[0].key].key);
										setCompareTab(COMPANY);
									}}
									selected={
										groupTab === COMPANIES[0].key ||
										groupTab === COMPANIES[1].key
									}>
									{label[1].label}
									{(groupTab === COMPANIES[0].key ||
										groupTab === COMPANIES[1].key) && (
											<>
												<Popover
													className='px-1'
													popoverTextHeader="**Company Performance**"
													popoverTextBody={`Shows top and bottom companies within the selected date range.`}
												/>
												<Dropdown menu={{ items: COMPANIES }} trigger={['click']}>
													<BiFilter className="fs-12 px-1" style={{ width: '20px', height: '20px' }} color="#225FB0" />
												</Dropdown>
											</>
										)}
								</RightTabItem>

							</div>
						</div>
						{/* {getExpandIcon(expandData, setExpandTab, expandTab)} */}
					</RightTabContainer>
				</Row>
			)}
			<CardContainer className="g-2 pb-2">
				{
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					data.length > 0 &&
					!expandTab &&
					data.map(
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						(
							{
								stock_code: stockCode,
								industry_code: industryCode,
								name,
								latest_sentiment_score,
								average_sentiment_score,
								id,
							},
							index
						) => {
							const color = hexToRgbA(getColorScheme(industryCode), 0.3);
							const isCompany = !!stockCode;
							const dataId = isCompany ? id : industryCode;

							return (
								<Col
									md={2}
									xs={6}
									key={index}
									style={{ minHeight: '20%' }}
									onClick={() => onClickIndustryCompany(isCompany, dataId)}>
									<StyledCard
										className="h-100 cursor-pointer"
										bgColor={color}>
										<Row className="p-1 d-flex-row align-content-between h-100">
											<div>
												<ColoredLabel
													className={`text-white text-center standard-code fw-600 fs-10 ${stockCode ? 'w-25' : 'w-30'
														}`}
													sector={industryCode}>
													{stockCode || industryCode}
												</ColoredLabel>
											</div>
											<div className="d-flex gap-2 justify-content-between align-content-end">
												<IndustryText>{name}</IndustryText>
												<CardPercentage>
													{convertToPercentage(average_sentiment_score)}%
												</CardPercentage>
											</div>
										</Row>
									</StyledCard>
								</Col>
							);
						}
					)
				}
				{data.length === 0 && !expandTab && (
					<span className="fs-12 fw-600 p-2">Loading...</span>
				)}
			</CardContainer>
			{expandTab && (
				<React.Fragment>
					<div className="d-flex align-items-center">
						<RightTabContainer className="pt-3 px-3 scrolling-wrapper flex-grow-1 justify-content-between">
							<div className="d-flex gap-2">
								<RightTabItem
									onClick={() => {
										setCompareTab(INDUSTRY_TAB);
										setGroupTab(0);
									}}
									selected={compareTab === INDUSTRY_TAB}>
									All Industries
								</RightTabItem>
								<RightTabItem
									onClick={() => {
										setCompareTab(COMPANY);
										setGroupTab(2);
									}}
									selected={compareTab === COMPANY}>
									All Companies
								</RightTabItem>
							</div>
							<div
								className="d-flex align-items-center"
								onClick={() => {
									setExpandTab(false);
								}}
								style={{ cursor: 'pointer' }}>
								<h5 className="fs-10 fw-600 mb-0 me-1">Close </h5>
								<div className="bg-lightgrey rounded px-1">
									<FiMinimize2 className="fs-10" />
								</div>
							</div>
						</RightTabContainer>
					</div>
					<div className="table-container">
						<div className="table-scrollable">
							{!isMobile ? (
								<table
									className={`table ${compareCardTableData.length != 0 && 'table-striped'
										} table-borderless w-100`}>
									{/* <table className="table table-striped table-borderless mb-4 align-middle"> */}
									<TableHead className="text-nowrap">
										<tr>
											<td>Symbol</td>
											<td>Name</td>
											<td>Latest Sentiment</td>
											<td>Change</td>
											<td className="d-flex gap-1">
												Selected Date Avg Sentiment
												<UpDownIconWrapper>
													<CaretUpFilled
														style={iconStyle(compareDataOrder === 'asc')}
														onClick={() => {
															// eslint-disable-next-line @typescript-eslint/no-unsafe-call
															setCompareDataOrder('asc');
														}}
													/>
													<CaretDownFilled
														style={iconStyle(compareDataOrder === 'desc')}
														onClick={() => {
															// eslint-disable-next-line @typescript-eslint/no-unsafe-call
															setCompareDataOrder('desc');
														}}
													/>
												</UpDownIconWrapper>
											</td>
											<td>Type</td>
										</tr>
									</TableHead>
									<tbody>
										{compareCardTableData.length == 0 && (
											<tr>
												<td colSpan={6}>
													<div className="m-2">
														<LoadingComponent />
													</div>
												</td>
											</tr>
										)}
										{compareCardTableData &&
											compareCardTableData.map(
												(
													{
														id,
														name,
														latest_sentiment_score,
														sentiment_score_change,
														average_sentiment_score,
														type,
														stock_code,
														industry_code,
													},
													index
												) => {
													const fixedSentimentScoreChange = convertValue(
														convertToPercentage(sentiment_score_change)
													).value;
													const isGrowth =
														convertValue(
															convertToPercentage(sentiment_score_change)
														).originalValue > 0;
													const isCompany = !!stock_code;

													return (
														<React.Fragment key={index}>
															<tr
																className="table-hover-blue"
																onClick={() =>
																	onClickIndustryCompany(isCompany, id)
																}>
																<td>
																	<ColoredLabel
																		sector={stock_code || industry_code}
																		className="text-white standard-code fw-600 fs-10 mx-1">
																		{stock_code || industry_code}
																	</ColoredLabel>
																</td>
																<td>
																	<span className="fs-10 fw-600 m-0">
																		{name}
																	</span>
																</td>
																<td>
																	<span className="fs-16 fw-600 m-0">
																		{
																			convertValue(
																				convertToPercentage(
																					average_sentiment_score
																				)
																			).value
																		}
																	</span>
																</td>
																<td>
																	<GrowthText
																		isGrowth={isGrowth}
																		sentimentScore={fixedSentimentScoreChange}
																		isNoText
																	/>
																</td>
																<td>
																	<InformationPercentageSpan
																		className={`${convertValue(average_sentiment_score)
																			.className
																			} standard-code`}
																		textStyle={
																			convertValue(average_sentiment_score)
																				.color
																		}>
																		{
																			convertValue(
																				convertToPercentage(
																					average_sentiment_score
																				)
																			).value
																		}
																	</InformationPercentageSpan>
																</td>
																<td>
																	{compareTab !== COMPANY ? (
																		<span className="text-darkblue bg-lightblue standard-code fw-600 fs-10">
																			Industry
																		</span>
																	) : (
																		<span className="text-white bg-darkerblue standard-code fw-600 fs-10">
																			Company
																		</span>
																	)}
																</td>
															</tr>
														</React.Fragment>
													);
												}
											)}
									</tbody>
								</table>
							) : (
								<React.Fragment>
									{compareCardTableData.map(
										(
											{
												id,
												name,
												latest_sentiment_score = 0,
												sentiment_score_change = 0,
												average_sentiment_score = 0,
												stock_code,
												industry_code,
											},
											index
										) => {
											const fixedSentimentScoreChange = convertValue(
												convertToPercentage(sentiment_score_change)
											).value;
											const isGrowth =
												convertValue(
													convertToPercentage(sentiment_score_change)
												).originalValue > 0;

											return (
												<TableMobile
													latest_sentiment_score={latest_sentiment_score}
													isGrowth={isGrowth}
													fixedSentimentScoreChange={fixedSentimentScoreChange}
													average_sentiment_score={average_sentiment_score}
													isMobile={true}
													key={index}
													name={name}
													stock_code={stock_code}
													id={id}
													type={compareTab === COMPANY ? 'C' : 'I'}
													onClickIndustryCompany={onClickIndustryCompany}
													industry_code={industry_code}
												/>
											);
										}
									)}
								</React.Fragment>
							)}
						</div>
					</div>
					{compareTab === COMPANY && (
						<div className="d-flex justify-content-end py-2">
							<Pagination
								size="small"
								showSizeChanger={false}
								defaultCurrent={compareCardPagination?.current_page}
								total={compareCardPagination?.total}
								onChange={page => {
									const pageUrl = `/?page=${page}`;
									getCompareTabData(pageUrl);
								}}
							/>
						</div>
					)}
					{/* <ProgressBarComponent data={progressBarData} /> */}
					{compareTab === INDUSTRY_TAB && (
						<FilterProgressBarContainer className="scrolling-wrapper d-flex justify-content-between">
							<div className="d-flex">
								<FilterProgressBarItem
									isActive={compareCardTab === ALL}
									onClick={() => setCompareCardTab(ALL)}>
									All
								</FilterProgressBarItem>
								<FilterProgressBarItem
									onClick={() => setCompareCardTab(SECTOR)}
									isActive={compareCardTab === SECTOR}>
									Sector
								</FilterProgressBarItem>
								<FilterProgressBarItem
									onClick={() => setCompareCardTab(INDUSTRY_GROUP)}
									isActive={compareCardTab === INDUSTRY_GROUP}>
									Industry Group
								</FilterProgressBarItem>
								<FilterProgressBarItem
									onClick={() => setCompareCardTab(INDUSTRY)}
									isActive={compareCardTab === INDUSTRY}>
									Industry
								</FilterProgressBarItem>
								<FilterProgressBarItem
									onClick={() => setCompareCardTab(SUB_INDUSTRY)}
									isActive={compareCardTab === SUB_INDUSTRY}>
									Sub-Industry
								</FilterProgressBarItem>
							</div>
							<Pagination
								size="small"
								showSizeChanger={false}
								defaultCurrent={compareCardPagination?.current_page}
								total={compareCardPagination?.total}
								onChange={page => {
									const pageUrl = `/?page=${page}`;
									getCompareTabData(pageUrl);
								}}
							/>
						</FilterProgressBarContainer>
					)}
				</React.Fragment>
			)}
		</WhiteContainer>
	);
};

export default CompareGroupCard;
