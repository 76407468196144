import React from 'react';
import _ from 'lodash';
import { Pagination } from 'antd';

import {
	GrowthTextStyled,
	InformationPercentage,
	InformationPercentageSpan,
	TableData,
	TableHead,
} from '../../Screens/Company/Company.styled';
import { convertToPercentage, trimStringToLength } from '../../Utils';
import { convertValue } from './TrendingReportsTable.component';
import { ColoredLabel, LoadingComponent } from '../../Screens/Industries/Industries.desktop.screen';

const WatchlistTableComponent = (props: any) => {
	const {
		watchlistDetails,
		onChangeWatchlistDetail,
		fetchingWatchlistDetail,
		selectedWatchlist,
		onClickCard,
		isLoadingWatchlist,

	} = props;

	const watchlistDetailsData = _.get(watchlistDetails, 'data', []);
	const watchlistDetailsMeta = _.get(watchlistDetails, 'meta', {});

	const currentPage = _.get(watchlistDetailsMeta, 'current_page', 1);
	const total = _.get(watchlistDetailsMeta, 'total', 1);
	const token = localStorage.getItem('token');

	return (
		<React.Fragment>
			<div className="table-container">
				<div className="table-scrollable">
					<table className={`table ${!isLoadingWatchlist && "table-striped"} table-borderless mb-4`}>
						<TableHead>
							<tr>
								<td>Symbol</td>
								<td>Name</td>
								{/* <td>Type</td> */}
								<td>Sentiment</td>
								<td>Change</td>
								<td>Positive</td>
								<td>Negative</td>
								<td>Neutral</td>
							</tr>
						</TableHead>
						<tbody>
							{isLoadingWatchlist && (
								<tr>
									<td colSpan={8} className='bg-white'>
										<div className="m-2">
											<LoadingComponent />
										</div>
									</td>
								</tr>
							)}
							{watchlistDetailsData.map((item: any, index: number) => {
								const { code, name, id, company_id } = item;

								const isCompany = item?.type === 'company';
								// const type = isCompany ? 'C' : 'I';
								const dataId = isCompany ? company_id : code;

								const sentiment = convertToPercentage(
									item?.final_score?.sentiment
								);
								const change = convertToPercentage(item?.final_score?.change);
								const positive = convertToPercentage(item?.score?.positive);
								const negative = convertToPercentage(item?.score?.negative);
								const neutral = convertToPercentage(item?.score?.neutral);

								return (
									<React.Fragment key={index}>
										<tr
											className="cursor-pointer row-selected"
											onClick={async () => {
												await onChangeWatchlistDetail(item);
											}}>
											<td
												className="fs-10 fw-400"
												onClick={() => onClickCard(isCompany, dataId)}>
												<ColoredLabel
													sector={code}
													className="company-stock-code fw-600 fs-10 mx-1">
													{code}
												</ColoredLabel>
											</td>
											<td
												className="fw-600 fs-10"
												onClick={() => onClickCard(isCompany, dataId)}>
												{name}
											</td>
											{/* <td className="fw-400 fs-10">
												{type === 'I' ? (
													<InformationPercentageSpan
														className="bg-blue standard-code"
														textStyle="#225FB0">
														{type}
													</InformationPercentageSpan>
												) : (
													<InformationPercentageSpan
														className="bg-darkerblue standard-code"
														textStyle="#ffffff">
														{type}
													</InformationPercentageSpan>
												)}
											</td> */}
											<td className="fw-400 fs-10">
												<InformationPercentageSpan
													className={`${convertValue(sentiment).className
														} standard-code`}
													textStyle={convertValue(sentiment).color}>
													{convertValue(sentiment).value}
												</InformationPercentageSpan>
											</td>
											<td className="fw-400 fs-10">
												<GrowthTextStyled isGrowth={true}>
													<InformationPercentageSpan
														className={`${convertValue(change).className
															} standard-code`}
														textStyle={convertValue(change).color}>
														{convertValue(change).value}
													</InformationPercentageSpan>
												</GrowthTextStyled>
											</td>
											<td className="fw-400 fs-10">
												<InformationPercentageSpan
													className="bg-success-100 standard-code"
													textStyle="#107C10">
													{convertValue(positive).value}
												</InformationPercentageSpan>
											</td>
											<td className="fw-400 fs-10">
												<InformationPercentageSpan
													className="bg-danger-33 standard-code"
													textStyle="#D13438">
													{convertValue(negative).value}
												</InformationPercentageSpan>
											</td>
											<td className="fw-400 fs-10">
												<InformationPercentageSpan className="bg-light standard-code">
													{convertValue(neutral).value}
												</InformationPercentageSpan>
											</td>
										</tr>
									</React.Fragment>
								);
							})}
						</tbody>
					</table>
					{!token &&
						<div className="text-center pb-3 px-2">
							Register now and personalize your watchlist!
						</div>
					}
					{token && watchlistDetailsData.length == 0 && !isLoadingWatchlist &&
						<div className="text-center pb-3 px-2">
							Add your watchlist by clicking Edit Watchlist.
						</div>
					}
				</div>
			</div>
			<div className="d-flex justify-content-end">
				<Pagination
					size="small"
					showSizeChanger={false}
					defaultCurrent={currentPage}
					total={total}
					onChange={async (page: number) => {
						await fetchingWatchlistDetail(selectedWatchlist, page);
					}}
				/>
			</div>
		</React.Fragment>
	);
};

export default WatchlistTableComponent;
