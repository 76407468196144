import { DatePicker } from 'antd';
import moment from 'moment';

import {
	InfoContainer,
	WhiteContainer,
	YearTab,
} from '../../Screens/Company/Company.styled';
import circleInfo from '../../Assets/Icon/circle-info.svg';
import { SORT_DATE } from '../../Constants';
import { IYearFooter } from '../../Screens/Company/Company.types';
import { Tooltip } from '..';
import { Popover } from '..'

const { RangePicker } = DatePicker;

const YearFooter = (props: IYearFooter) => {

	const {
		onChangeDate = (startDate: string, endDate: string) => { },
		startDate,
		finishDate,
	} = props;

	const selectedTab = 'ALL';

	const yearsDifference = moment(finishDate).diff(moment(startDate), 'years');

	const changeRangeDate = (newStartDate: string, newFinishDate: string) => {
		onChangeDate(newStartDate, newFinishDate);
	};

	return (
		<WhiteContainer className="w-100 justify-content-between margin-top-2 rounded d-flex">
			<div className="col-md-6 m-2 d-flex gap-1">
				<YearTab
					selected={yearsDifference > 10}
					onClick={() => changeRangeDate(SORT_DATE.ALL.gte, SORT_DATE.ALL.lte)}>
					All
				</YearTab>
				<YearTab
					onClick={() =>
						changeRangeDate(SORT_DATE.TEN_YEARS.gte, SORT_DATE.TEN_YEARS.lte)
					}
					selected={yearsDifference === 10}>
					10y
				</YearTab>
				<YearTab
					onClick={() =>
						changeRangeDate(SORT_DATE.FIVE_YEARS.gte, SORT_DATE.FIVE_YEARS.lte)
					}
					selected={yearsDifference === 5}>
					5y
				</YearTab>
				<YearTab
					onClick={() =>
						changeRangeDate(
							SORT_DATE.THREE_YEARS.gte,
							SORT_DATE.THREE_YEARS.lte
						)
					}
					selected={yearsDifference === 3}>
					3y
				</YearTab>
				<YearTab
					onClick={() =>
						changeRangeDate(SORT_DATE.ONE_YEAR.gte, SORT_DATE.ONE_YEAR.lte)
					}
					selected={yearsDifference === 1}>
					1y
				</YearTab>
				{/*<RangePicker*/}
				{/*	className="mx-3"*/}
				{/*	format={'YYYY/MM/DD'}*/}
				{/*	bordered={false}*/}
				{/*	onChange={(value: any) => {*/}
				{/*		if (value?.length > 0) {*/}
				{/*			changeRangeDate(*/}
				{/*				moment(value[0].$d).format('YYYY-MM-DD'),*/}
				{/*				moment(value[1].$d).format('YYYY-MM-DD')*/}
				{/*			);*/}
				{/*		}*/}
				{/*	}}*/}
				{/*/>*/}
				<Tooltip
					className='ps-1'
					tooltipTextBody="Click the button to change the date range."
				/>
			</div>
			<InfoContainer className="col-md-3 align-self-center gap-1">
				Keywords from {yearsDifference} years chart
			</InfoContainer>
		</WhiteContainer >
	);
};

export default YearFooter;
