import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import {
	ArcElement,
	CategoryScale,
	Chart as ChartJS,
	Legend,
	LinearScale,
	LineElement,
	PointElement,
	Title,
	Tooltip,
} from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import _ from 'lodash';

import { FlexDisplay } from '../Preferences/Preferences.styled';
import { Container, RowContainer, TabMenu } from './AdminPanel.styled';
import { IAdminPanelScreenState, IPageViewGraphData } from './AdminPanel.types';
import { ROLE } from '../../Constants';
import servicesRequest, {
	ADMIN_ACTIVE_USERS_URL,
	ADMIN_PAGE_VIEWS_URL,
	ADMIN_USERS_URL,
} from '../../Config/services.config';
import { renderAdminGraph, renderUserTable } from './Component';

ChartJS.register(
	ArcElement,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
);

export const TABLE_TAB = [
	{
		label: 'All',
	},
	{
		label: 'New',
	},
	{
		label: 'Demo',
	},
	{
		label: 'Basic',
	},
	{
		label: 'Standard',
	},
	{
		label: 'Developer',
	},
	{
		label: 'Enterprise',
	},
];

const GRAPH_TAB = {
	PAGE_VIEWS: 'Page Views',
	ACTIVE_USERS: 'Active Users',
	ACCOUNT_TYPE: 'Account Type',
};

const renderAccountTypeGraph = () => {
	const data = {
		labels: ['Demo', 'Basic', 'Standard'],
		datasets: [
			{
				data: [33, 33, 34],
				backgroundColor: ['#1F78B4', '#6DB8EA', '#164E73'],
			},
		],
	};

	return (
		<Pie
			options={{
				responsive: true,
				plugins: {
					legend: {
						display: true,
						position: 'right',
					},
				},
			}}
			data={data}
			width={50}
			height={50}
		/>
	);
};

const AdminPanelScreen = () => {
	const [graphTab, setGraphTab] = useState(GRAPH_TAB.PAGE_VIEWS);
	const [tableSelectedTab, setTableSelectedTab] = useState(TABLE_TAB[0].label);
	const [year, setYear] = useState('2023');
	const [pageViews, setPageViews] = useState<IPageViewGraphData | null>(null);
	const [activeUsers, setActiveUsers] = useState<IPageViewGraphData | null>(
		null
	);
	const [users, setUsers] = useState(null);

	const navigate = useNavigate();

	const props: IAdminPanelScreenState = {
		tableSelectedTab,
		setTableSelectedTab,
		graphTab,
		setGraphTab,
		users,
	};

	const handleChangeTab = (tab: string) => {
		setGraphTab(tab);
	};

	const token = localStorage.getItem('token');
	const isAdmin = localStorage.getItem('role') !== ROLE.MEMBER;

	useEffect(() => {
		if (!token || !isAdmin) {
			navigate('/');
		}
	}, []);

	const fetchPageViews = async () => {
		try {
			const request = servicesRequest();
			const {
				data: { data },
			} = await request.get(ADMIN_PAGE_VIEWS_URL + `?year=${year}`);

			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			setPageViews(data);
		} catch (error) {
			toast.error(
				'Something went wrong while fetching page views. Please check and try again.'
			);
		}
	};

	const fetchActiveUsers = async () => {
		try {
			const request = servicesRequest();
			const {
				data: { data },
			} = await request.get(ADMIN_ACTIVE_USERS_URL + `?year=${year}`);

			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			setActiveUsers(data);
		} catch (error) {
			toast.error(
				'Something went wrong while fetching active users. Please check and try again.'
			);
		}
	};

	const fetchUsers = async () => {
		try {
			const request = servicesRequest();
			const {
				data: { data },
			} = await request.get(ADMIN_USERS_URL);

			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			setUsers(data.users);
		} catch (error) {
			toast.error(
				'Something went wrong while fetching users. Please check and try again.'
			);
		}
	};

	useEffect(() => {
		fetchPageViews().catch(console.error);
		fetchActiveUsers().catch(console.error);
		fetchUsers().catch(console.error);
	}, [year]);

	if (token && isAdmin) {
		return (
			<Container>
				<Row>
					<Col md={8}>
						<FlexDisplay>
							<TabMenu
								isSelected={graphTab === GRAPH_TAB.PAGE_VIEWS}
								onClick={() => handleChangeTab(GRAPH_TAB.PAGE_VIEWS)}>
								Page Views
							</TabMenu>
							<TabMenu
								isSelected={graphTab === GRAPH_TAB.ACTIVE_USERS}
								onClick={() => handleChangeTab(GRAPH_TAB.ACTIVE_USERS)}>
								Active Users
							</TabMenu>
						</FlexDisplay>
					</Col>
					<Col md={4}>
						<TabMenu isSelected={true} className="w-30">
							Account Type
						</TabMenu>
					</Col>
				</Row>
				<div className="mt-3 mb-5">
					<RowContainer className="m-0 py-4">
						<Col md={8}>
							{graphTab === GRAPH_TAB.PAGE_VIEWS &&
								!_.isNull(pageViews) &&
								renderAdminGraph(pageViews)}

							{graphTab === GRAPH_TAB.ACTIVE_USERS &&
								!_.isNull(activeUsers) &&
								renderAdminGraph(activeUsers)}
						</Col>
						<Col md={4}>{renderAccountTypeGraph()}</Col>
					</RowContainer>
				</div>
				{renderUserTable(props)}
			</Container>
		);
	}

	return null;
};

export default AdminPanelScreen;
