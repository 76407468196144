// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React from 'react';
import _ from 'lodash';
import AsyncSelect from 'react-select/async';
import { components, type DropdownIndicatorProps } from 'react-select';
import { SearchOutlined } from '@ant-design/icons';

import servicesRequest, { SEARCH_URL } from '../../Config/services.config';
import {
	FlexDisplayMediumGap,
	FlexDisplaySpaceBetween,
} from '../../Screens/Preferences/Preferences.styled';
import {
	CompanyNameStandard,
	InformationPercentage,
} from '../../Screens/Company/Company.styled';
import { getDefaultMarket, trimStringToLength } from '../../Utils';
import { ColoredLabel } from '../../Screens/Industries/Industries.desktop.screen';

const loadOptions = async (inputValue: string, callback: any) => {
	if (inputValue.length > 1) {
		const request = servicesRequest();
		const response = await request
			.get(SEARCH_URL + `?country_id=${getDefaultMarket()}&q=${inputValue}`)
			.then(res => {
				const companies = _.get(res, 'data.data.companies', []);
				// const industries = _.get(res, 'data.data.industries', []);
				const industries = []
				const newData = companies.concat(industries);

				return newData.map((item: any) => ({
					value: item?.id,
					label: item?.name,
					...item,
				}));
			});
		callback(response);
	} else {
		callback(null);
	}
};

const CustomOption = (props: any) => {
	const { innerProps, isDisabled } = props;
	const data = _.get(props, 'data');
	const code: string = data?.industry_code || data?.stock_code;
	const label = data?.name;
	const type = data?.industry_code ? 'Industry' : 'Company';
	return !isDisabled ? (
		<div {...innerProps}>
			<FlexDisplaySpaceBetween className="m-2">
				<FlexDisplayMediumGap>
					<ColoredLabel
						sector={code}
						className="company-stock-code fw-600 fs-10 mx-1">
						{code}
					</ColoredLabel>
					<CompanyNameStandard className="">
						{trimStringToLength(label, 70)}
					</CompanyNameStandard>
				</FlexDisplayMediumGap>
				{type === 'Industry' ? (
					<InformationPercentage
						className="bg-blue rounded"
						textStyle="#225FB0">
						I
					</InformationPercentage>
				) : (
					<InformationPercentage
						className="bg-darkerblue rounded"
						textStyle="#ffffff">
						C
					</InformationPercentage>
				)}
			</FlexDisplaySpaceBetween>
		</div>
	) : null;
};

const customStyles = {
	container: provided => ({
		...provided,
		minHeight: '1px',
		textAlign: 'left',
		border: '4px',
	}),
	control: provided => ({
		...provided,
		border: 'none',
		backgroundColor: '#F5F5F5',
		borderRadius: '4px',
		minHeight: '1px',
		height: '30px',
		fontSize: '12px',
	}),
	input: provided => ({
		...provided,
		minHeight: '1px',
		fontSize: '12px',
	}),
	dropdownIndicator: provided => ({
		...provided,
		minHeight: '1px',
		paddingTop: '0',
		paddingBottom: '0',
		color: '#757575',
		fontSize: '12px',
	}),
	indicatorSeparator: provided => ({
		...provided,
		minHeight: '1px',
		height: '15px',
		fontSize: '12px',
	}),
	clearIndicator: provided => ({
		...provided,
		minHeight: '1px',
		fontSize: '12px',
	}),
	valueContainer: provided => ({
		...provided,
		// minHeight: '1px',
		height: '20px',
		paddingTop: '0',
		paddingBottom: '0',
		fontSize: '12px',
	}),
	singleValue: provided => ({
		...provided,
		minHeight: '1px',
		paddingBottom: '4px',
		fontSize: '12px',
	}),
};

const DropdownIndicator: React.FC<DropdownIndicatorProps> = props => {
	return (
		<components.DropdownIndicator {...props}>
			<SearchOutlined />
		</components.DropdownIndicator>
	);
};

const SearchCompanyComponent = (props: any) => {
	return (
		<AsyncSelect
			value={null}
			cacheOptions
			defaultOptions
			loadOptions={loadOptions}
			isLoading={false}
			placeholder="Search"
			onChange={data => {
				props.addToWatchlists(data);
			}}
			escapeClearsValue={true}
			// isClearable={true}
			components={{ Option: CustomOption, DropdownIndicator }}
			styles={customStyles}
		/>
	);
};

export default SearchCompanyComponent;
