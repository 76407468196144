import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';

import ReportDesktop from './Report.desktop.screen';
import ReportMobile from './Report.mobile.screen';

import servicesRequest, {
	DOCUMENT_LIST_URL,
} from '../../Config/services.config';
import { LIST_TYPE } from '../Industries/Industries.screen';
import useFilterData from '../../Hooks/useFilterData';
import { DATE_NOW, CALL, REPORT_DATE_RANGE, SCREEN_SIZE } from '../../Constants';
import useResizeScreen from '../../Hooks/useResizeScreen';
import { getDefaultMarket } from '../../Utils';

const ReportScreen = (): React.JSX.Element => {
	const screenType = useResizeScreen();

	const {
		data,
		getCompaniesNextPage,
		isLoadingScrollCompany,
		scrollDirection,
		setScrollDirection,
		industryCode,
		setIndustryCode,
		onClickConfirm,
		filtered,
		setFiltered,
		tempFilter,
		onDeleteList,
	} = useFilterData();

	const [startDate, setStartDate] = useState(DATE_NOW.clone().subtract(5, 'years').add(1, 'days').format('YYYY-MM-DD'));
	const [finishDate, setFinishDate] = useState(DATE_NOW.clone().format('YYYY-MM-DD'));
	const [selectedRange, setSelectedRange] = useState(
		REPORT_DATE_RANGE.FIVE_YEAR
	);

	const [documentType, setDocumentType] = useState(CALL[0]);

	const [trendingDocumentList, setTrendingDocumentList] = useState(null);
	const [selectIndustryDocumentList, setSelectIndustryDocumentList] = useState(
		null
	);

	const [selectedPaginationData, setSelectedPaginationData] = useState({
		last_page: 1,
		current_page: 1,
		last_page_url: '',
		next_page_url: '',
		previous_page_url: '',
		total: 0,
	});
	const [trendingPaginationData, setTrendingPaginationData] = useState({
		last_page: 1,
		current_page: 1,
		last_page_url: '',
		next_page_url: '',
		previous_page_url: '',
		total: 0,
	});
	const [tab, setTab] = useState(LIST_TYPE.TRENDING);
	const [isSubmitted, setIsSubmitted] = useState(false);

	const request = servicesRequest();

	const [searchParams] = useSearchParams();

	const paramsName = searchParams.get('name') || '';
	const paramsCode = searchParams.get('stock-code') || '';

	const getDocument = (pageUrl = '/?page=1') => {
		if (pageUrl !== null) {
			setSelectIndustryDocumentList(null);
			setSelectedPaginationData({ ...selectedPaginationData, current_page: -1 });
			setFiltered([...tempFilter]);

			const industryFiltered = tempFilter
				.filter(code => !_.isNaN(Number(code[1])))
				.map(code => code[1]);
			const companyFiltered = tempFilter
				.filter(code => _.isNaN(Number(code[1])))
				.map(code => code[1]);

			setTab(LIST_TYPE.SELECT_INDUSTRY);

			const url = `${DOCUMENT_LIST_URL}${pageUrl}&company_stock_code=${companyFiltered.toString()}
			&industry_code=${industryFiltered}&country_id=${getDefaultMarket()}&document_type=${documentType.value
				}&limit=20&document_date.gte=${startDate}&document_date.lte=${finishDate}`;

			request
				.get(url)
				.then((response: any) => {
					const responseData = _.get(response, 'data.data.data', []);
					const meta = _.get(response, 'data.data.meta', []);

					setSelectIndustryDocumentList(responseData);
					setSelectedPaginationData(meta);
				})
				.catch(() =>
					toast.error('Something went wrong. Please refresh the page.')
				);
			setIsSubmitted(false);
		}
	};

	const getList = (pageUrl = '/?page=1') => {
		if (pageUrl !== null) {
			setTrendingDocumentList(null);
			setTrendingPaginationData({ ...trendingPaginationData, current_page: -1 });
			request
				.get(
					`${DOCUMENT_LIST_URL}/trending${pageUrl}&country_id=${getDefaultMarket()}&document_type=filing&limit=15`
				)
				.then((response: any) => {
					const responseData = _.get(response, 'data.data.data', []);
					const meta = _.get(response, 'data.data.meta', []);

					setTrendingDocumentList(responseData);
					setTrendingPaginationData(meta);
				})
				.catch(() =>
					console.error('Something went wrong. Please refresh the page.')
				);
		}
	};

	const getAllDocument =
		(pageUrl = '/?page=1') =>
			() => {
				if (tab === LIST_TYPE.TRENDING) getList(pageUrl);
				if (tab === LIST_TYPE.SELECT_INDUSTRY) getDocument(pageUrl);
			};

	useEffect(() => {
		getAllDocument()();
	}, []);

	useEffect(() => {
		if (isSubmitted) {
			getAllDocument()();
		}
	}, [isSubmitted]);

	useEffect(() => {
		if (paramsName && paramsCode) {
			const initialFilter = [[paramsName, paramsCode]];
			// @ts-ignore
			setFiltered([...initialFilter]);
			setTab(LIST_TYPE.SELECT_INDUSTRY);
			setDocumentType({
				label: 'Corporate Filings',
				value: 'filing',
			});
			onClickConfirm([paramsName, paramsCode])();
			setIsSubmitted(true);
		}
	}, [paramsName, paramsCode]);

	const stateProps = {
		industryCode,
		setIndustryCode,
		onClickConfirm,
		filtered,
		tempFilter,
		onDeleteList,
		data,
		scrollDirection,
		setScrollDirection,
		setStartDate,
		setFinishDate,
		setSelectedRange,
		selectedRange,
		documentType,
		setDocumentType,
		documentList: {
			trending: trendingDocumentList,
			select_industry: selectIndustryDocumentList,
		},
		selectedPaginationData,
		trendingPaginationData,
		tab,
		setTab,
		isSubmitted,
		setIsSubmitted,
		getAllDocument,
		isLoadingScrollCompany,
		getCompaniesNextPage,
		startDate,
		finishDate,
	};

	return (
		<React.Fragment>
			{screenType === SCREEN_SIZE.TABLET ||
				screenType === SCREEN_SIZE.MOBILE ? (
				<ReportMobile {...stateProps} />
			) : (
				<ReportDesktop {...stateProps} />
			)}
		</React.Fragment>
	);
};

export default ReportScreen;
