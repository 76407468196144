// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { useState } from 'react';
import { Drawer } from 'antd';
import { FaArrowRight, FaExpandAlt } from 'react-icons/fa';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';

import { WhiteContainer } from './Company.styled';
import {
	CompanyName,
	Container,
	RightTabContainer,
	RightTabItem,
	RootContainer,
} from '../SingleReport/SingleReport.styled';
import {
	ESGComponent,
	OverviewComponent,
	SentimentComponent,
} from '../../Components/SingleReport/RightTab.component';
import { Button } from '../../Components';
import circleInfoBlue from '../../Assets/Icon/circle-info-blue.svg';
import { ICompanyDesktop } from './Company.types';
import useResizeScreen from '../../Hooks/useResizeScreen';
import { SCREEN_SIZE } from '../../Constants';
import { ToggleLeft } from '../../Components/Company/ToggleLeft.component';
import CompanyList from '../../Components/Company/SentimentList.component';
import Graph from '../../Components/Company/Graph.component';
import KeywordTable from '../../Components/Company/KeywordTable.component';
import YearFooter from '../../Components/Company/YearFooter.component';
import EarningCallTable from '../../Components/Company/EarningTable.component';
import { renderDisclaimer } from '../../Components/SingleReport';
import { checkOutMoreDetails, getCallsLabel } from '../../Utils';
import { Popover } from '../../Components';


const CompanyDesktop = (props: ICompanyDesktop) => {
	const [xCoordinate, setXCoordinate] = useState(0);
	const [date, setDate] = useState<Date | undefined>();
	const [isOpenCompanyList, showCompanyList] = useState(false);

	const {
		keywordList,
		setTabType: setCompanyListType,
		tabType: companyListType,
		list: companyList,
		paginationData,
		setData: setCompany,
		documentList,
		setDocumentList,
		tablePaginationData,
		setTablePaginationData,
		setDocument,
		document,
		documentId,
		setDocumentId,
		scoresAverage,
		onDocumentUrlClicked,
		setStartDate,
		setFinishDate,
		scoresList,
		setScoresList,
		stockPrices,
		setStockPrices,
		getList,
		getDocumentList,
		onChangeCompanyId,
		id,
		startDate,
		finishDate,
		onChangeDate,
		setFilter,
		filter,
		esgProps,
		navigateToCompare,
		documentType,
		companyData,
		companyReturn,
	} = props;

	const navigate = useNavigate();

	const screenType = useResizeScreen();
	const isSmallerScreen = screenType === SCREEN_SIZE.LAPTOP;
	const isSmartphone = screenType === SCREEN_SIZE.TABLET;

	const openToggle = () => showCompanyList(true);

	const renderGraphProps = () => ({
		xCoordinate,
		setXCoordinate,
		setDate,
		isSmallerScreen,
		date,
		scoresList,
		setScoresList,
		stockPrices,
		setStockPrices,
		isSmartphone,
		finishDate,
		startDate,
	});

	const stockCode = _.get(documentList, '[0].company.stock_code', '');
	const name = _.get(documentList, '[0].company.name', '');
	const exchange = _.get(documentList, '[0].company.exchange', '');

	const company = {
		id,
		name,
		exchange,
		stock_code: stockCode,
	};

	return (
		<React.Fragment>
			<RootContainer>
				<div className="align-items-stretch d-flex mb-2">
					{isSmallerScreen ? (
						<ToggleLeft onClick={openToggle} />
					) : (
						<CompanyList
							isSmallerScreen={isSmallerScreen}
							companyList={companyList}
							setCompanyListType={setCompanyListType}
							companyListType={companyListType}
							paginationData={paginationData}
							onChangePagination={getList}
							company={company}
							setCompany={setCompany}
							onChangeCompanyId={onChangeCompanyId}
						/>
					)}
					<Container
						md={isSmallerScreen ? '' : 7}
						className={`rounded ${!isSmallerScreen && 'margin-left-8'}`}>
						<div className="d-flex">
							<WhiteContainer className="rounded w-75 p-md-2 margin-right-2">
								<div className="d-flex justify-content-between p-md-1 ">
									<CompanyName className="m-0">
										Sentiment and Return by&nbsp;
										{company?.name}
									</CompanyName>
									<div
										className="d-flex align-items-center"
										onClick={props.navigateToCompare}
										style={{ cursor: 'pointer' }}>
										<h5 className="fs-10 fw-400 mb-0 me-1">Advanced </h5>
										<div className="bg-lightgrey rounded px-1">
											<FaExpandAlt className="fs-10" />
										</div>
									</div>
								</div>
								<Graph {...renderGraphProps()} />
							</WhiteContainer>
							<WhiteContainer className="rounded w-25">
								<KeywordTable
									keywordList={keywordList}
									keywordPagination={{ current_page: 1 }}
									navigateToCompare={navigateToCompare}
								/>
							</WhiteContainer>
						</div>
						<YearFooter
							startDate={startDate}
							setStartDate={setStartDate}
							finishDate={finishDate}
							setFinishDate={setFinishDate}
							onChangeDate={onChangeDate}
						/>
						<EarningCallTable
							pageType='Company'
							documentList={documentList}
							setDocumentList={setDocumentList}
							tablePaginationData={tablePaginationData}
							setTablePaginationData={setTablePaginationData}
							onChangePagination={value => {
								getDocumentList(`/?page=${value}`)();
							}}
							document={document}
							setDocument={setDocument}
							documentId={documentId}
							setDocumentId={setDocumentId}
							onDocumentUrlClicked={onDocumentUrlClicked}
							setFilter={setFilter}
							filter={filter}
							onClickViewMore={props.onClickViewMore}
							componentTitle={getCallsLabel(documentType)}
						/>
					</Container>
					<Container md={3} className="rounded mh-100 margin-left-8">
						<WhiteContainer className="rounded px-3 py-2 h-100">
							<RightTabContainer>
								<RightTabItem onClick={() => { }} selected={true}>
									Overview
									<Popover
										className='ps-1'
										popoverTextHeader="**Latest Report Overview**"
										popoverTextBody={`Details the latest report and compares average company sentiments and stock returns.`}
									/>
								</RightTabItem>
							</RightTabContainer>
							<CompanyName className="mt-2">
								<div className="fs-12 my-1" onClick={props.navigateToCompare}>
									<span
										onClick={props.navigateToCompare}
										className="company-stock-code"
										style={{ marginRight: '8px' }}>
										{stockCode}
									</span>{' '}
									{name}
								</div>
							</CompanyName>
							<OverviewComponent
								finishDate={finishDate}
								startDate={startDate}
								documents={document}
								companies={companyData}
								companyReturn={companyReturn}
							/>
							<RightTabContainer className="mt-5">
								<RightTabItem onClick={() => { }} selected={true}>
									Sentiments
									<Popover
										className='ps-1'
										popoverTextHeader="**Average Sentiment**"
										popoverTextBody={`Shows the company's average sentiment over the selected timeframe (default: 5 years).`}
									/>
								</RightTabItem>
							</RightTabContainer>
							<SentimentComponent
								documents={document}
								scoresAverage={scoresAverage}
								documentType={documentType}
								isCompanyPage
								scoreType='COMPANY'
							/>
							<RightTabContainer className="mt-5">
								<RightTabItem onClick={() => { }} selected={true}>
									ESG
									<Popover
										className='ps-1'
										popoverTextHeader="**ESG Overview**"
										popoverTextBody={`Displays the ESG classifications average for the selected timeframe (default: 5 years).`}
									/>
								</RightTabItem>
							</RightTabContainer>
							<ESGComponent {...esgProps} isCompanyPage />
							<div>
								<Button
									rounded
									label="Check out more details"
									icon={<FaArrowRight className="mx-2 pt-1" />}
									onClick={() => {
										navigate(checkOutMoreDetails(documentList, documentType));
									}}
								/>
							</div>
						</WhiteContainer>
					</Container>
				</div>
				<div className="margin-top-4 d-flex w-100">
					<Container
						className="rounded bg-white "
						style={{ paddingRight: '8px' }}>
						{renderDisclaimer()}
					</Container>
				</div>
			</RootContainer>
			<Drawer
				placement="left"
				closable={false}
				onClose={() => showCompanyList(false)}
				open={isOpenCompanyList}
				key="left">
				<CompanyList
					isSmallerScreen={isSmallerScreen}
					companyList={companyList}
					setCompanyListType={setCompanyListType}
					companyListType={companyListType}
					paginationData={paginationData}
					onChangePagination={getList}
					company={company}
					setCompany={setCompany}
					onChangeCompanyId={onChangeCompanyId}
				/>
			</Drawer>
		</React.Fragment>
	);
};

export default CompanyDesktop;
