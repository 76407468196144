import React from 'react';
import { Dropdown, Pagination } from 'antd';
import { BiFilter } from 'react-icons/bi';
import { FaChevronRight } from 'react-icons/fa';
import moment from 'moment';
import { FaArrowTrendDown, FaArrowTrendUp } from 'react-icons/fa6';
import { CaretDownFilled, CaretUpFilled } from '@ant-design/icons';

import { IEarningCallTable } from '../../Screens/Company/Company.types';
import {
	InformationPercentageSpan,
	Link,
	TableHead,
	TableRowStyled,
	WhiteContainer,
} from '../../Screens/Company/Company.styled';
import {
	RightTabContainer,
	RightTabItem,
} from '../../Screens/SingleReport/SingleReport.styled';
import { convertToPercentage, getDefaultCalls, getDefaultMarket } from '../../Utils';
import {
	convertValue,
	renderWatchlistMobile,
} from '../Home/TrendingReportsTable.component';
import { IDocumentListData } from '../../Screens/SingleReport/SingleReport.types';
import { FlexDisplaySmallGap } from '../../Screens/Preferences/Preferences.styled';
import {
	iconStyle,
	UpDownIconWrapper,
} from '../SingleReport/Sentiment.component';
import { ROUTES } from '../../Constants/routes';
import { LoadingComponent } from '../../Screens/Industries/Industries.desktop.screen';

import { Popover } from '..';


const EarningCallTable = ({
	items,
	isDropdown,
	documentList,
	tablePaginationData,
	onChangePagination,
	onClick,
	isMobileVersion = false,
	documentType = getDefaultCalls(),
	setFilter = () => { },
	filter,
	componentTitle = 'Earnings Calls',
	isWithoutTabHead = false,
	isWithCompanyDetail = false,
	onClickViewMore = () => { },
	isReportOrder = false,
	isHomePage = false,
	pageType = 'Company'
}: IEarningCallTable) => {
	const { current_page: currentPage, total } = tablePaginationData;
	if (!documentList || currentPage === -1) {
		return (
			<WhiteContainer
				className="w-100 p-md-3 rounded margin-top-8 d-flex align-items-center"
				style={{ minHeight: isHomePage ? '' : '90vh' }}>
				<LoadingComponent />
			</WhiteContainer>
		)
	}

	const popoverMsg = <Popover
		className='ps-1'
		popoverTextHeader={`**${pageType === 'Home' ? 'Trending' : pageType} Reports**`}
		popoverTextBody={pageType === 'Home'
			? 'Displays reports that have garnered the most user clicks over the past 30 days.'
			: `Lists all reports from the selected ${pageType.toLowerCase()}.`}
	/>
	return (
		<WhiteContainer
			className="w-100 p-md-3 rounded margin-top-8"
			style={{ minHeight: isHomePage ? '' : '60vh' }}>
			{!isWithoutTabHead && (
				<RightTabContainer className="d-flex rounded justify-content-between">
					{!isDropdown ? (
						<RightTabItem onClick={() => { }} selected={true}>
							{componentTitle}
							{popoverMsg}
						</RightTabItem>
					) : (
						<RightTabItem onClick={() => { }} selected={true}>
							{componentTitle}
							{popoverMsg}
							<Dropdown menu={{ items, onClick }} trigger={['click']}>
								<BiFilter className="fs-12 mx-2" color="#225FB0" />
							</Dropdown>
						</RightTabItem>
					)}

					<div
						className="d-flex align-items-center gap-1 cursor-pointer"
						onClick={() => {
							onClickViewMore();
						}}>
						<h5 className="fs-10 fw-600 m-0">View More </h5>
						<div className="bg-lightgrey rounded px-1">
							<FaChevronRight className="fs-10" />
						</div>
					</div>
				</RightTabContainer>
			)}
			{isMobileVersion &&
				documentList?.length > 0 &&
				documentList.map((item: IDocumentListData, index) => {
					const quarter = `${item?.fyear} Q${item?.fqtr}`;
					const date = moment(item?.date).format('MMMM D, YYYY');
					const title = item?.title;
					const sentiment = convertToPercentage(item?.final_score?.sentiment);
					const change = convertToPercentage(item?.final_score?.change);
					const positive = convertToPercentage(item?.score?.positive);
					const negative = convertToPercentage(item?.score?.negative);
					const neutral = convertToPercentage(item?.score?.neutral);

					const companyId = item?.company?.id || '';
					const documentId = item?.id || '';

					const companyName = item?.company?.name || '';
					const companyStockCode = item?.company?.stock_code || '';

					return (
						<React.Fragment key={index}>
							{renderWatchlistMobile(
								title,
								sentiment,
								change,
								positive,
								negative,
								neutral,
								quarter,
								date,
								companyId,
								documentId,
								documentType,
								companyName,
								companyStockCode
							)}
						</React.Fragment>
					);
				})}

			{!isMobileVersion && (
				<React.Fragment>
					<div className="table-container">
						<div className="table-scrollable">
							<table className="table table-striped table-borderless mb-4">
								<TableHead>
									<tr>
										{!isReportOrder && <td>Quarter</td>}
										{isWithCompanyDetail && (
											<>
												<td>Company Code</td>
												<td>Company Name</td>
											</>
										)}
										{isReportOrder && <td>Quarter</td>}
										<td>
											<FlexDisplaySmallGap>
												Release Date
												<UpDownIconWrapper>
													<CaretUpFilled
														style={iconStyle(filter === 'date.asc')}
														onClick={() => {
															// eslint-disable-next-line @typescript-eslint/no-unsafe-call
															setFilter('date.asc');
														}}
													/>
													<CaretDownFilled
														style={iconStyle(filter === 'date.desc')}
														onClick={() => {
															// eslint-disable-next-line @typescript-eslint/no-unsafe-call
															setFilter('date.desc');
														}}
													/>
												</UpDownIconWrapper>
											</FlexDisplaySmallGap>
										</td>
										<td>
											<FlexDisplaySmallGap>
												Title
												<UpDownIconWrapper>
													<CaretUpFilled
														style={iconStyle(filter === 'title.asc')}
														onClick={() => {
															// eslint-disable-next-line @typescript-eslint/no-unsafe-call
															setFilter('title.asc');
														}}
													/>
													<CaretDownFilled
														style={iconStyle(filter === 'title.desc')}
														onClick={() => {
															// eslint-disable-next-line @typescript-eslint/no-unsafe-call
															setFilter('title.desc');
														}}
													/>
												</UpDownIconWrapper>
											</FlexDisplaySmallGap>
										</td>
										<td>
											<FlexDisplaySmallGap>
												Sentiment
												{/* <UpDownIconWrapper>
													<CaretUpFilled
														style={iconStyle(filter === 'sentiment_score.asc')}
														onClick={() => {
															// eslint-disable-next-line @typescript-eslint/no-unsafe-call
															setFilter('sentiment_score.asc');
														}}
													/>
													<CaretDownFilled
														style={iconStyle(filter === 'sentiment_score.desc')}
														onClick={() => {
															// eslint-disable-next-line @typescript-eslint/no-unsafe-call
															setFilter('sentiment_score.desc');
														}}
													/>
												</UpDownIconWrapper> */}
											</FlexDisplaySmallGap>
										</td>
										<td>Change</td>
										<td>Positive</td>
										<td>Negative</td>
										<td>Neutral</td>
									</tr>
								</TableHead>
								<tbody>
									{documentList.length > 0 &&
										documentList.map((data: IDocumentListData, index) => {
											const {
												fyear,
												fqtr,
												date,
												title,
												final_score: { sentiment, change },
												score: { positive, negative, neutral },
											} = data;

											const documentId = data?.id || '';
											const companyId = data?.company?.id || '';
											const companyName = data?.company?.name || '';
											const companyStockCode = data?.company?.stock_code || '';

											const isGrowth =
												convertValue(convertToPercentage(change))
													.originalValue > 0;

											const renderChangeIcon = () => {
												const changeValue = convertToPercentage(change);
												if (change && !isNaN(Number(change))) {
													if (isGrowth) {
														return (
															<FlexDisplaySmallGap className="standard-padding">
																<FaArrowTrendUp />
																{convertValue(changeValue).value}
															</FlexDisplaySmallGap>
														);
													}
													if (!isGrowth) {
														return (
															<FlexDisplaySmallGap className="standard-padding">
																<FaArrowTrendDown />
																{convertValue(changeValue).value}
															</FlexDisplaySmallGap>
														);
													}
												}

												return convertValue(changeValue).value;
											};

											return (
												<React.Fragment key={index}>
													<TableRowStyled>
														{!isReportOrder && (
															<td className="fs-10 fw-400 standard-padding">
																Q{fqtr} {fyear}
															</td>
														)}

														{isWithCompanyDetail && (
															<>
																<td>
																	<Link
																		className="fs-10 fw-600 text-light company-stock-code text-decoration-none"
																		href={`${ROUTES.COMPANY}?country_id=${getDefaultMarket()}&document_type=${documentType}&company_id=${companyId}`}>
																		{companyStockCode}
																	</Link>
																</td>
																<td className="fs-10 fw-400 standard-padding">
																	{companyName}
																</td>
															</>
														)}
														{isReportOrder && (
															<td className="fs-10 fw-400 standard-padding">
																Q{fqtr} {fyear}
															</td>
														)}
														<td className="fs-10 fw-400 standard-padding">
															{moment(date).format('MMMM Do, YYYY')}
														</td>
														<td className="trim-text fs-10 fw-400 standard-padding">
															<Link
																href={`${ROUTES.SINGLE_REPORT}?country_id=${getDefaultMarket()}&document_type=${documentType}&company_id=${companyId}&document_id=${documentId}`}>
																{title}
															</Link>
														</td>
														<td>
															<InformationPercentageSpan
																className={`${convertValue(sentiment).className
																	} standard-code`}
																textStyle={convertValue(sentiment).color}>
																{
																	convertValue(convertToPercentage(sentiment))
																		.value
																}
															</InformationPercentageSpan>
														</td>
														<td>
															<InformationPercentageSpan
																textStyle={
																	convertValue(convertToPercentage(change))
																		.color
																}>
																{renderChangeIcon()}
															</InformationPercentageSpan>
														</td>
														<td>
															<InformationPercentageSpan
																className="bg-success-100 standard-code"
																textStyle="#107C10">
																{
																	convertValue(convertToPercentage(positive))
																		.value
																}
															</InformationPercentageSpan>
														</td>
														<td>
															<InformationPercentageSpan
																className="bg-danger-33 standard-code"
																textStyle="#D13438">
																{
																	convertValue(convertToPercentage(negative))
																		.value
																}
															</InformationPercentageSpan>
														</td>
														<td>
															<InformationPercentageSpan className="bg-light standard-code">
																{
																	convertValue(convertToPercentage(neutral))
																		.value
																}
															</InformationPercentageSpan>
														</td>
													</TableRowStyled>
												</React.Fragment>
											);
										})}
								</tbody>
							</table>
						</div>
					</div>
					<div className="d-flex justify-content-end">
						{tablePaginationData && (
							<Pagination
								size="small"
								showSizeChanger={false}
								defaultCurrent={currentPage}
								total={total}
								onChange={onChangePagination}
							/>
						)}
					</div>
				</React.Fragment>
			)}
		</WhiteContainer>
	);
};

export default EarningCallTable;
