import {
	DescriptionStyled,
	GreyDiv,
	GrowthTextStyled,
	InfoContainer,
	SubtitleStyled,
	WhiteContainer,
} from '../../Screens/Company/Company.styled';
import {
	Container,
	RightTabContainer,
	RightTabItem,
	ShowMobileOnly,
} from '../../Screens/SingleReport/SingleReport.styled';
import upIcon from '../../Assets/Icon/up-icon.svg';
import downIcon from '../../Assets/Icon/down-icon.svg';
import closeIcon from '../../Assets/Icon/close-icon-blue.svg';
import { ICompany, ICompanyList } from '../../Screens/Company/Company.types';
import { convertToPercentage } from '../../Utils';
import PaginationComponent from './Pagination.component';
import { ColoredLabel, LoadingComponent } from '../../Screens/Industries/Industries.desktop.screen';
import { convertValue } from '../Home/TrendingReportsTable.component';
import { Popover } from '..';


export const GrowthText = (props: {
	isGrowth: boolean;
	sentimentScore: string;
	isNoText?: boolean;
}) => (
	<GrowthTextStyled isGrowth={props.isGrowth}>
		{!props.isNoText && `Change:${' '}`}
		<img
			src={props.isGrowth ? upIcon : downIcon}
			alt="icon"
			className="cursor-pinter"
			onClick={() => { }}
		/>{' '}
		{props.sentimentScore}
	</GrowthTextStyled>
);

export const Company = (props: ICompany) => {
	const { data, isHomePage } = props;
	const {
		stock_code: stockCode,
		latest_sentiment_score: latestSentimentScore,
		sentiment_score_change: sentimentScoreChange,
		name,
		id,
		industry_code: industryCode,
	} = data;

	const fixedLatestSentimentScore = convertValue(
		convertToPercentage(latestSentimentScore)
	).value;
	const fixedSentimentScoreChange = convertValue(
		convertToPercentage(sentimentScoreChange)
	).value;
	const isGrowth =
		convertValue(convertToPercentage(sentimentScoreChange)).originalValue > 0;

	return (
		<GreyDiv
			isGrey={isHomePage}
			isClicked={false}
			onClick={() => {
				props?.onChangeCompanyId?.(id);
			}}>
			<div className="d-flex justify-content-between p-1">
				<ColoredLabel
					className="company-stock-code text-white text-center fw-600 fs-10"
					sector={industryCode}>
					{stockCode || industryCode}
				</ColoredLabel>
				<SubtitleStyled>{fixedLatestSentimentScore}</SubtitleStyled>
			</div>

			<div className="d-flex justify-content-between p-1">
				<DescriptionStyled>{name}</DescriptionStyled>
				{fixedSentimentScoreChange !== 'N/A' && (
					<GrowthText
						isGrowth={isGrowth}
						sentimentScore={fixedSentimentScoreChange}
					/>
				)}
			</div>
		</GreyDiv>
	);
};

const CompanyList = ({
	isSmallerScreen,
	onCloseIcon,
	companyList,
	companyListType,
	setCompanyListType,
	paginationData,
	onChangePagination,
	company,
	setCompany,
	onChangeCompanyId,
}: ICompanyList) => {
	const {
		current_page: currentPage,
		next_page_url: nextPageUrl,
		previous_page_url: previousPageUrl,
	} = paginationData;

	return (
		<Container md={isSmallerScreen ? 12 : ''} className="rounded" style={isSmallerScreen ? { paddingTop: '7vh' } : {}}>
			<WhiteContainer className="rounded p-2 mh-100 position-relative">
				<div className={`position-absolute end-0 ${isSmallerScreen ? "px-5" : "top-0 px-1"}`}>
					<Popover
						popoverTextHeader="**Trends and Movements**"
						popoverTextBody={`Displays companies trending based on user clicks and sentiment changes over the past 30 days.\
							\n1. **Trending Companies**: Displays companies that have garnered the most user clicks over the past 30 days. \
							\n2. **Movers-up**: Lists companies with increasing sentiment scores over the past 30 days.\
							\n3. **Movers-down**: Lists companies with decreasing sentiment scores over the past 30 days.`}
					/>
				</div>
				<RightTabContainer className="justify-content-between">
					<div className="d-flex gap-2">
						<RightTabItem
							onClick={() => {
								setCompanyListType('trending');
							}}
							selected={companyListType === 'trending'}>
							Trending
						</RightTabItem>
						<RightTabItem
							onClick={() => {
								setCompanyListType('movers-up');
							}}
							selected={companyListType === 'movers-up'}>
							Movers up
						</RightTabItem>
						<RightTabItem
							onClick={() => {
								setCompanyListType('movers-down');
							}}
							selected={companyListType === 'movers-down'}>
							Movers down
						</RightTabItem>
					</div>
					<ShowMobileOnly className="bg-blue px-2 pb-1 rounded z-1">
						<img
							src={closeIcon}
							alt="icon"
							className="cursor-pointer mx-1"
							onClick={onCloseIcon || (() => { })}
						/>
					</ShowMobileOnly>
				</RightTabContainer>
				<div className="p-2 d-flex justify-content-between">
					<InfoContainer>Symbol</InfoContainer>
					<InfoContainer>Sentiment Score</InfoContainer>
				</div>
				<table className="table table-striped table-borderless table-fixed">
					<tbody>
						{companyList ?
							companyList.map((data, index) => (
								<tr key={index}>
									<Company
										data={data}
										company={company}
										setCompany={setCompany}
										onChangeCompanyId={onChangeCompanyId}
									/>
								</tr>
							)) : <LoadingComponent />}
					</tbody>
				</table>
				{paginationData && (
					<PaginationComponent
						onChangePagination={onChangePagination}
						previousPageUrl={previousPageUrl}
						nextPageUrl={nextPageUrl}
						currentPage={currentPage}
					/>
				)}
			</WhiteContainer>
		</Container>
	);
};

export default CompanyList;
