import { FaChevronRight } from 'react-icons/fa';

import { IToggleLeft } from '../../Screens/Company/Company.types';

export const ToggleLeft = (props: IToggleLeft) => (
	<div
		className="p-2 bg-white position-fixed rounded shadow top-50"
		onClick={props.onClick}>
		<FaChevronRight />
	</div>
);
