import _ from 'lodash';

import {
	esg9Average,
	IDocuments,
	ISingleReport,
} from '../../Screens/SingleReport/SingleReport.types';
import {
	FlexDisplayNoGap,
	FlexDisplaySpaceBetween,
} from '../../Screens/Preferences/Preferences.styled';
import {
	DisclaimerLabel,
	InformationContainer,
	InformationLabel,
	InformationPercentage,
	ReportTitle,
} from '../../Screens/SingleReport/SingleReport.styled';
import {
	isDesktop,
	LEFT_TAB,
} from '../../Screens/SingleReport/SingleReport.screen';
import { convertToPercentage } from '../../Utils';
import {
	DisclaimerLink
} from '../Authorization/Authorization.styled';

export const renderInformation = (props: ISingleReport) => {
	const { selectedLeftTab = LEFT_TAB.SENTIMENT } = props;

	const renderMeter = () => {
		if (selectedLeftTab === LEFT_TAB.ESG) {
			return (
				<FlexDisplayNoGap>
					<InformationPercentage
						className="bg-success-33 rounded-left-only"
						width="6">
						Environmental
					</InformationPercentage>
					<InformationPercentage className="bg-blue-33" width="6">
						Social
					</InformationPercentage>
					<InformationPercentage className="bg-light text-dark" width="6">
						Governance
					</InformationPercentage>
					<InformationPercentage
						className="bg-dark text-light rounded-right-only"
						width="6">
						Non-ESG
					</InformationPercentage>
				</FlexDisplayNoGap>
			);
		}
		return (
			<FlexDisplayNoGap>
				<InformationPercentage className="bg-danger-100 rounded-left-only">
					100%
				</InformationPercentage>
				<InformationPercentage className="bg-danger-66">
					66%
				</InformationPercentage>
				<InformationPercentage className="bg-danger-33">
					33%
				</InformationPercentage>
				<InformationPercentage className="bg-light text-dark">
					Neutral
				</InformationPercentage>
				<InformationPercentage className="bg-success-33">
					33%
				</InformationPercentage>
				<InformationPercentage className="bg-success-66">
					66%
				</InformationPercentage>
				<InformationPercentage className="bg-success-100 rounded-right-only">
					100%
				</InformationPercentage>
			</FlexDisplayNoGap>
		);
	};

	return (
		<InformationContainer>
			{isDesktop && (
				<FlexDisplaySpaceBetween>
					{isDesktop && (
						<InformationLabel>
							Hover over scored lines to see detailed sentiment and ESG percentages.
						</InformationLabel>
					)}
					{renderMeter()}
				</FlexDisplaySpaceBetween>
			)}
			{!isDesktop && renderMeter()}
		</InformationContainer>
	);
};

export const renderDisclaimer = (isGrey = false) => {
	return (
		<InformationContainer isGrey={isGrey}>
			<ReportTitle>Legal Disclaimer</ReportTitle>
			<DisclaimerLabel className="mt-2 p-0 m-0">
				Updated: 1 December 2023 <br />
				<ul>
					<li>The material contained in FinSent by CBSA is a compilation of corporate
						10-K and 10-Q filings from the SEC and publicly available transcripts of
						earnings conference calls of public companies. The information provided
						to you is for reference purposes only and is not intended to be investment
						advice and should not be construed as investment advice. If investment
						advice or other professional assistance is required with respect to any
						issues referenced in the FinSent by CBSA Service, services of a competent
						investment professional should be immediately sought.</li>
					<li>Discrepancies between the information presented in this application and
						other applications, analyses, studies, articles, and publications may
						exist due to the different methodologies of processing the material in
						accordance with different purposes.</li>
					<li>FinSent by CBSA shall assume no liability for any errors, omissions, or
						inaccuracies in the information provided regardless of their cause. FinSent
						by CBSA shall assume no liability for any decisions made or actions taken
						or not taken by the user of this application in reliance upon any information
						furnished hereunder. Under no circumstances shall FinSent by CBSA be liable
						for any loss, damage, liability, or expense incurred or suffered that is
						claimed to have resulted from the use of this application, including, without
						limitation, any fault, error, omission, interruption, or delay with respect
						thereto. The use of this application indicates your unconditional acceptance
						of the above disclaimer.</li>
				</ul>
			</DisclaimerLabel>
			<DisclaimerLabel>
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<DisclaimerLink href="https://cbsa.hkust.edu.hk/terms-and-conditions-finsent"
						target="_blank" rel="noopener noreferrer"
					>Terms and Conditions</DisclaimerLink>
					<DisclaimerLink href="https://cbsa.hkust.edu.hk/legal-disclaimer-finsent"
						target="_blank" rel="noopener noreferrer"
					>Legal Disclaimer</DisclaimerLink>
					<DisclaimerLink href="https://cbsa.hkust.edu.hk/cookies-policy-finsent"
						target="_blank" rel="noopener noreferrer"
					>Cookies Policy</DisclaimerLink>
					<DisclaimerLink href="https://cbsa.hkust.edu.hk/privacy-policy-finsent"
						target="_blank" rel="noopener noreferrer"
					>Privacy Policy</DisclaimerLink>
				</div>
			</DisclaimerLabel>
		</InformationContainer>
	);
};

export const mappingSentences = (documents: IDocuments) => {
	return documents?.document?.sentences?.map(item => {
		const {
			id: sentenceId,
			sentence,
			type,
			position_in_paragraph: positionInParagraph,
		} = item;

		const scores = _.find(documents?.document?.scores?.sentence_scores, {
			sentence_id: sentenceId,
		});
		const esg3 = _.find(documents?.document?.esg?.sentences_esg?.esg3, {
			sentence_id: sentenceId,
		});
		const esg9 = _.find(documents?.document?.esg?.sentences_esg?.esg9, {
			sentence_id: sentenceId,
		});

		return {
			sentenceId,
			sentence,
			type,
			positionInParagraph,
			scores,
			esg3,
			esg9,
		};
	});
};

export const calculateEsgCompaniesIndustries = (data: esg9Average) => {
	const environmental = convertToPercentage(
		Number(data?.climate_change) +
		Number(data?.natural_capital) +
		Number(data?.pollution_and_waste)
	);
	const social = convertToPercentage(
		Number(data?.human_capital) +
		Number(data?.product_liability) +
		Number(data?.community_relations)
	);
	const governance = convertToPercentage(
		Number(data?.corporate_governance) +
		Number(data?.business_ethics_and_values)
	);

	return {
		nonEsg: convertToPercentage(data?.non_esg),
		environmental,
		social,
		governance,
		climateChange: data?.climate_change,
		naturalCapital: data?.natural_capital,
		pollutionAndWaste: data?.pollution_and_waste,
		humanCapital: data?.human_capital,
		productLiability: data?.product_liability,
		communityRelations: data?.community_relations,
		corporateGovernance: data?.corporate_governance,
		businessEthicsAndValues: data?.business_ethics_and_values,
	};
};

export const calculateESG = (documents: IDocuments) => {
	const climateChange = _.get(
		documents,
		'document.esg.document_esg.esg9.climate_change',
		0
	);
	const naturalCapital = _.get(
		documents,
		'document.esg.document_esg.esg9.natural_capital',
		0
	);
	const pollutionAndWaste = _.get(
		documents,
		'document.esg.document_esg.esg9.pollution_and_waste',
		0
	);
	const humanCapital = _.get(
		documents,
		'document.esg.document_esg.esg9.human_capital',
		0
	);
	const productLiability = _.get(
		documents,
		'document.esg.document_esg.esg9.product_liability',
		0
	);
	const communityRelations = _.get(
		documents,
		'document.esg.document_esg.esg9.community_relations',
		0
	);
	const corporateGovernance = _.get(
		documents,
		'document.esg.document_esg.esg9.corporate_governance',
		0
	);
	const businessEthicsAndValues = _.get(
		documents,
		'document.esg.document_esg.esg9.business_ethics_and_values',
		0
	);
	const nonEsg = convertToPercentage(
		_.get(documents, 'document.esg.document_esg.esg9.non_esg', 0)
	);

	const environmental = convertToPercentage(
		climateChange + naturalCapital + pollutionAndWaste
	);
	const social = convertToPercentage(
		humanCapital + productLiability + communityRelations
	);
	const governance = convertToPercentage(
		corporateGovernance + businessEthicsAndValues
	);

	return {
		nonEsg,
		environmental,
		social,
		governance,
		climateChange,
		naturalCapital,
		pollutionAndWaste,
		humanCapital,
		productLiability,
		communityRelations,
		corporateGovernance,
		businessEthicsAndValues,
	};
};
