import _ from 'lodash';

import { DOCUMENT_TYPE } from '../../Constants';
import { convertToPercentage } from '../../Utils';
import { Container } from '../../Screens/SingleReport/SingleReport.styled';
import { WhiteContainer } from '../../Screens/Company/Company.styled';
import { Company } from '../Company/SentimentList.component';
import { LineChart } from '../index';

const CompanyChartListComponent = (props: {
	data: any;
	documentType: string;
	onClickCard: (isCompany: boolean, value: string | number) => void;
}) => {
	const { data, documentType, onClickCard } = props;

	const lineChartData = _.get(data, 'scores.filing', []);
	// if (documentType === DOCUMENT_TYPE.CALL) {
	// 	lineChartData = _.get(data, 'scores.call', []);
	// }

	const reformatLineChartData = lineChartData.map((item: any) => {
		const value = convertToPercentage(
			Number(item?.score?.positive) - Number(item?.score?.negative)
		);
		return {
			date: new Date(item?.date),
			value: Number(value),
		};
	});

	const isCompany = !!data.stock_code;
	const id = isCompany ? data?.id : data?.industry_code;

	return (
		<Container md={6}>
			<WhiteContainer
				className="rounded p-2 cursor-pointer"
				onClick={() => onClickCard(isCompany, id)}>
				<Company isHomePage={true} company={data} data={data} />
				<div className="p-1">
					<LineChart
						data={reformatLineChartData}
						title={''}
						showToolTips={true}
					/>
				</div>
			</WhiteContainer>
		</Container>
	);
};

export default CompanyChartListComponent;
