import styled, { css } from 'styled-components';
import { FaAngleDown, FaAngleLeft, FaAngleUp } from 'react-icons/fa';
import { Col } from 'react-bootstrap';

import {
	FlexDisplayMediumGap,
	FlexDisplaySmallGap,
} from '../Preferences/Preferences.styled';
import { desktop, laptop, tablet } from '../../Utils/layout.utils';

export const RootContainer = styled.div`
	background-color: #dcdfe7;
	width: 100%;
	height: 100%;
	padding: 8px;
	${tablet(css`
		background-color: #fff;
	`)};
`;

export const ComponentContainer = styled(FlexDisplaySmallGap)`
	align-items: normal;

	${tablet(css`
		display: none;
	`)};
`;

export const ShowDesktopOnly = styled.div`
	${tablet(css`
		display: none;
	`)};
`;

export const ShowMobileOnly = styled.div`
	${laptop(css`
		display: none;
	`)};

	${desktop(css`
		display: none;
	`)};

	${tablet(css`
		display: block;
	`)};
`;

export const Container = styled(Col)``;

export const InformationContainer = styled.div<{ isGrey?: boolean }>`
	padding: 1vw;
	background-color: ${({ isGrey }) => (isGrey ? '#E0E0E0' : '#fff')};
	border-radius: 4px;
	min-height: 100%;

	${tablet(css`
		padding: 3vw;
	`)};
`;

export const InformationLabel = styled.div`
	color: #4e4e4e;
	font-size: 10px;
	font-style: normal;
	font-weight: 600;
	line-height: 12px;
`;

export const DisclaimerLabel = styled.p`
	color: #4e4e4e;
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
`;

export const InformationPercentage = styled.div<{ width?: string }>`
	width: ${({ width }) => (width ? width + 'vw' : '5vw')};
	color: #4e4e4e;
	font-size: 10px;
	font-style: normal;
	font-weight: 600;
	line-height: 20px;
	text-align: center;

	${tablet(css`
		width: 25%;
	`)};
`;

export const LeftComponent = styled.div`
	padding: 1vw;
`;

export const ReportTitleContainer = styled.div`
	padding: 1vw;
	border-bottom: 2px solid #e0e0e0;
`;

export const ReportTitle = styled.h3`
	color: #4e4e4e;
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: 20px;
	margin: 0;
`;

export const BackIconContainer = styled.div`
	background-color: #f5f5f5;
	padding: 0.5vh;
`;

export const BackIcon = styled(FaAngleLeft)`
	font-size: 18px;
	color: #62656f;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
`;

export const ExpandIcon = styled(FaAngleDown)`
	font-size: 18px;
	color: #62656f;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	background-color: #efefef;
	border-radius: 4px;
`;

export const NarrowIcon = styled(FaAngleUp)`
	font-size: 18px;
	color: #62656f;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	background-color: #efefef;
	border-radius: 4px;
`;

export const LeftComponentTab = styled(FlexDisplayMediumGap)`
	background-color: #e7e7e7;
	border-radius: 4px;
	padding: 0.5vh 0.3vw;
	box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.08);

	${tablet(css`
		padding: 0.5vh 0.8vw;
	`)};
`;

export const LeftComponentItem = styled.div<{
	selected?: boolean;
	width?: string;
}>`
	color: ${({ selected }) => (selected ? '#2D2D2D' : '#4e4e4e')};
	background-color: ${({ selected }) => (selected ? '#fff' : ' #e7e7e7')};
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;
	text-align: center;
	cursor: pointer;
	padding: 0.2vh 0.5vw;
	border-radius: 4px;
	width: ${({ width }) => width || '6.5vw'};
	transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;

	${tablet(css`
		width: 50%;
	`)};
`;

export const RightTabItem = styled.div<{ selected?: boolean }>`
	color: ${({ selected }) => (selected ? '#2D2D2D' : '#4e4e4e')};
	font-weight: ${({ selected }) => (selected ? 700 : 500)};
	font-size: 11px;
	font-style: normal;
	line-height: 20px;
	cursor: pointer;
	padding-bottom: 1rem;
	transition: color 0.3s ease-in-out, font-weight 0.1s ease-in-out;
	border-bottom: ${({ selected }) => (selected ? '1px solid #225FB0' : '0px')};
`;

export const RightTabContainer = styled(FlexDisplayMediumGap)`
	border-bottom: 1px solid #e0e0e0;

	${tablet(css`
		gap: 5vw;
	`)};
`;

export const CompanyName = styled.h5`
	color: #4e4e4e;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
`;

export const CompanyPercentage = styled.h2`
	color: #4e4e4e;
	font-size: 40px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
`;

export const LabelReport = styled.p`
	color: #4e4e4e;
	font-size: 13px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	margin: 0;
	padding: 0;
`;

export const LabelReportFloating = styled(LabelReport)`
	font-size: 10px;
`;

export const LabelDate = styled.small`
	color: #4e4e4e;
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
`;

export const LabelPercentage = styled.div<{
	color?: string;
	backgroundColor?: string;
}>`
	box-sizing: border-box;
	color: ${({ color }) => color || '#fff'};
	background-color: ${({ backgroundColor }) => backgroundColor || '#225FB0'};
	padding: 4px 16px;
	font-size: 13px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	border-radius: 4px;
	width: 100%;
	text-align: center;

	${tablet(css`
		text-align: center;
	`)};
`;

export const SentimentContainer = styled.div`
	margin-top: 1vh;
	max-height: 220px;
	overflow: auto;
	border: 1px solid #e0e0e0;
	border-radius: 4px;
	padding: 0.5vh 0.5vw;

	${tablet(css`
		padding: 0.5vh 1.5vw;
	`)};
`;

export const SentimentItem = styled(FlexDisplayMediumGap)`
	margin-top: 0.5vh;
	margin-bottom: 0.5vh;
	padding: 0.5vh 0;
	border-bottom: 1px solid #e0e0e0;
`;

export const SentimentPercentage = styled(LabelPercentage) <{ width?: number }>`
	text-align: center;
	font-size: 10px;
	width: ${({ width }) => (width ? `${width.toString()}rem` : '3 rem')};
	padding: 0.5vh 0.3vh;
`;

export const SentimentLabel = styled.div`
	color: #4e4e4e;
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
`;

export const TooltipAlertParagraph = styled.p`
	text-align: center;
	font-size: 10px;
	padding: 0.5vh 0.3vh;
	font-style: italic;
	margin: 0;
`;

export const MobileSection = styled.div`
	background-color: #fff;
	padding: 3vw;
	border-radius: 4px;
`;

export const TopSectionMobile = styled(MobileSection)`
	box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.04);
	margin: 0 1vw 1vw;
`;

export const FloatingContainer = styled.div`
	position: fixed;
	bottom: 20px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 999;
	width: 50%;
`;
