import { IAuthorizationProps } from '../Authorization/Authorization.types';
import {
	AuthorizationModal,
	ModalBody,
} from '../Authorization/Authorization.styled';
import { FlexDisplay } from '../../Screens/Preferences/Preferences.styled';
import { Button } from '../index';
import discoverIllustration from '../../Assets/finsent-welcome.png';
import {
	Caption,
	Image,
	Title,
} from '../FirstTimeModal/FirstTimeModal.component';
import { AUTHORIZATION_STEP } from '../../Constants';


const DiscoverModalComponent = (props: IAuthorizationProps) => {
	const { setDiscoverTutorialModal, setIsOpenAuthorization, setAuthorizationStep } = props;

	return (
		<AuthorizationModal
			show={props.isDiscoverTutorialModal}
			backdrop="false"
			centered={true}>
			<ModalBody>
				<center>
					<Image src={discoverIllustration} alt="welcome" />
				</center>
				<Title>Welcome to FinSent</Title>
				<Caption>
					Kickstart your journey to informed decisions with FinSent today.
				</Caption>
				<div>
					<FlexDisplay>
						<Button
							label="Sign in"
							onClick={() => {
								setDiscoverTutorialModal(false);
								setAuthorizationStep(AUTHORIZATION_STEP.LOGIN);
								setIsOpenAuthorization(true);
							}}
						/>
					</FlexDisplay>
				</div>
			</ModalBody>
		</AuthorizationModal>
	);
};

export default DiscoverModalComponent;
