import { CALL, DOCUMENT_TYPE, MARKET_DROPDOWN_SELECT } from '../Constants';
import { ROUTES } from '../Constants/routes';

export const toProperCase = (input: string) => {
	return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
};

export const convertToPercentage = (value: string | number, fixedDecimal = 2) => {
	return (parseFloat(<string>value) * 100).toFixed(fixedDecimal);
};

export const hexToRgbA = (hex: string, alpha: number) => {
	const r = parseInt(hex.slice(1, 3), 16);
	const g = parseInt(hex.slice(3, 5), 16);
	const b = parseInt(hex.slice(5, 7), 16);

	if (alpha) {
		return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
	} else {
		return 'rgb(' + r + ', ' + g + ', ' + b + ')';
	}
};

export const trimStringToLength = (str: string, maxLength?: number) => {
	if (maxLength && (str.length > maxLength)) {
		return str.substring(0, maxLength);
	} else {
		return str;
	}
};

export const getDefaultCalls = () => {
	let defaultCalls = DOCUMENT_TYPE.FILING;

	const selectedCall = localStorage.getItem('selectedCall');

	if (selectedCall) {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
		defaultCalls = JSON.parse(selectedCall).value;
	}

	return defaultCalls;
};

export const getDefaultMarket = () => {
	let defaultCalls = MARKET_DROPDOWN_SELECT[0].id;

	const selectedMarket = localStorage.getItem('selectedMarket');

	if (selectedMarket) {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
		defaultCalls = JSON.parse(selectedMarket).id;
	}

	return defaultCalls;
};

export const getCallsLabel = (type: string) => {
	// if (type === DOCUMENT_TYPE.CALL) {
	// 	return CALL[0].label;
	// }

	return CALL[0].label;
};

export const checkOutMoreDetails = (
	documentList: any,
	documentType: string
) => {
	if (documentList?.length <= 0) {
		return '';
	}

	const documentId = documentList[0]?.id || '';
	const companyId = documentList[0]?.company?.id || '';

	return `../${ROUTES.SINGLE_REPORT}?country_id=${getDefaultMarket()}&document_type=${documentType}&company_id=${companyId}&document_id=${documentId}`;
};
