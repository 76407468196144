import { useState, useEffect } from 'react';

import { SCREEN_SIZE } from '../Constants';

const { MOBILE, TABLET, LAPTOP, DESKTOP, LARGE_DESKTOP } = SCREEN_SIZE;

const useResizeScreen = () => {
	const getSize = () => {
		if (window.innerWidth < 425) {
			return MOBILE;
		}
		if (window.innerWidth < 930) {
			return TABLET;
		}
		if (window.innerWidth < 1400) {
			return LAPTOP;
		}
		if (window.innerWidth < 2560) {
			return DESKTOP;
		}
		return LARGE_DESKTOP;
	};

	const [screenType, setScreenType] = useState(getSize());

	const handleResize = () => {
		setScreenType(getSize());
	};

	useEffect(() => {
		if (typeof window !== 'undefined') {
			window.addEventListener('resize', handleResize);
		}

		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return screenType;
};

export default useResizeScreen;
