import React from 'react';
import styled from 'styled-components';

import { IAuthorizationProps } from '../Authorization/Authorization.types';
import {
	AuthorizationModal,
	ModalBody,
} from '../Authorization/Authorization.styled';
import { FlexDisplay } from '../../Screens/Preferences/Preferences.styled';
import { Button } from '../index';
import { BUTTON_TYPE } from '../Button/Button.component';
import welcomeIcon from '../../Assets/finsent-welcome.svg';

export const Title = styled.h3`
	color: #000;
	text-align: center;
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	letter-spacing: -0.96px;
	margin-top: 3vh;
`;

export const Caption = styled.p`
	color: #585858;
	text-align: center;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	letter-spacing: -0.16px;
`;

export const Image = styled.img`
	width: 8rem;
	padding-bottom: 1vh;
`;

const FirstTimeModalComponent = (
	props: IAuthorizationProps
): React.JSX.Element => {
	const { setIsFirstTimeUser, setIsOpenAuthorization } = props;

	const screenWidth = window.innerWidth;

	if (screenWidth <= 930) {
		return (
			<AuthorizationModal
				show={props.isFirstTimeUser}
				backdrop="true"
				size="xl"
				centered={true}>
				<ModalBody className="py-4 px-3">
					<center>
						<Image src={welcomeIcon} alt="welcome" />
					</center>
					<Title>Welcome to FinSent</Title>
					<Caption className="mb-5">
						Sign in to unleash the full potential of FinSent
					</Caption>
					<div className="mt-5">
						<Button
							label="Continue to Demo"
							onClick={() => {
								setIsFirstTimeUser(false);
							}}
							kind={BUTTON_TYPE.SECONDARY}
							mobilePadding="1.5vw"
						/>
						<div className="mt-2">
							<Button
								label="Sign in / Register"
								onClick={() => {
									setIsFirstTimeUser(false);
									setIsOpenAuthorization(true);
								}}
								mobilePadding="1.5vw"
							/>
						</div>
					</div>
				</ModalBody>
			</AuthorizationModal>
		);
	}

	return (
		<AuthorizationModal
			show={props.isFirstTimeUser}
			backdrop="true"
			centered={true}>
			<ModalBody>
				<center>
					<Image src={welcomeIcon} alt="welcome" />
				</center>
				<Title>Welcome to FinSent</Title>
				<Caption>Sign in to unleash the full potential of FinSent</Caption>
				<div>
					<FlexDisplay>
						<Button
							label="Continue to Demo"
							onClick={() => {
								setIsFirstTimeUser(false);
							}}
							kind={BUTTON_TYPE.SECONDARY}
						/>
						<Button
							label="Sign in / Register"
							onClick={() => {
								setIsFirstTimeUser(false);
								setIsOpenAuthorization(true);
							}}
						/>
					</FlexDisplay>
				</div>
			</ModalBody>
		</AuthorizationModal>
	);
};

export default FirstTimeModalComponent;
