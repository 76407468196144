import {
	css,
	DefaultTheme,
	FlattenInterpolation,
	FlattenSimpleInterpolation,
	ThemedStyledProps,
} from 'styled-components';
import { AnyIfEmpty } from 'react-redux';

const size = {
	mobile: '425px',
	tablet: '930px',
	laptop: '1024px',
	desktop: '2560px',
	largeDesktop: '6000px',
};

export const mobile = (
	inner: FlattenInterpolation<
		ThemedStyledProps<NonNullable<unknown>, AnyIfEmpty<DefaultTheme>>
	>
) => css`
	@media (max-width: ${size.mobile}) {
		${inner};
	}
`;

export const largeDesktop = (inner: FlattenSimpleInterpolation) => css`
	@media (max-width: ${size.largeDesktop}) {
		${inner};
	}
`;

export const tablet = (inner: FlattenSimpleInterpolation) => css`
	@media (max-width: ${size.tablet}) {
		${inner};
	}
`;

export const laptop = (inner: FlattenSimpleInterpolation) => css`
	@media (max-width: ${size.laptop}) {
		${inner};
	}
`;

export const desktop = (inner: FlattenSimpleInterpolation) => css`
	@media (max-width: ${size.desktop}) {
		${inner};
	}
`;

export const isMobile = () => window.innerWidth <= 930;
