import React, { useState } from 'react';
import _ from 'lodash';
import styled from 'styled-components';

import { WhiteContainer } from '../Company/Company.styled';
import { ColoredLabel } from '../Industries/Industries.desktop.screen';
import { LIST_TYPE } from '../Industries/Industries.screen';
import {
	Container,
	RightTabContainer,
	RightTabItem,
	ShowMobileOnly,
} from '../SingleReport/SingleReport.styled';
import { IReport } from './Report.types';
import { Button } from '../../Components';
import { BUTTON_TYPE } from '../../Components/Button/Button.component';
import SideTab from '../../Components/Compare/SideTab.component';
import EarningCallTable from '../../Components/Company/EarningTable.component';
import { renderDisclaimer } from '../../Components/SingleReport';
import { Popover } from '../../Components';


export const FilterReportsButton = styled.span`
	background: #e0ecfc;
	color: #1f78b4;
	font-size: 10px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
`;

const ReportMobile = (props: IReport) => {
	const [showFilter, setShowFilter] = useState(false);
	const {
		documentList,
		industryCode,
		setIndustryCode,
		onClickConfirm,
		filtered,
		onDeleteList,
		data,
		scrollDirection,
		setScrollDirection,
		setStartDate,
		setFinishDate,
		selectedRange,
		setSelectedRange,
		setDocumentType,
		documentType,
		getAllDocument,
		selectedPaginationData,
		trendingPaginationData,
		tab,
		setTab,
		setIsSubmitted,
		tempFilter,
		getCompaniesNextPage,
		isLoadingScrollCompany,
		startDate,
		finishDate,
	} = props;

	const { SELECT_INDUSTRY, TRENDING } = LIST_TYPE;

	const documentListTab = _.get(documentList, tab, []);
	const paginationTab = tab === TRENDING ? trendingPaginationData : selectedPaginationData;

	const onClickConfirmMobile = () => () => {
		setTab(LIST_TYPE.SELECT_INDUSTRY);
		setIsSubmitted(true);
		setShowFilter(false);
	};

	const sideTabProps = {
		industryCode,
		setIndustryCode,
		onClickConfirm,
		filtered: tempFilter,
		onDeleteList,
		data,
		scrollDirection,
		setScrollDirection,
		setStartDate,
		setFinishDate,
		selectedRange,
		setSelectedRange,
		setDocumentType,
		documentType,
		onConfirm: onClickConfirmMobile,
		getCompaniesNextPage,
		isLoadingScrollCompany,
		label: 'Confirm',
		startDate,
		finishDate,
		isRenderSentimentChart: !documentListTab
	};

	return (
		<React.Fragment>
			<ShowMobileOnly>
				<div className="bg-grey pt-2">
					{!showFilter && (
						<Container md={9} className="bg-white py-2">
							<RightTabContainer className="d-flex justify-content-between px-2 table-scrollable">
								<div className="d-flex gap-2">
									<RightTabItem
										onClick={() => {
											setTab(TRENDING);
										}}
										selected={tab === TRENDING}>
										Trending Reports
										<Popover
											className='ps-1'
											popoverTextHeader="**Trending Reports**"
											popoverTextBody={`Displays reports that have garnered the most user clicks over the past 30 days.`}
										/>
									</RightTabItem>
									{filtered.length > 0 && (
										<RightTabItem
											className="pb-0"
											onClick={() => {
												setTab(SELECT_INDUSTRY);
											}}
											selected={tab === SELECT_INDUSTRY}>
											<div className="bg-lightgrey rounded-top px-2 py-2 d-flex gap-1 align-content-center">
												<p className="mb-0 align-self-center">Selected</p>
												{filtered.map((code, index) => {
													return (
														<div
															className="bg-white rounded px-1 py-1"
															key={index}>
															<ColoredLabel
																sector={code[1]}
																className="text-white rounded p-1 fw-600 fs-10 mx-1">
																{code[1]}
															</ColoredLabel>
														</div>
													);
												})}
											</div>
										</RightTabItem>
									)}
								</div>
								<RightTabItem
									onClick={() => {
										setShowFilter(true);
									}}>
									<FilterReportsButton className="standard-code">
										Filter Reports
									</FilterReportsButton>
								</RightTabItem>
							</RightTabContainer>
							<EarningCallTable
								documentList={documentListTab}
								tablePaginationData={paginationTab}
								onChangePagination={page => {
									getAllDocument(`/?page=${page}`)();
								}}
								isMobileVersion={true}
								isWithoutTabHead={true}
								isReportOrder={true}
							/>
						</Container>
					)}
					{showFilter && (
						<WhiteContainer className="rounded px-3 pt-2 pb-4">
							<SideTab
								{...sideTabProps}
								isWithBackButton={true}
								backButtonOnClick={() => {
									setShowFilter(false);
								}}
							/>
							<div className="my-2">
								<Button
									kind={BUTTON_TYPE.SECONDARY}
									rounded
									label="Cancel"
									onClick={() => setShowFilter(false)}
								/>
							</div>
						</WhiteContainer>
					)}

					<div className="mt-2 bg-white">
						{renderDisclaimer()}
					</div>
				</div>
			</ShowMobileOnly>
		</React.Fragment>
	);
};

export default ReportMobile;
