// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { useState } from 'react';
import { Drawer } from 'antd';
import styled from 'styled-components';
import { FaArrowRight, FaExpandAlt } from 'react-icons/fa';
import moment from 'moment';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';

import useResizeScreen from '../../Hooks/useResizeScreen';
import { SCREEN_SIZE } from '../../Constants';
import {
	INDUSTRY_TYPE,
	LIST_TYPE,
	MostViewedCompanies,
} from './Industries.screen';
import {
	CompanyName,
	CompanyPercentage,
	Container,
	RightTabContainer,
	RightTabItem,
	RootContainer,
	ShowMobileOnly,
} from '../SingleReport/SingleReport.styled';
import { InfoContainer, WhiteContainer } from '../Company/Company.styled';
import { Button, LineChart } from '../../Components';
import {
	ESGComponent,
	SentimentComponent,
} from '../../Components/SingleReport/RightTab.component';
import KeywordTable from '../../Components/Company/KeywordTable.component';
import YearFooter from '../../Components/Company/YearFooter.component';
import EarningCallTable from '../../Components/Company/EarningTable.component';
import { ToggleLeft } from '../../Components/Company/ToggleLeft.component';
import closeIcon from '../../Assets/Icon/close-icon-blue.svg';
import { renderDisclaimer } from '../../Components/SingleReport';
import type { IIndustryDesktop, IIndustryList } from './Industries.types';
import {
	checkOutMoreDetails,
	convertToPercentage,
	getCallsLabel,
	hexToRgbA,
} from '../../Utils';
import PaginationComponent from '../../Components/Company/Pagination.component';
import { Company } from '../../Components/Company/SentimentList.component';
import IndustryDropdown from '../../Components/Industries/Dropdown.component';
import {
	FlexDisplaySmallGap,
	FlexDisplaySpaceBetween,
} from '../Preferences/Preferences.styled';
import { convertValue } from '../../Components/Home/TrendingReportsTable.component';
import { InformationPercentageSpan } from '../Company/Company.styled';

import { Popover } from '../../Components';


export const getColorScheme = (industryCode?: string) => {
	if (!industryCode) return '#225FB0';
	if (industryCode.match(/^50/)) return '#466BAE';
	if (industryCode.match(/^25/)) return '#CC9E22';
	if (industryCode.match(/^30/)) return '#C16E00';
	if (industryCode.match(/^10/)) return '#5E5F62';
	if (industryCode.match(/^40/)) return '#A0A0A0';

	if (industryCode.match(/^35/)) return '#B7354D';
	if (industryCode.match(/^20/)) return '#0D1E88';
	if (industryCode.match(/^45/)) return '#00764B';
	if (industryCode.match(/^15/)) return '#546292';
	if (industryCode.match(/^60/)) return '#A2A7BB';

	if (industryCode.match(/^55/)) return '#029D8F';
	return '#225FB0';
};

export const ColoredLabel = styled.span<{
	sector?: string;
	lineLabelColor?: string;
}>`
	background-color: ${props => {
		if (props.lineLabelColor) return props.lineLabelColor;
		if (props.sector) {
			const opacity =
				props.sector.length === 2 || !Number(props.sector)
					? 0
					: 1 - props.sector.length / 20;
			return hexToRgbA(getColorScheme(props.sector), opacity);
		}
		return '#225fb0';
	}};
	margin-bottom: 0 !important;
`;

export const LoadingComponent = () => (
	<div className="d-flex justify-content-center align-items-center w-100 h-100 flex-wrap">
		<Spinner
			animation="grow"
			size="sm"
			style={{ backgroundColor: '#225FB0' }}
		/>
		<Spinner
			animation="grow"
			size="sm"
			style={{ backgroundColor: '#225FB0' }}
		/>
		<Spinner
			animation="grow"
			size="sm"
			style={{ backgroundColor: '#225FB0' }}
		/>
	</div>
);

export const IndustryList = (props: IIndustryList) => {
	const {
		industryCode,
		setIndustryCode,
		isSmallerScreen,
		onCloseIcon,
		industryList,
		industry,
		setIndustry,
		paginationData,
		onChangePagination,
		industryListType,
		setIndustryListType,
		onClickConfirm,
		onChangeIndustryCode,
		documentList,
	} = props;

	const {
		current_page: currentPage,
		next_page_url: nextPageUrl,
		previous_page_url: previousPageUrl,
	} = paginationData;

	const { SELECT_INDUSTRY, TRENDING, MOVERS_UP, MOVERS_DOWN } = LIST_TYPE;

	return (
		<Container
			md={isSmallerScreen ? 12 : 2}
			className="rounded position-relative"
			style={isSmallerScreen ? { paddingTop: '10px' } : {}}>
			<div className={`position-absolute end-0 z-1 top-0 pe-1`}>
				<Popover
					popoverTextHeader="**Trends and Movements**"
					popoverTextBody={`Displays industries trending based on user clicks and sentiment changes over the past 30 days.\
							\n1. **Industry Selector**: Choose an industry from the dropdown menu.
							\n2. **Trending Industries**: Displays industries that have garnered the most user clicks over the past 30 days. \
							\n3. **Movers-up**: Lists industries with increasing sentiment scores over the past 30 days.\
							\n4. **Movers-down**: Lists industries with decreasing sentiment scores over the past 30 days.`}
				/>
			</div>
			<WhiteContainer className="rounded py-3 px-2 mh-100 position-relative">

				<RightTabContainer className="justify-content-between scrolling-wrapper">
					<div className="d-flex gap-2">
						<RightTabItem
							onClick={() => setIndustryListType(SELECT_INDUSTRY)}
							selected={industryListType === SELECT_INDUSTRY}>
							Select Industry
						</RightTabItem>
						<RightTabItem
							onClick={() => setIndustryListType(TRENDING)}
							selected={industryListType === TRENDING}>
							Trending
						</RightTabItem>
						<RightTabItem
							onClick={() => setIndustryListType(MOVERS_UP)}
							selected={industryListType === MOVERS_UP}>
							Movers Up
						</RightTabItem>
						<RightTabItem
							onClick={() => setIndustryListType(MOVERS_DOWN)}
							selected={industryListType === MOVERS_DOWN}>
							Movers Down
						</RightTabItem>
					</div>
					<ShowMobileOnly className="bg-blue px-2 pb-1 rounded z-2">
						<img
							src={closeIcon}
							alt="icon"
							className="cursor-pointer mx-1"
							onClick={onCloseIcon || (() => { })}
						/>
					</ShowMobileOnly>
				</RightTabContainer>
				{industryListType === SELECT_INDUSTRY ? (
					<div className="d-flex flex-column gap-2 mh-100">
						<IndustryDropdown
							industryCode={industryCode}
							setIndustryCode={setIndustryCode}
						/>
						<div className="d-flex justify-content-center">
							<Button
								onClick={onClickConfirm}
								width="75%"
								mobileWidth="60%"
								label="Confirm"
								isLoading={!documentList}
								rounded
							/>
						</div>
					</div>
				) : (
					<React.Fragment>
						<div className="p-2 d-flex justify-content-between">
							<InfoContainer>Symbol</InfoContainer>
							<InfoContainer>Sentiment Score</InfoContainer>
						</div>
						<table className="table table-striped table-borderless table-fixed">
							<tbody>
								{industryList ? (
									industryList?.map((data, index) => (
										<tr key={index}>
											<Company
												data={data}
												company={industry}
												setCompany={setIndustry}
												onChangeCompanyId={() => {
													onChangeIndustryCode(data?.industry_code);
												}}
											/>
										</tr>
									))
								) : (
									<LoadingComponent />
								)}
							</tbody>
						</table>
						{paginationData && (
							<PaginationComponent
								onChangePagination={onChangePagination}
								previousPageUrl={previousPageUrl}
								nextPageUrl={nextPageUrl}
								currentPage={currentPage}
							/>
						)}
					</React.Fragment>
				)}
			</WhiteContainer>
		</Container>
	);
};

const IndustriesDesktop = (props: IIndustryDesktop) => {
	const [isOpenIndustryList, showIndustryList] = useState(false);

	const {
		industryType,
		keywordList,
		earningCalls,
		industryCode,
		list: industryList,
		setList: setIndustryList,
		data: industry,
		setData: setIndustry,
		paginationData,
		setPaginationData,
		onChangePagination,
		tabType: industryListType,
		setTabType: setIndustryListType,
		documentList,
		setDocumentList,
		tablePaginationData,
		setTablePaginationData,
		setDocument,
		document,
		documentId,
		setDocumentId,
		onDocumentUrlClicked,
		getDocumentList,
		onClickFilter,
		startDate,
		setStartDate,
		finishDate,
		setFinishDate,
		scoresList,
		onClickConfirm,
		scoresAverage,
		onChangeDate,
		filter,
		setFilter,
		esgProps,
		onClickViewMore,
		navigateToCompare,
		onChangeIndustryCode,
		documentType,
		topCompanies,
	} = props;

	const navigate = useNavigate();

	const screenType = useResizeScreen();
	const isSmallerScreen = screenType === SCREEN_SIZE.LAPTOP;

	const openToggle = () => showIndustryList(true);

	const scoresListData =
		(scoresList &&
			scoresList.map(data => ({
				date: new Date(data?.date),
				value: convertToPercentage(
					Number(data?.score?.positive) - Number(data?.score?.negative)
				),
			}))) ||
		null;

	const isSector = industryType === INDUSTRY_TYPE.SECTOR;

	const setIndustryCode = (value: any) => {
		props.setIndustryCode(value);
	};

	const yearsDifference = moment(finishDate).diff(moment(startDate), 'years');

	return (
		<React.Fragment>
			<RootContainer>
				<div className="align-items-stretch d-flex mb-2">
					{isSmallerScreen ? (
						<ToggleLeft onClick={openToggle} />
					) : (
						<IndustryList
							isSmallerScreen={isSmallerScreen}
							industryCode={industryCode}
							setIndustryCode={setIndustryCode}
							industryList={industryList}
							setIndustryList={setIndustryList}
							industry={industry}
							setIndustry={setIndustry}
							paginationData={paginationData}
							setPaginationData={setPaginationData}
							onChangePagination={onChangePagination}
							industryListType={industryListType}
							setIndustryListType={setIndustryListType}
							onClickConfirm={onClickConfirm}
							onChangeIndustryCode={onChangeIndustryCode}
							startDate={startDate}
							finishDate={finishDate}
							documentList={documentList}
						/>
					)}
					<Container
						md={isSmallerScreen ? 9 : 7}
						className={`rounded ${!isSmallerScreen && 'margin-left-8'}`}>
						<div className="d-flex" style={{ height: '50vh' }}>
							<WhiteContainer className="margin-right-2 rounded w-75 p-md-2 d-flex flex-wrap justify-content-between">
								<CompanyName className="p-md-1 m-0 d-flex align-items-center">
									<h5 className="fs-20 m-0">Sentiment score by</h5>
									<ColoredLabel
										sector={industry?.industry_code}
										isSector={isSector}
										className="text-white standard-code fw-600 fs-10 mx-2">
										{industry?.industry_code}
									</ColoredLabel>
									<h5 className="fs-10 m-0">{industry?.name}</h5>
								</CompanyName>
								<div
									className="d-flex align-items-center"
									onClick={props.navigateToCompare}
									style={{ cursor: 'pointer' }}>
									<h5 className="fs-10 fw-400 mb-0 me-1">Advanced </h5>
									<div className="bg-lightgrey rounded px-1">
										<FaExpandAlt className="fs-10" />
									</div>
								</div>
								{scoresListData ? (
									<LineChart
										data={scoresListData}
										isGradient
										title="Sentiment"
										isSmallerScreen={false}
									/>
								) : (
									<LoadingComponent />
								)}
							</WhiteContainer>
							<WhiteContainer className="rounded w-25">
								<KeywordTable
									keywordList={keywordList}
									keywordPagination={{ current_page: 1 }}
									navigateToCompare={navigateToCompare}
								/>
							</WhiteContainer>
						</div>
						<YearFooter
							startDate={startDate}
							setStartDate={setStartDate}
							finishDate={finishDate}
							setFinishDate={setFinishDate}
							onChangeDate={onChangeDate}
						/>
						<EarningCallTable
							pageType='Industry'
							// onClick={onClickFilter}
							earningCalls={earningCalls}
							documentList={documentList}
							setDocumentList={setDocumentList}
							tablePaginationData={tablePaginationData}
							setTablePaginationData={setTablePaginationData}
							onChangePagination={value => {
								getDocumentList(`/?page=${value}`)();
							}}
							document={document}
							setDocument={setDocument}
							documentId={documentId}
							setDocumentId={setDocumentId}
							onDocumentUrlClicked={onDocumentUrlClicked}
							// isDropdown={true}
							items={[
								{
									label: 'Ascending',
									key: '0',
								},
								{ label: 'Descending', key: '1' },
							]}
							filter={filter}
							setFilter={setFilter}
							onClickViewMore={onClickViewMore}
							isWithCompanyDetail
							isReportOrder
							componentTitle={getCallsLabel(documentType)}
						/>
					</Container>
					<Container className="rounded bg-white margin-left-8">
						<WhiteContainer className="rounded px-3 py-2">
							<RightTabContainer>
								<RightTabItem onClick={() => { }} selected={true}>
									Overview
									<Popover
										className='ps-1'
										popoverTextHeader="**Sentiment Overview**"
										popoverTextBody={`Details the latest sentiments and trends within the industry.`}
									/>
								</RightTabItem>
							</RightTabContainer>
							<CompanyName className="mt-2 d-flex align-items-center">
								<ColoredLabel
									onClick={navigateToCompare}
									sector={industry?.industry_code}
									className="text-white standard-code fw-600 fs-10">
									{industry?.industry_code}
								</ColoredLabel>
								<h5 className="fs-10 m-0 mx-2" onClick={navigateToCompare}>
									{industry?.name}
								</h5>
							</CompanyName>
							<FlexDisplaySpaceBetween>
								<CompanyPercentage className="mt-1">
									{convertToPercentage(industry?.latest_sentiment_score)}%
								</CompanyPercentage>

								<FlexDisplaySmallGap>
									<span className="fs-10 fw-400">1 Day Average</span>
									<Popover
										className='ps-1'
										popoverTextHeader="**Latest sentiment**"
										popoverTextBody={`Shows the average of the reports in the latest 24 hours.`}
									/>
								</FlexDisplaySmallGap>
							</FlexDisplaySpaceBetween>
							<FlexDisplaySpaceBetween>
								<div className="bg-lightblue text-darkblue standard-code fw-600 fs-10">
									Industry average ({yearsDifference} Years)
									<Popover
										className='ps-1'
										popoverTextHeader="**Historic Average Sentiment**"
										popoverTextBody={`Shows the industry's sentiment **excluding** the latest from the selected date range.`}
									/>
								</div>
								<InformationPercentageSpan
									textStyle={convertValue(industry?.average_sentiment_score).color}
									className={`${convertValue(industry?.average_sentiment_score).className
										} standard-code`}>
									{convertToPercentage(industry?.average_sentiment_score)}%
								</InformationPercentageSpan>
							</FlexDisplaySpaceBetween>

							<MostViewedCompanies topCompanies={topCompanies} />
							<RightTabContainer className="mt-5">
								<RightTabItem onClick={() => { }} selected={true}>
									Sentiments
									<Popover
										className='ps-1'
										popoverTextHeader="**Average Sentiment**"
										popoverTextBody={`Shows the industry’s average sentiment over the selected timeframe (default: 5 years).`}
									/>
								</RightTabItem>
							</RightTabContainer>
							<SentimentComponent
								documents={document}
								scoresAverage={scoresAverage}
								documentType={documentType}
								isCompanyPage
								scoreType='COMPANY'
							/>
							<RightTabContainer className="mt-5">
								<RightTabItem onClick={() => { }} selected={true}>
									ESG
									<Popover
										className='ps-1'
										popoverTextHeader="**ESG Overview**"
										popoverTextBody={`Displays the ESG classifications average for the selected timeframe (default: 5 years).`}
									/>
								</RightTabItem>
							</RightTabContainer>
							<ESGComponent {...esgProps} isCompanyPage />
							<Button
								rounded
								label="Check out more details"
								icon={<FaArrowRight className="mx-2 pt-1" />}
								onClick={() => {
									navigate(checkOutMoreDetails(documentList, documentType));
								}}
							/>
						</WhiteContainer>
					</Container>
				</div>
				<div className="d-flex w-100 margin-top-4">
					{!isSmallerScreen ? (
						<Container
							md={9}
							className="rounded bg-white margin-right-8 d-flex">
							<Container md={12} className="bg-white rounded-start">
								{renderDisclaimer()}
							</Container>
							<Container className="rounded">
								<WhiteContainer
									className="h-100 rounded-end"
									style={{ width: '8px' }}></WhiteContainer>
							</Container>
						</Container>
					) : (
						<Container
							// md={9} 
							className="bg-white rounded">
							{renderDisclaimer()}
						</Container>
					)}
				</div>
			</RootContainer>
			<Drawer
				placement="left"
				closable={false}
				onClose={() => showIndustryList(false)}
				open={isOpenIndustryList}
				key="left">
				<IndustryList
					isSmallerScreen={isSmallerScreen}
					industryCode={industryCode}
					setIndustryCode={setIndustryCode}
					industryList={industryList}
					setIndustryList={setIndustryList}
					industry={industry}
					setIndustry={setIndustry}
					paginationData={paginationData}
					setPaginationData={setPaginationData}
					onChangePagination={onChangePagination}
					industryListType={industryListType}
					setIndustryListType={setIndustryListType}
					onClickConfirm={onClickConfirm}
					onChangeIndustryCode={onChangeIndustryCode}
				/>
			</Drawer>
		</React.Fragment>
	);
};

export default IndustriesDesktop;
