// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

interface PieChartProps {
	data: { value: string | number; label: string; color: string }[];
}

const PieChartComponent: React.FC<PieChartProps> = ({ data }) => {
	const svgRef = useRef<SVGSVGElement | null>(null);

	useEffect(() => {
		if (!data || !svgRef.current) return;

		d3.select(svgRef.current).selectAll('*').remove();

		const containerWidth = svgRef.current.clientWidth;
		const containerHeight = svgRef.current.clientHeight;
		const radius = Math.min(containerWidth, containerHeight) / 2;

		const svg = d3
			.select(svgRef.current)
			.append('svg')
			.attr('width', '100%')
			.attr('height', '100%')
			.append('g')
			.attr(
				'transform',
				`translate(${containerWidth / 2}, ${containerHeight / 2})`
			);

		const colorScale = d3.scaleOrdinal().range(data.map(d => d.color));

		const pie = d3.pie().value(d => d.value);

		const arc = d3
			.arc()
			.innerRadius(0) // No inner radius to make it a pie chart
			.outerRadius(radius);

		const pieData = pie(data);

		svg
			.selectAll('path')
			.data(pieData)
			.enter()
			.append('path')
			.attr('d', arc)
			.attr('fill', (d, i) => colorScale(i.toString())); // Use colorScale to assign colors
	}, [data]);

	return (
		<div className="pie-chart" style={{ width: '100%', height: '100%' }}>
			<svg ref={svgRef} style={{ width: '100%', height: '100%' }}></svg>
		</div>
	);
};

export default PieChartComponent;
