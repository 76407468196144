import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

interface IPaginationComponent {
	onChangePagination: (value: string) => any;
	previousPageUrl: string;
	nextPageUrl: string;
	currentPage: number;
}

const PaginationComponent = ({
	onChangePagination,
	previousPageUrl,
	nextPageUrl,
	currentPage,
}: IPaginationComponent) => (
	<div className="d-flex justify-content-end gap-3 align-items-center">
		<a onClick={onChangePagination(previousPageUrl)}>
			<FaChevronLeft className={`fs-10 ${previousPageUrl ? 'cursor-pointer' : 'cursor-none'}`} />
		</a>
		<div className="border-darkblue rounded px-2 cursor-pointer">{currentPage}</div>
		<a onClick={onChangePagination(nextPageUrl)}>
			<FaChevronRight className={`fs-10 ${nextPageUrl ? 'cursor-pointer' : 'cursor-none'}`} />
		</a>
	</div>
);

export default PaginationComponent;
