// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate, useSearchParams } from 'react-router-dom';

import IndustriesDesktop from './Industries.desktop.screen';
import IndustriesMobile from './Industries.mobile.screen';
import servicesRequest, {
	COMPANY_URL,
	INDUSTRY_URL,
} from '../../Config/services.config';
import useGetListData from '../../Hooks/useGetListData';
import { DATA_TYPE, SCREEN_SIZE, SORT_DATE } from '../../Constants';
import { ROUTES } from '../../Constants/routes';
import { calculateEsgCompaniesIndustries } from '../../Components/SingleReport';
import { convertToPercentage, getDefaultCalls, getDefaultMarket } from '../../Utils';
import useResizeScreen from '../../Hooks/useResizeScreen';
import {
	FlexDisplayMediumGap,
	FlexDisplaySmallGap,
} from '../Preferences/Preferences.styled';

export const RIGHT_TAB = {
	OVERVIEW: 'OVERVIEW',
	SENTIMENT: 'SENTIMENTS',
	ESG: 'ESG',
};

export const INDUSTRY_TYPE = {
	SECTOR: 'Sector',
	INDUSTRY_GROUP: 'Industry Group',
	INDUSTRY: 'Industry',
	SUB_INDUSTRY: 'Sub Industry',
};

export const LIST_TYPE = {
	TRENDING: 'trending',
	MOVERS_UP: 'movers-up',
	MOVERS_DOWN: 'movers-down',
	SELECT_INDUSTRY: 'select_industry',
};

interface IMostViewedCompanies {
	topCompanies?: any[];
}

export const MostViewedCompanies = ({ topCompanies }: IMostViewedCompanies) => {
	return (
		<>
			<h5 className="fw-600 fs-14 mt-3 mb-2">Most viewed companies</h5>
			<div className="border rounded">
				{topCompanies.map((company, index) => {
					return (
						<div
							key={index}
							className="border-bottom py-1 px-2 d-flex justify-content-between">
							<FlexDisplayMediumGap>
								<div className="company-stock-code fs-10 fw-600">
									{company.stock_code}
								</div>
								<h5 className="fs-12 fw-600 m-0 text-neutral">
									{company.name_verbose}
								</h5>
							</FlexDisplayMediumGap>
							<h5 className="fs-12 fw-600 m-0 standard-padding">
								{convertToPercentage(company.latest_sentiment_score)}%
							</h5>
						</div>
					);
				})}
			</div>
		</>
	);
};

const { INDUSTRIES } = DATA_TYPE;

const IndustriesScreen = () => {
	const [tempIndustryCode, setTempIndustryCode] = useState('');
	const [topCompanies, setTopCompanies] = useState([]);

	const screenType = useResizeScreen();
	const [searchParams] = useSearchParams();
	const documentType = searchParams.get('document_type') || getDefaultCalls();
	const id = searchParams.get('industry_code') || '';
	const startDateParams =
		searchParams.get('start-date') || SORT_DATE.FIVE_YEARS.gte;
	const endDateParams =
		searchParams.get('end-date') || SORT_DATE.FIVE_YEARS.lte;

	const stateProps = useGetListData({
		dataType: INDUSTRIES,
		documentType,
		id,
		startDateParams,
		endDateParams,
	});

	const request = servicesRequest();
	const navigate = useNavigate();

	const industryCode: string = id || stateProps?.data?.industry_code || '';

	const onDocumentUrlClicked = (documentId: number) => {
		navigate(
			`/${ROUTES.SINGLE_REPORT}?country_id=${getDefaultMarket()}&document_type=${documentType}&document_id=${documentId}&industry_code=${industryCode}`
		);
	};

	const fetchIndustries = () => {
		const sentimentUrl =
			stateProps.startDate && stateProps.finishDate
				? `${INDUSTRY_URL}/${industryCode}?country_id=${getDefaultMarket()}&document_type=${documentType}&document_date.gte=${stateProps.startDate}&document_date.lte=${stateProps.finishDate}`
				: `${INDUSTRY_URL}/${industryCode}?country_id=${getDefaultMarket()}&document_type=${documentType}`;
		request
			.get(sentimentUrl)
			.then(response => {
				const data = _.get(response, 'data.data', []);
				stateProps.setData(data);
			})
			.catch(() =>
				toast.error('Something went wrong. Please refresh the page.')
			);
	};

	const fetchTopCompanies = () => {
		request
			.get(
				`${COMPANY_URL}/trending?industry_code=${industryCode}&country_id=${getDefaultMarket()}&document_type=${documentType}&document_date.gte=${stateProps.startDate}&document_date.lte=${stateProps.finishDate}`
			)
			.then(response => {
				const data = _.get(response, 'data.data.data', []);
				setTopCompanies(data.slice(0, 3));
			})
			.catch(() =>
				console.error('Something went wrong. Please refresh the page.')
			);
	};

	const onClickConfirm = () => {
		navigate(
			`/${ROUTES.INDUSTRIES}?country_id=${getDefaultMarket()}&document_type=${documentType}&industry_code=${tempIndustryCode[1]}`
		);
	};

	stateProps.onChangeDate = (startDate: string, endDate: string) => {
		navigate(
			`?country_id=${getDefaultMarket()}&document_type=${documentType}&industry_code=${industryCode}&start-date=${startDate}&end-date=${endDate}`
		);

		window.location.reload();
	};

	const setIndustryCode = (code: string) => {
		setTempIndustryCode(code);
	};

	useEffect(() => {
		if (industryCode) {
			fetchIndustries();
			fetchTopCompanies();
		}
	}, [industryCode]);

	const esgProps = calculateEsgCompaniesIndustries(stateProps.esg9Average);

	const onClickViewMore = () => {
		navigate(
			`/${ROUTES.REPORT}?name=${stateProps?.document?.document?.company?.name}&stock-code=${stateProps?.document?.document?.company?.stock_code}`
		);
	};

	const navigateToCompare = () => {
		navigate(
			`/${ROUTES.COMPARE
			}?document-type=${documentType}&company-industry-filter=${encodeURIComponent(
				`${stateProps.data.name?.replaceAll(',', '_')},${stateProps.data.industry_code}`
			)}&date-start=${stateProps?.startDate}&date-finish=${stateProps?.finishDate
			}&keyword-page=1&all-document-type=false`
		);
	};

	stateProps.onChangeIndustryCode = (code: string) => {
		navigate(
			`?country_id=${getDefaultMarket()}&document_type=${documentType}&industry_code=${code}`
		);
	};

	const mappedStateProps = {
		industryCode,
		setIndustryCode,
		onDocumentUrlClicked,
		documentType,
		...stateProps,
		startDateParams,
		topCompanies,
	};

	return (
		<React.Fragment>
			{screenType === SCREEN_SIZE.TABLET ||
				screenType === SCREEN_SIZE.MOBILE ? (
				<IndustriesMobile
					onChangePagination={stateProps?.getList}
					onClickConfirm={onClickConfirm}
					esgProps={esgProps}
					onClickViewMore={onClickViewMore}
					navigateToCompare={navigateToCompare}
					{...mappedStateProps}
				/>
			) : (
				<IndustriesDesktop
					onChangePagination={stateProps?.getList}
					onClickConfirm={onClickConfirm}
					esgProps={esgProps}
					onClickViewMore={onClickViewMore}
					navigateToCompare={navigateToCompare}
					{...mappedStateProps}
				/>
			)}
		</React.Fragment>
	);
};

export default IndustriesScreen;
