// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import servicesRequest, {
	COMPANIES_URL,
	COMPANY_URL,
	DOCUMENT_URL,
	ESG9_URL,
	INDUSTRY_LIST_GROUPING_URL,
	INDUSTRY_LIST_URL,
	INDUSTRY_URL,
	KEYWORD_FREQUENCIES_URL,
	KEYWORD_LIST_URL,
	SCORES_URL,
	SCORE_LIST_URL,
	STOCK_PRICES_URL,
} from '../../Config/services.config';
import useFilterData from '../../Hooks/useFilterData';
import { LIST_TYPE } from '../Industries/Industries.screen';
import CompareDesktop from './Compare.desktop.screen';
import CompareMobile from './Compare.mobile.screen';
import { ICompareTable } from './Compare.types';
import {
	DATE_NOW,
	CALL,
	CHART_INTERVAL,
	COMPARE_CARD_TAB_TYPE,
	COMPARE_TAB,
	DOCUMENT_TYPE,
	FILTER_TYPE,
	REPORT_DATE_RANGE,
	SCREEN_SIZE,
} from '../../Constants';
import { RIGHT_TAB } from '../SingleReport/SingleReport.screen';
import { convertToPercentage, getDefaultMarket } from '../../Utils';
import useResizeScreen from '../../Hooks/useResizeScreen';
import { lineColors } from '../../Components/Compare/SentimentChart.component';
import { calculateEsgCompaniesIndustries } from '../../Components/SingleReport';

const { TOP, BOTTOM } = FILTER_TYPE;
export const TRENDING_TAB_FILTER = [
	{ filter: TOP, type: 'I' },
	{ filter: BOTTOM, type: 'I' },
	{ filter: TOP, type: 'C' },
	{ filter: BOTTOM, type: 'C' },
];

const Compare = () => {
	const { SELECT_INDUSTRY } = LIST_TYPE;
	const { ALL } = COMPARE_CARD_TAB_TYPE;

	const {
		data,
		getCompaniesNextPage,
		isLoadingScrollCompany,
		scrollDirection,
		setScrollDirection,
		industryCode,
		setIndustryCode,
		onClickConfirm,
		filtered,
		setFiltered,
		tempFilter,
		setTempFilter,
		onDeleteList,
	} = useFilterData();
	const screenType = useResizeScreen();
	const request = servicesRequest();
	const navigate = useNavigate();

	const [searchParams] = useSearchParams();

	const [startDate, setStartDate] = useState(
		DATE_NOW.clone().subtract(5, 'years').add(1, 'days').format('YYYY-MM-DD')
	);
	const [finishDate, setFinishDate] = useState(DATE_NOW.clone().format('YYYY-MM-DD'));
	const [selectedRange, setSelectedRange] = useState(
		REPORT_DATE_RANGE.FIVE_YEAR
	);
	const [documentType, setDocumentType] = useState(CALL[0]);

	const [tab, setTab] = useState(LIST_TYPE.SELECT_INDUSTRY);
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [tableData, setTableData] = useState({
		[SELECT_INDUSTRY]: [],
	});
	const [selectedTableData, setSelectedTableData] = useState([]);
	const [selectedTablePagination, setSelectedTablePagination] = useState({
		current_page: 1,
		last_page: 1,
	});

	const [groupTab, setGroupTab] = useState(2);
	const [companyData, setCompanyData] = useState({
		asc: [],
		desc: [],
	});
	const [industryData, setIndustryData] = useState({
		asc: [],
		desc: [],
	});
	const [keywordList, setKeywordList] = useState([]);
	const [keywordPagination, setKeywordPagination] = useState({
		current_page: 1,
		last_page: 1,
	});

	const [tableDataContent, setTableDataContent] = useState([]);

	const [compareCardTab, setCompareCardTab] = useState(ALL);
	const [compareCardTableData, setCompareCardTableData] = useState([]);
	const [compareCardPagination, setCompareCardPagination] = useState({
		current_page: 1,
		last_page: 1,
	});

	const [compareTab, setCompareTab] = useState(COMPARE_TAB.COMPANY);

	const [idFiltered, setIdFiltered] = useState({});
	const [scoresAverage, setScoresAverage] = useState([]);

	const [chartTab, setChartTab] = useState(RIGHT_TAB.SENTIMENT);
	const [esgData, setEsgData] = useState(null);

	const [sentimentData, setSentimentData] = useState([]);
	const [returnData, setReturnData] = useState([]);
	const [selectedLegend, setSelectedLegend] = useState({});

	const [intervalType, setIntervalType] = useState(CHART_INTERVAL.DAY);

	const [companiesByIndustryOrder, setCompaniesByIndustryOrder] =
		useState('asc');
	const [compareDataOrder, setCompareDataOrder] = useState('desc');

	const [isChartAllDocumentType, setIsChartAllDocumentType] = useState(false);

	const [isRenderReturnChart, setIsRenderReturnChart] = useState(false);
	const [isRenderSentimentChart, setIsRenderSentimentChart] = useState(false);

	const [compareSelectedOrder, setCompareSelectedOrder] = useState('desc');

	const [isLoadingAverage, setIsLoadingAverage] = useState(true);
	const [isLoadingESG, setIsLoadingESG] = useState(true);
	const [isLoadingKeywords, setIsLoadingKeywords] = useState(true);

	const onParamsChange = () => {
		const filterString = tempFilter.map((filter, index) => {
			return [filter[0].replaceAll(',', '_'), filter[1]];
		});
		navigate(
			`/compare?country_id=${getDefaultMarket()}&document-type=${documentType.value
			}&company-industry-filter=${encodeURIComponent(
				filterString.toString()
			)}&date-start=${startDate}&date-finish=${finishDate}&keyword-page=${keywordPagination.current_page
			}&all-document-type=${isChartAllDocumentType}`
		);
	};

	const getKeywordList =
		(
			industryCodeFiltered = idFiltered.industryCode,
			companyCodeFiltered = idFiltered.companyCode
		) =>
			(pageUrl = '/?page=1') => {
				setIsLoadingKeywords(true);
				const url = !(startDate && finishDate)
					? `${KEYWORD_LIST_URL}${pageUrl}&company_stock_code=${companyCodeFiltered}
			&industry_code=${industryCodeFiltered}&country_id=${getDefaultMarket()}&document_type=${documentType.value
					}&limit=10`
					: `${KEYWORD_LIST_URL}${pageUrl}&company_stock_code=${companyCodeFiltered}
			&industry_code=${industryCodeFiltered}&country_id=${getDefaultMarket()}&document_type=${documentType.value
					}&limit=10&document_date.gte=${startDate}&document_date.lte=${finishDate}`;

				request
					.get(url)
					.then((response: any) => {
						const responseData = _.get(response, `data.data`, []);
						const pagination = _.get(response, `data.data.meta`, []);
						setKeywordList(responseData);
						setKeywordPagination(pagination);
					})
					.catch(() =>
						console.error('Something went wrong. Please refresh the page.')
					)
					.finally(() => {
						setIsLoadingKeywords(false);
					});
			};

	const fetchingKeywords = (detail: any, page = 1) => {
		getKeywordList()(`/?page=${page}`);
	};

	const mapScoreList = (
		score: any,
		id: string | number,
		color: string,
		document: string
	) => {
		const mappedScore =
			score.length > 0
				? score.map((item, index) => {
					const y = convertToPercentage(
						Number(item.score.positive) - Number(item.score.negative)
					);

					return {
						x: new Date(item?.date),
						y,
						stockCode: id,
						score: item.score,
						color,
						document,
					};
				})
				: [];

		return mappedScore;
	};

	const mapStockPrice = (
		stockPrice: any,
		id: string | number,
		color: string,
		document: string
	) => {
		return stockPrice.map(
			(stock: { t: string | number | Date; c: number }, stockIndex) => {
				const y = stockIndex === 0 ? 0 : stock?.c;

				return {
					x: new Date(stock?.t),
					y: stock?.c,
					stockCode: id,
					score: {
						positive: stock?.c,
						negative: stock?.c,
					},
					document,
					color,
				};
			}
		);
	};

	const getCompanyChartData = async (companyId, documentTypeString, color) => {
		try {
			const scoresListUrl = `${SCORE_LIST_URL}?company_stock_code=${companyId}&country_id=${getDefaultMarket()}&document_type=${documentTypeString}&document_date.gte=${startDate}&document_date.lte=${finishDate}&date_group=${intervalType.toLowerCase()}`;
			const stockPricesUrl = `${STOCK_PRICES_URL}?company_stock_code=${companyId}&country_id=${getDefaultMarket()}&document_type=${documentTypeString}&date.gte=${startDate}&date.lte=${finishDate}&date_group=${intervalType.toLowerCase()}`;

			const scoreListResponse = await request
				.get(scoresListUrl)
				.catch(() => []);
			const stockPricesResponse = await request
				.get(stockPricesUrl)
				.catch(() => []);

			// const stockPricesData = _.get(
			// 	stockPricesResponse,
			// 	'data.data.data[0].stock_prices',
			// 	[]
			// );
			const allStockPrices = _.get(
				stockPricesResponse,
				'data.data.data[0].stock_prices',
				[]
			);
			const stockPricesData = allStockPrices.filter((stockPrice: { t: string }) => {
				const stockDate = moment(stockPrice.t);
				return stockDate.isAfter(moment(startDate)) && stockDate.isBefore(moment(finishDate));
			});

			const scoreListData = _.get(
				scoreListResponse,
				`data.data[0].scores.${documentTypeString}`,
				[]
			);

			const mappingScoreList = mapScoreList(
				scoreListData,
				companyId,
				color,
				documentTypeString
			);

			const mappingStockPrices = mapStockPrice(
				stockPricesData,
				companyId,
				color,
				documentTypeString
			);

			return { mappingScoreList, mappingStockPrices };
		} catch (error) {
			return { mappingScoreList: [], mappingStockPrices: [] };
		}
	};

	const getIndustryChartData = async (
		industryId,
		documentTypeString,
		color
	) => {
		try {
			const scoresListUrl = `${SCORE_LIST_URL}?industry_code=${industryId}&country_id=${getDefaultMarket()}&document_type=${documentTypeString}&document_date.gte=${startDate}&document_date.lte=${finishDate}&date_group=${intervalType.toLowerCase()}`;

			const scoreListResponse = await request.get(scoresListUrl);

			const scoreListData = _.get(
				scoreListResponse,
				`data.data[0].scores.${documentTypeString}`,
				[]
			);

			const mappingScoreList = mapScoreList(
				scoreListData,
				industryId,
				color,
				documentTypeString
			);

			return { mappingScoreList };
		} catch (error) {
			return { mappingScoreList: [] };
		}
	};

	const getDocument = (keywordPageUrl = '/?page=1') => {
		setSentimentData([]);
		setReturnData([]);
		setIsRenderReturnChart(false);
		setIsRenderReturnChart(false);

		setIsSubmitted(false);

		const filterWithColors = isChartAllDocumentType
			? [
				...tempFilter.map((filter, index) => [
					...filter.slice(0, 2),
					lineColors[index],
					'Call',
				]),
				...tempFilter.map((filter, index) => [
					...filter.slice(0, 2),
					lineColors[tempFilter.length + index],
					'Filing',
				]),
			]
			: tempFilter.map((filter, index) => [
				...filter.slice(0, 2),
				lineColors[index],
			]);

		setFiltered(filterWithColors);

		const industryCodeFiltered = tempFilter
			.filter(code => !_.isNaN(Number(code[1])))
			.map(code => code[1]);
		const companyCodeFiltered = tempFilter
			.filter(code => _.isNaN(Number(code[1])))
			.map(code => code[1]);

		const industryWithColorFiltered = filterWithColors
			.filter((code, index) => !_.isNaN(Number(code[1])))
			.map(code => code);
		const companyWithColorFiltered = filterWithColors
			.filter(code => _.isNaN(Number(code[1])))
			.map(code => code);

		const legend = filterWithColors.reduce(
			(all, [, value, , document]) => ({
				...all,
				[`${value}-${isChartAllDocumentType ? document.toLowerCase() : documentType.value
					}`]: true,
			}),
			{}
		);

		setSelectedLegend(legend);
		setIdFiltered({
			industryCode: industryCodeFiltered,
			companyCode: companyCodeFiltered,
			industryWithColorFiltered,
			companyWithColorFiltered,
		});

		setTab(LIST_TYPE.SELECT_INDUSTRY);

		getKeywordList(industryCodeFiltered, companyCodeFiltered)(keywordPageUrl);

		const sentimentArray = [];
		const returnArray = [];

		if (companyWithColorFiltered.length > 0) {
			companyWithColorFiltered.forEach(
				async ([companyName, companyId, color, docType], index) => {
					try {
						let docTypeParams = docType || documentType.value;
						docTypeParams = docTypeParams.toLowerCase();

						const totalAverageUrl = `${SCORES_URL}/average?company_stock_code=${companyId}&country_id=${getDefaultMarket()}&document_type=${docTypeParams}`;
						const averageUrl = `${SCORES_URL}/average?company_stock_code=${companyId}&country_id=${getDefaultMarket()}&document_type=${docTypeParams}&limit=20&document_date.gte=${startDate}&document_date.lte=${finishDate}`;
						const companiesUrl = `${COMPANIES_URL}/${companyId}?country_id=${getDefaultMarket()}&document_type=${docTypeParams}&limit=20&document_date.gte=${startDate}&document_date.lte=${finishDate}`;

						const response = await request.get(averageUrl);
						const totalAvgResponse = await request.get(totalAverageUrl);
						const companyResponse = await request.get(companiesUrl);

						const fixedDocType = docType || documentType.value;
						const { mappingScoreList, mappingStockPrices } =
							await getCompanyChartData(
								companyId,
								fixedDocType.toLowerCase(),
								color
							);

						sentimentArray.push(mappingScoreList);
						returnArray.push(mappingStockPrices);

						const companyResponseData = _.get(companyResponse, `data.data`, {});

						const responseData = _.get(
							response,
							`data.data.${docTypeParams}`,
							{}
						);
						const averageSentiment = Number(responseData.positive - responseData.negative);

						const totalAvgResponseData = _.get(
							totalAvgResponse,
							`data.data.${docTypeParams}`,
							{}
						);
						const totalAverageSentiment =
							Number(totalAvgResponseData.positive) - Number(totalAvgResponseData.negative);

						setTableData(prevData => ({
							[SELECT_INDUSTRY]: [
								...prevData[SELECT_INDUSTRY],
								{
									stock_code: companyId,
									name: companyName,
									average: averageSentiment,
									total: totalAverageSentiment,
									latest_sentiment_score:
										companyResponseData.latest_sentiment_score,
									sentiment_score_change:
										companyResponseData.sentiment_score_change,
									type: 'C',
									id: companyResponseData.id,
									color,
								},
							],
						}));

						setSentimentData(() => [...sentimentArray]);
						setReturnData(() => [...returnArray]);
					} catch (error) {
						console.error('Something went wrong. Please refresh the page.');
					}
				}
			);
		}
		if (industryWithColorFiltered.length > 0) {
			industryWithColorFiltered.forEach(
				async ([industryName, industryId, color, docType], index) => {
					try {
						const totalAverageUrl = `${SCORES_URL}/average?industry_code=${industryId}&country_id=${getDefaultMarket()}&document_type=${documentType.value
							}`;
						const averageUrl = `${SCORES_URL}/average?industry_code=${industryId}&country_id=${getDefaultMarket()}&document_type=${documentType.value
							}&limit=20&document_date.gte=${startDate}&document_date.lte=${finishDate}`;
						const industryUrl = `${INDUSTRY_URL}/${industryId}?country_id=${getDefaultMarket()}&document_type=${documentType.value
							}&limit=20&document_date.gte=${startDate}&document_date.lte=${finishDate}`;

						const response = await request.get(averageUrl);
						const totalAvgResponse = await request.get(totalAverageUrl);
						const industryResponse = await request.get(industryUrl);

						const fixedDocType = docType || documentType.value;

						const { mappingScoreList } = await getIndustryChartData(
							industryId,
							fixedDocType.toLowerCase(),
							color
						);
						sentimentArray.push(mappingScoreList);

						const industryResponseData = _.get(
							industryResponse,
							`data.data`,
							{}
						);
						const responseData = _.get(
							response,
							`data.data.${documentType.value}`,
							{}
						);
						const averageSentiment =
							responseData.positive - responseData.negative;

						const totalAvgResponseData = _.get(
							totalAvgResponse,
							`data.data.${documentType.value}`,
							{}
						);
						const totalAverageSentiment =
							totalAvgResponseData.positive - totalAvgResponseData.negative;
						setTableData(prevData => ({
							[SELECT_INDUSTRY]: [
								...prevData[SELECT_INDUSTRY],
								{
									industry_code: industryId,
									name: industryName,
									average: averageSentiment,
									total: totalAverageSentiment,
									latest_sentiment_score:
										industryResponseData.latest_sentiment_score,
									sentiment_score_change:
										industryResponseData.sentiment_score_change,
									type: 'I',
									id: industryResponseData.id,
									color,
								},
							],
						}));

						setSentimentData(() => [...sentimentArray]);
						setReturnData(() => [...returnArray]);
					} catch (error) {
						console.error('Something went wrong. Please refresh the page.');
					}
				}
			);
		}
	};

	const getList = (
		isCompany: boolean,
		filterUrl: string,
		pageUrl = '/?page=1'
	) => {
		if (pageUrl !== null) {
			const listUrl = isCompany
				? `${COMPANY_URL}${pageUrl}&order_by=sentiment.${filterUrl}`
				: `${INDUSTRY_LIST_URL}${pageUrl}&order_by=sentiment.${filterUrl}&industry_type=${getDefaultMarket() === 1 ? 'sector' : 'industry'
				}`;

			const url = `${listUrl}&country_id=${getDefaultMarket()}&document_type=${documentType.value
				}&limit=12&document_date.gte=${startDate}&document_date.lte=${finishDate}`;
			request
				.get(url)
				.then((response: any) => {
					const responseData = _.get(response, 'data.data.data', []);

					const meta = _.get(response, 'data.data.meta', []);
					if (isCompany) {
						setCompanyData({ ...companyData, [filterUrl]: responseData });
						return;
					}
					setIndustryData({ ...industryData, [filterUrl]: responseData });
				})
				.catch(() =>
					console.error('Something went wrong. Please refresh the page.')
				);
		}
	};

	const onDeleteFixList = (index: number) => () => {
		filtered.splice(index, 1);
		setFiltered([...filtered]);
	};

	const getCompaniesByIndustry = (pageUrl = '/?page=1') => {
		if (pageUrl !== null) {
			setSelectedTablePagination({ current_page: -1 });
			const url = !(startDate && finishDate)
				? `${COMPANY_URL}${pageUrl}&industry_code=${tab}&country_id=${getDefaultMarket()}&document_type=${documentType.value
				}&limit=10&order_by=name.${companiesByIndustryOrder}`
				: `${COMPANY_URL}${pageUrl}&industry_code=${tab}&country_id=${getDefaultMarket()}&document_type=${documentType.value
				}&limit=10&document_date.gte=${startDate}&document_date.lte=${finishDate}&order_by=name.${companiesByIndustryOrder} `;
			request
				.get(url)
				.then((response: any) => {
					const responseData = _.get(response, 'data.data.data', []);
					const meta = _.get(response, 'data.data.meta', {});
					setSelectedTablePagination(meta);
					setTableData({ ...tableData, [tab]: responseData });
				})
				.catch(() =>
					console.error('Something went wrong. Please refresh the page.')
				);
		}
	};

	const getUrlCompareData = (pageUrl = '/?page=1') => {
		if (compareTab === COMPARE_TAB.COMPANY) {
			return `${COMPANY_URL}${pageUrl}&country_id=${getDefaultMarket()}&limit=10&order_by=sentiment.${compareDataOrder}`;
		}
		if (compareCardTab !== ALL) {
			return `${INDUSTRY_LIST_URL}${pageUrl}&country_id=${getDefaultMarket()}&industry_type=${compareCardTab
				.toLowerCase()
				.replace(' ', '_')}&order_by=sentiment.${compareDataOrder}`;
		}
		return `${INDUSTRY_LIST_URL}${pageUrl}&country_id=${getDefaultMarket()}&order_by=sentiment.${compareDataOrder}`;
	};

	const getCompareTabData = (pageUrl = '/?page=1') => {
		const url = getUrlCompareData(pageUrl);
		const filteredUrl = !(startDate && finishDate)
			? `${url}&document_type=${documentType.value}`
			: `${url}&document_date.gte=${startDate}&document_date.lte=${finishDate}&document_type=${documentType.value}`;

		request
			.get(filteredUrl)
			.then((response: any) => {
				const responseData = _.get(response, `data.data.data`, []);
				const meta = _.get(response, 'data.data.meta', {});

				setCompareCardTableData(responseData);
				setCompareCardPagination(meta);
			})
			.catch(() =>
				console.error('Something went wrong. Please refresh the page.')
			);
	};

	const getScoresAverage = () => {
		const industryCodeFiltered = idFiltered.industryCode;
		const companyCodeFiltered = idFiltered.companyCode;

		if (industryCodeFiltered && companyCodeFiltered) {
			setIsLoadingAverage(true);
			request
				.get(
					`${SCORES_URL}/average?document_type=${documentType.value}&company_stock_code=${companyCodeFiltered}&industry_code=${industryCodeFiltered}&document_date.gte=${startDate}&document_date.lte=${finishDate}`
				)
				.then((response: any) => {
					const responseData = _.get(response, 'data.data', []);
					setScoresAverage(responseData);
				})
				.catch(() =>
					console.error('Something went wrong. Please refresh the page.')
				)
				.finally(() => {
					setIsLoadingAverage(false);
				});
		}
	};

	const getESGAverage = () => {
		const industryCodeFiltered = idFiltered.industryCode;
		const companyCodeFiltered = idFiltered.companyCode;

		if (industryCodeFiltered && companyCodeFiltered) {
			setIsLoadingESG(true);
			request
				.get(
					`${ESG9_URL}/average?document_type=${documentType.value}&company_stock_code=${companyCodeFiltered}&industry_code=${industryCodeFiltered}&document_date.gte=${startDate}&document_date.lte=${finishDate}`
				)
				.then((response: any) => {
					const responseData = _.get(
						response,
						`data.data.${documentType.value}`,
						{}
					);
					setEsgData(calculateEsgCompaniesIndustries(responseData));
				})
				.catch(() =>
					console.error('Something went wrong. Please refresh the page.')
				)
				.finally(() => {
					setIsLoadingESG(false);
				});
		}
	};

	const getDataByInterval = () => {
		const { industryWithColorFiltered, companyWithColorFiltered } = idFiltered;
		setSentimentData([]);
		setReturnData([]);
		setIsRenderReturnChart(false);
		setIsRenderReturnChart(false);

		const sentimentArray = [];
		const returnArray = [];

		if (companyWithColorFiltered.length > 0) {
			companyWithColorFiltered.forEach(
				async ([, companyId, color, docType], index) => {
					try {
						const fixedDocType = docType || documentType.value;

						const { mappingScoreList, mappingStockPrices } =
							await getCompanyChartData(
								companyId,
								fixedDocType.toLowerCase(),
								color
							);

						sentimentArray.push(mappingScoreList);
						returnArray.push(mappingStockPrices);

						setSentimentData(() => [...sentimentArray]);
						setReturnData(() => [...returnArray]);
					} catch (error) {
						console.error('Something went wrong. Please refresh the page.');
					}
				}
			);
		}
		if (industryWithColorFiltered.length > 0) {
			industryWithColorFiltered.forEach(
				async ([, industryId, color, docType], index) => {
					try {
						const fixedDocType = docType || documentType.value;
						const { mappingScoreList } = await getIndustryChartData(
							industryId,
							fixedDocType.toLowerCase(),
							color
						);
						sentimentArray.push(mappingScoreList);

						setSentimentData(() => [...sentimentArray]);
						setReturnData(() => [...returnArray]);
					} catch (error) {
						console.error('Something went wrong. Please refresh the page.');
					}
				}
			);
		}
	};

	const onChangeInterval = type => setIntervalType(type);

	const getKeywordFrequencies = async (keyword: string) => {
		try {
			const url =
				startDate && finishDate
					? `${KEYWORD_FREQUENCIES_URL}?document_type=${documentType.value}&keyword=${keyword}&company_stock_code=${idFiltered.companyCode}&industry_code=${idFiltered.industryCode}&document_date.gte=${startDate}&document_date.lte=${finishDate}`
					: `${KEYWORD_FREQUENCIES_URL}?document_type=${documentType.value}&keyword=${keyword}&company_stock_code=${idFiltered.companyCode}&industry_code=${idFiltered.industryCode}`;
			const {
				data: { data: frequencyData },
			} = await request.get(url);

			return frequencyData;
		} catch (error) {
			console.error('Something went wrong. Please check and try again.');
			return null;
		}
	};

	const onCheckedAllDocumentType = isChecked => {
		setIsChartAllDocumentType(isChecked);

		if (isChecked && tempFilter.length > 3) {
			const allFiltered = tempFilter.slice(0, 3);
			setTempFilter(prev => allFiltered);
		}
		setIsSubmitted(true);
	};

	const onClickIndustryCompany = (isCompany, id) => {
		if (isCompany) {
			navigate(
				`/companies?document_type=${documentType.value
				}&country_id=${getDefaultMarket()}&company_id=${id}`
			);
		} else {
			navigate(
				`/industries?document_type=${documentType.value
				}&country_id=${getDefaultMarket()}&industry_code=${id}`
			);
		}
	};

	const onConfirm = () => () => {
		if (tempFilter.length > 6) {
			toast.warning('Cannot filter more than 6 industries and companies.');
			return;
		}
		if (tempFilter.length > 3 && isChartAllDocumentType) {
			toast.warning(
				'Cannot filter more than 3 industries and companies if "Show All" is activated.'
			);
			return;
		}
		if (tempFilter.length === 0) {
			toast.warning('Please complete your filter input.');
			return;
		}
		setIsSubmitted(true);
	};

	useEffect(() => {
		const filterType = TRENDING_TAB_FILTER[groupTab].type;
		const filterUrl = TRENDING_TAB_FILTER[groupTab].filter;
		setTableDataContent([]);

		if (filterType === 'I') {
			getList(false, filterUrl);
		} else if (filterType === 'C') {
			getList(true, filterUrl);
		} else {
			const compareCardData =
				filterType === 'I' ? industryData[filterUrl] : companyData[filterUrl];
			setTableDataContent(compareCardData);
		}
	}, [groupTab, isSubmitted]);

	useEffect(() => {
		setTableDataContent([]);
		const compareCardData =
			TRENDING_TAB_FILTER[groupTab].type === 'I'
				? industryData[TRENDING_TAB_FILTER[groupTab].filter]
				: companyData[TRENDING_TAB_FILTER[groupTab].filter];
		setTableDataContent(compareCardData);
	}, [companyData, industryData]);

	useEffect(() => {
		if (isSubmitted) {
			setTableData({
				[SELECT_INDUSTRY]: [],
			});
			getDocument(`/?page=${keywordPagination.current_page}`);
			getCompareTabData();
			onParamsChange();
		}
	}, [isSubmitted]);

	useEffect(() => {
		if (tab !== LIST_TYPE.SELECT_INDUSTRY) {
			getCompaniesByIndustry();
		} else {
			setSelectedTableData(tableData[tab]);
		}
	}, [tab]);

	useEffect(() => {
		setSelectedTableData(tableData[tab]);
	}, [tableData]);

	useEffect(() => {
		setCompareCardTableData([]);
		setCompareCardPagination({
			current_page: 1,
		});
		getCompareTabData();
	}, [compareTab, compareCardTab, compareDataOrder]);

	useEffect(() => {
		getScoresAverage();
		getESGAverage();
	}, [idFiltered]);

	useEffect(() => {
		if (keywordPagination.current_page > 1) {
			onParamsChange();
		}
	}, [keywordPagination.current_page]);

	useEffect(() => {
		if (searchParams.get('company-industry-filter') && !isSubmitted) {
			const newFilter = [];
			const filter = searchParams.get('company-industry-filter')?.split(',');
			filter.forEach((item, index) => {
				if (index % 2 === 1)
					newFilter.push([
						filter[index - 1].replaceAll('-', ' ').replaceAll('_', ','),
						filter[index],
					]);
			});

			setTempFilter(newFilter);
			setIsChartAllDocumentType(
				searchParams.get('all-document-type') === 'true'
			);
			setStartDate(searchParams.get('date-start'));
			setFinishDate(searchParams.get('date-finish'));
			setDocumentType({
				value: searchParams.get('document-type'),
			});
			setKeywordPagination({
				current_page: searchParams.get('keyword-page') || 1,
			});
			setIsSubmitted(true);
		}
	}, []);

	useEffect(() => {
		if (filtered.length === 0 && companyData.desc.length > 0) {
			const compareTempData = companyData.desc.slice(0, 2);

			const companyFiltered = compareTempData
				.filter(i => _.isNaN(Number(i.stock_code)))
				.map(i => [i.name, i.stock_code]);

			setTempFilter(companyFiltered);
			setStartDate(
				DATE_NOW.clone().subtract(5, 'years').add(1, 'day').format('YYYY-MM-DD')
			);
			setFinishDate(DATE_NOW.clone().format('YYYY-MM-DD'));
			setIsSubmitted(true);
		}
	}, [companyData]);

	useEffect(() => {
		if (filtered.length > 0) {
			getDataByInterval();
		}
	}, [intervalType]);

	useEffect(() => {
		if (tab !== LIST_TYPE.SELECT_INDUSTRY) {
			getCompaniesByIndustry();
		}
	}, [companiesByIndustryOrder]);

	useEffect(() => {
		const isRenderSentiment = !isChartAllDocumentType
			? sentimentData.length === filtered.length
			: sentimentData.length === filtered.length * 2;
		const isRenderReturn = !isChartAllDocumentType
			? returnData.length === idFiltered?.companyCode?.length
			: returnData.length === idFiltered?.companyCode?.length * 2;
		if (isRenderReturn) {
			setIsRenderReturnChart(true);
		}
		if (isRenderSentiment) {
			setIsRenderSentimentChart(true);
		}
	}, [sentimentData, returnData]);

	const compareProps = () => ({
		industryCode,
		setIndustryCode,
		data,
		getCompaniesNextPage,
		isLoadingScrollCompany,
		scrollDirection,
		setScrollDirection,
		onClickConfirm,
		filtered,
		setFiltered,
		tempFilter,
		onDeleteList,
		setStartDate,
		setFinishDate,
		setSelectedRange,
		selectedRange,
		documentType,
		setDocumentType,
		tab,
		setTab,
		isSubmitted,
		onConfirm,
		tableData: selectedTableData,
		setTableData,
		groupTab,
		setGroupTab,
		tableDataContent,
		onDeleteFixList,
		keywordList,
		compareCardTab,
		setCompareCardTab,
		compareCardTableData,
		setCompareCardTableData,
		compareTab,
		setCompareTab,
		scoresAverage,
		chartTab,
		setChartTab,
		esgData,
		keywordPagination,
		setKeywordPagination,
		getKeywordList,
		onParamsChange,
		getCompaniesByIndustry,
		selectedTablePagination,
		compareCardPagination,
		getCompareTabData,
		returnData,
		sentimentData,
		onChangeInterval,
		intervalType,
		idFiltered,
		getKeywordFrequencies,
		fetchingKeywords,
		setCompareDataOrder,
		compareDataOrder,
		companiesByIndustryOrder,
		setCompaniesByIndustryOrder,
		isChartAllDocumentType,
		onCheckedAllDocumentType,
		onClickIndustryCompany,
		isRenderSentimentChart,
		isRenderReturnChart,
		startDate,
		finishDate,
		selectedLegend,
		setSelectedLegend,
		compareSelectedOrder,
		setCompareSelectedOrder,
		isLoadingAverage,
		isLoadingESG,
		isLoadingKeywords
	});

	return (
		<React.Fragment>
			{screenType === SCREEN_SIZE.TABLET ||
				screenType === SCREEN_SIZE.MOBILE ? (
				<CompareMobile {...compareProps()} />
			) : (
				<CompareDesktop {...compareProps()} />
			)}
		</React.Fragment>
	);
};

export default Compare;
