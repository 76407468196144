import styled, { css } from 'styled-components';
import { FaSignOutAlt } from 'react-icons/fa';

import { INavLinkProps } from '../../Components/NavigationBar/NavigationBar.types';
import { mobile } from '../../Utils/layout.utils';

export const Container = styled.div`
	padding: 6vh 6vw;

	${mobile(css`
		padding: 3vw;
	`)};
`;

export const SignOutIcon = styled(FaSignOutAlt)`
	font-size: 1rem;
	color: #ac0101;
`;

export const FlexDisplay = styled.div`
	display: flex;
	gap: 1.5vw;
`;

export const FlexDisplayNoGap = styled(FlexDisplay)`
	gap: 0;
`;

export const FlexDisplayCenter = styled(FlexDisplay)`
	justify-content: space-between;
	align-items: center;
	align-content: center;
`;

export const FlexDisplayFlexEnd = styled(FlexDisplay)`
	justify-content: flex-end;
	align-items: center;
	align-content: center;
`;

export const FlexDisplaySpaceBetween = styled(FlexDisplay)`
	justify-content: space-between;
	align-items: center;
	align-content: center;
`;

export const FlexDisplaySpaceBetweenNormal = styled(FlexDisplay)`
	justify-content: space-between;
	align-items: normal;
	align-content: normal;
`;

export const PreferencesSection = styled(FlexDisplay)`
	flex-direction: row;

	${mobile(css`
		flex-direction: column;
	`)};
`;

export const WelcomeText = styled.h1`
	padding-top: 1vh;
	color: var(--current-light-black, #000);
	font-size: 2.5rem;
	font-weight: 600;
	line-height: normal;

	${mobile(css`
		font-size: 1.3rem;
	`)};
`;

export const UsernameText = styled.h4`
	color: var(--current-light-black, #000);
	font-size: 1.5rem;

	${mobile(css`
		font-size: 0.8rem;
		letter-spacing: 0;
	`)};
`;

export const ContainerMenuTab = styled.div`
	padding: 4vh 0.5vw;

	${mobile(css`
		padding: 2vh 0.5vw;
	`)};
`;

export const MenuLeftTab = styled(FlexDisplay)`
	width: 40%;
	flex-direction: column;

	${mobile(css`
		width: 100%;
		flex-direction: row;
	`)};
`;

export const MenuRightTab = styled.div`
	width: 60%;

	${mobile(css`
		width: 100%;
	`)};
`;

export const SelectedTab = styled.div<INavLinkProps>`
	background-color: ${({ selected }) => (selected ? '#F5F6F9' : '#fff')};
	padding: 1rem;
	cursor: pointer;
	border-radius: 4px;

	${mobile(css`
		padding: 0.8rem;
		width: ${100 / 3}%;
	`)};
`;

export const TabTitle = styled.h5`
	color: var(--current-light-black, #000);
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;

	${mobile(css`
		font-size: 12px;
		padding: 0;
		margin: 0;
	`)};
`;

export const TabDescription = styled.h5`
	color: var(--neutral-black, #000);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;

	${mobile(css`
		display: none;
	`)};
`;

export const FlexDisplaySmallGap = styled(FlexDisplay)`
	gap: 0.3vw;
	align-items: center;
`;

export const FlexDisplayMediumGap = styled(FlexDisplay)`
	gap: 1vw;
	align-items: center;
`;

export const IconStyled = styled.img`
	width: 2.3vw;
	color: black;
	font-size: 35.5rem;
	padding-bottom: 1vh;

	${mobile(css`
		width: 3vw;
		padding-bottom: 0;
	`)};
`;

export const SignOutButton = styled(FlexDisplaySmallGap)`
	margin-top: 2vh;
	padding: 1vh 2vw;
	width: 13%;
	background: #ffdfdf;
	color: #ac0101;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 150%;
	border-radius: 4px;
	letter-spacing: -0.14px;
	cursor: pointer;
	gap: 0.5vw;

	${mobile(css`
		width: 100%;
		text-align: center !important;
	`)};
`;
