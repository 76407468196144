import styled, { css } from 'styled-components';
import { Form, FormControl } from 'react-bootstrap';

import { COLOR } from '../../Constants';
import { mobile } from '../../Utils/layout.utils';

export const ModalTitle = styled.h3`
	color: var(--current-light-black, #000);
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	letter-spacing: -0.96px;
	padding: 0;
	margin: 0;
`;

export const FormLabel = styled(Form.Label)`
	color: var(--neutral-black, #000);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	letter-spacing: -0.14px;
`;

export const FormInput = styled(Form.Control)<{ isError: boolean }>`
	border-radius: 4px;
	border-color: ${props =>
		props.isError ? `${COLOR.ERROR}` : `${COLOR.BORDER}`};

	&::placeholder {
		color: #c2c2c2;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 150%;
	}

	&:focus {
		box-shadow: none !important;
		border-color: ${COLOR.PRIMARY};
	}

	${mobile(css`
		&::placeholder {
			font-size: 12px;
			font-weight: 400;
			line-height: 100%;
		}
	`)};
`;

export const TopFormInput = styled(FormInput)<{ isError: boolean }>`
	border-radius: 4px 4px 0 0;
	border-color: ${props =>
		props.isError ? `${COLOR.ERROR}` : `${COLOR.BORDER}`};
`;

export const BottomFormInput = styled(FormInput)<{ isError: boolean }>`
	border-radius: 0 0 4px 4px;
	border-color: ${props =>
		props.isError ? `${COLOR.ERROR}` : `${COLOR.BORDER}`};
`;

export const FormControlStyled = styled(FormControl)<{ isError: boolean }>`
	border-color: ${props =>
		props.isError ? `${COLOR.ERROR}` : `${COLOR.BORDER}`};

	&::placeholder {
		color: #c2c2c2;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 150%;
		letter-spacing: -0.16px;
	}

	&:focus {
		box-shadow: none !important;
		border-color: ${COLOR.PRIMARY};
	}
`;

export const ForgotPassword = styled.p`
	color: ${COLOR.PRIMARY};
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 20px;
	text-align: right;
	cursor: pointer;
`;

export const CreateAccount = styled.p`
	color: #4e4e4e;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 20px;
	text-decoration-line: underline;
	margin-top: 1.5vh;
	cursor: pointer;
`;

export const StyledDivider = styled.div`
	display: flex;
	align-items: center;
	text-align: center;
	width: 100%;

	color: #585858;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	letter-spacing: -0.12px;

	&::before,
	&::after {
		content: '';
		flex: 1;
		border-bottom: 1px solid #ddd;
	}

	&::before {
		margin-right: 0.5rem;
	}

	&::after {
		margin-left: 0.5rem;
	}
`;
