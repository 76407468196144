// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

interface DonutChartProps {
	data: { neutral: string; positive: string; negative: string };
	averageReport?: string | number;
}

const DonutChartComponent: React.FC<DonutChartProps> = ({
	data,
	averageReport,
}) => {
	const svgRef = useRef<SVGSVGElement | null>(null);

	useEffect(() => {
		if (!data || !svgRef.current) return;

		// Clear previous chart
		d3.select(svgRef.current).selectAll('*').remove();

		const containerWidth = svgRef.current.clientWidth;
		const containerHeight = svgRef.current.clientHeight;
		const radius = Math.min(containerWidth, containerHeight) / 2;

		const svg = d3
			.select(svgRef.current)
			.append('svg')
			.attr('width', '100%')
			.attr('height', '100%')
			.append('g')
			.attr(
				'transform',
				`translate(${containerWidth / 2}, ${containerHeight / 2})`
			);

		const color = d3
			.scaleOrdinal()
			.domain(Object.keys(data))
			.range(['#F5F5F5', '#107C10', '#D13438']); // Define colors for each category

		const pie = d3
			.pie()
			.value(d => d.value)
			.startAngle(0) // Set the start angle to 0 to create a full circle
			.endAngle(2 * Math.PI); // Set the end angle to 2 * PI to create a full circle

		const dataForPie = Object.keys(data).map(key => ({
			category: key,
			value: data[key],
		}));

		const arc = d3
			.arc()
			.innerRadius(radius * 0.7)
			.outerRadius(radius);

		svg
			.selectAll('path')
			.data(pie(dataForPie))
			.enter()
			.append('path')
			.attr('d', arc)
			.attr('fill', d => color(d.data.category));

		const centerValue = averageReport || '';

		// Display the largest value in the center
		svg
			.append('text')
			.text(centerValue)
			.attr('text-anchor', 'middle')
			.attr('dy', '0.35em')
			.style('font-size', '20px')
			.style('font-weight', '600');
	}, [data]);

	return (
		<div className="donut-chart" style={{ width: '100%', height: '100%' }}>
			<svg ref={svgRef} style={{ width: '100%', height: '100%' }}></svg>
		</div>
	);
};

export default DonutChartComponent;
