import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Col, Modal, Row } from 'react-bootstrap';
import _ from 'lodash';
import { toast } from 'react-toastify';

import { ModalBody } from '../Authorization/Authorization.styled';
import { FormLabel, FormValue } from './Preferences.component.styled';
import {
	FlexDisplayFlexEnd,
	FlexDisplayMediumGap,
	FlexDisplaySmallGap,
	FlexDisplaySpaceBetween,
	IconStyled,
	SignOutButton,
} from '../../Screens/Preferences/Preferences.styled';
import ButtonComponent from '../Button/Button.component';
import checkIconDark from '../../Assets/Icon/check-circle-icon.svg';
import checkIconLight from '../../Assets/Icon/check-circle-light-icon.svg';
import ellipseIcon from '../../Assets/Icon/ellipse-icon.svg';
import notAvailable from '../../Assets/Icon/not-available-features.svg';
import changeIcon from '../../Assets/Icon/change_warning.svg';
import { INavLinkProps } from '../NavigationBar/NavigationBar.types';
import { SUBSCRIPTION_TYPE } from '../../Constants';
import servicesRequest, {
	SUBSCRIPTIONS_URL,
	USER_SUBSCRIPTION_URL,
} from '../../Config/services.config';
import {
	GetSubscriptionResponse,
	SubscriptionResponse,
	SubscriptionType,
} from '../../Screens/Preferences/Preferences.types';
import { mobile } from '../../Utils/layout.utils';
import {
	BASIC_FEATURES,
	DEMO_FEATURES,
	API_FEATURES,
} from './Components/SubscriptionFeatures.component';

const DUMMY_SELECTED_PLAN = 0;

const Card = styled.div<INavLinkProps>`
	padding: 2vh;
	border-radius: 4px;
	background: ${({ selected }) => (selected ? '#225FB0' : '#efeff1')};
	border: ${({ selected }) =>
		selected ? '1px solid #225FB0' : '1px solid #969696'};

	${mobile(css`
		padding: 0.1rem 0.3rem;
	`)};
`;

const CardPrice = styled.div`
	color: var(--neutral-black, #000);
	font-size: 32px;
	font-style: normal;
	font-weight: 600;
	line-height: 150%;
	letter-spacing: -0.32px;
	padding: 0;
	margin: 0;
`;

const DurationLabel = styled.span<INavLinkProps>`
	color: ${({ selected }) => (selected ? '#FFF' : '#373737')};
	font-size: 14px;
	font-style: normal;
	font-weight: 600;

	${mobile(css`
		font-size: 12px;
	`)};
`;

const Features = styled.p`
	color: #000;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	padding: 0;
	margin: 0;

	${mobile(css`
		font-size: 10px;
	`)};
`;

const SubscriptionName = styled.p<INavLinkProps>`
	color: ${({ selected }) => (selected ? '#FFF' : '#373737')};
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	padding: 0;
	margin: 0;

	${mobile(css`
		font-size: 12px;
	`)};
`;

const Icon = styled(IconStyled)`
	width: 1vw;
	padding-bottom: 0;

	${mobile(css`
		width: 2vw;
		margin-right: 5px;
	`)};
`;

const SubscriptionIcon = styled.img`
	color: #373737;

	${mobile(css`
		width: 0.7rem;
		margin-right: 5px;
	`)};
`;

const ComingSoon = styled.div`
	border-radius: 64px;
	background: #d7d7d7;
	color: #fff;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	padding: 4px 10px;
`;

const FeatureLabel = styled.div`
	border-radius: 64px;
	background: #225fb0;
	padding: 2px 16px;
	color: #fff;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	width: 40%;
	text-align: center;
	margin-bottom: 2vh;

	${mobile(css`
		width: 30%;
		font-size: 10px;
		padding: 2px 4px;
		margin-bottom: 1vh;
		margin-top: 2vh;
	`)};
`;

const Pricing = styled.div<INavLinkProps>`
	color: ${({ selected }) => (selected ? '#FFF' : '#373737')};

	${mobile(css`
		font-size: 12px;
	`)};
`;

const CurrentPlan = styled.div`
	border-radius: 64px;
	background: #fff;
	padding: 1px 16px;
	color: #225fb0;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;

	${mobile(css`
		font-size: 9px;
	`)};
`;

const SubscriptionDetails = (props: {
	selectedPlan: SubscriptionType | undefined;
	selectedId: number;
}) => {
	const { selectedId, selectedPlan } = props;

	if (selectedPlan) {
		return (
			<React.Fragment>
				{selectedId === 0 && (
					<React.Fragment>
						{DEMO_FEATURES?.map((item, index) => {
							return (
								<FlexDisplaySmallGap className="mb-2" key={index}>
									<Icon
										src={item?.value ? ellipseIcon : notAvailable}
										alt="icon"
									/>
									<Features>{item?.label}</Features>
								</FlexDisplaySmallGap>
							);
						})}
						<small className="fs-10 fw-600">
							* Reports from 2010 to 2015 <br />
							{/* &nbsp; &nbsp;50 views limit, 1 view/day thereafter */}
						</small>
					</React.Fragment>
				)}

				{selectedId === 5 && (
					<React.Fragment>
						{BASIC_FEATURES?.map((item, index) => {
							return (
								<FlexDisplaySmallGap className="mb-2" key={index}>
									<Icon
										src={item?.value ? ellipseIcon : notAvailable}
										alt="icon"
									/>
									<Features>{item?.label}</Features>
								</FlexDisplaySmallGap>
							);
						})}
						<small className="fs-10 fw-600">
							{/* * 100 views limit, 1 view/day thereafter */}
						</small>
					</React.Fragment>
				)}
				{selectedId === 7 && (
					<React.Fragment>
						{API_FEATURES?.map((item, index) => {
							return (
								<FlexDisplaySmallGap className="mb-2" key={index}>
									<Icon
										src={item?.value ? ellipseIcon : notAvailable}
										alt="icon"
									/>
									<Features>{item?.label}</Features>
								</FlexDisplaySmallGap>
							);
						})}
						<small className="fs-10 fw-600">
							* Contact us at cbsa@ust.hk for more details.
						</small>
					</React.Fragment>
				)}
			</React.Fragment>
		);
	}

	return null;
};

export const SubscriptionFeatures = (props: {
	currentPlan?: string;
	onSelect: (value: SubscriptionType) => void;
	onClickUpgrade?: () => void;
	updateSelectedPlan?: any;
}) => {
	const [selectedId, setSelectedId] = useState(DUMMY_SELECTED_PLAN);
	const [selectedPlan, setSelectedPlan] = useState<
		SubscriptionType | undefined
	>(undefined);
	const [userSubscription, setUserSubscription] = useState<
		SubscriptionType | undefined
	>(undefined);
	const [subscriptions, setSubscriptions] = useState<SubscriptionType[]>([]);

	const getSubscriptions = (
		response: GetSubscriptionResponse,
		isLoggedIn: boolean
	) => {
		const subscriptionsResponse = _.get(response, 'data.data.data', []);

		if (!isLoggedIn) {
			setSelectedPlan(subscriptionsResponse[0]);
			setSubscriptions([
				{
					name: 'Demo',
					id: 0,
					price: 0,
					api_access: -1,
					api_access_daily: -1,
					customer_support: false,
					portal_access: -1,
					access_limit: 0,
					access_limit_daily: 1,
					api_access_allowed: false,
					expiration: '',
					portal_access_allowed: false,
					portal_access_day: 0,
				},
				...subscriptionsResponse,
			]);
			return;
		}
		setSubscriptions(subscriptionsResponse);
	};

	const getUserSubscription = (response: SubscriptionResponse) => {
		const userSubscriptionResponse = _.get(
			response,
			'data.data.subscription',
			undefined
		);

		if (userSubscriptionResponse) {
			setUserSubscription(userSubscriptionResponse);
			setSelectedPlan(userSubscriptionResponse);

			setSelectedId(userSubscriptionResponse.id);
		}
	};

	const getSelectedPlan = () => {
		const selectedPlanResponse = subscriptions[selectedId - 1];

		if (selectedPlanResponse) {
			setSelectedPlan(selectedPlanResponse);
		}
	};

	const request = servicesRequest();

	useEffect(() => {
		const isLoggedIn = !!localStorage.getItem('token');

		request
			.get(SUBSCRIPTIONS_URL)
			.then(response => getSubscriptions(response, isLoggedIn))
			.catch(() =>
				console.error('Something went wrong. Please refresh the page.')
			);

		if (isLoggedIn) {
			request
				.get(USER_SUBSCRIPTION_URL)
				.then(response => getUserSubscription(response))
				.catch(() =>
					console.error('Something went wrong. Please refresh the page.')
				);
		}
	}, []);

	useEffect(() => {
		if (selectedId !== DUMMY_SELECTED_PLAN) {
			request
				.get(`${SUBSCRIPTIONS_URL}/${selectedId}`)
				.then(() => getSelectedPlan())
				.catch(() =>
					toast.error('Something went wrong. Please refresh the page.')
				);
		}
	}, [selectedId]);

	useEffect(() => {
		props?.updateSelectedPlan(selectedPlan);
	}, [selectedPlan]);
	return (
		<React.Fragment>
			<Row className="mt-2 g-2">
				<Col xs={12} sm={12} md={7}>
					<Row className="g-1">
						{subscriptions.length > 0 &&
							subscriptions?.map((item, index) => {
								const isSelected = item.id === selectedId;
								let currentPlanLabel = userSubscription?.id === item.id;

								if (item.id === 0) {
									currentPlanLabel = true;
								}
								if (item.name === 'ADMIN') { return }
								return (
									<Col
										key={index}
										md={12}
										sm={12}
										xs={12}
										className="cursor-pointer"
										onClick={() => {
											if (item.name === 'Basic' || item.name === 'Demo' || item.name == 'API') {
												setSelectedPlan(item);
												setSelectedId(item.id);
												props?.onSelect(item);
											}
										}}>
										<Card selected={isSelected}>
											<FlexDisplaySpaceBetween>
												<FlexDisplaySmallGap>
													<SubscriptionIcon
														src={isSelected ? checkIconLight : checkIconDark}
														alt="icon"
													/>
													<FlexDisplayMediumGap>
														<SubscriptionName selected={isSelected}>
															{item.name}
														</SubscriptionName>
														{currentPlanLabel && (
															<CurrentPlan>Current</CurrentPlan>
														)}
													</FlexDisplayMediumGap>
												</FlexDisplaySmallGap>
												{/* <CardPrice>
													{item.name === 'Basic' || item.name === 'Demo' ? (
														<Pricing selected={isSelected}>Free</Pricing>
													) : (
														<ComingSoon>Coming Soon</ComingSoon>
													)}
												</CardPrice> */}
											</FlexDisplaySpaceBetween>
										</Card>
									</Col>
								);
							})}
					</Row>
				</Col>
				<Col xs={12} sm={12} md={5} className="p-0 px-3">
					<FeatureLabel>Features</FeatureLabel>
					<SubscriptionDetails
						selectedPlan={selectedPlan}
						selectedId={selectedId}
					/>
				</Col>
			</Row>
			{userSubscription && (
				<FlexDisplayFlexEnd className="mt-3">
					<ButtonComponent
						onClick={props.onClickUpgrade}
						width="25%"
						label={userSubscription?.id === 7 ? "Contact CBSA" : "Upgrade"}
						disabled={
							selectedId === userSubscription.id ||
							(selectedPlan && _.toNumber(selectedPlan.price) <= 0)
						}
					/>
				</FlexDisplayFlexEnd>
			)}
		</React.Fragment>
	);
};

const SubscriptionComponent = (): React.JSX.Element => {
	const [isShowChangeModal, setShowChangeModal] = React.useState(false);
	const [selectedPlan, setSelectedPlan] = useState(null);

	const subscriptionName = _.get(selectedPlan, 'name', '');

	return (
		<React.Fragment>
			<FormLabel>Your Membership</FormLabel>
			<FormValue>{subscriptionName}</FormValue>

			<SubscriptionFeatures
				currentPlan={SUBSCRIPTION_TYPE.BASIC}
				onSelect={() => { }}
				onClickUpgrade={() => {
					setShowChangeModal(true);
				}}
				updateSelectedPlan={setSelectedPlan}
			/>
			<Modal
				show={isShowChangeModal}
				onHide={() => {
					setShowChangeModal(false);
				}}
				centered={true}>
				<Modal.Header className="bg-lightgrey">
					<Modal.Title>
						<h5 className="fs-24 fw-500">Change Membership</h5>
					</Modal.Title>
				</Modal.Header>

				<ModalBody>
					<div className="d-flex align-items-center bg-danger-pink w-100 text-red px-2 py-4">
						<img src={changeIcon} alt="icon" className="cursor-pointer mx-3" />
						<h5 className="fs-16 fw-600 m-0">
							Are you sure you want to change membership?
						</h5>
					</div>
				</ModalBody>

				<Modal.Footer>
					<ButtonComponent
						onClick={() => setShowChangeModal(false)}
						width="25%"
						label="Cancel"
						kind="SECONDARY"
					/>
					<SignOutButton className="w-25 m-0" onClick={() => { }}>
						Change
					</SignOutButton>
				</Modal.Footer>
			</Modal>
		</React.Fragment>
	);
};

export default SubscriptionComponent;
