import React from 'react';
import { Pagination } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import { BiTrendingDown, BiTrendingUp } from 'react-icons/bi';
import { FaArrowTrendDown, FaArrowTrendUp } from 'react-icons/fa6';

import {
	InformationPercentage,
	Link,
	TableData,
	TableHead,
} from '../../Screens/Company/Company.styled';
import {
	ITrendingReportsData,
	ITrendingReportsTableProps,
} from '../../Screens/Home/Home.types';
import { convertToPercentage, getDefaultMarket } from '../../Utils';
import { ROUTES } from '../../Constants/routes';
import { FlexDisplaySmallGap } from '../../Screens/Preferences/Preferences.styled';

export const convertValue = (value: string | number | undefined) => {
	const newValue = Number(value);

	if (_.isNaN(newValue) || _.isUndefined(newValue)) {
		return {
			className: 'bg-light',
			color: '#4e4e4e',
			value: 'N/A',
			originalValue: NaN,
			iconTrending: null,
		};
	}

	if (newValue > 0) {
		return {
			className: 'bg-success-100',
			color: '#107C10',
			value: `${newValue}%`,
			originalValue: newValue,
			iconTrending: <BiTrendingUp className="fs-12" color="#107C10" />,
		};
	}
	return {
		className: 'bg-danger-33',
		color: '#D13438',
		value: `${newValue}%`,
		originalValue: newValue,
		iconTrending: <BiTrendingDown className="fs-12" color="#D13438" />,
	};
};

export const renderWatchlistMobile = (
	title = '',
	sentiment = '-',
	change = '-',
	positive = '-',
	negative = '-',
	neutral = '-',
	quarter = '-',
	date = '-',
	companyId: number | string,
	documentId: number | string,
	documentType: string,
	companyName: string,
	companyStockCode: string
) => (
	<div className="px-2 py-2">
		<div className="d-flex align-items-center justify-content-between mb-15">
			<div className="d-flex gap-2">
				<h3 className="fs-10 m-0 fw-400 text-neutral">{quarter}</h3>
				<h3 className="fs-10 m-0 fw-400 text-neutral">{date}</h3>
			</div>
			<div className="d-flex gap-2">
				<Link
					className="fs-10 m-0 fw-600 text-neutral align-self-center"
					href={`${ROUTES.COMPANY}?country_id=${getDefaultMarket()}&document_type=${documentType}&company_id=${companyId}`}>
					{companyName}
				</Link>
				<h3 className="fs-10 m-0 fw-600 text-white p-1 company-stock-code">
					{companyStockCode}
				</h3>
			</div>
		</div>
		<Link
			className="fs-10"
			href={`${ROUTES.SINGLE_REPORT}?country_id=${getDefaultMarket()}&document_type=${documentType}&company_id=${companyId}&document_id=${documentId}`}>
			{title}
		</Link>
		<div className="d-flex justify-content-between align-items-center p-2 bg-lightgrey rounded mt-15">
			<div className="d-flex gap-2 align-items-center">
				<h2 className="fs-24 fw-700 m-0">{convertValue(sentiment).value}</h2>
				<h2 className="fs-12 fw-600 m-0">
					{convertValue(change).iconTrending}
					{convertValue(change).value}
				</h2>
			</div>
			<div className="d-flex gap-1">
				<InformationPercentage
					className="bg-success-100 rounded"
					textStyle="#107C10">
					{convertValue(positive).value}
				</InformationPercentage>
				<InformationPercentage
					className="bg-danger-33 rounded"
					textStyle="#D13438">
					{convertValue(negative).value}
				</InformationPercentage>
				<InformationPercentage className="bg-light rounded">
					{convertValue(neutral).value}
				</InformationPercentage>
			</div>
		</div>
	</div>
);

const TrendingReportsTableComponent = (props: ITrendingReportsTableProps) => {
	const {
		trendingReportsData,
		trendingReportsMeta,
		onChangePageTrending,
		isMobileVersion = false,
	} = props;

	const currentPage = _.get(trendingReportsMeta, 'current_page', 1);
	const total = _.get(trendingReportsMeta, 'total', 1);
	const documentType = props?.documentType || '';

	if (isMobileVersion) {
		return (
			<React.Fragment>
				{trendingReportsData?.length > 0 &&
					trendingReportsData?.map(
						(item: ITrendingReportsData, index: number) => {
							const quarter = `${item?.fyear} Q${item?.fqtr}`;
							const date = moment(item?.date).format('MMMM D, YYYY');
							const title = item?.title;
							const sentiment = convertToPercentage(
								item?.final_score?.sentiment
							);
							const change = convertToPercentage(item?.final_score?.change);
							const positive = convertToPercentage(item?.score?.positive);
							const negative = convertToPercentage(item?.score?.negative);
							const neutral = convertToPercentage(item?.score?.neutral);

							const companyId = item?.company?.id || '';
							const documentId = item?.id || '';

							const companyName = item?.company?.name || '';
							const companyStockCode = item?.company?.stock_code || '';

							return (
								<React.Fragment key={index}>
									{renderWatchlistMobile(
										title,
										sentiment,
										change,
										positive,
										negative,
										neutral,
										quarter,
										date,
										companyId,
										documentId,
										documentType,
										companyName,
										companyStockCode
									)}
								</React.Fragment>
							);
						}
					)}
			</React.Fragment>
		);
	}

	return (
		<React.Fragment>
			<div className="table-container">
				<div className="table-scrollable">
					<table className="table table-striped table-borderless mb-4">
						<TableHead>
							<tr>
								<td>Quarter</td>
								<td>Release Date</td>
								<td>Title</td>
								<td>Sentiment</td>
								<td>Change</td>
								<td>Positive</td>
								<td>Negative</td>
								<td>Neutral</td>
							</tr>
						</TableHead>
						<tbody>
							{trendingReportsData?.length > 0 &&
								trendingReportsData.map(
									(item: ITrendingReportsData, index: number) => {
										const quarter = `${item?.fyear} Q${item?.fqtr}`;
										const date = moment(item?.date).format('MMMM D, YYYY');
										const title = item?.title;
										const sentiment = convertToPercentage(
											item?.final_score?.sentiment
										);
										const change = convertToPercentage(
											item?.final_score?.change
										);
										const positive = convertToPercentage(item?.score?.positive);
										const negative = convertToPercentage(item?.score?.negative);
										const neutral = convertToPercentage(item?.score?.neutral);

										const companyId = item?.company?.id || '';
										const documentId = item?.id || '';

										const isGrowth = convertValue(change).originalValue > 0;

										const renderChangeIcon = () => {
											if (change && !isNaN(Number(change))) {
												if (isGrowth) {
													return (
														<FlexDisplaySmallGap>
															<FaArrowTrendUp />
															{convertValue(change).value}
														</FlexDisplaySmallGap>
													);
												}
												if (!isGrowth) {
													return (
														<FlexDisplaySmallGap>
															<FaArrowTrendDown />
															{convertValue(change).value}
														</FlexDisplaySmallGap>
													);
												}
											}

											return convertValue(change).value;
										};

										return (
											<React.Fragment key={index}>
												<tr>
													<TableData>{quarter}</TableData>
													<TableData>{date}</TableData>
													<TableData className="trim-text">
														<Link
															href={`${ROUTES.SINGLE_REPORT}?country_id=${getDefaultMarket()}&document_type=${documentType}&company_id=${companyId}&document_id=${documentId}`}>
															{title}
														</Link>
													</TableData>
													<td>
														<InformationPercentage
															className={`${
																convertValue(sentiment).className
															} rounded`}
															textStyle={convertValue(sentiment).color}>
															{convertValue(sentiment).value}
														</InformationPercentage>
													</td>
													<td>
														<InformationPercentage
															textStyle={convertValue(change).color}>
															{renderChangeIcon()}
														</InformationPercentage>
													</td>
													<td>
														<InformationPercentage
															className="bg-success-100 rounded"
															textStyle="#107C10">
															{convertValue(positive).value}
														</InformationPercentage>
													</td>
													<td>
														<InformationPercentage
															className="bg-danger-33 rounded"
															textStyle="#D13438">
															{convertValue(negative).value}
														</InformationPercentage>
													</td>
													<td>
														<InformationPercentage className="bg-light rounded">
															{convertValue(neutral).value}
														</InformationPercentage>
													</td>
												</tr>
											</React.Fragment>
										);
									}
								)}
						</tbody>
					</table>
				</div>
			</div>
			<div className="d-flex justify-content-end">
				<Pagination
					size="small"
					showSizeChanger={false}
					defaultCurrent={currentPage}
					total={total}
					onChange={page => {
						onChangePageTrending(page);
					}}
				/>
			</div>
		</React.Fragment>
	);
};

export default TrendingReportsTableComponent;
