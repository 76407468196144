import React, { useEffect, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import moment from 'moment';
import { DatePicker, List, Skeleton } from 'antd';
import { FaArrowLeft } from 'react-icons/fa';
import dayjs from 'dayjs';

import {
	RightTabContainer,
	RightTabItem,
} from '../../Screens/SingleReport/SingleReport.styled';
import { Button } from '..';
import {
	ColoredLabel,
	LoadingComponent,
} from '../../Screens/Industries/Industries.desktop.screen';
import { DATE_NOW, CALL, REPORT_DATE_RANGE } from '../../Constants';
import { ISideTab } from '../../Screens/Compare/Compare.types';
import IndustryDropdown from '../Industries/Dropdown.component';
import { ICompanyList } from '../../Screens/Report/Report.types';
import { FlexDisplay } from '../../Screens/Preferences/Preferences.styled';
import AutoCompleteComponent from './AutoComplete';

interface CompanyData {
	stock_code: string;
	name: string;
}

export const FilterReportsButton = styled.div`
	border-radius: 4px;
	background: #e0ecfc;
	color: #1f78b4;
	padding: 2px 4px;
	font-size: 10px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
`;

const { RangePicker } = DatePicker;

const ButtonStyled = styled.button<{ isSelected: boolean }>`
	width: 100%;
	border: ${({ isSelected }) =>
		isSelected ? '1px solid #225fb0' : '1px solid #ccc;'};
	cursor: pointer;
	background-color: ${({ isSelected }) => (isSelected ? '#e0ecfc' : '#fff')};
	color: #000;
	font-style: normal;
	font-size: 12px;
	font-weight: 600;
	line-height: 20px;
	margin: 0;
	padding: 4px 8px;

	&:hover {
		border: 1px solid #225fb0;
		background-color: #e0ecfc;
	}
`;

const ButtonContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
`;

const StyledListItem = styled(List.Item) <{ bgColor?: string }>`
	display: flex;
	align-items: center;
	padding: 8px !important;
	border-radius: 4px;
	background-color: ${({ bgColor }) => (bgColor ? bgColor : '#FFF')};
	margin: 0;

	.company-code {
		font-weight: 600;
		margin-right: 8px;
		margin-left: 8px;
	}
`;

type ButtonGroupProps = {
	items: any[];
	onClick: (value: string) => () => void;
	selectedRange: {
		label: string;
	};
};

const ButtonGroup = ({ items, onClick, selectedRange }: ButtonGroupProps) => {
	return (
		<ButtonContainer className="">
			{items.map((item, index) => (
				<Col xs={6} key={index} className="p-1">
					<ButtonStyled
						className="rounded"
						isSelected={item === selectedRange}
						onClick={onClick(item)}>
						{item.label}
					</ButtonStyled>
				</Col>
			))}
		</ButtonContainer>
	);
};

const CompanyList = (props: ICompanyList) => {
	const divRef = useRef(null);

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	const handleScroll = e => {
		if (divRef.current) {
			props.setScrollDirection([e.target.scrollTop, e.target.scrollHeight]);
		}
	};

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		document
			.getElementById('company-list')
			?.addEventListener('scroll', handleScroll);
		document
			.getElementById('company-list')
			?.addEventListener('touchmove', handleScroll);
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		return () => {
			document
				.getElementById('company-list')
				?.removeEventListener('scroll', handleScroll);
			document
				.getElementById('company-list')
				?.removeEventListener('touchmove', handleScroll);
		};
	}, []);

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		if (
			// props.scrollDirection[0] * 5 >= props.scrollDirection[1] - 10 &&
			!props.isLoadingScrollCompany
		) {
			props.getCompaniesNextPage();
		}
	}, [props.scrollDirection]);

	return (
		<div
			ref={divRef}
			onTouchMove={() => {
				if (!props.isLoadingScrollCompany) props.getCompaniesNextPage();
			}}>
			<List
				dataSource={props.data}
				renderItem={(item: CompanyData, index: number) => (
					<StyledListItem
						onClick={props.onClickConfirm([item.name, item.stock_code])}
						bgColor={index % 2 != 0 ? '#F5F5F5' : ''}
						className="hover-blue fs-10 fw-500">
						<span className="company-code fw-700">{item.stock_code}</span>
						{item.name}
					</StyledListItem>
				)}
				loadMore={
					(props.data.length > 0 && <div className="d-flex flex-column gap-2 mt-2">
						<Skeleton.Input
							block
							size="small"
							active></Skeleton.Input>
						<Skeleton.Input
							block
							size="small"
							active></Skeleton.Input>
					</div>)
				}
				id="company-list"
				className="mt-2"
				style={{
					height: '250px',
					overflow: 'hidden',
					overflowY: 'scroll',
					border: '1px solid #F5F5F5',
					borderRadius: '4px',
				}}
			/>
		</div>
	);
};

const SideTab = ({
	industryCode,
	setIndustryCode,
	onClickConfirm,
	filtered,
	onDeleteList,
	data,
	scrollDirection,
	setScrollDirection,
	setStartDate,
	setFinishDate,
	selectedRange,
	setSelectedRange,
	setDocumentType,
	documentType,
	onConfirm,
	getCompaniesNextPage,
	isLoadingScrollCompany,
	label,
	isWithBackButton = false,
	backButtonOnClick = () => { },
	startDate,
	finishDate,
	isRenderSentimentChart,
}: ISideTab) => {
	const { SIX_MONTH, YEAR, CUSTOM_DATE, THREE_YEAR, FIVE_YEAR, TEN_YEAR } =
		REPORT_DATE_RANGE;

	const buttonDateValue = Array.from(Object.values(REPORT_DATE_RANGE));

	const getRangeDate = (rangeType: string, value?: any) => {
		if (rangeType === CUSTOM_DATE.label) {
			return [
				moment(value[0].$d).format('YYYY-MM-DD'),
				moment(value[1].$d).format('YYYY-MM-DD'),
			];
		}
		if (rangeType === SIX_MONTH.label) {
			return [
				DATE_NOW.clone().subtract(6, 'months').add(1, 'day').format('YYYY-MM-DD'),
				DATE_NOW.clone().format('YYYY-MM-DD'),
			];
		}
		if (rangeType === YEAR.label) {
			return [
				DATE_NOW.clone().subtract(1, 'years').add(1, 'day').format('YYYY-MM-DD'),
				DATE_NOW.clone().format('YYYY-MM-DD'),
			];
		}
		if (rangeType === THREE_YEAR.label) {
			return [
				DATE_NOW.clone().subtract(3, 'years').add(1, 'day').format('YYYY-MM-DD'),
				DATE_NOW.clone().format('YYYY-MM-DD'),
			];
		}
		if (rangeType === FIVE_YEAR.label) {
			return [
				DATE_NOW.clone().subtract(5, 'years').add(1, 'day').format('YYYY-MM-DD'),
				DATE_NOW.clone().format('YYYY-MM-DD'),
			];
		}
		if (rangeType === TEN_YEAR.label) {
			return [
				DATE_NOW.clone().subtract(10, 'years').add(1, 'day').format('YYYY-MM-DD'),
				DATE_NOW.clone().format('YYYY-MM-DD'),
			];
		}
		return [
			DATE_NOW.clone().subtract(50, 'years').add(1, 'day').format('YYYY-MM-DD'),
			DATE_NOW.clone().format('YYYY-MM-DD'),
		];
	};

	const changeRangeDate = (range: any, value?: any) => () => {
		const [startDateGenerated, finishDateGenerated] = getRangeDate(
			range.label,
			value
		);
		setSelectedRange(range);
		setStartDate(startDateGenerated);
		setFinishDate(finishDateGenerated);
	};

	return (
		<React.Fragment>
			{isWithBackButton && (
				<div className="w-20 cursor-pointer" onClick={backButtonOnClick}>
					<FilterReportsButton className="py-1 px-2">
						<FlexDisplay>
							<FaArrowLeft className="mx-1" />
							<div>Back</div>
						</FlexDisplay>
					</FilterReportsButton>
				</div>
			)}

			{!isWithBackButton && (
				<RightTabContainer>
					<RightTabItem onClick={() => { }} selected={true}>
						Selected
					</RightTabItem>
				</RightTabContainer>
			)}
			<div className="table-scrollable mb-2">
				{filtered &&
					filtered.length > 0 &&
					filtered.map((code, index) => {
						return (
							<ColoredLabel
								key={index}
								sector={code[1]}
								className="text-white company-stock-code fw-600 fs-10 mx-1">
								{code[1]}
								<span
									className="ms-1 cursor-pointer"
									onClick={onDeleteList(index)}>
									x
								</span>
							</ColoredLabel>
						);
					})}
			</div>
			<AutoCompleteComponent onClickConfirm={onClickConfirm} />
			<RightTabContainer className="mt-4">
				<RightTabItem onClick={() => { }} selected={true}>
					Company
				</RightTabItem>
			</RightTabContainer>
			<CompanyList
				scrollDirection={scrollDirection}
				setScrollDirection={setScrollDirection}
				data={data}
				onClickConfirm={onClickConfirm}
				getCompaniesNextPage={getCompaniesNextPage}
				isLoadingScrollCompany={isLoadingScrollCompany}
			/>
			<RightTabContainer className="mt-4">
				<RightTabItem onClick={() => { }} selected={true}>
					Industry
				</RightTabItem>
			</RightTabContainer>
			<div className="w-100 mx-1 mt-2 d-flex gap-4 justify-content-between">
				<div className="d-flex row gap-1">
					<IndustryDropdown
						industryCode={industryCode}
						setIndustryCode={setIndustryCode}
					/>
				</div>
				<div
					onClick={onClickConfirm(industryCode)}
					className="hover-white justify-content-center cursor-pointer d-flex align-items-center rounded mh-100 px-3 py-2 bg-darkerblue text-white">
					<h3 className="fs-10 fw-600">Add</h3>
				</div>
			</div>
			<RightTabContainer className="mt-4">
				<RightTabItem onClick={() => { }} selected={true}>
					Date Range
				</RightTabItem>
			</RightTabContainer>
			<RangePicker
				className="mx-3"
				format={'YYYY/MM/DD'}
				bordered={false}
				onChange={(value: any) => {
					if (value?.length > 0) {
						changeRangeDate(CUSTOM_DATE, value)();
					}
				}}
				value={[
					dayjs(startDate, 'YYYY/MM/DD'),
					dayjs(finishDate, 'YYYY/MM/DD'),
				]}
			/>
			<ButtonGroup
				items={buttonDateValue.slice(0, -1)}
				onClick={changeRangeDate}
				selectedRange={selectedRange}
			/>
			<RightTabContainer className="my-3">
				<RightTabItem onClick={() => { }} selected={true}>
					Data Source
				</RightTabItem>
			</RightTabContainer>
			<Row className="g-2 mb-3">
				<ButtonGroup
					items={CALL}
					onClick={type => () => setDocumentType(type)}
					selectedRange={documentType}
				/>
			</Row>
			<Button
				isLoading={false}
				rounded
				label={label || 'Compare'}
				onClick={onConfirm()}
			/>
		</React.Fragment>
	);
};

export default SideTab;
