import { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Input } from 'antd';
import { Controller, useForm } from 'react-hook-form';
import _ from 'lodash';

import { Button } from '../index';
import { BUTTON_TYPE } from '../Button/Button.component';

const AddWatchlistComponent = (props: any) => {
	const {
		isShowAlertModal,
		setIsShowAlertModal,
		addWatchlist,
		watchlistToEdit,
		updateWatchlist,
		setWatchlistToEdit,
	} = props;

	const { handleSubmit, control, setValue, reset } = useForm({
		defaultValues: {
			name: '',
		},
	});

	const isEditMode = watchlistToEdit !== null;

	useEffect(() => {
		if (watchlistToEdit !== null) {
			setValue('name', watchlistToEdit.name);
		}
	}, [watchlistToEdit]);

	const onSubmit = (data: any) => {
		if (isEditMode) {
			const watchlistId = _.get(watchlistToEdit, 'id', '');
			updateWatchlist(watchlistId, data);
		}

		if (!isEditMode) {
			addWatchlist(data);
		}

		setTimeout(() => {
			setIsShowAlertModal(false);
			setWatchlistToEdit(null);
		}, 500);

		reset();
	};

	return (
		<Modal
			show={isShowAlertModal}
			onHide={() => {
				reset();
				setIsShowAlertModal(false);
			}}
			centered={true}
			size="sm">
			<Modal.Header>
				<div className="d-flex justify-content-between w-100 align-items-center">
					<h5 className="fs-12 fw-500 m-0 w-50">
						{isEditMode ? 'Enter a new name' : 'Add Watchlist'}
					</h5>
				</div>
			</Modal.Header>
			<Modal.Body>
				<Controller
					name="name"
					control={control}
					rules={{
						required: 'Name is required',
					}}
					render={({ field: { value, onChange } }) => (
						<Input
							placeholder="Watchlist name"
							onChange={onChange}
							type="email"
							value={value}
						/>
					)}
				/>
			</Modal.Body>
			<Modal.Footer>
				<div className="w-30 fs-12">
					<Button
						label="Cancel"
						rounded={false}
						size="small"
						kind={BUTTON_TYPE.SECONDARY}
						onClick={() => {
							reset();
							setIsShowAlertModal(false);
						}}
					/>
				</div>
				<div className="w-30 fs-12">
					<Button
						label="Save"
						rounded={false}
						size="small"
						onClick={handleSubmit(onSubmit)}
					/>
				</div>
			</Modal.Footer>
		</Modal>
	);
};

export default AddWatchlistComponent;
