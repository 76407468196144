import React, { useState } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';

import { IHandleChangeCall, IHandleChangeMarket } from './NavigationBar.types';
import {
	CALL,
	LOCAL_STORAGE_VARIABLE,
	MARKET_DROPDOWN_SELECT,
	SUBSCRIPTION_TYPE,
} from '../../Constants';
import { IAuthorizationProps } from '../Authorization/Authorization.types';
import { DemoAccountAlert } from '../index';
import NavigationDesktopComponent from './Desktop';
import NavigationMobileComponent from './Mobile';
import { ROUTES } from '../../Constants/routes';
import {
	companiesParams,
	compareParams,
	homeParams,
	industriesParams,
	reportParams,
	singleReportParams,
} from './NavigationBar.utils';
import { getDefaultCalls, getDefaultMarket } from '../../Utils';

export const LogoContainer = styled.img`
	width: 90px;
`;

const getSelectedCall = () => {
	if (localStorage.getItem('selectedCall')) {
		// @ts-ignore
		return JSON.parse(localStorage.getItem('selectedCall'));
	}

	return CALL[0];
};

const getSelectedMarket = () => {
	if (localStorage.getItem('selectedMarket')) {
		// @ts-ignore
		return JSON.parse(localStorage.getItem('selectedMarket'));
	}

	return MARKET_DROPDOWN_SELECT[0];
};

const NavigationBarComponent = (props: IAuthorizationProps) => {
	const navigate = useNavigate();

	const [searchValue, setSearchValue] = useState('');
	const [selectedMarket, setSelectedMarket] = useState(getSelectedMarket());
	const [selectedCall, setSelectedCall] = useState(getSelectedCall());

	const token = localStorage.getItem('token');
	const isLoggedIn = !!token;

	const location = useLocation();
	const currentRoute = location.pathname;

	const handleChangeCall = (payload: IHandleChangeCall) => {
		const callType = {
			label: payload.label,
			value: payload.value,
		};
		setSelectedCall({
			label: payload.label,
			value: payload.value,
		});
		localStorage.setItem('selectedCall', JSON.stringify(callType));
	};

	const onSearch = (value: string) => {
		setSearchValue(value);
	};

	const renderDemoAlertComponent = () => {
		const getFlag = localStorage.getItem(
			LOCAL_STORAGE_VARIABLE.SUBSCRIPTION_TYPE
		);

		if (getFlag && getFlag === SUBSCRIPTION_TYPE.DEMO) {
			return <DemoAccountAlert {...props} />;
		}
	};

	const onClickConfirm = (data: string | number[]) => (id: string) => {
		const isCompany = data[2];
		const dataId = isCompany ? id : data[1];

		if (isCompany) {
			navigate(
				`/companies?document_type=${selectedCall.value
				}&country_id=${getDefaultMarket()}&company_id=${dataId}`
			);
		} else {
			navigate(
				`/industries?document_type=${selectedCall.value
				}&country_id=${getDefaultMarket()}&industry_code=${dataId}`
			);
		}
	};

	const changeUrl = (newDocumentType: string, newMarket: number) => {
		let url;
		if (currentRoute === `/${ROUTES.SINGLE_REPORT}`) {
			url = singleReportParams(newDocumentType, newMarket);
		}

		if (currentRoute === `${ROUTES.ROOT}`) {
			url = homeParams(newDocumentType, newMarket);
		}

		if (currentRoute === `/${ROUTES.COMPANY}`) {
			url = companiesParams(newDocumentType, newMarket);
		}

		if (currentRoute === `/${ROUTES.INDUSTRIES}`) {
			url = industriesParams(newDocumentType, newMarket);
		}

		if (currentRoute === `/${ROUTES.REPORT}`) {
			url = reportParams(newDocumentType, newMarket);
		}

		if (currentRoute === `/${ROUTES.COMPARE}`) {
			url = compareParams(newDocumentType, newMarket);
		}

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		window.location.href = url;
	};

	const handleChangeMarket = (payload: IHandleChangeMarket) => {
		const market = {
			value: payload.value,
			id: payload.value,
		};
		setSelectedMarket(market);
		localStorage.setItem('selectedMarket', JSON.stringify(market));

		changeUrl(getDefaultCalls(), payload.value);
	};

	const changeDocumentType = (payload: { label: string; value: string }) => {
		const newDocumentType = payload?.value;

		handleChangeCall(payload);

		changeUrl(newDocumentType, getDefaultMarket());
	};

	let showDocumentDropdown = true;
	let showMarketDropdown = true;

	if (
		currentRoute === `/${ROUTES.COMPARE}` ||
		currentRoute === `/${ROUTES.REPORT}` ||
		currentRoute === `/${ROUTES.SINGLE_REPORT}`
	) {
		showDocumentDropdown = false;
	}
	if (currentRoute === `/${ROUTES.SINGLE_REPORT}`) {
		showMarketDropdown = false;
	}

	const navigationProps = () => ({
		...props,
		searchValue,
		setSearchValue,
		selectedMarket,
		setSelectedMarket,
		selectedCall,
		setSelectedCall,
		handleChangeMarket,
		handleChangeCall,
		onSearch,
		isLoggedIn,
		onClickConfirm,
		changeDocumentType,
		showDocumentDropdown,
		showMarketDropdown,
	});

	return (
		<React.Fragment>
			<NavigationDesktopComponent {...navigationProps()} />
			<NavigationMobileComponent {...navigationProps()} />
			{renderDemoAlertComponent()}
		</React.Fragment>
	);
};

export default NavigationBarComponent;
