import React from 'react';
import type { MenuProps } from 'antd';
import { Dropdown } from 'antd';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { toProperCase } from '../../Utils';
import { FlexDisplaySmallGap } from '../../Screens/Preferences/Preferences.styled';
import toolTipIcon from '../../Assets/Icon/tooltip-icon.svg';
import {
	ActionButton,
	ActionLabel,
	ActiveUserLabel,
	ExtendButton,
	Input,
	NotificationLabel,
	TableContainer,
	TableData,
	TableHead,
	TableHeader,
	TableRowHead,
	TooltipIcon,
	TooltipSpan,
} from './UserTable.styled';
import { ITableData, ITableProps } from './UserTable.types';

const renderTableHeadWithTooltips = (label: string, tooltipInfo: string) => {
	return (
		<FlexDisplaySmallGap>
			<div>{label}</div>
			<OverlayTrigger
				placement="top"
				overlay={
					<Tooltip id="tooltip">
						<TooltipSpan>{tooltipInfo}</TooltipSpan>
					</Tooltip>
				}>
				<TooltipIcon src={toolTipIcon} alt="tooltip icon" />
			</OverlayTrigger>
		</FlexDisplaySmallGap>
	);
};

const renderTableHeader = (): React.JSX.Element => {
	return (
		<thead>
			<TableRowHead>
				<TableHeader colSpan={8}>Basic Information</TableHeader>
				<TableHeader colSpan={4}>User Subscription Management</TableHeader>
			</TableRowHead>
			<TableRowHead>
				<TableHead>Email</TableHead>
				<TableHead>Joined</TableHead>
				<TableHead>Active</TableHead>
				<TableHead>Last Sign-in</TableHead>
				<TableHead>Action</TableHead>
				<TableHead>
					{renderTableHeadWithTooltips('Newsletter', 'News and Updates')}
				</TableHead>
				<TableHead>
					{renderTableHeadWithTooltips(
						'Watchlist',
						'Watchlist Report Announcement'
					)}
				</TableHead>
				<TableHead>
					{renderTableHeadWithTooltips('Tips', 'Tips and Tutorials')}
				</TableHead>
				<TableHead>Subscription</TableHead>
				<TableHead>Change Subscription</TableHead>
				<TableHead>Access Expiration</TableHead>
				<TableHead>Extended Access (days)</TableHead>
			</TableRowHead>
		</thead>
	);
};

const renderExtendAccess = () => {
	return (
		<FlexDisplaySmallGap>
			<Input placeholder="numbers" />
			<ExtendButton>Extend</ExtendButton>
		</FlexDisplaySmallGap>
	);
};

const actionItems: MenuProps['items'] = [
	{
		key: '1',
		label: <ActionLabel className="text-decoration-none">Activate</ActionLabel>,
	},
	{
		key: '2',
		label: (
			<ActionLabel className="text-decoration-none">Deactivate</ActionLabel>
		),
	},
	{
		key: '3',
		label: <ActionLabel className="text-decoration-none">Delete</ActionLabel>,
	},
	{
		key: '4',
		label: (
			<ActionLabel className="text-decoration-none">Force Password</ActionLabel>
		),
	},
];

const changeItems: MenuProps['items'] = [
	{
		key: '1',
		label: <ActionLabel>Demo</ActionLabel>,
	},
	{
		key: '2',
		label: <ActionLabel>Basic</ActionLabel>,
	},
	{
		key: '3',
		label: <ActionLabel>Standard</ActionLabel>,
	},
	{
		key: '4',
		label: <ActionLabel>Developer</ActionLabel>,
	},
	{
		key: '5',
		label: <ActionLabel>Enterprise</ActionLabel>,
	},
];

const renderActionButton = () => {
	return (
		<Dropdown menu={{ items: actionItems }} placement="bottomRight">
			<ActionButton>Action</ActionButton>
		</Dropdown>
	);
};

const renderChangeSubscriptionButton = () => {
	return (
		<Dropdown menu={{ items: changeItems }} placement="topLeft">
			<ActionButton>Change</ActionButton>
		</Dropdown>
	);
};

const renderTableBody = (data: ITableData[]): React.JSX.Element => {
	return (
		<tbody>
			{data.map((item, index) => (
				<tr key={index}>
					<TableData>
						<b>{item?.email}</b>
					</TableData>
					<TableData>{item?.joined}</TableData>
					<TableData>
						<ActiveUserLabel isActive={item?.active}>
							{item?.active ? 'Yes' : 'No'}
						</ActiveUserLabel>
					</TableData>
					<TableData>{item?.lastSignIn}</TableData>
					<TableData>{renderActionButton()}</TableData>
					<TableData>
						<NotificationLabel isActive={item?.newsLetter}>
							{item?.active ? 'On' : 'Off'}
						</NotificationLabel>
					</TableData>
					<TableData>
						<NotificationLabel isActive={item?.watchList}>
							{item?.watchList ? 'On' : 'Off'}
						</NotificationLabel>
					</TableData>
					<TableData>
						<NotificationLabel isActive={item?.tips}>
							{item?.tips ? 'On' : 'Off'}
						</NotificationLabel>
					</TableData>
					<TableData>{toProperCase(item?.subscription)}</TableData>
					<TableData>{renderChangeSubscriptionButton()}</TableData>
					<TableData>{item?.accessExpiration}</TableData>
					<TableData>{renderExtendAccess()}</TableData>
				</tr>
			))}
		</tbody>
	);
};

const UserTableComponent = (props: ITableProps): React.JSX.Element => {
	return (
		<div>
			<TableContainer>
				{renderTableHeader()}
				{renderTableBody(props?.data)}
			</TableContainer>
		</div>
	);
};

export default UserTableComponent;
