import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { IDocuments, IScoresList } from '../../Screens/Company/Company.types';
import { Dataset } from '../LineChart/LineChart.component';
import {
	CanvasReturnsStyled,
	CanvasSentimentStyled,
} from '../../Screens/Company/Company.styled';
import { LineChart } from '..';
import { convertToPercentage } from '../../Utils';
import { FaExpandAlt } from 'react-icons/fa';
import { LoadingComponent } from '../../Screens/Industries/Industries.desktop.screen';

interface IGraph {
	documents: IDocuments;
	sentiments: Dataset[];
	returns: Dataset[];
	xCoordinate: number;
	setXCoordinate: Dispatch<SetStateAction<number>>;
	setDate: Dispatch<SetStateAction<Date | undefined>>;
	isSmallerScreen: boolean;
	date?: Date;
	scoresList: IScoresList[];
	setScoresList: (value: IScoresList[]) => void;
	stockPrices: { t: string | number | Date; c: number }[];
	setStockPrices: (value: any[]) => void;
	navigateToCompare?: () => void;
	isSmartphone?: boolean;
	finishDate: Date;
	startDate: Date;
}

const Graph = (props: IGraph) => {
	const [scoresListData, setScoresListData] = useState<any>([]);
	const [stockPricesData, setStockPricesData] = useState<any>([]);

	const {
		xCoordinate,
		setXCoordinate,
		setDate,
		date,
		scoresList,
		stockPrices,
		isSmartphone,
		finishDate,
		startDate
	} = props;

	useEffect(() => {
		const mappingScoreList = scoresList && scoresList.map(data => ({
			date: new Date(data?.date),
			value: convertToPercentage(
				Number(data?.score?.positive) - Number(data?.score?.negative)
			),
		})) || null

		setScoresListData(mappingScoreList);

		const mappingStockPrices = stockPrices && stockPrices.map(
			(data: { t: string | number | Date; c: number }, index) => ({
				date: new Date(data?.t),
				value:
					index === 0
						? 0
						: (
							((Number(data.c) - Number(stockPrices[0]?.c)) /
								Number(stockPrices[0]?.c)) *
							100
						).toFixed(2),
			})
		) || null

		setStockPricesData(mappingStockPrices);
	}, [scoresList, stockPrices]);

	return (
		<React.Fragment>
			<CanvasSentimentStyled>
				{isSmartphone && (
					<div
						className="d-flex align-items-center position-absolute end-0 top-0"
						onClick={props.navigateToCompare}
						style={{ cursor: 'pointer' }}>
						<h5 className="fs-10 fw-400 mb-0 me-1">Advanced </h5>
						<div className="bg-lightgrey rounded px-1">
							<FaExpandAlt className="fs-10" />
						</div>
					</div>
				)}
				{scoresListData ? (
					<LineChart
						data={scoresListData}
						isGradient
						isNoXLabel
						title="Sentiment"
						xCoordinate={xCoordinate}
						date={date}
						setXCoordinate={setXCoordinate}
						setDate={setDate}
						finishDate={finishDate}
						startDate={startDate}
					/>
				) : (<LoadingComponent />)}
			</CanvasSentimentStyled>
			<CanvasReturnsStyled>
				{stockPricesData ? (
					<LineChart
						data={stockPricesData}
						isCurve
						title="Returns"
						xCoordinate={xCoordinate}
						date={date}
						setXCoordinate={setXCoordinate}
						setDate={setDate}
						startDate={startDate}
						finishDate={finishDate}
					/>
				) : (<LoadingComponent />)}
			</CanvasReturnsStyled>
		</React.Fragment>
	);
};

export default Graph;
