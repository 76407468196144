// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import { IPopoverProps } from './Popover.types';
import circleInfo from '../../Assets/Icon/circle-info.svg';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';


const PopoverComponent = (props: IPopoverProps): React.JSX.Element => {
    const { popoverPlacement, popoverTextHeader, popoverTextBody, popoverTrigger, className } = props

    return (
        <OverlayTrigger
            trigger={popoverTrigger || ['hover', 'focus']}
            placement={popoverPlacement || 'auto'}
            overlay={
                <Popover>
                    <Popover.Header as="h3"><Markdown remarkPlugins={[[remarkGfm, { singleTilde: false }]]}>{popoverTextHeader}</Markdown></Popover.Header>
                    <Popover.Body><Markdown remarkPlugins={[[remarkGfm, { singleTilde: false }]]}>{popoverTextBody}</Markdown></Popover.Body>
                </Popover>
            }
        >
            <img
                src={circleInfo}
                alt="tooltip icon"
                className={"cursor-pinter " + className}
            />
        </OverlayTrigger>
    );
};


export default PopoverComponent;
