// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useState, Dispatch, SetStateAction, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Checkbox, Input, Pagination } from 'antd';
import { Accordion, Col, Row } from 'react-bootstrap';
import { FaAngleDown, FaAngleUp, FaExpandAlt } from 'react-icons/fa';
import { FiMinimize2 } from 'react-icons/fi';
import { CaretDownFilled, CaretUpFilled } from '@ant-design/icons';
import _ from 'lodash';
import { BiFilter } from 'react-icons/bi';

import NewSentimentChart from '../../Components/Compare/NewSentimentChart.component';
import {
	InformationPercentage,
	Link,
	TableData,
	TableHead,
	WhiteContainer,
	YearTab,
} from '../Company/Company.styled';
import {
	ComponentContainer,
	Container,
	RightTabContainer,
	RightTabItem,
	RootContainer,
} from '../SingleReport/SingleReport.styled';
import groupCardData from './groupCardResponse.json';
import { ICompare, ICompareCard, ICompareTable } from './Compare.types';
import {
	CardContainer,
	CardPercentage,
	CustomTag,
	IndustryText,
	StyledCard,
} from './Compare.styled';
import { Button } from '../../Components';
import SentimentChartComponent, {
	lineColors,
} from '../../Components/Compare/SentimentChart.component';
import { ArrowUpCircle } from 'react-bootstrap-icons';
import { pointer } from 'd3';
import { mobile } from '../../Utils/layout.utils';
import IndustryDropdown from '../../Components/Industries/Dropdown.component';
import {
	ColoredLabel,
	getColorScheme,
	LoadingComponent,
} from '../Industries/Industries.desktop.screen';
import { ICompanyList } from '../Report/Report.types';
import YearFooter from '../../Components/Company/YearFooter.component';
import {
	CALL,
	CHART_INTERVAL,
	COMPARE_CARD_TAB_TYPE,
	COMPARE_TAB,
	REPORT_DATE_RANGE,
} from '../../Constants';
import { LIST_TYPE } from '../Industries/Industries.screen';
import { convertToPercentage, hexToRgbA } from '../../Utils';
import PaginationComponent from '../../Components/Company/Pagination.component';
import {
	ESGComponent,
	SentimentComponent,
} from '../../Components/SingleReport/RightTab.component';
import { ICompanyData, IPaginationData } from '../Company/Company.types';
import CompareGroupCard from '../../Components/Compare/CompareBottomTable.component';
import { RIGHT_TAB } from '../SingleReport/SingleReport.screen';
import {
	convertValue,
	renderWatchlistMobile,
} from '../../Components/Home/TrendingReportsTable.component';
import { GrowthText } from '../../Components/Company/SentimentList.component';
import SideTab from '../../Components/Compare/SideTab.component';
import KeywordListTableComponent from '../../Components/Home/KeywordListTable.component';
import {
	UpDownIconWrapper,
	iconStyle,
} from '../../Components/SingleReport/Sentiment.component';
import HighchartSentimentChart from '../../Components/Compare/HighchartSentimentChart.component';
import { renderDisclaimer } from '../../Components/SingleReport';
import { Popover } from '../../Components';


const { Search } = Input;
const { SELECT_INDUSTRY } = LIST_TYPE;

export const CustomSearch = styled(Search)`
	.ant-input {
		background-color: #f5f5f5;
		color: #4e4e4e;
		border-radius: 4px;
		border: 1px solid #f5f5f5;
	}

	.ant-input-search-button {
		background-color: #f5f5f5;
		border: none;
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;

		.anticon {
			color: #4e4e4e;
		}
	}

	border: none;
`;

const StyledAccordion = styled(Accordion)`
	padding: 8px;
`;

const StyledProgressContainer = styled.div`
	position: relative;
	background-color: #e9ecef;
	height: 28px;
	border-radius: 5px;
	margin-bottom: 2px;
`;

// Styled progress bar
const StyledProgressBar = styled.div<{ percent: string; color: string }>`
	position: absolute;
	top: 0;
	left: 0;
	width: ${({ percent }) => percent || '0%'};
	height: 100%;
	background-color: ${({ color }) => color || '#28a745'};
	border-radius: 5px 0 0 5px;
`;

// Styled tag inside the progress bar
const StyledTag = styled.div<{ color: string }>`
	position: absolute;
	top: 50%;
	left: 10px;
	transform: translateY(-50%);
	background-color: ${({ color }) => color || 'orange'};
	color: white;
	padding: 1px 6px;
	border-radius: 5px;
	font-weight: 600;
	font-size: 10px;
	font-style: normal;
	font-weight: 600;
	line-height: 20px; /* 200% */
`;
const StyledProgressBarTxt = styled.div`
	padding-left: 45px;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	color: #107c10;
	font-size: 10px;
	font-style: normal;
	font-weight: 600;
	line-height: 20px;
`;

const ExpandText = styled.p`
	margin-bottom: 0;
	margin-left: 8px;
	color: #225fb0;
	font-size: 10px;
	font-style: normal;
	font-weight: 600;
	line-height: 20px;
`;

const ExpandIcon = styled(FaAngleDown)`
	font-size: 14px;
	color: #62656f;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 4px;
`;

const NarrowIcon = styled(FaAngleUp)`
	font-size: 14px;
	color: #62656f;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 4px;
`;

const ExpandContainer = styled.div<{ expand: boolean }>`
	cursor: pointer;
	display: flex;
	padding: 4px 8px;
	align-items: center;
	gap: 4px;
	flex-shrink: 0;
	border-radius: ${({ expand }) => (expand ? '0' : '0px 0px 4px 4px')};
	background: #e0ecfc;
	margin-top: 10px;

	${mobile(css`
		display: none;
	`)};
`;

export const FilterProgressBarContainer = styled.div`
	display: flex;
	flex-direction: row;
	border-top: 1px solid #e0e0e0;
	border-bottom: 1px solid #e0e0e0;
	padding: 4px 8px;
	margin: 8px 0;
	column-gap: 8px;
`;

export const FilterProgressBarItem = styled.p<{ isActive: boolean }>`
	margin: 0;
	padding: 4px 8px;
	color: #4e5153;
	font-family: Inter;
	font-size: 10px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	border-radius: 4px;
	cursor: pointer;
	background-color: ${props => (props.isActive ? '#e0ecfc' : '#ffffff')};

	&:hover {
		background-color: #e0ecfc;
	}
`;

interface IProgressComp {
	number: number;
	text: string;
	percentage: string;
	tagColor: string;
}
const ProgressBarComponent = (props: { data: IProgressComp[] }) => {
	const { data } = props;

	return (
		<React.Fragment>
			<div className="p-1">
				{data &&
					data.map(({ number, text, percentage, tagColor }) => (
						<StyledProgressContainer key={`${text}-${percentage}`}>
							<StyledProgressBar percent={percentage} color="#CDE3CD">
								<StyledTag color={tagColor}>{number}</StyledTag>
							</StyledProgressBar>
							<StyledProgressBarTxt>
								{text} {percentage}
							</StyledProgressBarTxt>
						</StyledProgressContainer>
					))}
			</div>
		</React.Fragment>
	);
};

export const getExpandIcon = (
	data: ICompareTable[] | ICompanyData[] | undefined,
	setExpandTab: Dispatch<SetStateAction<boolean>>,
	expandTab: boolean
) => {
	if (data && data.length > 5) {
		if (expandTab) {
			return (
				<div
					className="d-flex align-items-center"
					onClick={() => {
						setExpandTab(false);
					}}
					style={{ cursor: 'pointer' }}>
					<h5 className="fs-10 fw-600 mb-0 me-1">Close </h5>
					<div className="bg-lightgrey rounded px-1">
						<FiMinimize2 className="fs-10" />
					</div>
				</div>
			);
		}
		return (
			<div
				className="d-flex align-items-center"
				onClick={() => {
					setExpandTab(true);
				}}
				style={{ cursor: 'pointer' }}>
				<h5 className="fs-10 fw-600 mb-0 me-1">View More </h5>
				<div className="bg-lightgrey rounded px-1">
					<FaExpandAlt className="fs-10" />
				</div>
			</div>
		);
	}
};

const TableCompare = (props: {
	tab: string;
	tempData: any[];
	companiesByIndustryOrder: string;
	setCompaniesByIndustryOrder: Dispatch<SetStateAction<string>>;
	onClickIndustryCompany: (value: boolean, value2: string | number) => void;
	selectedTablePagination: IPaginationData;
	compareSelectedOrder: string;
	setCompareSelectedOrder: Dispatch<SetStateAction<string>>;
}) => {
	const [isLoading, setIsLoading] = useState(false)
	const {
		tab,
		tempData,
		companiesByIndustryOrder,
		setCompaniesByIndustryOrder,
		onClickIndustryCompany,
		selectedTablePagination,
		compareSelectedOrder,
		setCompareSelectedOrder
	} = props;

	useEffect(() => {
		setIsLoading(true)
	}, [selectedTablePagination.current_page, tab])

	useEffect(() => {
		setIsLoading(true)
	}, [])

	useEffect(() => {
		setIsLoading(false)
	}, [tempData])

	const sortData = (data: any[], order) => {
		if (order == "asc") {
			return data.sort((a, b) => (a - b).average)
		}
		return data.sort((a, b) => (b - a).average)
	}
	if (isLoading) return (
		<div className="m-2">
			<LoadingComponent />
		</div>
	)
	return (
		<table className={`table ${tempData.length && "table-striped"} table-borderless w-100`}>
			{tab === SELECT_INDUSTRY ? (
				<React.Fragment>
					<TableHead className="text-nowrap">
						<tr>
							<td>Symbol</td>
							<td>Name</td>
							<td>Latest Sentiment</td>
							<td>Change</td>
							<td className="d-flex gap-1">Selected date avg sentiment
								<UpDownIconWrapper>
									<CaretUpFilled
										style={iconStyle(compareSelectedOrder === 'asc')}
										onClick={() => {
											// eslint-disable-next-line @typescript-eslint/no-unsafe-call
											setCompareSelectedOrder('asc');
										}}
									/>
									<CaretDownFilled
										style={iconStyle(compareSelectedOrder === 'desc')}
										onClick={() => {
											// eslint-disable-next-line @typescript-eslint/no-unsafe-call
											setCompareSelectedOrder('desc');
										}}
									/>
								</UpDownIconWrapper>
							</td>
							<td>All-time average sentiment</td>
							<td>Type</td>
						</tr>
					</TableHead>
					<tbody>
						{!tempData.length && (
							<tr>
								<td colSpan={7}>
									<div className="m-2">
										<LoadingComponent />
									</div>
								</td>
							</tr>
						)
						}
						{tempData && sortData(tempData, compareSelectedOrder).map(
							(
								{
									id,
									name,
									average,
									total,
									type,
									stock_code,
									latest_sentiment_score,
									sentiment_score_change,
									color,
									industry_code
								},
								index
							) => {
								const fixedSentimentScoreChange = convertValue(
									convertToPercentage(sentiment_score_change)
								).value;
								const isGrowth =
									convertValue(convertToPercentage(sentiment_score_change))
										.originalValue > 0;
								const isCompany = !!stock_code;

								return (
									<React.Fragment key={index}>
										<tr className="table-hover-blue">
											<td onClick={() => onClickIndustryCompany(isCompany, id)}>
												<ColoredLabel
													lineLabelColor={color}
													sector={stock_code || industry_code}
													className="company-stock-code fw-600 fs-10 mx-1">
													{stock_code || industry_code}
												</ColoredLabel>
											</td>
											<td onClick={() => onClickIndustryCompany(isCompany, id)}>
												<span className="fs-10 fw-600 m-0">{name}</span>
											</td>
											<td>
												<span className="fs-16 fw-600 m-0">
													{
														convertValue(
															convertToPercentage(latest_sentiment_score)
														).value
													}
												</span>
											</td>
											<td>
												<GrowthText
													isGrowth={isGrowth}
													sentimentScore={fixedSentimentScoreChange}
													isNoText
												/>
											</td>
											<td>
												<span
													className={`${convertValue(average).className
														} standard-code fs-10 fw-600`}>
													{convertValue(convertToPercentage(average)).value}
												</span>
											</td>
											<td>
												<span
													className={`${convertValue(total).className
														} standard-code fs-10 fw-600`}>
													{convertValue(convertToPercentage(total)).value}
												</span>
											</td>
											<td>
												{type === 'I' ? (
													<span className="text-darkblue bg-lightblue standard-code fs-10 fw-600">
														Industry
													</span>
												) : (
													<span className="text-white bg-darkerblue standard-code fs-10 fw-600">
														Company
													</span>
												)}
											</td>
										</tr>
									</React.Fragment>
								);
							}
						)}
					</tbody>
				</React.Fragment>
			) : (
				<React.Fragment>
					<TableHead className="text-nowrap">
						<tr>
							<td>Symbol</td>
							<td className="d-flex gap-1">
								Name
								<UpDownIconWrapper>
									<CaretUpFilled
										style={iconStyle(companiesByIndustryOrder === 'asc')}
										onClick={() => {
											// eslint-disable-next-line @typescript-eslint/no-unsafe-call
											setCompaniesByIndustryOrder('asc');
										}}
									/>
									<CaretDownFilled
										style={iconStyle(companiesByIndustryOrder === 'desc')}
										onClick={() => {
											// eslint-disable-next-line @typescript-eslint/no-unsafe-call
											setCompaniesByIndustryOrder('desc');
										}}
									/>
								</UpDownIconWrapper>
							</td>
							<td>Latest Sentiment</td>
							<td>Change</td>
							<td>Selected Date Avg Sentiment</td>
							<td>Type</td>
						</tr>
					</TableHead>
					<tbody>
						{tempData &&
							tempData.map(
								(
									{
										id,
										name,
										latest_sentiment_score,
										sentiment_score_change,
										average_sentiment_score,
										type,
										stock_code,
										industry_code
									},
									index
								) => {
									const fixedSentimentScoreChange = convertValue(
										convertToPercentage(sentiment_score_change)
									).value;
									const isGrowth =
										convertValue(convertToPercentage(sentiment_score_change))
											.originalValue > 0;
									const isCompany = !!stock_code;

									return (
										<React.Fragment key={index}>
											<tr className="table-hover-blue">
												<td
													onClick={() => onClickIndustryCompany(isCompany, id)}>
													<ColoredLabel
														sector={stock_code || industry_code}
														className="company-stock-code fw-600 fs-10 mx-1">
														{stock_code || industry_code}
													</ColoredLabel>
												</td>
												<td
													onClick={() => onClickIndustryCompany(isCompany, id)}>
													<span className="fs-10 fw-600 m-0">{name}</span>
												</td>
												<td>
													<span className="fs-16 fw-600 m-0">
														{
															convertValue(
																convertToPercentage(latest_sentiment_score)
															).value
														}
													</span>
												</td>
												<td>
													<GrowthText
														isGrowth={isGrowth}
														sentimentScore={fixedSentimentScoreChange}
														isNoText
													/>
												</td>
												<td>
													<InformationPercentage
														className={`${convertValue(average_sentiment_score).className
															} rounded`}
														textStyle={
															convertValue(average_sentiment_score).color
														}>
														{
															convertValue(
																convertToPercentage(average_sentiment_score)
															).value
														}
													</InformationPercentage>
												</td>
												<TableData>
													<div>
														{type === 'I' ? (
															<span className="text-darkblue bg-lightblue rounded p-2 fw-600 fs-10">
																Industry
															</span>
														) : (
															<span className="text-white bg-darkerblue rounded p-2 fw-600 fs-10">
																Company
															</span>
														)}
													</div>
												</TableData>
											</tr>
										</React.Fragment>
									);
								}
							)}
					</tbody>
				</React.Fragment>
			)}
		</table>
	);
};

export const TableMobile = (props: {
	stock_code: any;
	id: string;
	type: string;
	isGrowth: boolean;
	average_sentiment_score: any;
	fixedSentimentScoreChange: string;
	latest_sentiment_score: any;
	isMobile: boolean;
	name: string;
	total?: number;
	onClickIndustryCompany: (value2: boolean, value: string | number) => void;
	industry_code: number;
	color?: string;
}) => {
	const {
		stock_code,
		id,
		type,
		isGrowth,
		average_sentiment_score,
		fixedSentimentScoreChange,
		latest_sentiment_score,
		isMobile,
		name,
		total,
		onClickIndustryCompany,
		industry_code,
		color,
	} = props;

	const isCompany = !!stock_code;

	return (
		<div
			className="mw-100 list-data py-2 hover-blue"
			onClick={() => onClickIndustryCompany(isCompany, id)}>
			<div className="d-flex justify-content-between">
				<div className="d-flex gap-2 align-items-center">
					<div>
						{type === 'I' ? (
							<div className="text-darkblue bg-lightblue rounded px-2 py-1 fw-600 fs-10">
								Industry
							</div>
						) : (
							<div className="text-white bg-darkerblue rounded px-2 py-1 fw-600 fs-10">
								Company
							</div>
						)}
					</div>
					<div>
						<ColoredLabel
							lineLabelColor={color}
							sector={stock_code || industry_code}
							className={`${isMobile ? 'me-2' : 'mb-3'
								} text-white rounded p-1 fw-600 fs-10 d-block`}>
							{stock_code || industry_code}
						</ColoredLabel>
					</div>
					<h5 className="fs-10 fw-600 d-block m-0">{name}</h5>
				</div>
				<div className="d-flex gap-2 align-items-center">
					<GrowthText
						isGrowth={isGrowth}
						sentimentScore={fixedSentimentScoreChange}
						isNoText
					/>
					<div className="fs-16 fw-400 m-0">
						{convertValue(convertToPercentage(latest_sentiment_score)).value}
					</div>
				</div>
			</div>
			<div className="d-flex justify-content-between p-2 bg-lightgrey rounded mt-15">
				<div className="d-flex gap-1 bg-lightgrey w-50 align-items-center justify-content-start">
					<h5 className="fs-10 fw-400 d-block m-0">Selected date avg score</h5>
					<InformationPercentage
						className={`${convertValue(average_sentiment_score).className
							} rounded`}
						textStyle={convertValue(average_sentiment_score).color}>
						{convertValue(convertToPercentage(average_sentiment_score)).value}
					</InformationPercentage>
				</div>
				{total && (
					<React.Fragment>
						<span style={{ color: '#C9C9C9' }} className="fs-18 fw-500">
							|
						</span>
						<div className="d-flex gap-1 bg-lightgrey w-50 align-items-center justify-content-end">
							<h5 className="fs-10 fw-400 d-block m-0">All time avg score</h5>
							<InformationPercentage
								className={`${convertValue(total).className} rounded`}
								textStyle={convertValue(total).color}>
								{convertValue(convertToPercentage(total)).value}
							</InformationPercentage>
						</div>
					</React.Fragment>
				)}
			</div>
		</div>
	);
};

const TableCompareMobile = (props: {
	tab: string;
	tempData: any[];
	companiesByIndustryOrder: string;
	setCompaniesByIndustryOrder: Dispatch<SetStateAction<string>>;
	isMobile?: boolean;
	onClickIndustryCompany: (value2: boolean, value: string | number) => void;
	selectedTablePagination: IPaginationData;
	compareSelectedOrder: string;
	setCompareSelectedOrder: Dispatch<SetStateAction<string>>;
}) => {
	const [isLoading, setIsLoading] = useState(false)

	const {
		tab,
		tempData,
		isMobile,
		companiesByIndustryOrder,
		setCompaniesByIndustryOrder,
		onClickIndustryCompany,
		selectedTablePagination,
		compareSelectedOrder,
		setCompareSelectedOrder
	} = props;

	const sortData = (data: any[], order) => {
		if (order == "asc") {
			return data.sort((a, b) => a.latest_sentiment_score - b.latest_sentiment_score)
		}
		return data.sort((a, b) => b.latest_sentiment_score - a.latest_sentiment_score)
	}

	useEffect(() => {
		setIsLoading(true)
	}, [selectedTablePagination, tab])

	useEffect(() => {
		setIsLoading(false)
	}, [tempData])


	return (
		<React.Fragment>
			{tab === SELECT_INDUSTRY ? (
				<React.Fragment>
					{tempData &&
						sortData(tempData, compareSelectedOrder).map(
							(
								{
									id,
									name,
									average,
									total,
									type,
									stock_code,
									latest_sentiment_score,
									sentiment_score_change,
									industry_code,
									color,
								},
								index
							) => {
								const fixedSentimentScoreChange = convertValue(
									convertToPercentage(sentiment_score_change)
								).value;
								const isGrowth =
									convertValue(convertToPercentage(sentiment_score_change))
										.originalValue > 0;

								return (
									<TableMobile
										latest_sentiment_score={latest_sentiment_score}
										isGrowth={isGrowth}
										fixedSentimentScoreChange={fixedSentimentScoreChange}
										average_sentiment_score={average}
										isMobile={true}
										key={index}
										name={name}
										stock_code={stock_code}
										id={id}
										type={type}
										total={total}
										onClickIndustryCompany={onClickIndustryCompany}
										industry_code={industry_code}
										color={color}
									/>
								);
							}
						)}
				</React.Fragment>
			) : (
				<React.Fragment>
					{tempData &&
						tempData.map(
							(
								{
									id,
									name,
									latest_sentiment_score = 0,
									sentiment_score_change = 0,
									average_sentiment_score = 0,
									type,
									stock_code,
									industry_code,
								},
								index
							) => {
								const fixedSentimentScoreChange = convertValue(
									convertToPercentage(sentiment_score_change)
								).value;
								const isGrowth =
									convertValue(convertToPercentage(sentiment_score_change))
										.originalValue > 0;

								return (
									<TableMobile
										latest_sentiment_score={latest_sentiment_score}
										isGrowth={isGrowth}
										fixedSentimentScoreChange={fixedSentimentScoreChange}
										average_sentiment_score={average_sentiment_score}
										isMobile={true}
										key={index}
										name={name}
										stock_code={stock_code}
										id={id}
										type={type}
										onClickIndustryCompany={onClickIndustryCompany}
										industry_code={industry_code}
									/>
								);
							}
						)}
				</React.Fragment>
			)}
		</React.Fragment>
	);
};

export const renderCompareTable = (
	data: ICompareTable[],
	setTab: Dispatch<SetStateAction<string>>,
	tab: string,
	filtered: any[],
	getCompaniesByIndustry: (value?: string) => void,
	selectedTablePagination: IPaginationData,
	idFiltered: any,
	companiesByIndustryOrder: string,
	setCompaniesByIndustryOrder: Dispatch<SetStateAction<string>>,
	onClickIndustryCompany: (value: boolean, value2: string | number) => void,
	isAllChartDocumentType: boolean,
	isMobile?: boolean,
	compareSelectedOrder: string,
	setCompareSelectedOrder: Dispatch<SetStateAction<string>>
) => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const [expandTab, setExpandTab] = useState(false);

	const tempData = !expandTab
		? (data?.length > 5 && data.slice(0, 6)) || data
		: data;
	const filterData = isAllChartDocumentType
		? filtered.slice(0, filtered.length / 2)
		: filtered;

	return (
		<div className="rounded margin-top-8">
			{filtered && filtered.length > 0 && (
				<React.Fragment>
					<div className="w-100 rounded">
						<RightTabContainer className="pt-2 px-3 justify-content-between">
							<div className="d-flex table-scrollable scrolling-wrapper">
								<RightTabItem
									className="pb-0"
									onClick={() => {
										setTab(SELECT_INDUSTRY);
									}}
									selected={tab === SELECT_INDUSTRY}>
									<div className="py-2 d-flex gap-1 align-content-center">
										<p className="mb-0 align-self-center">Selected</p>
										<Popover
											className='ps-1'
											popoverTextHeader="**Selected Companies and Industries**"
											popoverTextBody={`Displays the latest sentiments, changes, and average sentiments for the selected companies and industries over chosen timeframes. \
											\n1. **Latest Sentiment**: Displays the most recent sentiment scores— for companies, based on the latest report; for industries, based on the average sentiment over the last 24 hours.\
											\n2. **Change**: Shows the sentiment change— for companies, between the latest and the previous report; for industries, between the latest 24-hour average and the past five-year industry average.\
											\n3. **Selected Date Average Sentiment**: Indicates the average sentiment for the selected company or industry within the chosen date range.\
											\n4. **All-Time Average Sentiment**: Reflects the overall average sentiment of the selected company or industry across all available data.`}
										/>
									</div>
								</RightTabItem>
								{idFiltered && idFiltered.industryCode?.length > 0 && (
									<RightTabItem
										className="pb-0"
										selected={tab !== SELECT_INDUSTRY}>
										<div
											className={`${tab !== SELECT_INDUSTRY
												? 'bg-darkerblue'
												: 'bg-lightgrey'
												} rounded-top px-2 py-1 mx-2 d-flex gap-1 align-content-center`}>
											<p
												className={`mb-0 align-self-center ${tab !== SELECT_INDUSTRY && 'text-white'
													}`}>
												Industry Companies
											</p>
											{filterData.map((code, index) => {
												if (!_.isNaN(Number(code[1])))
													return (
														<div
															className={`${tab === code[1]
																? 'text-darkblue bg-lightblue'
																: 'bg-white'
																} rounded px-1 py-1`}
															key={index}
															onClick={() => {
																setTab(code[1]);
															}}>
															<ColoredLabel
																sector={code[1]}
																className="company-stock-code fw-600 fs-10 mx-1">
																{code[1]}
															</ColoredLabel>
															{code[0]}
														</div>
													);
											})}
										</div>
									</RightTabItem>
								)}
							</div>
							{getExpandIcon(data, setExpandTab, expandTab)}
						</RightTabContainer>
						<div className="table-container">
							<div className="table-scrollable">
								{isMobile ? (
									<TableCompareMobile
										tab={tab}
										tempData={tempData}
										companiesByIndustryOrder={companiesByIndustryOrder}
										setCompaniesByIndustryOrder={setCompaniesByIndustryOrder}
										onClickIndustryCompany={onClickIndustryCompany}
										selectedTablePagination={selectedTablePagination}
										compareSelectedOrder={compareSelectedOrder}
										setCompareSelectedOrder={setCompareSelectedOrder}
									/>
								) : (
									<TableCompare
										tab={tab}
										tempData={tempData}
										companiesByIndustryOrder={companiesByIndustryOrder}
										setCompaniesByIndustryOrder={setCompaniesByIndustryOrder}
										onClickIndustryCompany={onClickIndustryCompany}
										selectedTablePagination={selectedTablePagination}
										compareSelectedOrder={compareSelectedOrder}
										setCompareSelectedOrder={setCompareSelectedOrder}

									/>
								)}
							</div>
						</div>
					</div>
					{tab !== SELECT_INDUSTRY && expandTab && (
						<div className="d-flex justify-content-end">
							<Pagination
								size="small"
								showSizeChanger={false}
								defaultCurrent={selectedTablePagination?.current_page}
								total={selectedTablePagination?.total}
								onChange={page => {
									const pageUrl = `/?page=${page}`;
									getCompaniesByIndustry(pageUrl);
								}}
							/>
						</div>
					)}
					{/*<ExpandContainer*/}
					{/*	expand={expandTab}*/}
					{/*	onClick={() => {*/}
					{/*		setExpandTab(prevState => !prevState);*/}
					{/*	}}>*/}
					{/*	{expandTab ? <ExpandIcon /> : <NarrowIcon />}*/}
					{/*	<ExpandText>Expand list of industry & company scores</ExpandText>*/}
					{/*</ExpandContainer>*/}
				</React.Fragment>
			)}
		</div>
	);
};

export const renderCompareTable2 = (data: any[]) => (
	<WhiteContainer className="w-100 p-md-3 mt-1 rounded">
		<RightTabContainer className="pt-2 mx-2 scrolling-wrapper">
			<RightTabItem onClick={() => { }} selected={true}>
				Selected
			</RightTabItem>
			<RightTabItem onClick={() => { }} selected={false}>
				Industries Companies
			</RightTabItem>
			<RightTabItem onClick={() => { }} selected={false}>
				<CustomTag className="rounded" bgColor={'#D47645'} color={'white'}>
					{'25102010'}
				</CustomTag>
				Automobile Manufacturers
			</RightTabItem>
			<RightTabItem onClick={() => { }} selected={false}>
				<CustomTag className="rounded" bgColor={'#358190'} color={'white'}>
					{'45'}
				</CustomTag>
				Information Technology
			</RightTabItem>
		</RightTabContainer>
		<div className="table-container">
			<div className="table-scrollable">
				<table className="table table-striped table-borderless w-100">
					<TableHead className="text-nowrap">
						<tr>
							<td>Ticker</td>
							<td>Name</td>
							<td>Sentiment Score</td>
							<td>All-time average sentiment score</td>
							<td>Type</td>
						</tr>
					</TableHead>
					<tbody>
						{[...data, ...data].map(
							(
								{
									ticker,
									name,
									sentiment_score,
									all_time_average_sentiment_score,
									type,
								},
								index
							) => (
								<React.Fragment key={index}>
									<tr>
										<td>
											<CustomTag
												className="rounded"
												bgColor={index % 2 == 0 ? '#225FB0' : '#D47645'}
												color={'white'}>
												{ticker}
											</CustomTag>
										</td>
										<TableData>{name}</TableData>
										<td>
											<InformationPercentage
												className="bg-success-100 rounded"
												textStyle="#107C10">
												{sentiment_score}%
											</InformationPercentage>
										</td>
										<TableData>{all_time_average_sentiment_score}%</TableData>
										<td>
											<CustomTag
												className="rounded"
												bgColor={index % 2 == 0 ? '#c4cfdf' : '#225FB0'}
												color={index % 2 == 0 ? '#225FB0' : '#FFFFF'}>
												{type}
											</CustomTag>
										</td>
									</tr>
								</React.Fragment>
							)
						)}
					</tbody>
				</table>
			</div>
		</div>
	</WhiteContainer>
);

export const FilteredComponent = (props: {
	filtered: any[];
	documentType: string;
	isMobile?: boolean;
	onCheckedAllDocumentType: (value: boolean) => void;
	isChartAllDocumentType: boolean;
	setSelectedLegend: (value: any) => void;
	selectedLegend: any;
}) => {
	const {
		isChartAllDocumentType,
		filtered,
		documentType,
		isMobile,
		onCheckedAllDocumentType,
		selectedLegend,
		setSelectedLegend,
	} = props;
	const onChange = (e: any) => onCheckedAllDocumentType(e.target.checked);

	const onClickCard = (stockCode: any, document: any) => () => {
		const key = `${stockCode}-${document}`;
		const newSelectedLegendStatus = { [key]: !selectedLegend[key] };

		setSelectedLegend({ ...selectedLegend, ...newSelectedLegendStatus });
	};

	return (
		<div style={{ height: 'fit-content' }} className="mt-2 position-relative">
			<div className={`position-absolute end-0 top-0 px-1`}>
				<Popover
					popoverTextHeader="**Sentiment and Returns**"
					popoverTextBody={`View sentiment scores and stock returns for companies. Industries show sentiment only. Click on the legend items to toggle the lines on or off.`}
				/>
			</div>
			<div
				className={`table-scrollable ${isMobile ? 'd-flex-row' : 'd-flex'
					} gap-1`}>
				{filtered.map((code, index) => {
					const documentTypeString = isChartAllDocumentType
						? code[3]
						: `${documentType[0].toUpperCase()}${documentType.slice(
							1,
							documentType.length
						)}`;
					const isVisible =
						selectedLegend[`${code[1]}-${documentTypeString?.toLowerCase()}`];

					return (
						<Col
							onClick={onClickCard(code[1], documentTypeString?.toLowerCase())}
							md={3}
							xs={12}
							key={index}
							style={{ height: isMobile ? '5vh' : '' }}
							className={`cursor-pointer rounded ${isMobile
								? 'd-flex align-items-center justify-content-between my-1 py-1 px-2'
								: 'd-flex-row justify-content-between p-2'
								} bg-lightgrey position-relative`}>
							<div className="d-flex align-items-stretch justify-content-between">
								<ColoredLabel
									lineLabelColor={!isVisible ? '#B6B6B6' : code[2]}
									key={index}
									sector={code[1]}
									className={`${isMobile ? 'me-2' : 'mb-3'
										} text-white standard-code fw-600 fs-10 align-self-center`}>
									{code[1]}
								</ColoredLabel>
								{isMobile && <h5 className="fs-10 fw-600 mt-2">{code[0]}</h5>}
								{!isMobile && (
									<h5
										className={`fs-10 fw-500 ${!isVisible ? '' : 'text-darkblue'
											}`}>
										{documentTypeString}
									</h5>
								)}
							</div>
							{!isMobile && <h5 className="fs-10 fw-600 mt-2">{code[0]}</h5>}
							{isMobile && (
								<h5
									className={`fs-10 fw-500 ${!isVisible ? '' : 'text-darkblue'
										} m-0`}>
									{documentTypeString}
								</h5>
							)}
						</Col>
					);
				})}
			</div>
			{/* <Checkbox
				checked={isChartAllDocumentType}
				onChange={onChange}
				style={{
					fontSize: '12px',
					fontWeight: '500',
					marginTop: '10px',
				}}>
				Show both calls and filings (Max 3 items)
			</Checkbox> */}
		</div>
	);
};

const CompareDesktop = (props: ICompare) => {
	const {
		industryCode,
		setIndustryCode,
		onClickConfirm,
		onDeleteList,
		data,
		scrollDirection,
		setScrollDirection,
		setStartDate,
		setFinishDate,
		selectedRange,
		setSelectedRange,
		setDocumentType,
		documentType,
		filtered,
		getCompaniesNextPage,
		isLoadingScrollCompany,
		onConfirm,
		setTab,
		tab,
		tempFilter,
		tableData,
		groupTab,
		setGroupTab,
		tableDataContent,
		onDeleteFixList,
		keywordList,
		compareCardTab,
		setCompareCardTab,
		compareCardTableData,
		setCompareCardTableData,
		compareTab,
		setCompareTab,
		scoresAverage,
		chartTab,
		setChartTab,
		esgData,
		keywordPagination,
		setKeywordPagination,
		getKeywordList,
		onParamsChange,
		getCompaniesByIndustry,
		selectedTablePagination,
		compareCardPagination,
		getCompareTabData,
		returnData,
		sentimentData,
		onChangeInterval,
		intervalType,
		idFiltered,
		getKeywordFrequencies,
		fetchingKeywords,
		setCompareDataOrder,
		compareDataOrder,
		companiesByIndustryOrder,
		setCompaniesByIndustryOrder,
		isChartAllDocumentType,
		onCheckedAllDocumentType,
		onClickIndustryCompany,
		isRenderReturnChart,
		isRenderSentimentChart,
		startDate,
		finishDate,
		selectedLegend,
		setSelectedLegend,
		isSmallerScreen,
		compareSelectedOrder,
		setCompareSelectedOrder,
		isLoadingKeywords,
		isLoadingAverage,
		isLoadingESG,
	} = props;

	// // eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// // @ts-ignore
	// const [data, setData] = useState<ICompareTable[]>(tableResponseData);
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	const [dataCard, setDataCard] = useState<ICompareCard[]>(groupCardData);

	const { DAY, WEEK, MONTH } = CHART_INTERVAL;

	const sideTabProps = {
		industryCode,
		setIndustryCode,
		onClickConfirm,
		filtered: tempFilter,
		onDeleteList,
		data,
		scrollDirection,
		setScrollDirection,
		setStartDate,
		setFinishDate,
		selectedRange,
		setSelectedRange,
		setDocumentType,
		documentType,
		onConfirm,
		getCompaniesNextPage,
		isLoadingScrollCompany,
		label: 'Compare',
		startDate,
		finishDate,
		selectedLegend,
		setSelectedLegend,
		isRenderSentimentChart
	};

	return (
		<React.Fragment>
			<RootContainer>
				<div className="rounded d-flex mb-2">
					<div className="rounded bg-white margin-right-8">
						<WhiteContainer className="mw-100 rounded px-3 py-2">
							<SideTab {...sideTabProps} />
						</WhiteContainer>
					</div>
					<Container
						md={9}
						className="rounded m-0"
						style={{ height: 'fit-content' }}>
						<div className="d-flex h-50 m-0">
							<Row
								className="mh-100 bg-white rounded w-75"
								style={{ marginRight: '8px', marginLeft: '0px' }}>
								<FilteredComponent
									filtered={filtered}
									documentType={documentType.value}
									isChartAllDocumentType={isChartAllDocumentType}
									onCheckedAllDocumentType={onCheckedAllDocumentType}
									selectedLegend={selectedLegend}
									setSelectedLegend={setSelectedLegend}
								/>
								{(isRenderSentimentChart && isRenderReturnChart) ? (
									<NewSentimentChart
										sentiment={sentimentData}
										returnData={returnData}
										maxAxisValue={finishDate}
										selectedLegend={selectedLegend}
									/>
								) : <LoadingComponent />}
							</Row>
							<div className="rounded w-25 h-50" style={{}}>
								<WhiteContainer className="rounded mh-100 px-2 pb-2">
									<RightTabContainer className="pt-2">
										<RightTabItem
											onClick={() => {
												setChartTab(RIGHT_TAB.SENTIMENT);
											}}
											selected={chartTab === RIGHT_TAB.SENTIMENT}>
											Sentiments
										</RightTabItem>
										<RightTabItem
											onClick={() => {
												setChartTab(RIGHT_TAB.ESG);
											}}
											selected={chartTab === RIGHT_TAB.ESG}>
											ESG
										</RightTabItem>
									</RightTabContainer>
									{chartTab === RIGHT_TAB.SENTIMENT && (
										<SentimentComponent
											scoresAverage={scoresAverage}
											documentType={documentType.value}
											scoreType='COMPANY'
											isCompanyPage
											isComparePage
											isLoadingAverage={isLoadingAverage}
										/>
									)}
									{chartTab === RIGHT_TAB.ESG && (
										<ESGComponent {...esgData} isCompanyPage isComparePage={false} isLoadingESG={isLoadingESG} />
									)}
								</WhiteContainer>
								<div className="rounded h-50 mt-2" style={{}}>
									<RightTabContainer className="pt-2 px-2 bg-white w-100 rounded-top">
										<RightTabItem onClick={() => { }} selected={true}>
											Top Keywords
										</RightTabItem>
									</RightTabContainer>
									<KeywordListTableComponent
										keywords={keywordList}
										documentType={documentType.value}
										getKeywordFrequencies={getKeywordFrequencies}
										isLoadingKeywords={isLoadingKeywords}
										fetchingKeywords={fetchingKeywords}
										selectedWatchlistDetails={''}
										limit={10}
										placement="left"
									/>
								</div>

							</div>
						</div>
						{/* Remove interval option temporarily */}
						{/* <div className="margin-top-2 d-flex gap-1 bg-white rounded p-2">
							<div className="bg-lightgrey px-2 d-flex align-items-center">
								<h5 className="fs-10 fw-600 m-0">Interval</h5>
							</div>
							<YearTab
								onClick={() => onChangeInterval(DAY)}
								selected={intervalType === DAY}>
								D
							</YearTab>
							<YearTab
								onClick={() => onChangeInterval(WEEK)}
								selected={intervalType === WEEK}>
								W
							</YearTab>
							<YearTab
								onClick={() => onChangeInterval(MONTH)}
								selected={intervalType === MONTH}>
								M
							</YearTab>
						</div> */}
						<WhiteContainer className="rounded">
							{renderCompareTable(
								tableData,
								setTab,
								tab,
								filtered,
								getCompaniesByIndustry,
								selectedTablePagination,
								idFiltered,
								companiesByIndustryOrder,
								setCompaniesByIndustryOrder,
								onClickIndustryCompany,
								isChartAllDocumentType,
								false,
								compareSelectedOrder,
								setCompareSelectedOrder
							)}
						</WhiteContainer>
						<WhiteContainer className="rounded">
							<CompareGroupCard
								data={tableDataContent}
								groupTab={groupTab}
								setGroupTab={setGroupTab}
								compareCardTab={compareCardTab}
								setCompareCardTab={setCompareCardTab}
								compareCardTableData={compareCardTableData}
								setCompareCardTableData={setCompareCardTableData}
								compareTab={compareTab}
								setCompareTab={setCompareTab}
								compareCardPagination={compareCardPagination}
								getCompareTabData={getCompareTabData}
								setCompareDataOrder={setCompareDataOrder}
								compareDataOrder={compareDataOrder}
								onClickIndustryCompany={onClickIndustryCompany}
							/>
						</WhiteContainer>
					</Container>
				</div>
				<div className="d-flex w-100 margin-top-4">
					<Container
						// md={9}
						className="rounded bg-white d-flex">
						<Container md={12} className="bg-white rounded">
							{renderDisclaimer()}
						</Container>
					</Container>
				</div>
			</RootContainer>
		</React.Fragment>
	);
};

export default CompareDesktop;
