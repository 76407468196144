import styled, { css } from 'styled-components';
import { Input } from 'antd';
import { FaBars, FaGear, FaXmark } from 'react-icons/fa6';
import { FaAngleRight } from 'react-icons/fa';
import { Nav } from 'react-bootstrap';

import { INavLinkProps } from './NavigationBar.types';
import { tablet } from '../../Utils/layout.utils';

const { Search } = Input;

export const ContainerNavBar = styled.div`
	width: 100%;
	box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.04);

	${tablet(css`
		display: none;
	`)};
`;

export const SearchInput = styled(Search)``;

export const ContainerGearIcon = styled.div`
	border-radius: 4px;
	background: #f5f5f5;
	padding: 0.6rem;
	cursor: pointer;
	align-items: center;
`;

export const GearIcon = styled(FaGear)`
	font-size: 12px;
	color: #62656f;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const RightArrowIcon = styled(FaAngleRight)`
	font-size: 18px;
	color: #62656f;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const BarsIcon = styled(FaBars)`
	font-size: 18px;
	color: #62656f;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const CloseIcon = styled(FaXmark)`
	font-size: 18px;
	color: #62656f;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const NavLink = styled(Nav.Link) <INavLinkProps>`
	padding: 5px 8px;
	color: ${({ selected }) => (selected ? '#1F78B4' : '#212121')};
	background-color: ${({ selected }) => (selected ? '#E0ECFC' : '#ffffff')};
	font-size: 12px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	border-radius: 4px;
`;
