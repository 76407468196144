import { Dropdown, MenuProps } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FaChevronDown } from 'react-icons/fa';
import _ from 'lodash';

import { INDUSTRY_TYPE } from '../../Screens/Industries/Industries.screen';
import {
	IDropdownCustom,
	IDropdownItem,
	IDropdown,
} from '../../Screens/Industries/Industries.types';
import servicesRequest, {
	INDUSTRY_LIST_GROUPING_URL,
} from '../../Config/services.config';
import { toast } from 'react-toastify';
import { ColoredLabel } from '../../Screens/Industries/Industries.desktop.screen';
import { getDefaultMarket } from '../../Utils';

const DropdownTitleStyled = styled.div<{
	isActive: boolean;
	disabled: boolean;
}>`
	background-color: ${props => {
		if (props.disabled) return '#EAEAEA';
		return props.isActive ? '#E0ECFC' : '#f5f5f5';
	}};
`;

const DropdownItem = (props: IDropdownItem) => {
	return (
		<div
			className="d-flex gap-2 my-1 align-items-center mw-100"
			onClick={() => props.changeSelector(props.title, props.number)}>
			<ColoredLabel
				sector={props.number}
				className="company-stock-code fw-600 fs-10">
				{props.number}
			</ColoredLabel>
			<h5 className="fw-500 fs-12 mt-1">{props.title}</h5>
		</div>
	);
};

const DropdownCustom = (props: IDropdownCustom) => {
	const [isActive, setIsActive] = useState(false);

	const items: MenuProps['items'] = props.isLoading
		? [
			{
				label: <p>Loading...</p>,
				key: 0,
			},
		]
		: props.dropdownData.map((data, index) => {
			return {
				label: (
					<DropdownItem
						number={data.industry_code}
						title={data.name}
						changeSelector={props.changeSelector}
						sectorId={props.sectorId}
						isSector={props.isSector}
					/>
				),
				key: index,
			};
		});

	useEffect(() => {
		if (props.number) setIsActive(true);
		else setIsActive(false);
	}, [props.number]);

	return (
		<Dropdown
			/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
								// @ts-ignore */
			menu={{ items }}
			trigger={['click']}
			onOpenChange={() => {
				props.setIndustryType(props.type);
			}}
			className={`${props.disabled ? 'disabled-dropdown' : ''}`}
			disabled={props.disabled}
			placement="bottomLeft">
			<DropdownTitleStyled
				isActive={isActive}
				disabled={props.disabled}
				className="rounded d-flex justify-content-between align-items-center px-2 py-2">
				<div className="d-flex gap-2">
					{props.number && (
						<ColoredLabel
							sector={props.number}
							className="text-white rounded py-1 px-2 fw-600 fs-10">
							{props.number}
						</ColoredLabel>
					)}
					<h5 className="fw-500 fs-12 mt-1 m-0">{props.title}</h5>
				</div>
				<FaChevronDown className="fs-12 mx-2" />
			</DropdownTitleStyled>
		</Dropdown>
	);
};

const IndustryDropdown = (props: IDropdown) => {
	const [dropdownData, setDropdownData] = useState([]);
	const [selectedSector, setSelectedSector] = React.useState([
		INDUSTRY_TYPE.SECTOR,
		'',
	]);
	const [selectedIndustryGroup, setSelectedIndustryGroup] = React.useState([
		INDUSTRY_TYPE.INDUSTRY_GROUP,
		'',
	]);
	const [selectedIndustry, setSelectedIndustry] = React.useState([
		INDUSTRY_TYPE.INDUSTRY,
		'',
	]);
	const [selectedSubIndustry, setSelectedSubIndustry] = React.useState([
		INDUSTRY_TYPE.SUB_INDUSTRY,
		'',
	]);
	const [sectorId, setSectorId] = React.useState('');
	const [industryType, setIndustryType] = React.useState(INDUSTRY_TYPE.SECTOR);
	const [isLoading, setIsLoading] = React.useState(false);

	const isSector = industryType === INDUSTRY_TYPE.SECTOR;
	const isUS = getDefaultMarket() === 1

	const request = servicesRequest();

	const changeSelector = (selected: any, number: any) => {
		if (industryType === INDUSTRY_TYPE.SECTOR) {
			setSelectedSector([selected, number]);
			setSectorId(number);
		} else if (industryType === INDUSTRY_TYPE.INDUSTRY_GROUP)
			setSelectedIndustryGroup([selected, number]);
		else if (industryType === INDUSTRY_TYPE.INDUSTRY)
			setSelectedIndustry([selected, number]);
		else setSelectedSubIndustry([selected, number]);

		// @ts-ignore
		props.setIndustryCode([selected, number]);
	};

	const getIndustryCode = () => {
		if (industryType === INDUSTRY_TYPE.SECTOR) {
			return '';
		}
		if (industryType === INDUSTRY_TYPE.INDUSTRY_GROUP) {
			return selectedSector[1];
		}
		if (industryType === INDUSTRY_TYPE.INDUSTRY)
			return selectedIndustryGroup[1];
		if (industryType === INDUSTRY_TYPE.SUB_INDUSTRY) return selectedIndustry[1];
	};

	const getSectorData = () => {
		setIsLoading(true);
		request
			.get(
				`${INDUSTRY_LIST_GROUPING_URL}?country_id=${getDefaultMarket()}&industry_type=${industryType
					.toLowerCase()
					.replace(' ', '_')}&industry_code=${getIndustryCode()}`
			)
			.then(response => {
				const data = _.get(response, 'data.data.industries', []);
				setDropdownData(data);
				if (industryType === INDUSTRY_TYPE.SECTOR && isUS) {
					setSelectedIndustryGroup([INDUSTRY_TYPE.INDUSTRY_GROUP, '']);
					setSelectedIndustry([INDUSTRY_TYPE.INDUSTRY, '']);
					setSelectedSubIndustry([INDUSTRY_TYPE.SUB_INDUSTRY, '']);
				}
				if (industryType === INDUSTRY_TYPE.INDUSTRY_GROUP) {
					setSelectedIndustry([INDUSTRY_TYPE.INDUSTRY, '']);
					setSelectedSubIndustry([INDUSTRY_TYPE.SUB_INDUSTRY, '']);
				}
				if (industryType === INDUSTRY_TYPE.INDUSTRY) {
					setSelectedSubIndustry([INDUSTRY_TYPE.SUB_INDUSTRY, '']);
				}
				setIsLoading(false);
			})
			.catch(() =>
				console.error('Something went wrong. Please refresh the page.')
			);
	};

	useEffect(() => {
		getSectorData();
	}, [industryType]);

	return (
		<React.Fragment>
			{isUS && <DropdownCustom
				number={selectedSector[1]}
				title={selectedSector[0]}
				disabled={false}
				type={INDUSTRY_TYPE.SECTOR}
				sectorId={sectorId}
				dropdownData={dropdownData}
				isSector={isSector}
				setIndustryType={setIndustryType}
				changeSelector={changeSelector}
				isLoading={isLoading}
			/>}
			<DropdownCustom
				number={selectedIndustryGroup[1]}
				title={selectedIndustryGroup[0]}
				disabled={selectedSector[0] === INDUSTRY_TYPE.SECTOR}
				type={INDUSTRY_TYPE.INDUSTRY_GROUP}
				sectorId={sectorId}
				dropdownData={dropdownData}
				isSector={isSector}
				setIndustryType={setIndustryType}
				changeSelector={changeSelector}
				isLoading={isLoading}
			/>
			<DropdownCustom
				number={selectedIndustry[1]}
				title={selectedIndustry[0]}
				disabled={selectedIndustryGroup[0] === INDUSTRY_TYPE.INDUSTRY_GROUP}
				type={INDUSTRY_TYPE.INDUSTRY}
				sectorId={sectorId}
				dropdownData={dropdownData}
				isSector={isSector}
				setIndustryType={setIndustryType}
				changeSelector={changeSelector}
				isLoading={isLoading}
			/>
			<DropdownCustom
				number={selectedSubIndustry[1]}
				title={selectedSubIndustry[0]}
				disabled={selectedIndustry[0] === INDUSTRY_TYPE.INDUSTRY}
				type={INDUSTRY_TYPE.SUB_INDUSTRY}
				sectorId={sectorId}
				dropdownData={dropdownData}
				isSector={isSector}
				setIndustryType={setIndustryType}
				changeSelector={changeSelector}
				isLoading={isLoading}
			/>
		</React.Fragment>
	);
};

export default IndustryDropdown;
