import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
	AdminPanelScreen,
	CompanyScreen,
	CompareScreen,
	HomeScreen,
	IndustriesScreen,
	PreferencesScreen,
	ReportScreen,
	ResetPasswordScreen,
	SingleReportScreen,
} from './Screens';
import {
	DiscoverModal,
	FirstTimeModal,
	NavigationBar,
	SubscriptionModal,
} from './Components';
import AuthorizationComponent from './Components/Authorization/Authorization.component';
import {
	AUTHORIZATION_STEP,
	LOCAL_STORAGE_VARIABLE,
	SUBSCRIPTION_TYPE,
} from './Constants';
import { ROUTES } from './Constants/routes';

const App = () => {
	const [isFirstTimeUser, setIsFirstTimeUser] = useState(false);
	const [isOpenAuthorization, setIsOpenAuthorization] = useState(false); //TODO:: move local state to redux state
	const [isSubscriptionModal, setIsSubscriptionModal] = useState(false);
	const [isDiscoverTutorialModal, setDiscoverTutorialModal] = useState(false);
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [isAdmin, setIsAdmin] = useState(false);
	const [authorizationStep, setAuthorizationStep] = useState(
		AUTHORIZATION_STEP.LOGIN
	);
	const [mobileTitle, setMobileTitle] = useState('');

	const authorizationProps = () => ({
		isOpenAuthorization,
		setIsOpenAuthorization,
		isFirstTimeUser,
		setIsFirstTimeUser,
		isSubscriptionModal,
		setIsSubscriptionModal,
		authorizationStep,
		setAuthorizationStep,
		isDiscoverTutorialModal,
		setDiscoverTutorialModal,
		isLoggedIn,
		setIsLoggedIn,
		isAdmin,
		setIsAdmin,
		mobileTitle,
		setMobileTitle,
	});

	useEffect(() => {
		if (isFirstTimeUser)
			localStorage.setItem(
				LOCAL_STORAGE_VARIABLE.FIRST_TIME_USER,
				String(false)
			);

		if (!isFirstTimeUser) {
			const item = localStorage.getItem(LOCAL_STORAGE_VARIABLE.FIRST_TIME_USER);
			if (!item) setIsFirstTimeUser(true);
		}
	}, [isFirstTimeUser]);

	useEffect(() => {
		localStorage.setItem(
			LOCAL_STORAGE_VARIABLE.SUBSCRIPTION_TYPE,
			SUBSCRIPTION_TYPE.DEMO
		);
		const token = localStorage.getItem('token');
		if (token) {
			setIsLoggedIn(true);
		}
	}, []);

	return (
		<Router>
			<ToastContainer />
			<NavigationBar {...authorizationProps()} />
			<FirstTimeModal {...authorizationProps()} />
			<AuthorizationComponent {...authorizationProps()} />
			<SubscriptionModal {...authorizationProps()} />
			<DiscoverModal {...authorizationProps()} />
			<Routes>
				<Route path={ROUTES.ROOT} element={<HomeScreen />} />
				<Route
					path={ROUTES.PREFERENCES}
					element={<PreferencesScreen {...authorizationProps()} />}
				/>
				<Route
					path={ROUTES.RESET_PASSWORD}
					element={<ResetPasswordScreen {...authorizationProps()} />}
				/>
				<Route path={ROUTES.ADMIN} element={<AdminPanelScreen />} />
				<Route
					path={ROUTES.SINGLE_REPORT}
					element={<SingleReportScreen {...authorizationProps()} />}
				/>
				<Route path={ROUTES.COMPANY} element={<CompanyScreen />} />
				<Route path={ROUTES.INDUSTRIES} element={<IndustriesScreen />} />
				<Route path={ROUTES.COMPARE} element={<CompareScreen />} />
				<Route path={ROUTES.REPORT} element={<ReportScreen />} />
			</Routes>
		</Router>
	);
};

export default App;
