import React, { useState } from 'react';
import { Col } from 'react-bootstrap';
import { useSwipeable } from 'react-swipeable';

import {
	RightTabContainer,
	RightTabItem,
	ShowMobileOnly,
	TopSectionMobile,
} from '../SingleReport/SingleReport.styled';

import tableResponseData from './tableResponse.json';
import groupCardData from './groupCardResponse.json';

import { ICompare, ICompareCard, ICompareTable } from './Compare.types';
import { WhiteContainer, YearTab } from '../Company/Company.styled';
import {
	FilteredComponent,
	// compareGroupCard,
	renderCompareTable,
	renderCompareTable2,
} from './Compare.desktop.screen';
import SentimentChartComponent from '../../Components/Compare/SentimentChart.component';
import { Dot } from '../../Components/Industries/RightTab.component';
import KeywordTable from '../../Components/Company/KeywordTable.component';
import { Pagination } from 'antd';
import {
	ESGComponent,
	SentimentComponent,
} from '../../Components/SingleReport/RightTab.component';
import { RIGHT_TAB } from '../SingleReport/SingleReport.screen';
import { Button } from '../../Components';
import { BUTTON_TYPE } from '../../Components/Button/Button.component';
import { LIST_TYPE } from '../Industries/Industries.screen';
import SideTab from '../../Components/Compare/SideTab.component';
import { CHART_INTERVAL } from '../../Constants';
import CompareGroupCard from '../../Components/Compare/CompareBottomTable.component';
import KeywordListTableComponent from '../../Components/Home/KeywordListTable.component';
import { FaArrowLeft } from 'react-icons/fa';
import NewSentimentChart from '../../Components/Compare/NewSentimentChart.component';
import { renderDisclaimer } from '../../Components/SingleReport';
import { Popover } from '../../Components';


const CompareMobile = (props: ICompare) => {
	const MOBILE_TAB = ['CHART', 'SENTIMENT', 'ESG', 'KEYWORD_TABLE'];
	const [mobileTab, setMobileTab] = useState(MOBILE_TAB[0]);
	const [showFilter, setShowFilter] = useState(false);
	const [index, setIndex] = useState(0);
	const [isSwipeRight, setIsSwipeRight] = useState(false);

	const {
		industryCode,
		setIndustryCode,
		onClickConfirm,
		onDeleteList,
		data,
		scrollDirection,
		setScrollDirection,
		setStartDate,
		setFinishDate,
		selectedRange,
		setSelectedRange,
		setDocumentType,
		documentType,
		filtered,
		getCompaniesNextPage,
		isLoadingScrollCompany,
		onConfirm,
		setTab,
		tab,
		tempFilter,
		tableData,
		groupTab,
		setGroupTab,
		tableDataContent,
		onDeleteFixList,
		keywordList,
		compareCardTab,
		setCompareCardTab,
		compareCardTableData,
		setCompareCardTableData,
		compareTab,
		setCompareTab,
		scoresAverage,
		chartTab,
		setChartTab,
		esgData,
		keywordPagination,
		setKeywordPagination,
		getKeywordList,
		onParamsChange,
		getCompaniesByIndustry,
		selectedTablePagination,
		compareCardPagination,
		getCompareTabData,
		onChangeInterval,
		intervalType,
		sentimentYValue,
		returnYValue,
		fetchingKeywords,
		getKeywordFrequencies,
		idFiltered,
		setCompareDataOrder,
		compareDataOrder,
		companiesByIndustryOrder,
		setCompaniesByIndustryOrder,
		isChartAllDocumentType,
		onCheckedAllDocumentType,
		onClickIndustryCompany,
		isRenderSentimentChart,
		isRenderReturnChart,
		startDate,
		finishDate,
		returnData,
		sentimentData,
		selectedLegend,
		setSelectedLegend,
		compareSelectedOrder,
		setCompareSelectedOrder
	} = props;

	const { DAY, WEEK, MONTH } = CHART_INTERVAL;

	const onClickConfirmMobile = () => () => {
		setTab(LIST_TYPE.SELECT_INDUSTRY);
		onConfirm()();
		setShowFilter(false);
	};

	const sideTabProps = {
		industryCode,
		setIndustryCode,
		onClickConfirm,
		filtered: tempFilter,
		onDeleteList,
		data,
		scrollDirection,
		setScrollDirection,
		setStartDate,
		setFinishDate,
		selectedRange,
		setSelectedRange,
		setDocumentType,
		documentType,
		onConfirm: onClickConfirmMobile,
		getCompaniesNextPage,
		isLoadingScrollCompany,
		label: 'Compare',
		startDate,
		finishDate,
		isRenderSentimentChart
	};

	const handlers = useSwipeable({
		onSwipedLeft: () => {
			if (index !== MOBILE_TAB.length) {
				setIsSwipeRight(true);
				setMobileTab(MOBILE_TAB[index + 1]);
				setIndex(index + 1);
			}
		},
		onSwipedRight: () => {
			if (index !== 0) {
				setIsSwipeRight(false);
				setMobileTab(MOBILE_TAB[index - 1]);
				setIndex(index - 1);
			}
		},
		swipeDuration: 500,
		preventScrollOnSwipe: true,
		trackMouse: true,
	});

	return (
		<React.Fragment>
			<ShowMobileOnly>
				<div className="bg-grey">
					<div className={`${showFilter ? 'd-none' : 'd-block'}`}>
						<WhiteContainer className="px-3 py-3 d-block">
							<FilteredComponent
								filtered={filtered}
								documentType={documentType.value}
								isChartAllDocumentType={isChartAllDocumentType}
								onCheckedAllDocumentType={onCheckedAllDocumentType}
								isMobile
								selectedLegend={selectedLegend}
								setSelectedLegend={setSelectedLegend}
							/>
							<RightTabContainer
								{...handlers}
								className="d-flex justify-content-between mt-2">
								<div className="justify-content-start d-flex gap-2">
									<RightTabItem
										onClick={() => {
											setMobileTab(MOBILE_TAB[0]);
										}}
										selected={mobileTab === MOBILE_TAB[0]}>
										Chart
										{mobileTab === MOBILE_TAB[0] && <Popover
											className="ps-1"
											popoverTextHeader="**Sentiment and Returns**"
											popoverTextBody={`View sentiment scores and stock returns for companies. Industries show sentiment only. Click on the legend items to toggle the lines on or off.`}
										/>}
									</RightTabItem>
									<RightTabItem
										onClick={() => {
											setMobileTab(MOBILE_TAB[1]);
										}}
										selected={mobileTab === MOBILE_TAB[1]}>
										Sentiments
										{mobileTab === MOBILE_TAB[1] && <Popover
											className="ps-1"
											popoverTextHeader="**Combined Sentiments**"
											popoverTextBody={`Displays overall sentiments of all the selected company and industry data combined.`}
										/>}
									</RightTabItem>
									<RightTabItem
										onClick={() => {
											setMobileTab(MOBILE_TAB[2]);
										}}
										selected={mobileTab === MOBILE_TAB[2]}>
										ESG
										{mobileTab === MOBILE_TAB[2] && <Popover
											className="ps-1"
											popoverTextHeader="**ESG Percentages**"
											popoverTextBody={`Shows ESG classification percentages from selected data. \n**Note**: this is not a score, but a simple percentage of ESG classifications.`}
										/>}
									</RightTabItem>
									<RightTabItem
										onClick={() => {
											setMobileTab(MOBILE_TAB[3]);
										}}
										selected={mobileTab === MOBILE_TAB[3]}>
										Top Keywords
										{mobileTab === MOBILE_TAB[3] && <Popover
											className="ps-1"
											popoverTextHeader="**Top Keywords**"
											popoverTextBody={`Lists the most frequent keywords from selected data..`}
										/>}
									</RightTabItem>
								</div>
								<div
									className="fs-10 fw-600 m-0 cursor-pointer standard-code bg-blue text-darkblue"
									onClick={() => {
										setShowFilter(true);
									}}>
									Data Filter
								</div>
							</RightTabContainer>
							<div
								{...handlers}
								className={`${isSwipeRight ? 'swipe-effect-right' : 'swipe-effect-left'
									}`}>
								{mobileTab === MOBILE_TAB[0] && (
									<React.Fragment>
										<div className="mt-2">
											{(isRenderSentimentChart && isRenderReturnChart) && (
												<NewSentimentChart
													sentiment={sentimentData}
													returnData={returnData}
													maxAxisValue={finishDate}
													selectedLegend={selectedLegend}
												/>
											)}
										</div>
										{/* Remove interval option temporarily */}
										{/* <div className="col-md-6 m-2 mt-5 d-flex gap-1">
											<div className="bg-lightgrey px-2 d-flex align-items-center">
												<h5 className="fs-10 fw-600 m-0">Interval</h5>
											</div>
											<YearTab
												onClick={() => onChangeInterval(DAY)}
												selected={intervalType === DAY}>
												D
											</YearTab>
											<YearTab
												onClick={() => onChangeInterval(WEEK)}
												selected={intervalType === WEEK}>
												W
											</YearTab>
											<YearTab
												onClick={() => onChangeInterval(MONTH)}
												selected={intervalType === MONTH}>
												M
											</YearTab>
										</div> */}
									</React.Fragment>
								)}
								{mobileTab == MOBILE_TAB[1] && (
									<div>
										<KeywordListTableComponent
											keywords={keywordList}
											documentType={documentType.value}
											getKeywordFrequencies={getKeywordFrequencies}
											fetchingKeywords={fetchingKeywords}
											selectedWatchlistDetails={''}
											limit={15}
											isMobile
										/>
									</div>
								)}
								{mobileTab === MOBILE_TAB[2] && (
									<div>
										<SentimentComponent
											documentType={documentType.value}
											scoresAverage={scoresAverage}
											scoreType='COMPANY'
											isCompanyPage={true}
											isComparePage={true}
										/>
									</div>
								)}
								{mobileTab === MOBILE_TAB[3] && (
									<ESGComponent {...esgData} isCompanyPage isComparePage={false} />
								)}
							</div>
						</WhiteContainer>
						<WhiteContainer className="px-3 mt-2 mb-2">
							{renderCompareTable(
								tableData,
								setTab,
								tab,
								filtered,
								getCompaniesByIndustry,
								selectedTablePagination,
								idFiltered,
								companiesByIndustryOrder,
								setCompaniesByIndustryOrder,
								onClickIndustryCompany,
								isChartAllDocumentType,
								true,
								compareSelectedOrder,
								setCompareSelectedOrder
							)}
						</WhiteContainer>
						{/* <WhiteContainer className="rounded mb-3">
							{renderCompareTable2(data)}
						</WhiteContainer> */}
						<WhiteContainer className="px-3 py-2">
							<CompareGroupCard
								data={tableDataContent}
								groupTab={groupTab}
								setGroupTab={setGroupTab}
								compareCardTab={compareCardTab}
								setCompareCardTab={setCompareCardTab}
								compareCardTableData={compareCardTableData}
								setCompareCardTableData={setCompareCardTableData}
								compareTab={compareTab}
								setCompareTab={setCompareTab}
								compareCardPagination={compareCardPagination}
								getCompareTabData={getCompareTabData}
								isMobile={true}
								setCompareDataOrder={setCompareDataOrder}
								compareDataOrder={compareDataOrder}
								onClickIndustryCompany={onClickIndustryCompany}
							/>
						</WhiteContainer>
					</div>
					<WhiteContainer
						className={`pt-2 px-3 pb-4 ${showFilter ? 'd-block' : 'd-none'
							}`}>
						<SideTab
							{...sideTabProps}
							isWithBackButton={true}
							backButtonOnClick={() => {
								setShowFilter(false);
							}}
						/>
					</WhiteContainer>
					<div className="mt-2 bg-white">
						{renderDisclaimer()}
					</div>
				</div>
			</ShowMobileOnly>
		</React.Fragment>
	);
};

export default CompareMobile;
