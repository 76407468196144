import { AutoComplete, Input } from 'antd';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import _ from 'lodash';

import servicesRequest, { SEARCH_URL } from '../../Config/services.config';
import {
	FlexDisplayMediumGap,
	FlexDisplaySpaceBetween,
} from '../../Screens/Preferences/Preferences.styled';
import { ColoredLabel } from '../../Screens/Industries/Industries.desktop.screen';
import { getDefaultMarket } from '../../Utils';

interface IAutoComplete {
	onClickConfirm: (value: any[]) => (value1: string) => void;
	additionalAction?: () => void;
}

const AutoCompleteComponent = (props: IAutoComplete) => {
	const EMPTY_OPTIONS = {
		label: 'Not found',
	};

	const [searchValue, setSearchValue] = useState('');
	const [options, setOptions] = useState<any[]>([]);

	const [isKeyDown, setIsKeyDown] = useState(false);

	const { onClickConfirm, additionalAction = () => { } } = props;

	const request = servicesRequest();

	const renderLabel = (code: string, name: string, index: number) => (
		<FlexDisplaySpaceBetween className="my-1">
			<FlexDisplayMediumGap>
				<ColoredLabel
					key={index}
					sector={code}
					className="text-white standard-code fw-600 fs-10">
					{code}
				</ColoredLabel>
				<span className="ms-1 cursor-pointer fw-500 fs-12">{name}</span>
			</FlexDisplayMediumGap>
		</FlexDisplaySpaceBetween>
	);

	const searchData = () => {
		request
			.get(`${SEARCH_URL}?country_id=${getDefaultMarket()}&q=${searchValue}`)
			.then((response: any) => {
				const companiesResponse = _.get(response, 'data.data.companies', []);
				const industriesResponse = _.get(response, 'data.data.industries', []);
				const mappedCompanies = companiesResponse.map(
					(d: any, index: number) => {
						return {
							label: renderLabel(d.stock_code, d.name, index),
							value: d.name,
							key: d.stock_code,
							id: d.id,
							isCompany: true,
						};
					}
				);
				const mappedIndustries = industriesResponse.map(
					(d: any, index: number) => {
						return {
							label: renderLabel(d.industry_code, d.name, index),
							value: d.name,
							key: d.industry_code,
							id: d.id,
							isCompany: false,
						};
					}
				);
				/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
								// @ts-ignore */
				const mappedData =
					mappedIndustries.length === 0 && mappedCompanies.length === 0
						? [EMPTY_OPTIONS]
						: [...mappedIndustries, ...mappedCompanies];
				/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
								// @ts-ignore */
				setOptions(mappedData);
			})
			.catch(() =>
				toast.error('Something went wrong. Please refresh the page.')
			);
	};

	useEffect(() => {
		if (searchValue.length > 2 && !isKeyDown) {
			searchData();
		}
	}, [isKeyDown]);

	useEffect(() => {
		function handleKeyDown() {
			setIsKeyDown(true);
			setTimeout(() => {
				setIsKeyDown(false);
			}, 600);
		}

		document.addEventListener('keydown', handleKeyDown);

		return function cleanup() {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, []);

	return (
		<AutoComplete
			size="small"
			options={options}
			onSelect={(value, option) => {
				onClickConfirm([option.value, option.key, option.isCompany])(option.id);
				setSearchValue('');
				additionalAction();
			}}
			style={{ width: '100%', height: 'inherit' }}
			onSearch={text => {
				setSearchValue(text);
			}}
			value={searchValue}
			placeholder="Search for companies & industries">
			<Input.Search />
		</AutoComplete>
	);
};

export default AutoCompleteComponent;
