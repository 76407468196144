import React from 'react';
import { createRoot } from 'react-dom/client';

import reportWebVitals from './reportWebVitals';

import App from './App';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

import { HOTJAR, GA } from './Constants/index';
import Hotjar from '@hotjar/browser';
import ReactGA from "react-ga4";

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);

// Add Hotjar analytics
Hotjar.init(Number(HOTJAR.siteId), Number(HOTJAR.hotjarVersion));

// Add Google Analytics 
ReactGA.initialize(String(GA.MEASUREMENT_ID));

reportWebVitals();
