import styled, { css } from 'styled-components';

import { desktop, laptop, tablet } from '../../Utils/layout.utils';

export const CanvasSentimentStyled = styled.div`
	width: 100% !important;
	height: 25vh !important;
	position: relative;
`;

export const CanvasReturnsStyled = styled.div`
	width: 100% !important;
	height: 25vh !important;
`;

export const TableHead = styled.thead`
	font-size: 10px;
	font-weight: 600;
`;

export const TableData = styled.td<{ fontWeight?: string }>`
	font-size: 10px;
	font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : '400')};
`;

export const CompanyNameStandard = styled.p`
	font-size: 10px;
	font-weight: 600;
	margin: 0;
`;

export const WhiteContainer = styled.div`
	background-color: white;
`;

export const YearTab = styled.div<{
	selected?: boolean;
	tabColor?: string;
	textColor?: string;
}>`
	font-size: 10px;
	font-weight: 600;
	cursor: pointer;
	background-color: ${({ selected, tabColor }) => {
		if (tabColor) return tabColor;
		if (selected) return '#E0ECFC';
		return '#FFFFFF';
	}};
	color: ${({ selected, textColor }) => {
		if (textColor) return textColor;
		if (selected) return '#1F78B4';
		return '#4E5153';
	}};
	border-radius: 0.2vw;
	height: fit-content;

	${laptop(css`
		padding: 0.5vw;
	`)};

	${desktop(css`
		padding: 0.5vw;
	`)};

	${tablet(css`
		padding: 1vw;
	`)};

	&:hover {
		background-color: #ebf3fd !important;
	}
`;

export const InfoContainer = styled.div`
	font-size: 10px;
	font-weight: 600;
	color: #4e5153;

	${laptop(css`
		display: flex;
	`)};

	${desktop(css`
		display: flex;
	`)};

	${tablet(css`
		display: none;
	`)};
`;

export const Link = styled.a`
	text-decoration: underline;
	cursor: pointer;
	color: #4e5153;
`;

export const InformationPercentage = styled.div<{
	textStyle?: string;
}>`
	color: ${({ textStyle }) => (textStyle ? textStyle : '#4e4e4e')};
	font-size: 10px;
	font-style: normal;
	font-weight: 600;
	text-align: center;

	${laptop(css`
		width: 3vw;
	`)};

	${desktop(css`
		width: 3vw;
	`)};

	${tablet(css`
		width: 10vw;
	`)};
`;

export const InformationPercentageSpan = styled.span<{
	textStyle?: string;
}>`
	color: ${({ textStyle }) => (textStyle ? textStyle : '#4e4e4e')};
	font-size: 10px;
	font-style: normal;
	font-weight: 600;
	text-align: center;
`;

export const DescriptionStyled = styled.h3`
	font-size: 10px;
	font-weight: 600;
	color: #4e4e4e;
	margin: 0;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	width: 40%;
`;

export const SubtitleStyled = styled.h3`
	font-size: 16px;
	font-weight: 600;
	margin: 0;
`;

export const GrowthTextStyled = styled.span<{
	isGrowth: boolean;
}>`
	font-size: 10px;
	font-weight: 600;
	color: ${props => (props.isGrowth ? '#107C10' : '#D13438')};
	margin: 0;
`;

export const GreyDiv = styled.div<{
	isClicked: boolean;
	isGrey?: boolean;
}>`
	border-radius: 5px;
	background-color: ${props =>
		props.isGrey
			? '#F5F5F5'
			: props.isClicked
			? '#E0ECFC'
			: '#ffffff'} !important;
	cursor: pointer;

	&:hover {
		background-color: #ebf3fd !important;
	}
`;

export const TableRowStyled = styled.tr<{
	isClicked?: boolean;
}>`
	border-radius: 5px;
	background-color: ${props =>
		props.isClicked ? '#E0ECFC' : '#fffff'} !important;
	cursor: pointer;

	&:hover > td:nth-child(odd),
	:hover > td:nth-child(even) {
		background-color: #ebf3fd !important;
	}

	& td:nth-child(odd) {
		background-color: ${props =>
			props.isClicked ? '#E0ECFC' : '#fffff'} !important;
	}

	& td:nth-child(even) {
		background-color: ${props =>
			props.isClicked ? '#E0ECFC' : '#fffff'} !important;
	}
`;
