// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import styled, { css } from 'styled-components';
import { OverlayTrigger } from 'react-bootstrap';

import { FlexDisplaySmallGap, FlexDisplaySpaceBetweenNormal } from '../../Screens/Preferences/Preferences.styled';
import { LEFT_TAB } from '../../Screens/SingleReport/SingleReport.screen';

import {
	LeftComponent,
	LeftComponentItem,
	LeftComponentTab,
	SentimentPercentage,
	ShowDesktopOnly,
	TooltipAlertParagraph,
} from '../../Screens/SingleReport/SingleReport.styled';

import { ISentenceEsg9, ISentenceScores, ISingleReport } from '../../Screens/SingleReport/SingleReport.types';
import { renderPercentage, SENTIMENT } from './Sentiment.component';
import { tablet } from '../../Utils/layout.utils';
import { mappingSentences } from './index';
import { Popover } from '..';


const sentenceColorGuide = (esgType: string) => {
	let color = '#fff';
	let backgroundColor = '#4E4E4E';
	let esgCategory = '';

	if (
		esgType === 'Climate Change' ||
		esgType === 'Natural Capital' ||
		esgType === 'Pollution & Waste'
	) {
		esgCategory = '(E)';
		backgroundColor = '#82A844';
		color = '#FFF';
	}

	if (
		esgType === 'Human Capital' ||
		esgType === 'Product Liability' ||
		esgType === 'Community Relations'
	) {
		esgCategory = '(S)';
		backgroundColor = '#339CC2';
		color = '#FFF';
	}

	if (
		esgType === 'Corporate Governance' ||
		esgType === 'Business Ethics & Values'
	) {
		esgCategory = '(G)';
		backgroundColor = '#96959F';
		color = '#FFF';
	}

	return {
		color,
		backgroundColor,
		esgCategory,
	};
};

export const renderPositiveBackgroundColor = (scores: ISentenceScores) => {
	let backgroundColor = '#A6D0A6';

	const positive = (Number(scores?.positive) * 100).toFixed(2);

	if (positive >= 66 && positive < 100) backgroundColor = '#BDDBBD';
	if (positive >= 33 && positive < 66) backgroundColor = '#CDE3CD';

	return backgroundColor;
};

export const renderNegativeBackgroundColor = (scores: ISentenceScores) => {
	let backgroundColor = '#EAA2A4';

	const positive = (Number(scores?.negative) * 100).toFixed(2);

	if (positive >= 66 && positive < 100) backgroundColor = '#EFBABC';
	if (positive >= 33 && positive < 66) backgroundColor = '#F5D4D5';

	return backgroundColor;
};

const ContainerContent = styled.div`
	height: 30rem;
	overflow: auto;

	${tablet(css`
		height: 100%;
		overflow: auto;
	`)};
`;

const Title = styled.h3`
	color: #4e4e4e;
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: 20px;
`;

export const Person = styled.h6`
	font-weight: 700;
	font-size: 14px;
`;

const sentenceBackgroundColor = (props: {
	type?: string;
	scores?: ISentenceScores;
	selectedLeftTab?: string;
	esg9Type?: ISentenceEsg9;
}) => {
	if (props.selectedLeftTab === LEFT_TAB.ESG && props.esg9Type !== 'Non-ESG') {
		const { backgroundColor } = sentenceColorGuide(props?.esg9Type);

		return backgroundColor;
	}

	if (props.selectedLeftTab === LEFT_TAB.SENTIMENT) {
		return props.type === 'Neutral'
			? 'initial'
			: props.type === 'Positive'
				? renderPositiveBackgroundColor(props.scores)
				: props.type === 'Negative'
					? renderNegativeBackgroundColor(props.scores)
					: 'initial';
	}
};

const sentenceColor = (props: {
	selectedLeftTab?: string;
	esg9Type?: ISentenceEsg9;
}) => {
	if (props.selectedLeftTab === LEFT_TAB.SENTIMENT) {
		return 'initial';
	}

	if (props.selectedLeftTab === LEFT_TAB.ESG && props.esg9Type !== 'Non-ESG') {
		const { color } = sentenceColorGuide(props?.esg9Type);
		return color;
	}

	return '#4E4E4E';
};

const Sentence = styled.span<{
	type?: string;
	scores?: ISentenceScores;
	selectedLeftTab?: string;
	esg9Type?: ISentenceEsg9;
}>`
	background-color: ${({ type, scores, selectedLeftTab, esg9Type }) =>
		sentenceBackgroundColor({ type, scores, selectedLeftTab, esg9Type })};
	color: ${({ selectedLeftTab, esg9Type }) =>
		sentenceColor({ selectedLeftTab, esg9Type })};
	cursor: ${({ type }) => (type === 'Neutral' ? 'text' : 'pointer')};
	font-size: 12px;
	font-style: normal;
	line-height: 20px;
`;

const TooltipStyled = styled.div`
	border-radius: 4px;
	border: 1px solid #e0e0e0;
	background: #fff;
	padding: 0.5vh;
`;

const renderTooltip = (sentence: any, props: any) => {
	const esgType = sentence?.esg9?.type;

	const { color, backgroundColor, esgCategory } = sentenceColorGuide(esgType);

	return (
		<TooltipStyled id="button-tooltip" {...props}>
			<FlexDisplaySmallGap>
				<SentimentPercentage
					width={3}
					color={color}
					backgroundColor={renderPositiveBackgroundColor(sentence?.scores)}>
					{renderPercentage(sentence, SENTIMENT.POSITIVE)}%
				</SentimentPercentage>
				<SentimentPercentage
					width={3}
					color={color}
					backgroundColor={renderNegativeBackgroundColor(sentence?.scores)}>
					{renderPercentage(sentence, SENTIMENT.NEGATIVE)}%
				</SentimentPercentage>
				<SentimentPercentage color="#000" backgroundColor="#F5F5F5" width={3}>
					{renderPercentage(sentence, SENTIMENT.NEUTRAL)}%
				</SentimentPercentage>
				<SentimentPercentage
					width={7}
					color={color}
					backgroundColor={backgroundColor}>
					{esgType} {esgCategory}
				</SentimentPercentage>
			</FlexDisplaySmallGap>
		</TooltipStyled>
	);
};

const renderTooltipAlert = (props: any) => {
	return (
		<TooltipStyled id="button-tooltip" {...props}>
			<TooltipAlertParagraph className="m-0">
				The table has been excluded from our analysis as it is not relevant to
				our sentiment focus or area of interest.
			</TooltipAlertParagraph>
		</TooltipStyled>
	);
};

export const renderTab = (props: ISingleReport) => {
	const { onChangeLeftTab, selectedLeftTab } = props;

	return (
		<LeftComponentTab>
			<LeftComponentItem
				onClick={() => {
					onChangeLeftTab(LEFT_TAB.SENTIMENT);
				}}
				selected={selectedLeftTab === LEFT_TAB.SENTIMENT}>
				Sentiment
				{selectedLeftTab === LEFT_TAB.SENTIMENT && <Popover
					className='ps-1'
					popoverTextHeader="**Sentiment Highlights**"
					popoverTextBody={`View the sentiment score highlights; hover or click for details.`}
				/>}
			</LeftComponentItem>
			<LeftComponentItem
				onClick={() => {
					onChangeLeftTab(LEFT_TAB.ESG);
				}}
				selected={selectedLeftTab === LEFT_TAB.ESG}>
				ESG
				{selectedLeftTab === LEFT_TAB.ESG && <Popover
					className='ps-1'
					popoverTextHeader="**ESG Highlights**"
					popoverTextBody={`View the ESG classification highlights; hover or click for details.`}
				/>}
			</LeftComponentItem>
		</LeftComponentTab>
	);
};

const renderSentences = (data: ISingleReport, selectedTab: string) => {
	const getDocuments = _.get(data, 'documents', []);

	const rawSentences = mappingSentences(getDocuments) || [];

	const paragraphs = [];
	let currentParagraph = [];

	rawSentences.forEach(sentence => {
		if (sentence.type === 'title') {
			paragraphs.push(
				<Title className="my-3" key={`title-${paragraphs.length}`}>
					{sentence.sentence}
				</Title>
			);
		}

		if (sentence.type === 'person') {
			if (currentParagraph.length > 0) {
				paragraphs.push(
					<div key={`paragraph-${paragraphs.length}`}>{currentParagraph}</div>
				);
				currentParagraph = [];
			}
			// Add the title as an <h3> with sentenceId as a data attribute
			paragraphs.push(
				<Person
					className="my-2"
					key={`person-${paragraphs.length}`}
					dangerouslySetInnerHTML={{ __html: sentence.sentence }}
				/>
			);
		}

		if (sentence.sentence === '[DATA_TABLE_REMOVED]') {
			currentParagraph.push(
				<>
					<OverlayTrigger
						key={sentence.sentenceId}
						placement="top"
						delay={{ show: 250, hide: 400 }}
						overlay={props => renderTooltipAlert(props)}>
						<Sentence
							type={sentence?.scores?.sentiment}
							scores={sentence?.scores}
							selectedLeftTab={selectedTab}
							id={sentence.sentenceId}
							key={`sentence-${sentence.sentenceId}`}
							esg3={sentence?.esg3}
							data-sentence-id={sentence?.sentenceId}
							esg9Type={sentence?.esg9?.type}
							dangerouslySetInnerHTML={{ __html: sentence.sentence }}
						/>
					</OverlayTrigger>
					<br />
				</>
			);
		}

		if (sentence.type === 'sentence') {
			currentParagraph.push(
				<>
					<OverlayTrigger
						key={sentence.sentenceId}
						placement="top"
						delay={{ show: 250, hide: 400 }}
						overlay={props => renderTooltip(sentence, props)}>
						<Sentence
							type={sentence?.scores?.sentiment}
							scores={sentence?.scores}
							selectedLeftTab={selectedTab}
							id={sentence?.sentenceId}
							key={`sentence-${sentence?.sentenceId}`}
							esg3={sentence?.esg3}
							data-sentence-id={sentence?.sentenceId}
							esg9Type={sentence?.esg9?.type}
							dangerouslySetInnerHTML={{ __html: sentence.sentence }}
						/>
					</OverlayTrigger>{' '}
				</>
			);
		}
	});

	// Add the last paragraph if it's not empty
	if (currentParagraph.length > 0) {
		paragraphs.push(
			<div key={`paragraph-${paragraphs.length}`}>{currentParagraph}</div>
		);
	}

	return <div>{paragraphs}</div>;
};

const LeftTabComponent = (props: ISingleReport) => {
	return (
		<LeftComponent>
			<FlexDisplaySpaceBetweenNormal className="mb-2">
				<div>
					<h3 className="fw-bold">Contents</h3>
				</div>
				<ShowDesktopOnly>{renderTab(props)}</ShowDesktopOnly>
			</FlexDisplaySpaceBetweenNormal>
			<ContainerContent>
				{renderSentences(props, props.selectedLeftTab)}
			</ContainerContent>
		</LeftComponent>
	);
};

export default LeftTabComponent;
