// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

export interface Dataset {
	date: Date;
	value: number;
}

interface LineChartProps {
	data: { date: string; value: number }[];
	isCurve?: boolean;
	isGradient?: boolean;
	isNoXLabel?: boolean;
	title: string;
	xCoordinate?: number;
	date?: Date;
	setXCoordinate?: (arg0: number) => void;
	setDate?: (arg0?: Date) => void;
	showToolTips?: boolean;
	finishDate?: Date;
	startDate?: Date;
}

const LineChartComponent: React.FC<LineChartProps> = ({
	data,
	isCurve = false,
	isGradient = false,
	isNoXLabel = false,
	title,
	showToolTips = true,
	finishDate,
	startDate,
}) => {
	const svgRef = useRef<SVGSVGElement | null>(null);

	useEffect(() => {
		let maxYValue = 5;
		let minYValue = -0.1;

		// eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
		data.forEach((item: { value: number }) => {
			// Check if the current value is greater than the current maximum
			if (Number(item.value) > maxYValue) {
				// Update the maximum value and corresponding object
				maxYValue = Number(item.value);
			}

			if (Number(item.value) < minYValue) {
				minYValue = Number(item.value);
			}
		});

		if (!data || !svgRef.current) return;

		d3.select(svgRef.current).selectAll('*').remove();

		const containerWidth = svgRef.current.clientWidth;
		const containerHeight = svgRef.current.clientHeight;

		const margin = {
			top: 0.06 * containerHeight,
			right: 0.06 * containerWidth,
			bottom: isNoXLabel ? 0.06 * containerHeight : 0.18 * containerHeight,
			left: 0.14 * containerWidth,
		};
		const width = containerWidth - margin.left - margin.right;
		const height = containerHeight - margin.top - margin.bottom;

		const x = d3.scaleTime().range([0, width]);
		const y = d3
			.scaleLinear()
			.domain([minYValue, maxYValue])
			.range([height, 0]);

		if (startDate && finishDate) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
			x.domain([new Date(startDate), new Date(finishDate)]);
		} else {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
			x.domain(d3.extent(data, d => d.date));
		}
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
		y.domain([minYValue, maxYValue]).nice();

		let line;
		if (isCurve) {
			line = d3
				.line()
				.defined(function (d) {
					return (d.close !== null);
				})
				.curve(d3.curveBumpX)
				// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
				.x(d => x(d.date))
				// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
				.y(d => y(d.value));
		} else {
			line = d3
				.line()
				.defined(function (d) {
					return (d.close !== null);
				}) // TODO: NEED TO CHECK THIS
				// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
				.x(d => x(d.date))
				// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
				.y(d => y(d.value))
		}

		const svg = d3
			.select(svgRef.current)
			.append('svg')
			.attr('width', width + margin.left + margin.right)
			.attr('height', height + margin.top + margin.bottom)
			.append('g')
			.attr('transform', `translate(${margin.left},${margin.top})`);

		if (showToolTips) {
			svg
				// eslint-disable-next-line @typescript-eslint/no-use-before-define
				.on('pointerenter pointermove', pointerMoved)
				// eslint-disable-next-line @typescript-eslint/no-use-before-define
				.on('pointerleave', pointerLeft)
				// eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
				.on('touchstart', event => event.preventDefault());
		}

		svg
			.selectAll('yGrid')
			// eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
			.data(y.ticks(5))
			.join('line')
			.attr('class', 'y-grid-line')
			.attr('x1', 0)
			.attr('x2', width)
			.attr('y1', d => y(d))
			.attr('y2', d => y(d))
			.attr('stroke', '#e0e0e0')
			.attr('stroke-width', 0.5);

		svg
			.append('path')
			.attr('fill', 'none')
			.attr('stroke', '#1F78B4')
			.attr('stroke-width', 1.5)
			.attr('d', line(data))

		svg
			.append('text')
			.attr('class', 'y label')
			.attr('text-anchor', 'middle')
			.attr('y', -45)
			.attr('x', -height / 2)
			.attr('dy', '.5em')
			.attr('transform', 'rotate(-90)')
			.style('font-size', '12px')
			.style('font-family', 'Inter')
			.style('color', '#4E4E4E')
			.text(title);

		if (data.length === 1) {
			svg.append('g')
				.selectAll("dot")
				.data(data)
				.enter()
				.append("circle")
				.attr("cx", function (d) { return x(d.date); })
				.attr("cy", function (d) { return y(d.value); })
				.attr("r", 3)
				.style("fill", "#1F78B4")
		}

		if (!isNoXLabel) {
			svg
				.append('g')
				.attr('transform', `translate(0,${height})`)
				.call(
					d3
						.axisBottom(x)
						.ticks(d3.timeYear.every(1))
						.tickFormat(d3.timeFormat('%Y'))
				);
		}

		if (isGradient) {
			const createGradient = select => {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
				const gradient = select
					.select('defs')
					.append('linearGradient')
					.attr('id', 'gradient')
					.attr('x1', '0%')
					.attr('y1', '100%')
					.attr('x2', '0%')
					.attr('y2', '0%');

				// eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
				gradient
					.append('stop')
					.attr('offset', '0%')
					.attr('stop-color', 'white')
					.attr('stop-opacity', 1);

				// eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
				gradient
					.append('stop')
					.attr('offset', '100%')
					.attr('stop-color', '#BED8E9')
					.attr('stop-opacity', 0);
			};

			svg.append('defs');
			svg.call(createGradient);
			svg
				.selectAll('.line-chart')
				.data(data)
				.enter()
				.append('path')
				.attr('d', () => {
					// eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
					const lineValues: string = line(data).slice(1);
					const splitedValues = lineValues.split(',');

					return `M0,${height},${lineValues},l0,${height - splitedValues[splitedValues.length - 1]
						}`;
				})
				.style('fill', 'url(#gradient)');
		}

		if (showToolTips) {
			svg
				.append('g')
				.call(
					d3
						.axisLeft(y)
						.ticks(5)
						.tickFormat((t: string) => `${t}%`)
				)
				.style('font-size', '10px')
				.style('font-family', 'Inter')
				.style('color', '#4E4E4E');
		} else {
			svg
				.append('g')
				.call(d3.axisLeft(y).ticks(5))
				.style('font-size', '10px')
				.style('font-family', 'Inter')
				.style('color', '#4E4E4E');
		}

		const tooltip = svg.append('g');

		// eslint-disable-next-line @typescript-eslint/unbound-method,@typescript-eslint/no-unsafe-return
		const bisect = d3.bisector(d => d.date).center;

		function pointerMoved(event) {
			const i = bisect(data, x.invert(d3.pointer(event)[0]));
			tooltip.style('display', null);
			tooltip.attr(
				'transform',
				// eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
				`translate(${x(data[i].date)},${y(data[i].value)})`
			);

			let path = tooltip.select('path');
			if (path.empty()) {
				path = tooltip
					.append('path')
					.attr('fill', 'white')
					.attr('stroke', '#F5F5F5');
			}

			let text = tooltip.select('text');
			if (text.empty()) {
				text = tooltip.append('text');
			}

			text
				.style('font-family', 'Inter')
				.style('font-size', '10px')
				.style('fill', 'black');

			text.selectAll('tspan').remove(); // Clear existing tspans

			// eslint-disable-next-line @typescript-eslint/no-shadow
			function size(text, path) {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
				const textNode = text.node();
				if (textNode) {
					// eslint-disable-next-line @typescript-eslint/no-shadow,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
					const { y, width: w, height: h } = textNode.getBBox();
					// eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
					text.attr('transform', `translate(${-w / 2},${15 - y})`);
					// eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
					path.attr(
						'd',
						// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
						`M${-w / 2 - 10},5H-5l5,-5l5,5H${w / 2 + 10}v${h + 20}h-${w + 20}z`
					);
				}
			}

			// eslint-disable-next-line @typescript-eslint/no-shadow
			text.call(text =>
				text
					.selectAll('tspan')
					// eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
					.data([d3.timeFormat('%b %d, %Y')(data[i].date), data[i].value])
					.join('tspan')
					.attr('x', 0)
					// eslint-disable-next-line @typescript-eslint/no-shadow
					.attr('y', (_, i) => `${i * 1.1}em`)
					// eslint-disable-next-line @typescript-eslint/no-shadow
					.attr('font-weight', (_, i) => (i ? null : 'bold'))
					// eslint-disable-next-line @typescript-eslint/no-unsafe-return
					.text(d => d)
			);

			size(text, path);
		}

		function pointerLeft() {
			tooltip.style('display', 'none');
		}
	}, [data]);

	return (
		<div
			className="line-chart scrolling-wrapper"
			style={{ width: '100%', height: '100%' }}>
			<svg ref={svgRef} style={{ width: '100%', height: '100%' }}></svg>
		</div>
	);
};

export default LineChartComponent;
