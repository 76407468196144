import React, { useState } from 'react';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import axios, { AxiosInstance } from 'axios';
import { toast } from 'react-toastify';
import _ from 'lodash';
import {
	CredentialResponse,
	GoogleLogin,
	GoogleOAuthProvider,
} from '@react-oauth/google';

import {
	CreateAccount,
	ForgotPassword,
	FormControlStyled,
	FormInput,
	FormLabel,
	ModalTitle,
	StyledDivider,
} from './SignIn.styled';
import {
	DataRequest,
	IAuthorizationElementProps,
	ISignInResponseType,
} from '../Authorization/Authorization.types';
import { AUTHORIZATION_STEP, ERROR_CODES } from '../../Constants';
import servicesRequest, {
	SIGN_IN_SOCIAL_URL,
	SIGN_IN_URL,
} from '../../Config/services.config';
import { Button, VerificationModal } from '../index';
import { ErrorResponse } from '../VerificationModal/VerificationModal.types';
import { CONFIG } from '../../Config';

const SignInComponent = (props: IAuthorizationElementProps) => {
	const [isLoading, setIsLoading] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [stepOTP, setStepOTP] = useState(false);

	const togglePasswordVisibility = () => setShowPassword(prevShow => !prevShow);

	const { changeStep, showDiscoverTutorialModal, onHide, changeBackdropBehaviour } = props;
	const {
		handleSubmit,
		control,
		formState: { errors },
		getValues
	} = useForm({
		defaultValues: {
			email: '',
			password: '',
		},
	});

	const onSubmit = async (data: DataRequest) => {
		setIsLoading(true);
		try {
			const response: ISignInResponseType = await axios.post(
				SIGN_IN_URL,
				data
			);

			if (!response) {
				toast.error('Your username/password is wrong.');
				return;
			}

			const token: string = _.get(response, 'data.data.token.token', '');
			const roleResponse: string = _.get(response, 'data.data.user.role', '');

			localStorage.setItem('role', roleResponse);
			localStorage.setItem('token', token);

			toast.success('Sign in success!');
			onHide();
			props.setIsLoggedIn(true);

			window.location.reload();
		} catch (error: unknown) {
			const err = error as ErrorResponse;
			const statusCode = _.get(err, 'response.data.status_code');
			const errorMessage = _.get(err, 'response.data.message');

			if (statusCode === ERROR_CODES.VALIDATION_ERROR) {
				toast.error(
					'Something is wrong with your input. Please check and try again.'
				);
			}
			if (errorMessage === 'If you havent verified your email, check your email before continuing') {
				setStepOTP(true);
			} else {
				toast.error('Your username/password is wrong.');
			}
		} finally {
			setIsLoading(false);
		}
	};

	const signInProcessWithGoogle = async (payload: CredentialResponse) => {
		const { credential } = payload;

		const data = {
			provider: 'google',
			token: credential,
		};

		const request: AxiosInstance = servicesRequest();
		const response: ISignInResponseType = await request.post(
			SIGN_IN_SOCIAL_URL,
			data
		);

		const token: string = _.get(response, 'data.data.token.token', '');
		const roleResponse: string = _.get(response, 'data.data.user.role', '');

		localStorage.setItem('role', roleResponse);
		localStorage.setItem('token', token);

		toast.success('Sign in success!');
		onHide();
		props.setIsLoggedIn(true);

		window.location.reload();
	};

	const errorMessage = () => {
		toast.warning(
			'Sorry, we unable to find your account, please check your email and password, and try again'
		);
	};

	if (stepOTP) {
		return (
			<VerificationModal
				email={getValues('email')}
				showDiscoverTutorialModal={showDiscoverTutorialModal}
				onHide={onHide}
				changeBackdropBehaviour={changeBackdropBehaviour}
			/>
		);
	}
	return (
		<React.Fragment>
			<ModalTitle>Welcome to FinSent</ModalTitle>
			<div className="mt-4">
				<Form>
					<Form.Group className="mb-3">
						<FormLabel>Email</FormLabel>
						<Controller
							name="email"
							control={control}
							rules={{
								required: 'Please type your email',
							}}
							render={({ field: { onChange, value } }) => (
								<FormInput
									type="email"
									label="Email"
									placeholder="mail@website.com"
									onChange={onChange}
									value={value}
									isError={!!errors?.email}
								/>
							)}
						/>
						<small className="text-red">
							{errors?.email && errors.email?.message}
						</small>
					</Form.Group>

					<Form.Group className="mb-3">
						<FormLabel>Password</FormLabel>
						<InputGroup>
							<Controller
								name="password"
								control={control}
								rules={{
									required: 'Please type your password',
								}}
								render={({ field: { onChange, value } }) => (
									<FormControlStyled
										label="Password"
										value={value}
										onChange={onChange}
										isError={!!errors?.password}
										type={showPassword ? 'text' : 'password'}
										placeholder="Min 8 characters"
									/>
								)}
							/>
							<span
								className="input-group-text cursor-pointer"
								onClick={togglePasswordVisibility}>
								{showPassword ? <FaEyeSlash /> : <FaEye />}
							</span>
						</InputGroup>
						<small className="text-red">
							{errors?.password && errors.password?.message}
						</small>
					</Form.Group>
					<Row className="justify-content-end">
						<Col>
							<Form.Group className="mb-3">
								<Form.Check type="checkbox" label="Remember me" />
							</Form.Group>
						</Col>
						<Col>
							<ForgotPassword
								onClick={() => changeStep(AUTHORIZATION_STEP.FIND_PASSWORD)}>
								Find Password
							</ForgotPassword>
						</Col>
					</Row>
					<Button
						isLoading={isLoading}
						label="Sign In"
						onClick={handleSubmit(onSubmit)}
						mobilePadding="1.5vw"
					/>
				</Form>
				<CreateAccount onClick={() => changeStep(AUTHORIZATION_STEP.REGISTER)}>
					Create an account
				</CreateAccount>
				{/* <StyledDivider>
					<span>or</span>
				</StyledDivider>
				<GoogleOAuthProvider clientId={CONFIG.GOOGLE_OAUTH_CLIENT_ID}>
					<div className="d-flex flex-row justify-content-center gap-3 mt-3 mb-2">
						<div>
							<GoogleLogin
								locale='en'
								shape="square"
								onSuccess={signInProcessWithGoogle}
								onError={errorMessage}
							/>
						</div>
					</div>
				</GoogleOAuthProvider> */}
			</div>
		</React.Fragment>
	);
};

export default SignInComponent;
