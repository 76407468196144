// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useEffect, useRef } from 'react';
import * as d3 from 'd3';

import { firstFinalData, secondFinalData } from './dummyData';

export const lineColors = [
	'#225FB0',
	'#F1BF42',
	'#5DBEDC',
	'#EA8237',
	'#7A35C6',
	'#2E8503',
];

type ISentimentChartComponentProps = {
	xLabel?: string;
	yLabel?: string;
	isCurve?: boolean;
	isNoXLabel?: boolean;
	title?: string;
	data?: { x: Date; y: number }[][];
	tooltipLabel?: string;
	minYValue?: number;
	maxYValue?: number;
};

const SentimentChartComponent = (props: ISentimentChartComponentProps) => {
	const {
		isCurve = false,
		isNoXLabel = false,
		title = 'Testing Title',
		data = [firstFinalData, secondFinalData],
		tooltipLabel = 'Sentiment',
		minYValue = 0,
		maxYValue = 0,
	} = props;

	const svgRef = useRef();

	const combineData: { x: Date; y: number }[] = data.reduce(
		(acc, currentArray) => {
			return [...acc, ...currentArray];
		},
		[]
	);

	combineData.sort((a, b) => new Date(a.x) - new Date(b.x));

	const startDate = new Date(combineData[0]?.x);
	const endDate = new Date(combineData[combineData.length - 1]?.x);

	const x = d3.timeDays(startDate, endDate);

	useEffect(() => {
		const svg = d3.select(svgRef.current);

		const margin = { top: 0, right: 20, bottom: 10, left: 40 };
		const width = 450 - margin.left - margin.right;
		const height = 200 - margin.top - margin.bottom;

		const innerWidth = width - margin.left - margin.right;
		const innerHeight = height - margin.top - margin.bottom;

		const g = svg
			.append('g')
			.attr('transform', `translate(${margin.left},${margin.top})`);

		const xScale = d3
			.scaleUtc()
			.domain([d3.min(x), d3.max(x)])
			.range([0, innerWidth]);

		const xScale2 = d3
			.scaleUtc()
			.domain([d3.min(x), d3.max(x)])
			.range([0, innerWidth]);

		const yScale = d3
			.scaleLinear()
			.domain([minYValue - 30, maxYValue + 100])
			.nice()
			.range([innerHeight, 0]);

		let line = d3
			.line()
			// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
			.x(d => xScale(d.x))
			// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
			.y(d => yScale(d.y));

		if (isCurve) {
			line = line.curve(d3.curveBasis);
		}

		g.append('defs')
			.append('clipPath')
			.attr('id', 'clip')
			.append('rect')
			.attr('x', 0)
			.attr('width', innerWidth)
			.attr('height', innerHeight);

		if (!isNoXLabel) {
			g.append('g')
				.attr('class', 'x-axis')
				.attr('clip-path', 'url(#clip)')
				.attr('transform', `translate(0,${innerHeight})`)
				.call(d3.axisBottom(xScale))
				.style('font-size', '10px')
				.style('font-family', 'Inter')
				.style('color', '#4E4E4E');
		}

		g.selectAll('yGrid')
			// eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
			.data(yScale.ticks(d3.max(data, d => d.population)))
			.join('line')
			.attr('x1', 0)
			.attr('x2', width)
			.attr('y1', d => yScale(d))
			.attr('y2', d => yScale(d))
			.attr('stroke', '#e0e0e0')
			.attr('stroke-width', 0.5);

		g.append('g')
			.attr('class', 'y-axis')
			.call(
				d3
					.axisLeft(yScale)
					.ticks(10)
					// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
					.tickFormat(t => `${t}%`)
			)
			.style('font-size', '10px')
			.style('font-family', 'Inter')
			.style('color', '#4E4E4E');

		// const colorScale = d3.scaleOrdinal().range(colorList);

		const linesGroup = g.append('g').attr('clip-path', 'url(#clip)');

		function mouseOver(event, d) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
			const stockCode: string = d[0].stockCode;

			let sumPositive = 0;
			let sumNegative = 0;
			let sumNeutral = 0;
			let count = 0;

			// eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
			d.forEach(
				(entry: {
					score: { positive: number; negative: number; neutral: number };
				}) => {
					const { score } = entry;
					sumPositive += parseFloat(score.positive);
					sumNegative += parseFloat(score.negative);
					sumNeutral += parseFloat(score.neutral);
					count++;
				}
			);

			// Calculate averages
			const averagePositive = ((sumPositive / count) * 100).toFixed(1);
			const averageNegative = ((sumNegative / count) * 100).toFixed(1);
			const averageNeutral = ((sumNeutral / count) * 100).toFixed(1);

			// Show tooltip on mouseover
			const [xPos, yPos] = d3.pointer(event);
			d3.select(this).style('stroke-width', '3px');
			svg
				.append('foreignObject')
				.attr('id', 'tooltip')
				.attr('x', xPos)
				.attr('y', yPos - 15)
				.attr('width', 200) // Set the width as needed
				.attr('height', 100) // Set the height as needed
				.html(
					() => `
						<div class='d-flex align-items-center justify-content-between multi-line-tooltip py-1 px-2'>
							<p class='fs-10 p-0 m-0 text-darkblue fw-600'>${tooltipLabel}</p>
							<div class='bg-darkblue rounded p-1'>
								<p class='fs-10 p-0 m-0 fw-600 text-light'>${stockCode}</p>
							</div>
							<p class='fs-10 p-0 m-0 text-green fw-600'>${averagePositive}%</p>
							<p class='fs-10 p-0 m-0 text-red fw-600'>${averageNegative}%</p>
							<p class='fs-10 p-0 m-0 fw-600'>${averageNeutral}%</p>
						</div>
					`
				);
		}

		const updateLegends = currentData => {
			d3.selectAll('.lineLegend').remove();
			const legendKeys = Object.keys(data[0]);
			const lineLegend = svg
				.selectAll('.lineLegend')
				.data(legendKeys)
				.enter()
				.append('g')
				.attr('class', 'lineLegend')
				.attr('transform', (d, i) => {
					return `translate(0, ${i * 20})`;
				});
			lineLegend
				.append('text')
				.text(d => {
					if (d === 'date') {
						return `${d}: ${currentData[d].toLocaleDateString()}`;
					} else if (
						d === 'high' ||
						d === 'low' ||
						d === 'open' ||
						d === 'close'
					) {
						return `${d}: ${currentData[d].toFixed(2)}`;
					} else {
						return `${d}: ${currentData[d]}`;
					}
				})
				.style('fill', 'black')
				.attr('transform', 'translate(15,9)');
		};

		const focus = g
			.append('g')
			.attr('class', 'focus')
			.style('display', 'none');
		focus.append('circle').attr('r', 4.5);
		focus.append('line').classed('x', true);
		focus.append('line').classed('y', true);

		d3.selectAll('.focus line').style('fill', 'none');
		d3.selectAll('.focus line').style('stroke', '#67809f');
		d3.selectAll('.focus line').style('stroke-width', '1.5px');
		d3.selectAll('.focus line').style('stroke-dasharray', '3 3');

		// eslint-disable-next-line @typescript-eslint/unbound-method,@typescript-eslint/no-unsafe-return
		const bisectDate = d3.bisector(d => d.x).center;
		function generateCrosshair(event, d) {
			//returns corresponding value from the domain
			const correspondingDate = xScale.invert(d3.pointer(event)[0]);
			//gets insertion point
			const i = bisectDate(d, correspondingDate, 1);
			const d0 = d[i - 1];
			const d1 = d[i];

			const currentPoint =
				correspondingDate - d0.x > d1.x - correspondingDate ? d1 : d0;

			focus.attr(
				'transform',
				`translate(${xScale(currentPoint.x)},${yScale(currentPoint.y)})`
			);
			focus
				.select('line.x')
				.attr('x1', 0)
				.attr('x2', width - xScale(currentPoint.x))
				.attr('y1', 0)
				.attr('y2', 0);
			focus
				.select('line.y')
				.attr('x1', 0)
				.attr('x2', 0)
				.attr('y1', 0)
				.attr('y2', height - yScale(currentPoint.y));

			updateLegends(currentPoint);
		}

		g.append('text')
			.attr('class', 'y label')
			.attr('text-anchor', 'middle')
			.attr('y', -45)
			.attr('x', -height / 3)
			.attr('dy', '.5em')
			.attr('transform', 'rotate(-90)')
			.style('font-size', '12px')
			.style('font-family', 'Inter')
			.style('color', '#4E4E4E')
			.text(title);

		linesGroup
			.selectAll('.line')
			.data(data)
			.enter()
			.append('path')
			.attr('class', 'line')
			.attr('d', d => line(d))
			.style('stroke', (_, i) => {
				return data[i][0]?.color;
			})
			.on('mouseover', mouseOver)
			.on('mouseout', function () {
				d3.select(this).style('stroke-width', '1.5px');
				svg.select('#tooltip').remove();
			})
		// .on('mousemove', generateCrosshair);

		const zoom = d3
			.zoom()
			.scaleExtent([1, 5])
			.on('zoom', event => {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
				const newXScale = event.transform.rescaleX(xScale2);
				// eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
				xScale.domain(newXScale.domain());

				d3.select(svgRef.current).selectAll('.line').remove();
				d3.select(svgRef.current).selectAll('.x-axis').remove();
				linesGroup.select('.line').remove();
				d3.select(svgRef.current).selectAll('#tooltip').remove();

				linesGroup
					.selectAll('.line')
					.data(data)
					.enter()
					.append('path')
					.attr('class', 'line')
					.attr('d', d => line(d))
					.style('stroke', (_, i) => data[i][0]?.color)
					// eslint-disable-next-line @typescript-eslint/no-shadow
					.on('mouseover', mouseOver)
					.on('mouseout', function () {
						// Hide tooltip on mouseout
						d3.select(this).style('stroke-width', '1.5px');
						svg.select('#tooltip').remove();
					})
				// .on('mousemove', generateCrosshair);

				if (!isNoXLabel) {
					g.append('g')
						.attr('class', 'x-axis')
						.attr('clip-path', 'url(#clip)')
						.attr('transform', `translate(0,${innerHeight})`)
						// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
						.call(d3.axisBottom(newXScale))
						.style('font-size', '10px')
						.style('font-family', 'Inter')
						.style('color', '#4E4E4E');
				}
			});

		svg.call(zoom);
	}, []);

	return (
		<div className="w-100 d-flex justify-content-start ps-3">
			<svg ref={svgRef} height={200} style={{ overflow: 'visible' }} />
		</div>
	);
};

export default SentimentChartComponent;
