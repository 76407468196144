// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect, useState } from 'react';

import { RIGHT_TAB } from '../SingleReport/SingleReport.screen';
import {
	CompanyName,
	ShowMobileOnly,
	TopSectionMobile,
} from '../SingleReport/SingleReport.styled';
import RightTabComponent from '../../Components/SingleReport/RightTab.component';
import {
	renderFloatingComponent,
	ScrollAppearComponent,
} from '../SingleReport/SingleReport.mobile.screen';
import {
	CanvasSentimentStyled,
	WhiteContainer,
} from '../Company/Company.styled';
import YearFooter from '../../Components/Company/YearFooter.component';
import KeywordTable from '../../Components/Company/KeywordTable.component';
import { IndustryList, LoadingComponent } from './Industries.desktop.screen';
import { IIndustryDesktop } from './Industries.types';
import EarningCallTable from '../../Components/Company/EarningTable.component';
import { LineChart } from '../../Components';
import { convertToPercentage, getCallsLabel } from '../../Utils';
import { FaExpandAlt } from 'react-icons/fa';
import { renderDisclaimer } from '../../Components/SingleReport';

const IndustriesMobile = (props: IIndustryDesktop) => {
	const [selectedRightTab, setSelectedRightTab] = useState(RIGHT_TAB.OVERVIEW);
	const [isCompanyListOpen, setIsCompanyListOpen] = useState(false);
	const [isVisible, setIsVisible] = useState(false);

	const {
		keywordList,
		earningCalls,
		industryType,
		industryCode,
		setIndustryCode,
		list: industryList,
		setList: setIndustryList,
		data: industry,
		setData: setIndustry,
		paginationData,
		setPaginationData,
		onChangePagination,
		tabType: industryListType,
		setTabType: setIndustryListType,
		documentList,
		setDocumentList,
		tablePaginationData,
		setTablePaginationData,
		setDocument,
		document,
		documentId,
		setDocumentId,
		onDocumentUrlClicked,
		getDocumentList,
		onClickFilter,
		startDate,
		setStartDate,
		finishDate,
		setFinishDate,
		onChangeDate,
		scoresList,
		onClickConfirm,
		scoresAverage,
		filter,
		setFilter,
		navigateToCompare,
		onChangeIndustryCode,
		documentType,
		topCompanies,
	} = props;

	const scoresListData =
		(scoresList &&
			scoresList.map(data => ({
				date: new Date(data?.date),
				value: convertToPercentage(
					Number(data?.score?.positive) - Number(data?.score?.negative)
				),
			}))) ||
		null;

	const onChangeRightTab = (selectedTab: string): void => {
		setSelectedRightTab(selectedTab);
	};

	const onClickIcon = () => {
		setIsCompanyListOpen(true);
	};

	const onCloseIcon = () => {
		setIsCompanyListOpen(false);
	};

	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY * window.innerWidth) {
				setIsVisible(true);
			} else {
				setIsVisible(false);
			}
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	if (isCompanyListOpen) {
		return (
			<IndustryList
				isSmallerScreen={true}
				industryCode={industryCode}
				setIndustryCode={setIndustryCode}
				industryList={industryList}
				setIndustryList={setIndustryList}
				industry={industry}
				setIndustry={setIndustry}
				paginationData={paginationData}
				setPaginationData={setPaginationData}
				onChangePagination={onChangePagination}
				industryListType={industryListType}
				setIndustryListType={setIndustryListType}
				onClickConfirm={onClickConfirm}
				onCloseIcon={onCloseIcon}
				onChangeIndustryCode={onChangeIndustryCode}
			/>
		);
	}
	return (
		<React.Fragment>
			<ShowMobileOnly>
				<div className="bg-grey pt-2">
					<WhiteContainer className="px-3 py-3">
						<RightTabComponent
							finishDate={finishDate}
							startDate={startDate}
							documents={document}
							selectedRightTab={selectedRightTab}
							onChangeRightTab={onChangeRightTab}
							isCompanyPage
							pageType='Industry'
							scoreType='COMPANY'
							onClickIcon={onClickIcon}
							companies={industry}
							scoresAverage={scoresAverage}
							listFilterLabel="Show Industry List"
							navigateToCompare={navigateToCompare}
							isIndustryPage={true}
							documentType={documentType}
							topCompanies={topCompanies}
						/>
						<ScrollAppearComponent style={{ top: '40px' }} isVisible={isVisible}>
							{/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
							// @ts-ignore */}
							{renderFloatingComponent({
								documents: document,
								selectedRightTab,
								setSelectedRightTab,
								scoresAverage,
								companies: industry,
								industry,
								isIndustryPage: true,
								industryType,
								documentType,
							})}
						</ScrollAppearComponent>
					</WhiteContainer>
					<div className="mt-2 px-3 py-3 bg-white">
						<WhiteContainer className="w-100">
							<CompanyName className="p-md-1 m-0 d-flex align-items-center">
								<h5 className="fs-16 fw-600 m-0 my-2">
									Sentiment score by {industry?.name}
								</h5>
							</CompanyName>
							<CanvasSentimentStyled>
								<div
									className="d-flex align-items-center position-absolute end-0 me-3"
									onClick={props.navigateToCompare}
									style={{ cursor: 'pointer' }}>
									<h5 className="fs-10 fw-400 mb-0 me-1">Advanced </h5>
									<div className="bg-lightgrey rounded px-1">
										<FaExpandAlt className="fs-10" />
									</div>
								</div>
								{scoresListData ? (
									<LineChart
										data={scoresListData}
										isGradient
										title="Sentiment"
										isSmallerScreen={false}
									/>
								) : (
									<LoadingComponent />
								)}
							</CanvasSentimentStyled>
						</WhiteContainer>
					</div>
					<div className="px-3 bg-white">
						<YearFooter
							startDate={startDate}
							setStartDate={setStartDate}
							finishDate={finishDate}
							setFinishDate={setFinishDate}
							onChangeDate={onChangeDate}
						/>
					</div>
					<div className="mt-2 px-3 py-2 bg-white">
						{documentList ? <EarningCallTable
							pageType='Industry'
							// onClick={onClickFilter}
							earningCalls={earningCalls}
							documentList={documentList}
							setDocumentList={setDocumentList}
							tablePaginationData={tablePaginationData}
							setTablePaginationData={setTablePaginationData}
							onChangePagination={value => {
								getDocumentList(`/?page=${value}`)();
							}}
							document={document}
							setDocument={setDocument}
							documentId={documentId}
							setDocumentId={setDocumentId}
							onDocumentUrlClicked={onDocumentUrlClicked}
							// isDropdown={true}
							items={[
								{
									label: 'Ascending',
									key: '1',
								},
								{ label: 'Descending', key: '2' },
							]}
							isMobileVersion={true}
							filter={filter}
							setFilter={setFilter}
							componentTitle={getCallsLabel(documentType)}
						/> : <LoadingComponent />}
					</div>
					<WhiteContainer className="mt-2 py-2">
						<KeywordTable
							keywordList={keywordList}
							keywordPagination={{ current_page: 1 }}
							navigateToCompare={navigateToCompare}
						/>
					</WhiteContainer>
					<div className="mt-2 bg-white">
						{renderDisclaimer()}
					</div>
				</div>
			</ShowMobileOnly>
		</React.Fragment>
	);
};

export default IndustriesMobile;
