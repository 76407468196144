import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AxiosInstance } from 'axios';

import {
	EditButton,
	FormLabel,
	FormValue,
	LabelContainer,
	LabelParent,
} from './Preferences.component.styled';
import ChangeNameComponent from './Components/ChangeName.component';
import ChangeEmailComponent from './Components/ChangeEmail.component';
import { Button } from '../index';
import { UserSettingType } from '../../Screens/Preferences/Preferences.types';
import servicesRequest, {
	PASSWORD_RESET_REQUEST_URL,
} from '../../Config/services.config';
import { BUTTON_TYPE } from '../Button/Button.component';

export type IValue = {
	target: {
		value: string;
	};
};

const EDIT_MODE = {
	CHANGE_NAME: 'CHANGE_NAME',
	CHANGE_EMAIL: 'CHANGE_EMAIL',
	CHANGE_PASSWORD: 'CHANGE_PASSWORD',
};

const UserSettingComponent = (props: UserSettingType): React.JSX.Element => {
	const [editMode, setEditMode] = useState('');
	const [isChange, setIsChange] = useState(false);

	const { firstName, lastName, fullName, email, changeShouldReload } = props;
	useNavigate();

	const dummyUserProfile = {
		lastUpdated: '',
	};

	const onChangeMode = (value: string) => {
		if (value === editMode) {
			if (isChange) toast.warning('Confirm closing this section?')
			else setEditMode('');
		}
		else if (isChange) {
			toast.warning('Confirm closing this section?')
		}
		else {
			setEditMode(value);
		}
		setIsChange(false)
	};

	const requestResetPassword = async () => {
		try {
			const request: AxiosInstance = servicesRequest();
			await request.post(PASSWORD_RESET_REQUEST_URL, { email });
			toast.success('Please check your email to complete the password reset process.');
		} catch (error) {
			toast.error('There seems to be an issue with your email. Please double-check and try again.');
		}
	};

	return (
		<React.Fragment>
			<LabelParent>
				<LabelContainer>
					<div>
						<FormLabel>Full Name</FormLabel>
						<FormValue>{fullName}</FormValue>
					</div>
					<EditButton onClick={() => onChangeMode(EDIT_MODE.CHANGE_NAME)}>
						{editMode === EDIT_MODE.CHANGE_NAME ? 'Close' : 'Edit'}
					</EditButton>
				</LabelContainer>
				{editMode === EDIT_MODE.CHANGE_NAME && (
					<ChangeNameComponent
						lastName={lastName}
						firstName={firstName}
						email={email}
						setEditMode={setEditMode}
						changeShouldReload={changeShouldReload}
						setIsChange={setIsChange}
						isChange={isChange}
					/>
				)}
			</LabelParent>

			<LabelParent>
				<LabelContainer>
					<div>
						<FormLabel>Change Email</FormLabel>
						<FormValue>{email}</FormValue>
					</div>
					<EditButton onClick={() => onChangeMode(EDIT_MODE.CHANGE_EMAIL)}>
						{editMode === EDIT_MODE.CHANGE_EMAIL ? 'Close' : 'Edit'}
					</EditButton>
				</LabelContainer>
				{editMode === EDIT_MODE.CHANGE_EMAIL && (
					<ChangeEmailComponent
						lastName={lastName}
						firstName={firstName}
						email={email}
						setEditMode={setEditMode}
						changeShouldReload={changeShouldReload}
						setIsChange={setIsChange}
						isChange={isChange}
					/>
				)}
			</LabelParent>

			<LabelParent>
				<LabelContainer>
					<div>
						<FormLabel>Change Password</FormLabel>
						<FormValue>{dummyUserProfile.lastUpdated}</FormValue>
					</div>
					<EditButton onClick={() => onChangeMode(EDIT_MODE.CHANGE_PASSWORD)}>
						{editMode === EDIT_MODE.CHANGE_PASSWORD ? 'Close' : 'Edit'}
					</EditButton>
				</LabelContainer>
				{editMode === EDIT_MODE.CHANGE_PASSWORD && (
					<div className="mb-2">
						<Button
							label="Send password reset email"
							width="40%"
							onClick={requestResetPassword}
							kind={BUTTON_TYPE.PRIMARY}
						/>
					</div>
				)}
			</LabelParent>
		</React.Fragment>
	);
};

export default UserSettingComponent;
