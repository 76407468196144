import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import _ from 'lodash';
import moment from 'moment';

import servicesRequest, {
	COMPANY_URL,
	DOCUMENT_LIST_URL,
	DOCUMENT_URL,
	ESG9_URL,
	INDUSTRY_LIST_URL,
	KEYWORD_LIST_URL,
	SCORE_LIST_URL,
	SCORES_URL,
	STOCK_PRICES_URL,
} from '../Config/services.config';
import { DATA_TYPE, SORT_DATE } from '../Constants';
import { LIST_TYPE } from '../Screens/Industries/Industries.screen';
import { getDefaultMarket } from '../Utils';

interface IUseGetListData {
	dataType: {
		field: string;
		value: string;
	};
	documentType: string;
	id: number | string;
	onChangeCompanyId?: (companyId: string) => void;
	startDateParams?: string | null;
	endDateParams?: string | null;
	onChangeDate?: (startDate: string, endDate: string) => void;
}

interface IDataType {
	id?: string;
	industry_code?: string;
}

const { COMPANY } = DATA_TYPE;

const useGetListData = ({
	dataType,
	documentType,
	id,
	startDateParams = SORT_DATE.FIVE_YEARS.gte,
	endDateParams = SORT_DATE.FIVE_YEARS.lte,
}: IUseGetListData) => {
	const isCompany = dataType?.field === COMPANY.field;

	const [tabType, setTabType] = useState(LIST_TYPE.TRENDING);
	const [list, setList] = useState(null);

	const [data, setData] = useState<IDataType>({
		id: undefined,
		industry_code: undefined,
	});
	const [paginationData, setPaginationData] = useState({
		last_page: 1,
		current_page: 1,
	});

	const [documentList, setDocumentList] = useState(null);
	const [document, setDocument] = useState();
	const [documentId, setDocumentId] = useState('');
	const [tablePaginationData, setTablePaginationData] = useState({
		last_page: 1,
		current_page: 1,
	});
	const [keywordList, setKeywordList] = useState([]);

	const [startDate, setStartDate] = useState(startDateParams);
	const [finishDate, setFinishDate] = useState(endDateParams);

	const [scoresList, setScoresList] = useState(null);
	const [stockPrices, setStockPrices] = useState(null);
	const [scoresAverage, setScoresAverage] = useState(null);
	const [esg9Average, setEsg9Average] = useState(null);

	const [filter, setFilter] = useState(null);

	const listUrl = isCompany ? COMPANY_URL : INDUSTRY_LIST_URL;
	const dataTypeId = id || _.get(data, dataType?.value, '');

	const request = servicesRequest();

	const getList =
		(pageUrl = '/?page=1') =>
			() => {
				if (pageUrl !== null && tabType !== LIST_TYPE.SELECT_INDUSTRY) {
					setList(null)
					const url = `${listUrl}/${tabType}${pageUrl}&country_id=${getDefaultMarket()}&document_type=${documentType}&limit=10
					&document_date.gte=${startDate}&document_date.lte=${finishDate}
					${tabType === LIST_TYPE.TRENDING ? `&trending_date.gte=${moment(finishDate).subtract(30, 'days').format('YYYY-MM-DD')}&trending_date.lte=${finishDate}` : ''}`;

					request
						.get(url)
						.then((response: any) => {
							const responseData = _.get(response, 'data.data.data', []);
							const meta = _.get(response, 'data.data.meta', []);
							setList(responseData);
							setPaginationData(meta);
							if (!id) {
								setData(responseData[0]);
							}
						})
						.catch(() =>
							console.error('Something went wrong. Please refresh the page.')
						);
				}
			};

	const getDocumentList =
		(pageUrl = '/?page=1') =>
			() => {
				if (pageUrl !== null) {
					setDocumentList(null)
					const url = !filter
						? `${DOCUMENT_LIST_URL}${pageUrl}&${dataType?.field}=${dataTypeId}&country_id=${getDefaultMarket()}&document_type=${documentType}&limit=10`
						: `${DOCUMENT_LIST_URL}${pageUrl}&${dataType?.field}=${dataTypeId}&country_id=${getDefaultMarket()}&document_type=${documentType}&limit=10&order_by=${filter}`;
					request
						.get(url)
						.then((response: any) => {
							const responseData = _.get(response, 'data.data.data', []);
							const meta = _.get(response, 'data.data.meta', []);

							setDocumentList(responseData);
							setDocumentId(responseData[0].id);
							setTablePaginationData(meta);
						})
						.catch(() =>
							console.error('Something went wrong. Please refresh the page.')
						);
				}
			};

	const getKeywordList = () => {
		const url = `${KEYWORD_LIST_URL}?${dataType?.field}=${dataTypeId}&country_id=${getDefaultMarket()}&document_type=${documentType}&limit=10&document_date.gte=${startDate}&document_date.lte=${finishDate}`;
		request
			.get(url)
			.then((response: any) => {
				const responseData = _.get(response, `data.data.${documentType}`, []);
				setKeywordList(responseData);
			})
			.catch(() =>
				console.error('Something went wrong. Please refresh the page.')
			);
	};

	const getScoresList = () => {
		if (dataTypeId) {
			setScoresList(null)
			const url =
				startDate && finishDate
					? `${SCORE_LIST_URL}?${dataType?.field}=${dataTypeId}&country_id=${getDefaultMarket()}&document_type=${documentType}&document_date.gte=${startDate}&document_date.lte=${finishDate}`
					: `${SCORE_LIST_URL}?${dataType?.field}=${dataTypeId}&country_id=${getDefaultMarket()}&document_type=${documentType}`;
			request
				.get(url)
				.then((response: any) => {
					const responseData = _.get(
						response,
						`data.data[0].scores.${documentType}`,
						[]
					);
					setScoresList(responseData);
				})
				.catch(() =>
					console.error('Something went wrong. Please refresh the page.')
				);
		}
	};

	const getStockPricesList = () => {
		if (dataTypeId) {
			setStockPrices(null);
			const url =
				startDate && finishDate
					? `${STOCK_PRICES_URL}?${dataType?.field}=${dataTypeId}&country_id=${getDefaultMarket()}&sort=asc&document_type=${documentType}&date.gte=${startDate}&date.lte=${finishDate}`
					: `${STOCK_PRICES_URL}?${dataType?.field}=${dataTypeId}&country_id=${getDefaultMarket()}&sort=asc&document_type=${documentType}`;
			request
				.get(url)
				.then((response: any) => {
					const allStockPrices = _.get(
						response,
						'data.data.data[0].stock_prices',
						[]
					);
					const filteredStockPrices = allStockPrices.filter((stockPrice: { t: string }) => {
						const stockDate = moment(stockPrice.t);
						return stockDate.isAfter(moment(startDate)) && stockDate.isBefore(moment(finishDate));
					});
					setStockPrices(filteredStockPrices);
				})
				.catch(() =>
					console.error('Something went wrong. Please refresh the page.')
				);
		}
	};

	const getDocument = () => {
		let url = `${DOCUMENT_URL}/${documentId}?`;

		url += `document_date.gte=${startDate}&document_date.lte=${finishDate}`;
		request
			.get(url)
			.then((response: any) => {
				const responseData = _.get(response, 'data.data', '');

				setDocument(responseData);
			})
			.catch(() =>
				console.error('Something went wrong. Please refresh the page.')
			);
	};

	const getEsg9Average = () => {
		const url = `${ESG9_URL}/average?${dataType?.field}=${dataTypeId}&country_id=${getDefaultMarket()}&document_type=${documentType}&document_date.gte=${startDate}&document_date.lte=${finishDate}`;

		request
			.get(url)
			.then((response: any) => {
				const responseData = _.get(response, `data.data.${documentType}`, []);
				setEsg9Average(responseData);
			})
			.catch(() =>
				console.error('Something went wrong. Please refresh the page.')
			);
	};

	const getScoresAverage = () => {
		if (dataTypeId) {
			request
				.get(
					`${SCORES_URL}/average?document_type=${documentType}&${dataType?.field}=${dataTypeId}&document_date.gte=${startDate}&document_date.lte=${finishDate}`
				)
				.then((response: any) => {
					const responseData = _.get(response, 'data.data', []);
					setScoresAverage(responseData);
				})
				.catch(() =>
					console.error('Something went wrong. Please refresh the page.')
				);
		}
	};

	useEffect(() => getList()(), [tabType, documentType]);
	useEffect(() => {
		if (dataTypeId) {
			getDocumentList()();
			getKeywordList();
			getScoresList();
			getEsg9Average();
			if (isCompany) getStockPricesList();
			if (!isCompany) getScoresAverage();
		}
	}, [dataTypeId, documentType]);

	useEffect(() => {
		if (documentId) {
			getDocument();
			if (isCompany) getScoresAverage();
		}
	}, [documentId, documentType]);

	useEffect(() => {
		if (startDate && finishDate) {
			getScoresList();
			if (isCompany) getStockPricesList();
		}
	}, [startDate, finishDate, documentType, startDateParams, endDateParams]);

	useEffect(() => {
		if (filter) {
			getDocumentList()();
		}
	}, [filter, documentType]);

	return {
		keywordList,
		list,
		setTabType,
		tabType,
		paginationData,
		data,
		setData,
		documentList,
		setDocumentList,
		tablePaginationData,
		setTablePaginationData,
		setDocument,
		document,
		documentId,
		setDocumentId,
		scoresAverage,
		startDate,
		setStartDate,
		finishDate,
		setFinishDate,
		scoresList,
		setScoresList,
		stockPrices,
		setStockPrices,
		getList,
		getDocumentList,
		filter,
		setFilter,
		id,
		esg9Average,
	};
};

export default useGetListData;
