import React from 'react';
import { Pagination } from 'antd';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';

import {
	ComponentContainer,
	Container,
	RightTabContainer,
	RightTabItem,
	RootContainer,
} from '../SingleReport/SingleReport.styled';
import { LeftContainer } from './Home.styled';
import { WhiteContainer, YearTab } from '../Company/Company.styled';
import { renderDisclaimer } from '../../Components/SingleReport';
import { IPaginationMeta, ITrendingReportsData } from './Home.types';
import CompanyChartListComponent from '../../Components/Home/CompanyChartList.component';
import { LIST_TYPE } from '../Industries/Industries.screen';
import { LIST_TYPE_SECTOR } from './Home.screen';
import { FlexDisplaySmallGap } from '../Preferences/Preferences.styled';
import WatchlistTableComponent from '../../Components/Home/WatchlistTable.component';
import KeywordListTableComponent from '../../Components/Home/KeywordListTable.component';
import WatchlistModalComponent from '../../Components/Home/WatchlistModal.component';
import EarningCallTable from '../../Components/Company/EarningTable.component';
import {
	ColoredLabel,
	LoadingComponent,
} from '../Industries/Industries.desktop.screen';
import { ROUTES } from '../../Constants/routes';
import { Popover } from '../../Components';


const HomeDesktop = (props: any) => {
	const {
		trendingReports,
		onChangePage,
		trendingData,
		documentType,
		selectedType,
		setSelectedType,
		selectedListType,
		setSelectedListType,
		watchlists,
		selectedWatchlist,
		setSelectedWatchlist,
		fetchingWatchlistDetail,
		selectedWatchlistDetails,
		setIsShowWatchlistModal,
		filter,
		setFilter,
		onClickCard,
	} = props;

	const navigate = useNavigate();

	const trendingReportsData: ITrendingReportsData[] = _.get(
		trendingReports,
		'data',
		null
	);

	const trendingReportsMeta: IPaginationMeta = _.get(
		trendingReports,
		'meta',
		{}
	);

	const token = localStorage.getItem('token');
	const trendingList = _.get(trendingData, 'data', null);
	const trendingMeta = _.get(trendingData, 'meta', {});

	const currentPage = _.get(trendingMeta, 'current_page', 1);
	const total = _.get(trendingMeta, 'total', 1);

	const watchlistData = _.get(watchlists, 'data', []);
	const listType = selectedListType === LIST_TYPE_SECTOR.COMPANIES ? 'companies' : 'industries'

	return (
		<React.Fragment>
			<RootContainer className="mb-0 pb-2 p-2">
				<div className="d-flex align-items-stretch h-100">
					<Container md={7} className="rounded mh-100">
						<LeftContainer className="h-100">
							<Container md={8} style={{}} className="position-relative">
								<div className={`position-absolute end-0 top-0 px-1`}>
									<Popover
										popoverTextHeader="**Edit Watchlist**"
										popoverTextBody={`Click to add or modify your watchlist and track your favorite companies. Click on the company name to navigate to the respective company.`}
									/>
								</div>
								<WhiteContainer className="rounded py-3 mh-100">
									<RightTabContainer className="d-flex justify-content-between px-3">
										<FlexDisplaySmallGap>
											{watchlistData.length > 0 ?
												watchlistData?.map(
													(item: { name: string }, index: number) => (
														<RightTabItem
															onClick={async () => {
																setSelectedWatchlist(item);
																await fetchingWatchlistDetail(item);
															}}
															selected={item === selectedWatchlist}
															key={index}>
															{item?.name}
														</RightTabItem>
													)
												) :
												<RightTabItem selected={true}>
													Watchlist
												</RightTabItem>
											}
										</FlexDisplaySmallGap>
										{token && <h5
											className="fs-10 fw-600 standard-code bg-blue text-darkblue cursor-pointer m-0"
											onClick={() => {
												setIsShowWatchlistModal(true);
											}}>
											Edit Watchlist
										</h5>}
									</RightTabContainer>
									<WatchlistTableComponent {...props} />
								</WhiteContainer>
							</Container>
							<Container
								className="margin-left-8"
								style={{}}>
								<WhiteContainer className="rounded py-3 mh-100">
									<RightTabContainer className="d-flex g-2 align-items-start px-3">
										<RightTabItem selected={true}>
											Top Keywords
											<Popover
												className="ps-1"
												popoverTextHeader="**Top Keywords**"
												popoverTextBody={`View the most frequently used words in the reports of selected companies from the past five years. \
												\n\nUpdate the keywords by clicking on the sentiment score.`}
											/>
										</RightTabItem>
										{selectedWatchlistDetails?.code && (
											<ColoredLabel
												sector={selectedWatchlistDetails?.code}
												className="text-white standard-code fw-600 fs-10">
												{selectedWatchlistDetails?.code}
											</ColoredLabel>
										)}
									</RightTabContainer>
									<KeywordListTableComponent {...props} />
								</WhiteContainer>{' '}
							</Container>
							<Container md={12}>
								<WhiteContainer className="rounded py-1 h-100">
									<EarningCallTable
										pageType='Home'
										componentTitle="Trending Reports"
										documentList={trendingReportsData}
										tablePaginationData={trendingReportsMeta}
										onChangePagination={page =>
											onChangePage({ trendingReportsParam: page })
										}
										filter={filter}
										setFilter={setFilter}
										isWithCompanyDetail
										isReportOrder
										isHomePage
										onClickViewMore={() => {
											navigate(`/${ROUTES.REPORT}`);
										}}
									/>
								</WhiteContainer>{' '}
							</Container>
						</LeftContainer>
					</Container>
					{/* Right Container */}
					<Container className="rounded margin-left-8">
						<WhiteContainer className="rounded p-2">
							<div className="d-flex justify-content-between align-items-center">
								<div className="d-flex gap-1">
									<YearTab
										selected={selectedType === LIST_TYPE.TRENDING}
										onClick={() => setSelectedType(LIST_TYPE.TRENDING)}>
										Trending
										{selectedType === LIST_TYPE.TRENDING && <Popover
											className='ps-1'
											popoverTextHeader="**Trending Companies**"
											popoverTextBody={`Lists ${listType} currently popular based on user clicks in the past 30 days.`}
										/>}
									</YearTab>
									<YearTab
										selected={selectedType === LIST_TYPE.MOVERS_UP}
										onClick={() => setSelectedType(LIST_TYPE.MOVERS_UP)}>
										Movers Up
										{selectedType === LIST_TYPE.MOVERS_UP && <Popover
											className='ps-1'
											popoverTextHeader="**Movers Up**"
											popoverTextBody={`Highlights ${listType} with increasing sentiment scores.`}
										/>}
									</YearTab>
									<YearTab
										selected={selectedType === LIST_TYPE.MOVERS_DOWN}
										onClick={() => setSelectedType(LIST_TYPE.MOVERS_DOWN)}>
										Movers Down
										{selectedType === LIST_TYPE.MOVERS_DOWN && <Popover
											className='ps-1'
											popoverTextHeader="**Movers Down**"
											popoverTextBody={`Highlights ${listType} with decreasing sentiment scores`}
										/>}
									</YearTab>
								</div>
								<div className='d-flex flex-row'>
									<div
										className="fs-10 fw-600 bg-blue text-darkblue standard-code cursor-pointer"
										onClick={() => {
											if (selectedListType === LIST_TYPE_SECTOR.COMPANIES) {
												setSelectedListType(LIST_TYPE_SECTOR.INDUSTRIES);
											} else {
												setSelectedListType(LIST_TYPE_SECTOR.COMPANIES);
											}
										}}>
										{selectedListType === LIST_TYPE_SECTOR.COMPANIES
											? 'Company'
											: 'Industry'}
										<Popover
											className='ps-1'
											popoverTextHeader="**Industry/Company Switch**"
											popoverTextBody={`Toggle between viewing data for industries or companies.`}
										/>
									</div>
								</div>
							</div>
						</WhiteContainer>
						<div className="row g-1" style={{ marginTop: '0px' }}>
							{trendingList ? (
								trendingList?.map((item: any, index: number) => (
									<CompanyChartListComponent
										data={item}
										documentType={documentType}
										key={index}
										onClickCard={onClickCard}
									/>
								))
							) : (
								<div style={{ minHeight: '100vh' }}>
									<LoadingComponent />
								</div>
							)}
							<Container md={12}>
								<WhiteContainer className="rounded p-1">
									<center>
										<Pagination
											size="small"
											showSizeChanger={false}
											defaultCurrent={currentPage}
											total={total}
											onChange={page => {
												onChangePage({ companyPageParam: page });
											}}
										/>
									</center>
								</WhiteContainer>
							</Container>
						</div>
					</Container>
				</div>
				<ComponentContainer className="margin-top-8">
					<Container
						// md={9} 
						className="rounded">
						{renderDisclaimer()}
					</Container>
				</ComponentContainer>
			</RootContainer>
			<WatchlistModalComponent {...props} />
		</React.Fragment>
	);
};

export default HomeDesktop;
