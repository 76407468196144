import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';

import { IAuthorizationProps } from '../Authorization/Authorization.types';
import { ModalBody } from '../Authorization/Authorization.styled';
import { AUTHORIZATION_STEP, SUBSCRIPTION_TYPE } from '../../Constants';
import { SubscriptionFeatures } from '../Preferences/Subscription.component';
import { FlexDisplayFlexEnd } from '../../Screens/Preferences/Preferences.styled';
import ButtonComponent, { BUTTON_TYPE } from '../Button/Button.component';

export const Title = styled.h3`
	color: var(--current-light-black, #000);
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	letter-spacing: -0.96px;
`;

const SubTitle = styled.h6`
	color: #4e4e4e;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 20px;
`;

const SubscriptionModalComponent = (
	props: IAuthorizationProps
): React.JSX.Element => {
	const [selectedPlan, setSelectedPlan] = useState(0);

	return (
		<Modal
			size="lg"
			show={props.isSubscriptionModal}
			onHide={() => {
				props.setIsSubscriptionModal(false);
			}}
			centered={true}>
			<ModalBody>
				<Title>Welcome to FinSent</Title>
				<SubTitle>Choose your membership</SubTitle>
				<SubscriptionFeatures
					currentPlan={SUBSCRIPTION_TYPE.DEMO}
					onSelect={value => {
						setSelectedPlan(value.id);
					}}
					updateSelectedPlan={() => { }}
				/>

				<FlexDisplayFlexEnd className="mt-3 gx-1">
					<ButtonComponent
						onClick={() => {
							props.setIsSubscriptionModal(false);
						}}
						kind={BUTTON_TYPE.SECONDARY}
						width="25%"
						mobileWidth="50%"
						label="Continue to Demo"
					/>
					<ButtonComponent
						onClick={() => {

							if (selectedPlan === 7) {
								window.open('https://cbsa.hkust.edu.hk', '_blank')
							} else {
								props.setIsSubscriptionModal(false);
								props.setIsOpenAuthorization(true);
								props.setAuthorizationStep(AUTHORIZATION_STEP.REGISTER);
							};

						}}
						width="25%"
						mobileWidth="50%"
						label={selectedPlan === 7 ? "Contact CBSA" : "Upgrade"}
						disabled={selectedPlan === 0}
					/>

				</FlexDisplayFlexEnd>
			</ModalBody>
		</Modal>
	);
};

export default SubscriptionModalComponent;
