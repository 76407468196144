import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { RIGHT_TAB } from './SingleReport.screen';
import RightTabComponent, {
	calculateOverview,
	calculateSentiment,
	DonutLegend,
	LegendContainer,
	SENTIMENT_MENU_TAB,
} from '../../Components/SingleReport/RightTab.component';
import LeftTabComponent, {
	renderTab,
} from '../../Components/SingleReport/LeftTab.component';
import SentimentComponent from '../../Components/SingleReport/Sentiment.component';
import FilingsComponent from '../../Components/SingleReport/Filings.component';

import {
	CompanyName,
	CompanyPercentage,
	ExpandIcon,
	FloatingContainer,
	LabelPercentage,
	LabelReportFloating,
	MobileSection,
	NarrowIcon,
	ShowMobileOnly,
	TopSectionMobile,
} from './SingleReport.styled';

import { ISingleReport } from './SingleReport.types';
import {
	FlexDisplayFlexEnd,
	FlexDisplaySmallGap,
	FlexDisplaySpaceBetween,
	FlexDisplaySpaceBetweenNormal,
} from '../Preferences/Preferences.styled';
import {
	calculateESG,
	renderDisclaimer,
	renderInformation,
} from '../../Components/SingleReport';

export const ScrollAppearComponent = styled.div<{ isVisible: boolean }>`
	display: ${({ isVisible }) => (isVisible ? '' : 'none')};
	transform: translateY(${({ isVisible }) => (isVisible ? '0' : '5vw')})
		translateX(-50%);
	transition: opacity 0.5s, transform 0.05s;
	position: fixed;
	top: 3rem;
	left: 50%;
	width: 95%;
	z-index: 1000;
`;

const FloatingOverview = styled(FlexDisplaySpaceBetween)`
	background-color: white;
	padding: 1vh 2vh;
	border-radius: 4px;
	border: 1px solid #e0e0e0;
	box-shadow: 2px 2px 16px 0 rgba(0, 0, 0, 0.08);
	margin: 1.5vh;
`;

const CompanyPercentageReformat = styled(CompanyPercentage)`
	color: #0a0a0a;
	font-size: 26px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
`;

const CompanyNameReformat = styled(CompanyName)`
	color: #4e4e4e;
	font-size: 10px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
`;

const FloatingComponent = styled(FlexDisplaySpaceBetweenNormal)`
	border-radius: 4px;
	background: #fff;
	box-shadow: 2px 2px 16px 0 rgba(0, 0, 0, 0.08);
	padding: 1vh;
`;

const renderFloatingSentiment = (props: ISingleReport) => {
	const { positive, negative, neutral } = calculateSentiment(
		props,
		SENTIMENT_MENU_TAB.COMPANY,
	);

	return (
		<FloatingComponent className="mt-2">
			<LegendContainer>
				<FlexDisplaySmallGap>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="8"
						height="9"
						viewBox="0 0 8 9"
						fill="none">
						<circle cx="4" cy="4.57129" r="4" fill="#107C10" />
					</svg>
					<DonutLegend>Positive</DonutLegend>
				</FlexDisplaySmallGap>
				<DonutLegend>{positive}%</DonutLegend>
			</LegendContainer>

			<LegendContainer>
				<FlexDisplaySmallGap>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="8"
						height="9"
						viewBox="0 0 8 9"
						fill="none">
						<circle cx="4" cy="4.57129" r="4" fill="#D13438" />
					</svg>
					<DonutLegend>Negative</DonutLegend>
				</FlexDisplaySmallGap>
				<DonutLegend>{negative}%</DonutLegend>
			</LegendContainer>

			<LegendContainer>
				<FlexDisplaySmallGap>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="8"
						height="9"
						viewBox="0 0 8 9"
						fill="none">
						<circle cx="4" cy="4.57129" r="4" fill="#96959F" />
					</svg>
					<DonutLegend>Neutral</DonutLegend>
				</FlexDisplaySmallGap>
				<DonutLegend>{neutral}%</DonutLegend>
			</LegendContainer>
		</FloatingComponent>
	);
};

const renderFloatingESG = (props: ISingleReport) => {
	const { documents } = props;

	const { environmental, social, governance } = calculateESG(documents);

	return (
		<FloatingComponent className="mt-2">
			<LegendContainer>
				<FlexDisplaySmallGap>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="8"
						height="9"
						viewBox="0 0 8 9"
						fill="none">
						<circle cx="4" cy="4.57129" r="4" fill="#107C10" />
					</svg>
					<DonutLegend>Environmental</DonutLegend>
				</FlexDisplaySmallGap>
				<DonutLegend>{environmental}%</DonutLegend>
			</LegendContainer>

			<LegendContainer>
				<FlexDisplaySmallGap>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="8"
						height="9"
						viewBox="0 0 8 9"
						fill="none">
						<circle cx="4" cy="4.57129" r="4" fill="#339CC2" />
					</svg>
					<DonutLegend>Social</DonutLegend>
				</FlexDisplaySmallGap>
				<DonutLegend>{social}%</DonutLegend>
			</LegendContainer>

			<LegendContainer>
				<FlexDisplaySmallGap>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="8"
						height="9"
						viewBox="0 0 8 9"
						fill="none">
						<circle cx="4" cy="4.57129" r="4" fill="#96959F" />
					</svg>
					<DonutLegend>Governance</DonutLegend>
				</FlexDisplaySmallGap>
				<DonutLegend>{governance}%</DonutLegend>
			</LegendContainer>
		</FloatingComponent>
	);
};

const renderFloatingOverview = (props: ISingleReport) => {
	const { newCurrentDate, currentChangeInSentiment, currentDocumentScore } =
		calculateOverview(props);

	// @ts-ignore
	const code = props?.companies?.stock_code || props?.industry?.industry_code;
	// @ts-ignore
	const name = props?.companies?.name || props?.industry?.name;

	return (
		<FloatingOverview>
			<div>
				<CompanyName className="mt-2 fs-24">
					<span className="fw-bold">{code}</span>
				</CompanyName>
				<CompanyNameReformat className="mt-2 fs-10">{name}</CompanyNameReformat>
			</div>
			<div>
				<FlexDisplayFlexEnd>
					<CompanyPercentageReformat className="mt-1 fs-24">
						{currentDocumentScore}
					</CompanyPercentageReformat>
					<LabelPercentage
						color={currentChangeInSentiment.charAt(0) === '-' ? "#D13438" : "#107C10"}
						backgroundColor={currentChangeInSentiment.charAt(0) === '-' ? "#f5d4d5" : "#107C1035"}
						className="fs-12">
						{currentChangeInSentiment}
					</LabelPercentage>
				</FlexDisplayFlexEnd>
				<LabelReportFloating>{props.isIndustryPage ? "Latest Report: " : "Report Date: "}{newCurrentDate}</LabelReportFloating>
			</div>
		</FloatingOverview>
	);
};

export const renderFloatingComponent = (props: ISingleReport) => {
	const { selectedRightTab } = props;
	if (selectedRightTab === RIGHT_TAB.OVERVIEW) {
		return renderFloatingOverview(props);
	}

	if (selectedRightTab === RIGHT_TAB.SENTIMENT) {
		return renderFloatingSentiment(props);
	}

	if (selectedRightTab === RIGHT_TAB.ESG) {
		return renderFloatingESG(props);
	}

	return <></>;
};

const SingleReportMobile = (props: ISingleReport) => {
	const [expandTab, setExpandTab] = useState<boolean>(false);
	const [isVisible, setIsVisible] = useState<boolean>(false);

	useEffect(() => {
		const handleScroll = () => {
			const scrollThreshold = 400;
			if (window.scrollY >= scrollThreshold) {
				setIsVisible(true);
			} else {
				setIsVisible(false);
			}
		};

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<ShowMobileOnly>
			<TopSectionMobile>
				<RightTabComponent {...props} />
				<ScrollAppearComponent style={{ top: '40px' }} isVisible={isVisible}>
					{renderFloatingComponent(props)}
				</ScrollAppearComponent>

				{expandTab && (
					<React.Fragment>
						<SentimentComponent {...props} />
						<FilingsComponent {...props} />
					</React.Fragment>
				)}

				<div className="mt-3">
					<center>
						{expandTab ? (
							<NarrowIcon
								onClick={() => {
									setExpandTab(false);
								}}
							/>
						) : (
							<ExpandIcon
								onClick={() => {
									setExpandTab(true);
								}}
							/>
						)}
					</center>
				</div>
			</TopSectionMobile>
			<MobileSection>
				<LeftTabComponent {...props} />
			</MobileSection>
			{renderInformation(props)}
			{renderDisclaimer(true)}
			<FloatingContainer>{renderTab(props)}</FloatingContainer>
		</ShowMobileOnly>
	);
};

export default SingleReportMobile;
