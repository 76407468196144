import styled, { css } from 'styled-components';
import { Button } from 'antd';

import { COLOR } from '../../Constants';
import { IButtonStyled } from './Button.types';
import { BUTTON_TYPE } from './Button.component';
import { mobile } from '../../Utils/layout.utils';

const colorUtils = (kind?: string) => {
	if (kind === BUTTON_TYPE.SECONDARY) {
		return {
			background: '#EFEFEF',
			color: '#A6A6A6',
			hoverColor: '#A6A6A6 !important',
		};
	}

	if (kind === BUTTON_TYPE.DANGER) {
		return {
			background: '#FFDFDF',
			color: '#AC0101',
			hoverColor: '#AC0101 !important',
		};
	}

	if (kind === BUTTON_TYPE.PRIMARY) {
		return {
			background: `${COLOR.PRIMARY}`,
			color: '#fff',
			hoverColor: '#fff !important',
		};
	}

	return {
		background: `${COLOR.PRIMARY}`,
		color: '#fff',
	};
};

export const ButtonStyled = styled(Button) <IButtonStyled>`
	background: ${({ kind }) => colorUtils(kind).background};
	padding: 0.7vh 1vh;
	color: ${({ kind }) => colorUtils(kind).color};
	font-size: 15px;
	font-style: normal;
	font-weight: 600;
	width: ${props => {
		const { width } = props;
		return width ? width : '100%';
	}};
	height: 100%;
	border-radius: ${({ rounded }) => (rounded ? '2vw' : '4px')};
	border: none;

	&:hover {
		color: ${({ kind }) => colorUtils(kind).hoverColor};
	}

	${mobile(css`
		padding: ${props => {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			const { mobilePadding } = props;
			return mobilePadding ? mobilePadding : '0.5vw';
		}};

		width: ${props => {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			const { mobileWidth } = props;
			return mobileWidth ? mobileWidth : '100%';
		}};
	`)};
`;
