import { ROUTES } from '../../Constants/routes';

export const getQueryParam = (param: string) => {
	const searchParams = new URLSearchParams(location.search);
	return searchParams.get(param);
};

export const singleReportParams = (newDocumentType: string, newMarket: number) => {
	const companyId = getQueryParam('company_id') || '';
	const documentId = getQueryParam('document_id') || '';

	return `${ROUTES.SINGLE_REPORT}?country_id=${newMarket}&document_type=${newDocumentType}&company_id=${companyId}&document_id=${documentId}`;
};

export const homeParams = (newDocumentType: string, newMarket: number) => {
	return `?country_id=${newMarket}&document_type=${newDocumentType}`;
};

export const companiesParams = (newDocumentType: string, newMarket: number) => {
	return `${ROUTES.COMPANY}?country_id=${newMarket}&document_type=${newDocumentType}`;
};

export const industriesParams = (newDocumentType: string, newMarket: number) => {
	return `${ROUTES.INDUSTRIES}?country_id=${newMarket}&document_type=${newDocumentType}`;
};

export const reportParams = (newDocumentType: string, newMarket: number) => {
	return `${ROUTES.REPORT}?country_id=${newMarket}&document_type=${newDocumentType}`;
};

export const compareParams = (newDocumentType: string, newMarket: number) => {
	return `${ROUTES.COMPARE}?country_id=${newMarket}`;
};