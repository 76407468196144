import React from 'react';
import { Line } from 'react-chartjs-2';
import { Pagination } from 'antd';
import _ from 'lodash';
import moment from 'moment';

import {
	IAdminPanelScreenState,
	IPageViewGraphData,
	IUserData,
} from '../AdminPanel.types';
import { UserTable } from '../../../Components';
import { FlexDisplaySmallGap } from '../../Preferences/Preferences.styled';
import { Tab } from '../AdminPanel.styled';
import { TABLE_TAB } from '../AdminPanel.screen';

export const renderAdminGraph = (data: IPageViewGraphData) => {
	const { labels, datasets } = data;
	const options = {
		responsive: true,
		plugins: {
			legend: {
				position: 'top' as const,
			},
			title: {
				display: false,
			},
		},
	};

	const mappingDatasets = datasets.map(
		(item: {
			label: string;
			data: number[];
			borderColor: string;
			backgroundColor: string;
		}) => {
			return {
				label: item.label,
				data: item.data,
				borderColor: item.borderColor,
				backgroundColor: item.backgroundColor,
			};
		}
	);

	const graphData = {
		labels,
		datasets: mappingDatasets,
	};

	return <Line options={options} data={graphData} />;
};

const renderTableTab = (props: IAdminPanelScreenState) => {
	return (
		<div className="mb-2">
			<FlexDisplaySmallGap>
				{TABLE_TAB?.map((item, index) => (
					<Tab
						isSelected={item?.label === props?.tableSelectedTab}
						key={index}
						onClick={() => {
							props?.setTableSelectedTab(item?.label);
						}}>
						{item?.label}
					</Tab>
				))}
			</FlexDisplaySmallGap>
		</div>
	);
};

export const renderUserTable = (props: IAdminPanelScreenState) => {
	const meta = _.get(props, 'users.meta', []);
	const data = _.get(props, 'users.data', []);

	const reformatData = data?.map((item: IUserData) => {
		let newsLetter = false;
		let watchList = false;
		let tips = false;

		const userNotificationPreferences = item.user_notification_preference;

		if (userNotificationPreferences.length > 0) {
			const findTipsSetting = userNotificationPreferences.find(
				x => x.notification_preference_id === 1
			);
			const findNewsSettings = userNotificationPreferences.find(
				x => x.notification_preference_id === 2
			);
			const findWatchlistSettings = userNotificationPreferences.find(
				x => x.notification_preference_id === 3
			);

			tips = _.get(findTipsSetting, 'turn_on', false);
			newsLetter = _.get(findNewsSettings, 'turn_on', false);
			watchList = _.get(findWatchlistSettings, 'turn_on', false);
		}

		return {
			email: item.email,
			joined: moment(item.created_at).format('YYYY-MM-DD HH:mm:ss'),
			active: item.is_active,
			lastSignIn: '2023-08-01 T13:01:20',
			newsLetter,
			watchList,
			tips,
			subscription: item.user_active_subscription.subscription.name,
			accessExpiration: item.user_active_subscription.end_date,
		};
	});

	const currentPage = _.get(meta, 'current_page', 1);
	const total = _.get(meta, 'total', 1);

	return (
		<React.Fragment>
			{renderTableTab(props)}
			<UserTable data={reformatData} />
			<Pagination
				defaultCurrent={currentPage}
				total={total}
				showSizeChanger={false}
			/>
		</React.Fragment>
	);
};
