export const DEMO_FEATURES = [
	{
		label: 'Registration is not required',
		value: true,
	},
	{
		label: 'Portal access (limited data*)',
		value: true,
	},
	{
		label: 'Full access to the current data',
		value: false,
	},
	{
		label: 'Early-adopter access to future services',
		value: false,
	},
	{
		label: 'Data API',
		value: false,
	},
];

export const BASIC_FEATURES = [
	{
		label: 'Full portal access',
		value: true,
	},
	{
		label: 'Early-adopter access to future services',
		value: true,
	},
	{
		label: 'Updates, announcements, newsletter',
		value: true,
	},
	{
		label: 'Data API',
		value: false,
	},
];

export const API_FEATURES = [
	{
		label: 'Data access via CBSA data API',
		value: true,
	},
	{
		label: 'Sentiment and ESG data from FinBERT',
		value: true,
	},
	{
		label: 'Corporate filing texts',
		value: true,
	},
	{
		label: 'Company details',
		value: true,
	}
];