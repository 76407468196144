export const ROUTES = {
	ROOT: '/',
	RESET_PASSWORD: 'reset-password',
	PREFERENCES: 'preferences',
	ADMIN: 'ADMIN',
	SINGLE_REPORT: 'single-report',
	COMPANY: 'companies',
	COMPARE: 'compare',
	INDUSTRIES: 'industries',
	REPORT: 'reports',
};
