import styled from 'styled-components';
import { Form } from 'react-bootstrap';

import { FormInput as FormInputSignIn } from '../SignIn/SignIn.styled';
import { FlexDisplay } from '../../Screens/Preferences/Preferences.styled';

export const FormLabel = styled.p`
	color: var(--neutral-black, #000);
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 150%;
	padding: 0;
	margin: 0 0 1vh 0;
`;

export const FormValue = styled.p`
	color: #4e4e4e;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	padding: 0;
`;

export const EditButton = styled.p`
	cursor: pointer;
	color: #1350a0;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 150%;
	text-decoration-line: underline;
`;

export const LabelParent = styled.div`
	border-bottom: 1px solid #ebebeb;
`;

export const FormInput = styled(FormInputSignIn)`
	border: none;
	padding: 0;
`;

export const LabelContainer = styled(FlexDisplay)`
	padding-top: 2vh;
	justify-content: space-between;
	align-items: center;
	align-content: center;
`;

export const ContainerSendCode = styled(LabelContainer)`
	justify-content: flex-start;
`;

export const LabelInput = styled(FormLabel)`
	color: #818181;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	letter-spacing: -0.12px;
`;

export const FormGroup = styled(Form.Group)`
	border-radius: 4px;
	border: 1px solid #c2c2c2;
	padding: 10px;
`;

export const LabelSendCode = styled.span`
	color: #225fb0;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	letter-spacing: -0.14px;
	cursor: pointer;
	padding-top: 0.5vh;
`;

export const LabelResend = styled.span`
	color: #818181;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	letter-spacing: -0.12px;
`;
