import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import { FaArrowLeft } from 'react-icons/fa';

import { IFulfilledInput } from './Authorization.types';
import { COLOR } from '../../Constants';

export const AuthorizationModal = styled(Modal)`
	border-radius: 4px;
	box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.04);
`;

export const ModalBody = styled.div`
	padding: 2.5vw;
`;

export const BackButton = styled(FaArrowLeft)`
	font-size: 20px;
	color: #4e4e4e;
	padding-bottom: 2px;
	cursor: pointer;
	margin-right: 5px;
`;

export const PasswordChecker = styled.p<IFulfilledInput>`
	padding: 0;
	margin: 0;
	color: ${({ fulfilled }) => (fulfilled ? COLOR.SECONDARY : COLOR.ERROR)};
	font-size: 12px;
	font-style: normal;
	font-weight: 600;
	line-height: 20px;
`;

export const DisclaimerLabel = styled.p`
	color: #000;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	letter-spacing: -0.12px;
`;

export const DisclaimerLink = styled.a`
	cursor: pointer;
	text-decoration-line: underline;
`;
