/* eslint-disable */
// @ts-nocheck
import axios, { AxiosError, AxiosRequestConfig, AxiosResponseHeaders } from 'axios';
import _ from 'lodash';
import { toast } from 'react-toastify';

import { ErrorResponse } from '../Components/VerificationModal/VerificationModal.types';

export const SERVICES_URL = process.env.REACT_APP_API_URL;
export const SIGN_IN_URL = SERVICES_URL + '/auth/login';
export const SIGN_IN_SOCIAL_URL = SERVICES_URL + '/auth/social-auth/login';
export const SIGNUP_SERVICE_URL = SERVICES_URL + '/auth/register';
export const SIGNUP_SOCIAL_SERVICE_URL = SERVICES_URL + '/auth/social-auth/register';
export const USER_VERIFICATION_URL = SERVICES_URL + '/user-verification';
export const EMAIL_VERIFICATION_REQUEST_URL =
	SERVICES_URL + '/user/email-verification-request';
export const PASSWORD_RESET_REQUEST_URL =
	SERVICES_URL + '/auth/password-reset/request';
export const PASSWORD_RESET_VERIFY_TOKEN_URL =
	SERVICES_URL + '/auth/password-reset/verify-token';
export const PASSWORD_RESET_SAVE_URL = SERVICES_URL + '/auth/password-reset';
export const USER_PROFILE = SERVICES_URL + '/user/profile';
export const REQUEST_EDIT_PROFILE_URL =
	SERVICES_URL + '/user/profile/update-info';
export const NOTIFICATION_URL = SERVICES_URL + '/user/notification/preferences';
export const NOTIFICATION_CHANGE_URL =
	SERVICES_URL + '/user/notification/preferences';
export const SUBSCRIPTIONS_URL = SERVICES_URL + '/subscriptions';
export const USER_SUBSCRIPTION_URL =
	SERVICES_URL + '/user/subscriptions/check-status';
export const COMPANY_URL = SERVICES_URL + '/company-list';
export const KEYWORD_LIST_URL = SERVICES_URL + '/keywords-list';
export const ADMIN_URL = SERVICES_URL + '/admin';
export const ADMIN_PAGE_VIEWS_URL = ADMIN_URL + '/dashboard/page-views';
export const ADMIN_ACTIVE_USERS_URL = ADMIN_URL + '/dashboard/active-users';
export const ADMIN_USERS_URL = ADMIN_URL + '/users';
export const DOCUMENT_LIST_URL = SERVICES_URL + '/document-list';
export const DOCUMENT_URL = SERVICES_URL + '/documents';
export const COMPANIES_URL = SERVICES_URL + '/companies';
export const SCORES_URL = SERVICES_URL + '/scores';
export const ESG9_URL = SERVICES_URL + '/esg9';
export const SCORE_LIST_URL = SERVICES_URL + '/scores-list';
export const STOCK_PRICES_URL = SERVICES_URL + '/stock-prices';
export const INDUSTRY_LIST_GROUPING_URL = SERVICES_URL + '/industry-list/grouping';
export const INDUSTRY_LIST_URL = SERVICES_URL + '/industry-list';
export const INDUSTRY_URL = SERVICES_URL + '/industries';
export const WATCHLIST_URL = SERVICES_URL + '/user/watchlists';
export const SEARCH_URL = SERVICES_URL + '/search';
export const KEYWORD_FREQUENCIES_URL = SERVICES_URL + '/keyword-frequencies';

const requestHandler =
	(request: AxiosRequestConfig) => {
		const token: string = localStorage.getItem('token');
		request.headers.Authorization = `Bearer ${token}`;

		return request;
	};

const responseHandler = (response: AxiosResponseHeaders) => {
	const res = response as ErrorResponse;
	const statusCode = _.get(res, 'response.data.status_code');

	if (statusCode === 403 || statusCode === 401) {
		localStorage.clear();
		window.location = '/';
	}

	return response;
};

const errorHandler = (error: AxiosError) => {
	const err = error as ErrorResponse;
	const statusCode = _.get(err, 'response.data.status_code');

	if (statusCode === 403) {
		toast.warning('Your subscription cannot access the API.')
	}
	if (statusCode === 401) {
		localStorage.clear();
		window.location = '/'; // TODO: change error code when password is wrong and user is not verifying email
	} else {
		return Promise.reject(error);
	}
};

const servicesRequest = (type = '') => {
	const contentType =
		type === 'formData' ? 'multipart/form-data' : 'application/json';
	const request = axios.create({
		headers: {
			'Content-Type': contentType,
		},
	});

	request.interceptors.request.use(
		request => requestHandler(request),
		(error: AxiosError) => errorHandler(error)
	);

	request.interceptors.response.use(
		response => responseHandler(response),
		(error: AxiosError) => errorHandler(error)
	);

	return request;
};

export default servicesRequest;
