import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import _ from 'lodash';

import servicesRequest, {
	COMPANY_URL,
} from '../Config/services.config';
import { getDefaultMarket } from '../Utils';

const useFilterData = () => {
	const [data, setData] = useState([]);
	const [paginationData, setPaginationData] = useState({
		last_page: 1,
		current_page: 1,
		last_page_url: '',
		next_page_url: '',
		previous_page_url: '',
	});
	const [isLoadingScrollCompany, setIsLoadingScrollCompany] = useState(false);
	const [scrollDirection, setScrollDirection] = useState([0, 0]);

	const [industryCode, setIndustryCode] = useState(['']);

	const [filtered, setFiltered] = useState([]);
	const [tempFilter, setTempFilter] = useState([]);

	const request = servicesRequest();

	const getCompanies = (url?: string) => {
		const pageUrl = url || '/?page=1';
		setIsLoadingScrollCompany(true);
		request
			.get(`${COMPANY_URL}${pageUrl}&country_id=${getDefaultMarket()}&document_type=filing`)
			.then((response: any) => {
				const responseData = _.get(response, 'data.data.data', []);
				const meta = _.get(response, 'data.data.meta', []);
				/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
								// @ts-ignore */
				setData([...data, ...responseData]);
				setPaginationData(meta);
			})
			.catch(() =>
				console.error('Something went wrong. Please refresh the page.')
			)
			.finally(() => setIsLoadingScrollCompany(false));
	}

	const getCompaniesNextPage = () => {
		getCompanies(paginationData.next_page_url);
	};

	const onClickConfirm = (dataCode: any[]) => () => {
		if (!dataCode[0][0]) {
			toast.warning('Choose the industry first.')
			return;
		}
		const isDataExist = tempFilter.filter((filter) => filter[1] === dataCode[1]);

		if (isDataExist.length > 0) {
			toast.warning('Data already added.')
			return;
		}
		if (dataCode.length > 0) {
			// @ts-ignore
			setTempFilter([...tempFilter, dataCode]);
		}
	};

	const onDeleteList = (index: number) => () => {
		tempFilter.splice(index, 1);
		setTempFilter(
			/* // eslint-disable-next-line @typescript-eslint/ban-ts-comment
								// @ts-ignore */
			[...tempFilter]
		);
	};

	useEffect(() => {
		getCompanies();
	}, []);

	return {
		data,
		getCompaniesNextPage,
		isLoadingScrollCompany,
		scrollDirection,
		setScrollDirection,
		industryCode,
		setIndustryCode,
		onClickConfirm,
		filtered,
		setFiltered,
		tempFilter,
		setTempFilter,
		onDeleteList,
	};
};

export default useFilterData;
