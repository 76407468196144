import React, { useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import _ from 'lodash';
import { InfoCircleOutlined } from '@ant-design/icons';

import { RIGHT_TAB } from '../../Screens/SingleReport/SingleReport.screen';

import {
	CompanyName,
	CompanyPercentage,
	LabelDate,
	LabelPercentage,
	LabelReport,
	LeftComponentItem,
	LeftComponentTab,
	RightTabContainer,
	RightTabItem,
	SentimentLabel,
	ShowMobileOnly,
} from '../../Screens/SingleReport/SingleReport.styled';
import {
	FlexDisplayMediumGap,
	FlexDisplaySmallGap,
	FlexDisplaySpaceBetween,
} from '../../Screens/Preferences/Preferences.styled';

import {
	IESGComponents,
	ISentimentComponent,
	ISingleReport,
} from '../../Screens/SingleReport/SingleReport.types';
import DonutChartComponent from '../DonutChart/DonutChart.component';
import PieChartComponent from '../PieChart/PieChart.component';
import { convertToPercentage, hexToRgbA } from '../../Utils';
import { calculateESG } from './index';
import { convertValue } from '../Home/TrendingReportsTable.component';
import { FilterReportsButton } from '../Compare/SideTab.component';
import { MostViewedCompanies } from '../../Screens/Industries/Industries.screen';
import { LoadingComponent } from '../../Screens/Industries/Industries.desktop.screen';
import { WhiteContainer } from '../../Screens/Company/Company.styled';

import { Popover } from '..';

export const DonutLegend = styled.div<{ color?: string }>`
	color: ${props => props.color || '#4e4e4e'};
	font-size: 10px;
	font-style: normal;
	font-weight: 600;
	line-height: 20px;
`;

export const LegendContainer = styled(FlexDisplaySpaceBetween) <{
	color?: string;
	transparency?: number;
}>`
	border-radius: 4px;
	background: ${props =>
		props.color && props.transparency
			? hexToRgbA(props.color, props.transparency)
			: '#efeded'};
	padding: 4px 8px;
	width: 100%;
`;

export const ESGContainer = styled.div`
	border-radius: 4px;
	border: 1px solid #e0e0e0;
	background: #f9f9f9;
	box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.04);
	padding: 1vh;
`;

const ESGLabel = styled(SentimentLabel)`
	font-weight: 600;
`;

const ESGItem = styled(FlexDisplaySpaceBetween)`
	border-bottom: 1px solid #e0e0e0;
`;

export const SENTIMENT_MENU_TAB = {
	COMPANY: 'COMPANY',
	DOCUMENT: 'DOCUMENT',
};

const ESG_TAB = {
	ENVIRONMENTAL: 'ENVIRONMENTAL',
	SOCIAL: 'SOCIAL',
	GOVERNANCE: 'GOVERNANCE',
};

export const calculateOverview = (props: ISingleReport) => {
	const { isIndustryPage, documents, companies } = props;
	const currentDate = _.get(documents, 'document.date', null);
	const previousDate = _.get(documents, 'previous_document.date', null);

	let newCurrentDate = currentDate;
	let newPreviousDate = previousDate;

	if (!_.isNull(currentDate)) {
		newCurrentDate = moment(currentDate).format('MMMM DD, YYYY');
	}

	if (!_.isNull(previousDate)) {
		newPreviousDate = moment(previousDate).format('MMMM DD, YYYY');
	}

	// CURRENT SENTIMENT SCORE
	const currentDocumentScore = isIndustryPage ? Number(companies?.latest_sentiment_score) :
		_.get(documents, 'document.scores.document_score.positive', 0) -
		_.get(documents, 'document.scores.document_score.negative', 0);

	// SENTIMETN SCORE CHANGE
	const currentChangeInSentiment = convertValue(
		convertToPercentage(isIndustryPage ? Number(companies?.sentiment_score_change) : _.get(companies, 'sentiment_score_change', NaN))
	).value;

	// PREVIOUS REPORT'S SENTIMENT
	const previousReportSentiment = convertValue(
		convertToPercentage(_.get(companies, 'previous_sentiment_score', NaN))
	).value;

	// COMPANY AVERAGE
	const companyAverage = convertValue(
		convertToPercentage(_.get(companies, 'average_sentiment_score', NaN))
	).value;

	return {
		newCurrentDate,
		newPreviousDate,
		currentChangeInSentiment,
		previousReportSentiment,
		companyAverage,
		currentDocumentScore: convertValue(
			convertToPercentage(currentDocumentScore)
		).value,
	};
};

export const calculateIndustryOverview = (props: ISingleReport) => {
	const { isIndustryPage, documents, companies } = props;

	const currentDate = _.get(documents, 'document.date', null);
	const previousDate = _.get(documents, 'previous_document.date', null);

	let newCurrentDate = currentDate;
	let newPreviousDate = previousDate;

	if (!_.isNull(currentDate)) {
		newCurrentDate = moment(currentDate).format('MMMM DD, YYYY');
	}

	if (!_.isNull(previousDate)) {
		newPreviousDate = moment(previousDate).format('MMMM DD, YYYY');
	}

	// CURRENT CHANGE IN SENTIMENT
	const currentDocumentScore = isIndustryPage ? Number(companies?.latest_sentiment_score) :
		_.get(documents, 'document.scores.document_score.positive', 0) -
		_.get(documents, 'document.scores.document_score.negative', 0);

	// PREVIOUS REPORT'S SENTIMENT
	const currentChangeInSentiment = convertValue(
		convertToPercentage(_.get(companies, 'sentiment_score_change', NaN))
	).value;

	const previousReportSentiment = convertValue(
		convertToPercentage(_.get(companies, 'previous_sentiment_score', NaN))
	).value;

	// COMPANY AVERAGE
	const companyAverage = convertValue(
		convertToPercentage(_.get(companies, 'average_sentiment_score', NaN))
	).value;

	return {
		newCurrentDate,
		newPreviousDate,
		currentChangeInSentiment,
		previousReportSentiment,
		companyAverage,
		currentDocumentScore: convertValue(
			convertToPercentage(currentDocumentScore)
		).value,
	};
};


export const OverviewComponent = (props: ISingleReport) => {
	const {
		isIndustryPage = false,
		topCompanies,
		companyReturn,
		finishDate,
		startDate,
		companies
	} = props;
	const {
		newCurrentDate,
		newPreviousDate,
		currentChangeInSentiment,
		previousReportSentiment,
		companyAverage,
		currentDocumentScore,
	} = isIndustryPage ? calculateIndustryOverview(props) : calculateOverview(props);

	const yearsDifference = startDate === undefined || finishDate === undefined ?
		5 : moment(finishDate).diff(moment(startDate), 'years');

	return (
		<React.Fragment>
			<FlexDisplaySpaceBetween>
				<CompanyPercentage className="mt-1">
					{currentDocumentScore}
				</CompanyPercentage>
				{isIndustryPage && (
					<FlexDisplayMediumGap>
						<span className="fs-10 fw-400">1 Day Average</span>
						<InfoCircleOutlined style={{ color: '#BCBCBC', fontSize: 14 }} />
					</FlexDisplayMediumGap>
				)}
			</FlexDisplaySpaceBetween>

			{!isIndustryPage && (
				<>
					<div className="mt-3">
						<FlexDisplaySpaceBetween>
							<div className="w-60">
								<LabelReport>Current change in sentiment</LabelReport>
								<LabelDate>
									({_.isNull(newCurrentDate) ? 'N/A' : newCurrentDate})
								</LabelDate>
							</div>
							<div className="w-40">
								{currentChangeInSentiment.charAt(0) === '-' ?
									<LabelPercentage color="#D13438" backgroundColor="#f5d4d5">
										{currentChangeInSentiment}
									</LabelPercentage> :
									<LabelPercentage color="#107C10" backgroundColor="#a6d0a6">
										{currentChangeInSentiment}
									</LabelPercentage>
								}
							</div>
						</FlexDisplaySpaceBetween>
					</div>

					<div className="mt-3">
						<FlexDisplaySpaceBetween>
							<div className="w-60">
								<LabelReport>Previous report’s sentiment</LabelReport>
								<LabelDate>
									({_.isNull(newPreviousDate) ? 'N/A' : newPreviousDate})
								</LabelDate>
							</div>
							<div className="w-40">
								<LabelPercentage>{previousReportSentiment}</LabelPercentage>
							</div>
						</FlexDisplaySpaceBetween>
					</div>
				</>
			)}

			<div className="mt-3">
				<FlexDisplaySpaceBetween>
					<div className="w-60">
						<LabelReport>
							{isIndustryPage ? 'Industry' : 'Company'} average
							<Popover
								className='ps-1'
								popoverTextHeader="**Historic Average Sentiment**"
								popoverTextBody={`Displays average sentiment **excluding** the score of the latest report.`}
							/>
						</LabelReport>
						<LabelDate>
							({_.isNull(yearsDifference) ? 'N/A' : `${yearsDifference} years`})
						</LabelDate>
					</div>
					<div className="w-40">
						<LabelPercentage>{companyAverage}</LabelPercentage>
					</div>
				</FlexDisplaySpaceBetween>
			</div>

			{companyReturn && (
				<div className="mt-3">
					<FlexDisplaySpaceBetween>
						<div className="w-60">
							<LabelReport>Returns</LabelReport>
							<LabelDate>
								(
								{_.isNull(yearsDifference) ? 'N/A' : `${yearsDifference} years`}
								)
							</LabelDate>
						</div>
						<div className="w-40">
							<LabelPercentage>{companyReturn}</LabelPercentage>
						</div>
					</FlexDisplaySpaceBetween>
				</div>
			)}

			{isIndustryPage && <MostViewedCompanies topCompanies={topCompanies} />}
		</React.Fragment>
	);
};

export const calculateSentiment = (props: ISentimentComponent, tab: string) => {
	const { scoresAverage, documents, documentType } = props;

	const companyPositive =
		_.get(scoresAverage, `${documentType}.positive`, 0);
	const companyNegative =
		_.get(scoresAverage, `${documentType}.negative`, 0);
	const companyNeutral =
		_.get(scoresAverage, `${documentType}.neutral`, 0);

	const documentPositive =
		_.get(documents, 'document.scores.document_score.positive', 0);
	const documentNegative =
		_.get(documents, 'document.scores.document_score.negative', 0);
	const documentNeutral =
		_.get(documents, 'document.scores.document_score.neutral', 0);

	const positive =
		tab === SENTIMENT_MENU_TAB.COMPANY ? companyPositive : documentPositive;
	const negative =
		tab === SENTIMENT_MENU_TAB.COMPANY ? companyNegative : documentNegative;
	const neutral =
		tab === SENTIMENT_MENU_TAB.COMPANY ? companyNeutral : documentNeutral;

	const averageReport = convertValue(
		convertToPercentage(Number(positive) - Number(negative))
	).value;

	return {
		averageReport: averageReport,
		positive: convertToPercentage(positive),
		negative: convertToPercentage(negative),
		neutral: convertToPercentage(neutral),
	};
};

export const SentimentComponent = (props: ISentimentComponent) => {
	const { isCompanyPage, isComparePage, isLoadingAverage, scoreType } = props;

	const [tab, setTab] = useState(SENTIMENT_MENU_TAB.DOCUMENT);

	const { averageReport, positive, negative, neutral } = calculateSentiment(
		props,
		scoreType ? scoreType : tab
	);

	const companyData = {
		neutral,
		positive,
		negative,
	};

	const renderSentimentTab = () => {
		return (
			<LeftComponentTab className="mb-3">
				<LeftComponentItem
					width="50%"
					onClick={() => {
						setTab(SENTIMENT_MENU_TAB.DOCUMENT);
					}}
					selected={tab === SENTIMENT_MENU_TAB.DOCUMENT}>
					Document
				</LeftComponentItem>
				<LeftComponentItem
					width="50%"
					onClick={() => {
						setTab(SENTIMENT_MENU_TAB.COMPANY);
					}}
					selected={tab === SENTIMENT_MENU_TAB.COMPANY}>
					Company
				</LeftComponentItem>
			</LeftComponentTab>
		);
	};

	const renderSentimentContent = () => (
		<React.Fragment>
			{/* {isLoadingAverage && <LoadingComponent />} */}
			{!isLoadingAverage && (
				<>
					<DonutChartComponent
						data={companyData}
						averageReport={averageReport}
					/>
					<div>
						<LegendContainer className="mb-1">
							<FlexDisplaySmallGap>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="8"
									height="9"
									viewBox="0 0 8 9"
									fill="none">
									<circle cx="4" cy="4.57129" r="4" fill="#107C10" />
								</svg>
								<DonutLegend>Positive</DonutLegend>
							</FlexDisplaySmallGap>
							<DonutLegend>{convertValue(positive).value}</DonutLegend>
						</LegendContainer>

						<LegendContainer className="mb-1">
							<FlexDisplaySmallGap>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="8"
									height="9"
									viewBox="0 0 8 9"
									fill="none">
									<circle cx="4" cy="4.57129" r="4" fill="#D13438" />
								</svg>
								<DonutLegend>Negative</DonutLegend>
							</FlexDisplaySmallGap>
							<DonutLegend>{convertValue(negative).value}</DonutLegend>
						</LegendContainer>

						<LegendContainer className="mb-1">
							<FlexDisplaySmallGap>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="8"
									height="9"
									viewBox="0 0 8 9"
									fill="none">
									<circle cx="4" cy="4.57129" r="4" fill="#96959F" />
								</svg>
								<DonutLegend>Neutral</DonutLegend>
							</FlexDisplaySmallGap>
							<DonutLegend>{convertValue(neutral).value}</DonutLegend>
						</LegendContainer>
					</div>
				</>
			)}
		</React.Fragment>
	);
	if (isLoadingAverage) {
		return (
			<WhiteContainer>
				<div className="mt-4">
					<LoadingComponent />
				</div>
			</WhiteContainer>
		);
	}

	return (
		<div
			className="mt-2 mb-2"
			style={{ height: isComparePage ? '100%' : 'fit-content' }}>
			<React.Fragment>
				{!isCompanyPage && renderSentimentTab()}
				{!isComparePage ? (
					<FlexDisplaySpaceBetween>
						{renderSentimentContent()}
					</FlexDisplaySpaceBetween>
				) : (
					<div
						className="d-grid mh-100"
						style={{ alignContent: 'space-evenly' }}>
						{renderSentimentContent()}
					</div>
				)}
			</React.Fragment>
		</div>
	);
};

export const ESGComponent = (props: IESGComponents) => {
	let {
		nonEsg,
		environmental,
		social,
		governance,
		climateChange,
		naturalCapital,
		pollutionAndWaste,
		humanCapital,
		productLiability,
		communityRelations,
		corporateGovernance,
		businessEthicsAndValues,
	} = props;

	const { isCompanyPage, isComparePage, esg9Average, isLoadingESG } = props;

	const [tab, setTab] = useState(SENTIMENT_MENU_TAB.DOCUMENT);
	const [esgTab, setEsgTab] = useState(ESG_TAB.ENVIRONMENTAL);

	if (tab === SENTIMENT_MENU_TAB.COMPANY && !isCompanyPage) {
		nonEsg = convertToPercentage(_.get(esg9Average, 'non_esg', 0));
		climateChange = _.get(esg9Average, 'climate_change', 0);

		naturalCapital = _.get(esg9Average, 'natural_capital', 0);
		pollutionAndWaste = _.get(esg9Average, 'pollution_and_waste', 0);
		humanCapital = _.get(esg9Average, 'human_capital', 0);
		productLiability = _.get(esg9Average, 'product_liability', 0);
		communityRelations = _.get(esg9Average, 'community_relations', 0);
		corporateGovernance = _.get(esg9Average, 'corporate_governance', 0);
		businessEthicsAndValues = _.get(
			esg9Average,
			'business_ethics_and_values',
			0
		);

		environmental = convertToPercentage(
			Number(esg9Average?.climate_change) +
			Number(esg9Average?.natural_capital) +
			Number(esg9Average?.pollution_and_waste)
		);

		social = convertToPercentage(
			Number(esg9Average?.human_capital) +
			Number(esg9Average?.product_liability) +
			Number(esg9Average?.community_relations)
		);

		governance = convertToPercentage(
			Number(esg9Average?.corporate_governance) +
			Number(esg9Average?.business_ethics_and_values)
		);
	}

	const data = [
		{
			value: environmental,
			label: 'Environmental',
			color: '#82A844',
		},
		{
			value: social,
			label: 'Social',
			color: '#339CC2',
		},
		{
			value: governance,
			label: 'Governance',
			color: '#96959F',
		},
		{
			value: nonEsg,
			label: 'Non-ESG',
			color: '#4E4E4E',
		},
	];

	const renderSentimentTab = () => {
		return (
			<LeftComponentTab className="mb-3">
				<LeftComponentItem
					width="50%"
					onClick={() => {
						setTab(SENTIMENT_MENU_TAB.DOCUMENT);
					}}
					selected={tab === SENTIMENT_MENU_TAB.DOCUMENT}>
					Document
				</LeftComponentItem>
				<LeftComponentItem
					width="50%"
					onClick={() => {
						setTab(SENTIMENT_MENU_TAB.COMPANY);
					}}
					selected={tab === SENTIMENT_MENU_TAB.COMPANY}>
					Company
				</LeftComponentItem>
			</LeftComponentTab>
		);
	};

	const renderESGTable = () => {
		const environmentalItem = [
			{
				label: 'Climate Change',
				value: convertToPercentage(climateChange),
			},
			{
				label: 'Natural Capital',
				value: convertToPercentage(naturalCapital),
			},
			{
				label: 'Pollution & Waste',
				value: convertToPercentage(pollutionAndWaste),
			},
		];

		const socialItem = [
			{
				label: 'Human Capital',
				value: convertToPercentage(humanCapital),
			},
			{
				label: 'Product Liability',
				value: convertToPercentage(productLiability),
			},
			{
				label: 'Community Relations',
				value: convertToPercentage(communityRelations),
			},
		];

		const governanceItem = [
			{
				label: 'Corporate Governance',
				value: convertToPercentage(corporateGovernance),
			},
			{
				label: 'Business Ethics & Values',
				value: convertToPercentage(businessEthicsAndValues),
			},
		];

		let itemToMap = environmentalItem;
		if (esgTab === ESG_TAB.ENVIRONMENTAL) itemToMap = environmentalItem;
		if (esgTab === ESG_TAB.SOCIAL) itemToMap = socialItem;
		if (esgTab === ESG_TAB.GOVERNANCE) itemToMap = governanceItem;

		return (
			<React.Fragment>
				<ESGContainer>
					{itemToMap?.map((item, index) => (
						<ESGItem className="py-1" key={index}>
							<ESGLabel>{item?.label}</ESGLabel>
							<ESGLabel>{convertValue(item?.value).value}</ESGLabel>
						</ESGItem>
					))}
				</ESGContainer>
			</React.Fragment>
		);
	};
	if (isLoadingESG) {
		return (
			<WhiteContainer>
				<div className="mt-4">
					<LoadingComponent />
				</div>
			</WhiteContainer>
		);
	}

	return (
		<div
			className="mt-2 d-grid"
			style={{
				alignContent: 'space-evenly',
				height: isComparePage ? '100%' : 'fit-content',
			}}>
			{isCompanyPage ? null : renderSentimentTab()}
			<FlexDisplaySpaceBetween>
				<PieChartComponent data={data} />
				<div>
					{!isComparePage &&
						data.map((item, index) => {
							const transparency = index === 3 ? 1 : 0.3;
							const fillColor = index === 3 ? '#ffffff' : item.color;
							const color = index === 3 ? '#ffffff' : '#4e4e4e';

							return (
								<LegendContainer
									className="mb-1"
									key={index}
									color={item.color}
									transparency={transparency}>
									<FlexDisplaySmallGap>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="8"
											height="9"
											viewBox="0 0 8 9"
											fill="none">
											<circle cx="4" cy="4.57129" r="4" fill={fillColor} />
										</svg>
										<DonutLegend color={color}>{item.label}</DonutLegend>
									</FlexDisplaySmallGap>
									<DonutLegend color={color}>
										{convertValue(item.value).value}
									</DonutLegend>
								</LegendContainer>
							);
						})}
				</div>
			</FlexDisplaySpaceBetween>
			<div>
				<div className="mt-4 mb-1">
					<RightTabContainer>
						<RightTabItem
							onClick={() => {
								setEsgTab(ESG_TAB.ENVIRONMENTAL);
							}}
							selected={esgTab === ESG_TAB.ENVIRONMENTAL}>
							Environmental
						</RightTabItem>
						<RightTabItem
							onClick={() => {
								setEsgTab(ESG_TAB.SOCIAL);
							}}
							selected={esgTab === ESG_TAB.SOCIAL}>
							Social
						</RightTabItem>
						<RightTabItem
							onClick={() => {
								setEsgTab(ESG_TAB.GOVERNANCE);
							}}
							selected={esgTab === ESG_TAB.GOVERNANCE}>
							Governance
						</RightTabItem>
					</RightTabContainer>
				</div>
				{renderESGTable()}
			</div>
		</div>
	);
};

const RightTabComponent = (props: ISingleReport) => {
	const {
		onChangeRightTab,
		selectedRightTab,
		isCompanyPage,
		onClickIcon,
		companies,
		documents,
		listFilterLabel = 'Show Company List',
		navigateToCompare,
		esg9Average,
		isIndustryPage = false,
		companyReturn,
		pageType = 'Company'
	} = props;

	const companyStockCode = _.get(companies, 'stock_code', '');
	const industryCode = _.get(companies, 'industry_code', '');
	const companyName = _.get(companies, 'name', '');

	const esgProps = calculateESG(documents);

	return (
		<React.Fragment>
			<RightTabContainer>
				<RightTabItem
					onClick={() => {
						onChangeRightTab(RIGHT_TAB.OVERVIEW);
					}}
					selected={selectedRightTab === RIGHT_TAB.OVERVIEW}>
					Overview
					{selectedRightTab === RIGHT_TAB.OVERVIEW && <Popover
						className='ps-1'
						popoverTextHeader={isCompanyPage ? "**Latest Report Overview**" : "**Report Overview**"}
						popoverTextBody={pageType === 'Industry'
							? 'Details the latest sentiments and trends within the industry'
							: isCompanyPage ? `Details the latest report and compares average company sentiments and stock returns.`
								: "Details of this document including the average company sentiments."}
					/>}
				</RightTabItem>
				<RightTabItem
					onClick={() => {
						onChangeRightTab(RIGHT_TAB.SENTIMENT);
					}}
					selected={selectedRightTab === RIGHT_TAB.SENTIMENT}>
					Sentiments
					{selectedRightTab === RIGHT_TAB.SENTIMENT && <Popover
						className='ps-1'
						popoverTextHeader="**Average Sentiment**"
						popoverTextBody={isCompanyPage
							? `Shows the ${pageType.toLowerCase()}'s average sentiment over the selected timeframe (default: 5 years).`
							: 'View the average sentiment of this document and the company.'}
					/>}
				</RightTabItem>
				<RightTabItem
					onClick={() => {
						onChangeRightTab(RIGHT_TAB.ESG);
					}}
					selected={selectedRightTab === RIGHT_TAB.ESG}>
					ESG
					{selectedRightTab === RIGHT_TAB.ESG && <Popover
						className='ps-1'
						popoverTextHeader="**Average ESG**"
						popoverTextBody={isCompanyPage
							? `Displays the ESG classifications average for the selected timeframe (default: 5 years).`
							: 'View the average ESG classifications of this document and the company'}
					/>}
				</RightTabItem>
			</RightTabContainer>

			<CompanyName className="mt-2 d-flex justify-content-between align-items-center">
				<div className="fs-12 my-1" onClick={navigateToCompare}>
					<span
						className="company-stock-code"
						style={{ marginRight: '8px' }}
						onClick={navigateToCompare}>
						{companyStockCode || industryCode}
					</span>{' '}
					{companyName}
				</div>

				{isCompanyPage && (
					<ShowMobileOnly className="bg-blue standard-code">
						<FilterReportsButton
							className="cursor-pointer"
							onClick={onClickIcon}>
							<span className="fs-10">{listFilterLabel}</span>
						</FilterReportsButton>
					</ShowMobileOnly>
				)}
			</CompanyName>

			{selectedRightTab === RIGHT_TAB.OVERVIEW && (
				<OverviewComponent {...props} companyReturn={companyReturn} />
			)}
			{selectedRightTab === RIGHT_TAB.SENTIMENT && (
				<SentimentComponent {...props} />
			)}
			{selectedRightTab === RIGHT_TAB.ESG && (
				<ESGComponent
					{...esgProps}
					esg9Average={esg9Average}
					isCompanyPage={isCompanyPage}
				/>
			)}
		</React.Fragment>
	);
};

export default RightTabComponent;
