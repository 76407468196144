import {
	RightTabContainer,
	RightTabItem,
	SentimentContainer,
	SentimentItem,
	SentimentLabel,
	SentimentPercentage,
} from '../../Screens/SingleReport/SingleReport.styled';
import {
	IDocumentListData,
	ISingleReport,
} from '../../Screens/SingleReport/SingleReport.types';
import { convertToPercentage, getDefaultMarket, toProperCase } from '../../Utils';
import { ROUTES } from '../../Constants/routes';
import { Link } from '../../Screens/Company/Company.styled';
import { DOCUMENT_TYPE } from '../../Constants';
import { Popover } from '..';


const FillingsComponent = (props: ISingleReport) => {
	const {
		documentListData: documentListData,
		documentType = DOCUMENT_TYPE.FILING,
	} = props;

	const renderSentimentTable = () => {
		return (
			<SentimentContainer className="position-relative">
				<SentimentItem>
					<div className="w-15">
						<SentimentLabel className="fw-bold">Score</SentimentLabel>
					</div>
					<div className="w-20">
						<SentimentLabel className="fw-bold">Quarter</SentimentLabel>
					</div>
					<SentimentLabel className="fw-bold">Title</SentimentLabel>
				</SentimentItem>
				{documentListData?.map((item: IDocumentListData, index: number) => {
					return (
						<SentimentItem key={index}>
							<div className="w-15">
								<SentimentPercentage
									className="trim-text"
									width={3}
									color="#107C10"
									backgroundColor="#107C1035">
									{convertToPercentage(
										Number(item?.score?.positive) -
										Number(item?.score?.negative)
									)}
									%
								</SentimentPercentage>
							</div>
							<div className="w-20">
								<SentimentLabel className="trim-text">
									{item.fyear} Q{item?.fqtr}
								</SentimentLabel>
							</div>

							<SentimentLabel className="text-decoration-underline trim-text">
								<Link
									href={`${ROUTES.SINGLE_REPORT}?country_id=${getDefaultMarket()}&document_type=${item?.type}&company_id=${item?.company?.id}&document_id=${item?.id}`}>
									{item.title}
								</Link>
							</SentimentLabel>
						</SentimentItem>
					);
				})}
			</SentimentContainer>
		);
	};

	return (
		<div className="mt-4">
			<RightTabContainer className="position-relative">
				<RightTabItem selected={true}>
					{toProperCase(documentType)}s
				</RightTabItem>
				<div className={`position-absolute end-0 top-0 px-1`}>
					<Popover
						className='ps-1'
						popoverTextHeader="**Related Reports**"
						popoverTextBody={`View other reports from the same company.`}
					/>
				</div>
			</RightTabContainer>
			{renderSentimentTable()}
		</div>
	);
};

export default FillingsComponent;
