// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from 'react';
import { ITooltipProps } from './Tooltip.types';
import circleInfo from '../../Assets/Icon/circle-info.svg';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';


const TooltipComponent = (props: ITooltipProps): React.JSX.Element => {

    const { tooltipPlacement, tooltipTextBody, tooltipTrigger, className } = props
    return (
        <OverlayTrigger
            placement={tooltipPlacement || 'auto'}
            trigger={tooltipTrigger || 'hover'}
            overlay={<Tooltip><Markdown remarkPlugins={[[remarkGfm, { singleTilde: false }]]}>{tooltipTextBody}</Markdown></Tooltip>}
        >
            <img
                src={circleInfo}
                alt="tooltip icon"
                className={"cursor-pinter " + className}
            />
        </OverlayTrigger>
    );
};


export default TooltipComponent;
