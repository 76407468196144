import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { AxiosInstance } from 'axios';
import _ from 'lodash';

import { FlexDisplay } from '../../../Screens/Preferences/Preferences.styled';
import {
	ContainerSendCode,
	FormGroup,
	FormInput,
	LabelInput,
	LabelResend,
	LabelSendCode,
} from '../Preferences.component.styled';
import { Button } from '../../index';
import { IValue } from '../UserSetting.component';
import { FormInput as FormInputSignIn } from '../../SignIn/SignIn.styled';
import { ChangeNameType } from '../../../Screens/Preferences/Preferences.types';
import servicesRequest, {
	REQUEST_EDIT_PROFILE_URL,
} from '../../../Config/services.config';
import { ERROR_CODES } from '../../../Constants';
import { ErrorResponse } from '../../VerificationModal/VerificationModal.types';

const ChangeEmailComponent = (props: ChangeNameType): React.JSX.Element => {
	const defaultEmail = props.email;
	const { setIsChange, isChange } = props

	const [isLoading, setIsLoading] = useState(false);
	const [email, setEmail] = useState(defaultEmail);
	const [countdown, setCountdown] = useState(0);
	const [eligibleRequestOTP, setEligibleRequestOTP] = useState(false);
	const [verificationCode, setVerificationCode] = useState('');

	useEffect(() => {
		let intervalId: string | number | NodeJS.Timeout | undefined;

		if (countdown > 0) {
			intervalId = setInterval(() => {
				setCountdown(prevCountdown => prevCountdown - 1);
			}, 1000);
		} else {
			setEligibleRequestOTP(false);
		}

		return () => {
			clearInterval(intervalId);
		};
	}, [countdown]);

	useEffect(() => {
		if (email === defaultEmail) {
			setIsChange(false);
		} else {
			setIsChange(true);
		}
	}, [email]);

	const handleRequestOTP = async () => {
		setCountdown(60);
		setEligibleRequestOTP(true);

		try {
			const request: AxiosInstance = servicesRequest();
			await request.post(REQUEST_EDIT_PROFILE_URL, {
				field: 'email',
			});

			toast.success('Check email for OTP (One-Time Password).');
		} catch (error) {
			toast.error('Error requesting OTP (One-Time Password). Please try again.');
		}
	};

	const handleSubmit = async () => {
		setIsLoading(true);
		try {
			const request: AxiosInstance = servicesRequest();
			await request.put(REQUEST_EDIT_PROFILE_URL, {
				field: 'email',
				token: verificationCode,
				first_name: props.firstName,
				last_name: props.lastName,
				email,
			});
			props.setEditMode('');
			props.changeShouldReload();

			toast.success('Profile successfully edited.');
		} catch (error) {
			const err = error as ErrorResponse;
			const statusCode = _.get(err, 'response.data.status_code');
			const message = _.get(err, 'response.data.message');

			if (statusCode === ERROR_CODES.VERIFICATION_ERROR) {
				toast.error(message);
			} else if (statusCode === ERROR_CODES.VALIDATION_ERROR) {
				toast.error('Your email must be unique.');
			} else {
				toast.error('Something went wrong. Please try again.');
			}
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className="mb-1">
			<FlexDisplay>
				<FormGroup className="mb-3 w-50">
					<LabelInput>Email</LabelInput>
					<FormInput
						type="email"
						placeholder="mail@website.com"
						value={email}
						onChange={(event: IValue) => setEmail(event.target.value)}
					/>
				</FormGroup>
			</FlexDisplay>
			{isChange && (
				<React.Fragment>
					<ContainerSendCode className="w-50 mb-2">
						<div className="w-50">
							<FormInputSignIn
								type="text"
								placeholder="Verification Code"
								value={verificationCode}
								onChange={(event: IValue) =>
									setVerificationCode(event.target.value)
								}
							/>
						</div>

						<div>
							{eligibleRequestOTP ? (
								<LabelResend>{`resend code in ${countdown}s`}</LabelResend>
							) : (
								<LabelSendCode onClick={handleRequestOTP}>
									send code
								</LabelSendCode>
							)}
						</div>
					</ContainerSendCode>
					<Button
						label="Verify"
						isLoading={isLoading}
						onClick={handleSubmit}
						width="25%"
						mobileWidth="50%"
						disabled={!isChange || verificationCode === ''}
					/>
				</React.Fragment>
			)}
		</div>
	);
};

export default ChangeEmailComponent;
