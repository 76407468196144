import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Form, InputGroup } from 'react-bootstrap';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import styled from 'styled-components';
import {
	SetURLSearchParams,
	useNavigate,
	useSearchParams,
} from 'react-router-dom';
import { AxiosInstance } from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import _ from 'lodash';

import {
	AuthorizationModal,
	ModalBody,
	PasswordChecker,
} from '../../Components/Authorization/Authorization.styled';
import {
	FormControlStyled,
	FormLabel,
	ModalTitle,
} from '../../Components/SignIn/SignIn.styled';
import { ERROR_CODES, PASSWORD_ERROR_CONDITION } from '../../Constants';
import { passwordValidationIcon } from '../../Components/SignUp/SignUp.component';
import { Button } from '../../Components';
import { FlexDisplaySmallGap } from '../Preferences/Preferences.styled';
import { Caption } from '../../Components/FirstTimeModal/FirstTimeModal.component';
import {
	IAuthorizationProps,
	ResetPasswordRequestType,
} from '../../Components/Authorization/Authorization.types';
import servicesRequest, {
	PASSWORD_RESET_SAVE_URL,
	PASSWORD_RESET_VERIFY_TOKEN_URL,
} from '../../Config/services.config';
import { ErrorResponse } from '../../Components/VerificationModal/VerificationModal.types';

const SuccessCaption = styled(Caption)`
	text-align: left;
	margin-top: 2vh;
	margin-bottom: 2vh;
`;

const STEP = {
	INPUT_NEW_PASSWORD: 'INPUT_NEW_PASSWORD',
	CONFIRM_PASSWORD: 'CONFIRM_PASSWORD',
	SUCCESS_DISPLAY: 'SUCCESS_DISPLAY',
};

const ResetPasswordScreen = (
	props: IAuthorizationProps
): React.JSX.Element | null => {
	const [showPassword, setShowPassword] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [step, setStep] = useState(STEP.CONFIRM_PASSWORD);

	const navigate = useNavigate();
	const [params, setParams]: [URLSearchParams, SetURLSearchParams] =
		useSearchParams();

	const emailParams = params.get('email');
	const tokenParams = params.get('token');

	const togglePasswordVisibility = () => setShowPassword(prevShow => !prevShow);

	const {
		handleSubmit,
		control,
		formState: { errors },
		getValues,
	} = useForm({
		defaultValues: {
			password: '',
			confirmPassword: '',
		},
	});

	const { password } = getValues();

	const atLeastEightChar =
		errors?.password?.message === PASSWORD_ERROR_CONDITION.ERR1;
	const mustContainNumberAndSymbol =
		errors?.password?.message === PASSWORD_ERROR_CONDITION.ERR3;
	const cantContainPassword =
		errors?.password?.message === PASSWORD_ERROR_CONDITION.ERR2;

	const onSubmit = async (data: ResetPasswordRequestType) => {
		setIsLoading(true);
		try {
			const request: AxiosInstance = servicesRequest();

			if (step === STEP.INPUT_NEW_PASSWORD) {
				await request.post(PASSWORD_RESET_VERIFY_TOKEN_URL, {
					email: emailParams,
					token: tokenParams,
				});

				setStep(STEP.CONFIRM_PASSWORD);
			} else {
				await request.post(PASSWORD_RESET_SAVE_URL, {
					email: emailParams,
					token: tokenParams,
					password: data.password,
				});

				setStep(STEP.SUCCESS_DISPLAY);
			}
		} catch (error) {
			const err = error as ErrorResponse;
			const statusCode = _.get(err, 'response.data.status_code');
			const message = _.get(err, 'response.data.message');
			if (statusCode === ERROR_CODES.VERIFICATION_ERROR) {
				toast.error(message);
			} else {
				toast.error('Something went wrong. Please try agin.');
			}
		} finally {
			setIsLoading(false);
		}
	};

	const handleRedirectSignIn = () => {
		props.setIsOpenAuthorization(true);
		navigate('/');
	};

	const renderInputPassword = () => {
		return (
			<React.Fragment>
				<ModalTitle>Reset Password</ModalTitle>
				<Form.Group className="mb-3 mt-2">
					<FormLabel>New Password</FormLabel>
					<InputGroup>
						<Controller
							name="password"
							control={control}
							rules={{
								required: 'Password is required',
								minLength: {
									value: 8,
									message: PASSWORD_ERROR_CONDITION.ERR1,
								},
								validate: {
									containsNameAndEmail: value => {
										try {
											if (emailParams && value.includes(emailParams)) {
												return PASSWORD_ERROR_CONDITION.ERR2;
											}
											return true;
										} catch (error) {
											return false;
										}
									}, // TODO: seems we cat check the value here bcs it may come from diff browser
									containsNumberOrSymbol: value =>
										/[0-9!@#$%^&*()_+{}\[\]:;<>,.?~\-/\\]/.test(value) ||
										PASSWORD_ERROR_CONDITION.ERR3,
								},
							}}
							render={({ field }) => (
								<FormControlStyled
									{...field}
									type={
										showPassword && step === STEP.INPUT_NEW_PASSWORD
											? 'text'
											: 'password'
									}
									placeholder="Min 8 characters"
								/>
							)}
						/>
						{step === STEP.INPUT_NEW_PASSWORD && (
							<span
								className="input-group-text cursor-pointer"
								onClick={togglePasswordVisibility}>
								{showPassword ? <FaEyeSlash /> : <FaEye />}
							</span>
						)}
					</InputGroup>
					{password !== '' && (
						<div className="mt-2">
							<PasswordChecker fulfilled={!cantContainPassword}>
								{passwordValidationIcon(!cantContainPassword)} Can’t contain
								your name or email address
							</PasswordChecker>
							<PasswordChecker fulfilled={!atLeastEightChar}>
								<FlexDisplaySmallGap>
									{passwordValidationIcon(!atLeastEightChar)} At least 8
									characters
								</FlexDisplaySmallGap>
							</PasswordChecker>
							<PasswordChecker fulfilled={!mustContainNumberAndSymbol}>
								<FlexDisplaySmallGap>
									{passwordValidationIcon(!mustContainNumberAndSymbol)} Contains
									a number or symbol
								</FlexDisplaySmallGap>
							</PasswordChecker>
						</div>
					)}
				</Form.Group>
				{step === STEP.CONFIRM_PASSWORD && (
					<Form.Group className="mb-3 mt-2">
						<FormLabel>Confirm Password</FormLabel>
						<InputGroup>
							<Controller
								name="confirmPassword"
								control={control}
								rules={{
									required: 'Confirm password is required',
									validate: {
										containsTheSamePassword: value =>
											value === getValues('password') ||
											'The password is incorrect',
									},
								}}
								render={({ field }) => (
									<FormControlStyled
										{...field}
										type={'password'}
										placeholder="Min 8 characters"
									/>
								)}
							/>
						</InputGroup>
						<small className="text-red">
							{errors?.confirmPassword && errors.confirmPassword?.message}
						</small>
					</Form.Group>
				)}
				<Button
					isLoading={isLoading}
					label="Next"
					onClick={handleSubmit(onSubmit)}
				/>
			</React.Fragment>
		);
	};

	const renderSuccess = () => {
		return (
			<React.Fragment>
				<ModalTitle>Sign in with new password</ModalTitle>
				<SuccessCaption>
					You can now use your new password to sign in
				</SuccessCaption>
				<Button label="Sign in" onClick={handleRedirectSignIn} />
			</React.Fragment>
		);
	};

	return (
		<AuthorizationModal
			centered={true}
			backdrop="static"
			show={true}
			onHide={() => { }}>
			<ToastContainer />
			<ModalBody>
				{step !== STEP.SUCCESS_DISPLAY && renderInputPassword()}
				{step === STEP.SUCCESS_DISPLAY && renderSuccess()}
			</ModalBody>
		</AuthorizationModal>
	);
};

export default ResetPasswordScreen;
