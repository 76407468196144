import React from 'react';
import { IKeywordList, IPaginationData } from '../../Screens/Company/Company.types';
import {
	RightTabContainer,
	RightTabItem,
} from '../../Screens/SingleReport/SingleReport.styled';
import { TableData, TableHead } from '../../Screens/Company/Company.styled';
import { FaExpandAlt } from 'react-icons/fa';
import { Popover } from '..'


const KeywordTable = (props: { navigateToCompare: () => void, keywordList: IKeywordList[], keywordPagination: IPaginationData, limit?: number }) => (
	<React.Fragment>
		<RightTabContainer className="pt-2 mx-2 justify-content-between">
			<RightTabItem onClick={() => { }} selected={true}>
				Top Keywords
				<Popover
					className='ps-1'
					popoverTrigger='hover'
					popoverPlacement='auto'
					popoverTextHeader="**Top Keywords**"
					popoverTextBody={`Shows all keywords from the company within the selected timeframe (default: 5 years).`}
				/>
			</RightTabItem>
			<div
				className="d-flex align-items-center"
				onClick={props.navigateToCompare}
				style={{ cursor: 'pointer' }}>
				<h5 className="fs-10 fw-400 mb-0 me-1">View More </h5>
				<div className="bg-lightgrey rounded px-1">
					<FaExpandAlt className="fs-10" />
				</div>
			</div>
		</RightTabContainer>
		<div className="table-container">
			<div className="table-scrollable">
				<table className="table table-striped table-borderless w-100">
					<TableHead>
						<tr>
							<td>Rank</td>
							<td>Keyword</td>
							<td>Frequency</td>
						</tr>
					</TableHead>
					<tbody>
						{props.keywordList.map(
							({ word, count }: IKeywordList, i: number) => (
								<tr key={i}>
									<TableData>{((props.keywordPagination.current_page - 1) * (props.limit || 10)) + i + 1}</TableData>
									<TableData>{word}</TableData>
									<TableData>{count}</TableData>
								</tr>
							)
						)}
					</tbody>
				</table>
			</div>
		</div>
	</React.Fragment>
);

export default KeywordTable;
