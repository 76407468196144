import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';
import { Controller, useForm } from 'react-hook-form';
import { AxiosInstance } from 'axios';
import { toast } from 'react-toastify';
import _ from 'lodash';

import servicesRequest, {
	PASSWORD_RESET_REQUEST_URL,
} from '../../Config/services.config';
import { FormInput, FormLabel, ModalTitle } from '../SignIn/SignIn.styled';
import {
	FindPasswordTypes,
	PasswordRequestData,
} from '../Authorization/Authorization.types';
import { BackButton } from '../Authorization/Authorization.styled';
import { AUTHORIZATION_STEP, ERROR_CODES } from '../../Constants';
import { Button } from '../index';
import { ErrorResponse } from '../VerificationModal/VerificationModal.types';

const Label = styled.p`
	color: #8b8b8b;
	font-size: 12px;
	font-style: normal;
	font-weight: 600;
	line-height: 20px;
`;

const FindPasswordComponent = (props: FindPasswordTypes): React.JSX.Element => {
	const {
		handleSubmit,
		control,
		formState: { errors },
	} = useForm({
		defaultValues: {
			email: '',
		},
	});

	const [isLoading, setIsLoading] = useState(false);

	const { changeStep } = props;

	const onSubmit = async (data: PasswordRequestData) => {
		setIsLoading(true);
		try {
			const request: AxiosInstance = servicesRequest();
			await request.post(PASSWORD_RESET_REQUEST_URL, data);

			toast.success('Please check your email to complete the password reset process.');
		} catch (error) {
			const err = error as ErrorResponse;
			const statusCode = _.get(err, 'response.data.status_code');

			if (statusCode === ERROR_CODES.VALIDATION_ERROR) {
				toast.error("There seems to be an issue with your email. Please double-check and try again.");
			} else {
				toast.error('Something went wrong. Plese try again.');
			}
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<React.Fragment>
			<ModalTitle>
				<BackButton onClick={() => changeStep(AUTHORIZATION_STEP.LOGIN)} />
				Find your password
			</ModalTitle>
			<div className="mt-4">
				<Form>
					<Form.Group className="mb-3">
						<FormLabel>Email</FormLabel>
						<Controller
							name="email"
							control={control}
							rules={{
								required: 'Email is required',
								pattern: {
									value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, // eslint-disable-line
									message: 'Invalid email address',
								},
							}}
							render={({ field: { value, onChange } }) => (
								<FormInput
									onChange={onChange}
									type="email"
									placeholder="mail@website.com"
									value={value}
									rules={{
										required: 'Please input your email',
									}}
								/>
							)}
						/>
						<small className="text-red">
							{errors?.email && errors.email?.message}
						</small>
					</Form.Group>
					<Label>You will receive the email shortly</Label>
					<Button
						isLoading={isLoading}
						label="Send"
						onClick={handleSubmit(onSubmit)}
					/>
				</Form>
			</div>
		</React.Fragment>
	);
};

export default FindPasswordComponent;
