import styled, { css } from 'styled-components';
import { desktop, laptop, tablet } from '../../Utils/layout.utils';
import { Tag } from 'antd';
import { Card, Row } from 'react-bootstrap';

export const CustomTag = styled(Tag) <{
	bgColor?: string;
	color?: string;
	fontSize?: string;
}>`
	background-color: ${props => props.bgColor || 'red'} !important;
	color: ${props => props.color || 'white'} !important;
	font-size: ${props => props.fontSize || '10px'};
	font-style: normal;
	font-weight: 600;
	padding: 2px 6px;

	${tablet(css`
		padding: 1px 5px;
	`)};
`;

export const CardContainer = styled(Row)`
	margin: 0px;
`;

export const StyledCard = styled(Card) <{ bgColor?: string }>`
	background-color: ${props => props.bgColor || '#F0FDDB'};
	padding: 4px;
	border-radius: 8px;
	min-height: 100%;
	margin: 0;
`;

export const CardPercentage = styled.div`
	color: #4e4e4e;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	margin: 0;
	float: right;
	align-self: end;
`;

export const IndustryText = styled.p`
	font-weight: 600;
	font-size: 10px;
	font-style: normal;
	margin-bottom: 0px;
	color: #4e4e4e;
	padding-top: 2px;
	width: 50%;
`;
