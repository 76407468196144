import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';

import servicesRequest, {
	NOTIFICATION_URL,
	NOTIFICATION_CHANGE_URL,
} from '../../Config/services.config';
import {
	GetNotificationResponse,
	NotificationType,
} from '../../Screens/Preferences/Preferences.types';

import {
	FormLabel,
	FormValue,
	LabelContainer,
	LabelParent,
} from './Preferences.component.styled';

const NotificationComponent = (): React.JSX.Element => {
	const [notifications, setNotifications] = useState<NotificationType[]>([]);

	const getNotification = (response: GetNotificationResponse) => {
		const notificationsResponse: NotificationType[] = _.get(
			response,
			'data.data.data',
			[]
		);

		setNotifications(notificationsResponse);
	};

	useEffect(() => {
		const request = servicesRequest();
		request
			.get(NOTIFICATION_URL)
			.then(response => getNotification(response))
			.catch(() =>
				console.error('Something went wrong. Please refresh the page.')
			);
	}, []);

	const handleOnChange = async (
		notification: NotificationType,
		turnOn: boolean,
		index: number
	): Promise<void> => {
		try {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			const newArr = notifications.toSpliced(index, 1, {
				...notification,
				user_preference: {
					id: notification.id,
					turn_on: !turnOn,
				},
			});
			setNotifications(newArr);

			const request = servicesRequest();
			await request.post<void>(
				NOTIFICATION_CHANGE_URL + `/${notification.id}/change`,
				{
					turn_on: !turnOn,
				}
			);
			toast.success('Your notification settings have been successfully updated.');
		} catch (error) {
			toast.error('Something went wrong. Please refresh the page.');
		}
	};

	return (
		<React.Fragment>
			{notifications.length > 0 &&
				notifications.map((notification, index) => {
					const {
						id,
						name,
						description,
						user_preference: userPreference,
					} = notification;
					const turnOn = !!userPreference?.turn_on || false;

					return (
						<LabelParent key={id}>
							<LabelContainer>
								<div>
									<FormLabel>{name}</FormLabel>
									<FormValue>{description}</FormValue>
								</div>
								<Form.Check
									type="switch"
									id="custom-switch"
									value={id.toString()}
									checked={turnOn}
									onChange={() => handleOnChange(notification, turnOn, index)}
								/>
							</LabelContainer>
						</LabelParent>
					);
				})}
		</React.Fragment>
	);
};

export default NotificationComponent;
