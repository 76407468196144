/* eslint-disable */
// @ts-nocheck
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import SingleReportDesktop from './SingleReport.desktop.screen';
import SingleReportMobile from './SingleReport.mobile.screen';
import { RootContainer } from './SingleReport.styled';

import { IAuthorizationProps } from '../../Components/Authorization/Authorization.types';
import { IDocumentListCompany, IDocuments } from './SingleReport.types';
import { useSearchParams } from 'react-router-dom';
import servicesRequest, {
	COMPANIES_URL,
	DOCUMENT_LIST_URL,
	DOCUMENT_URL,
	ESG9_URL,
	SCORES_URL,
} from '../../Config/services.config';
import { getDefaultCalls } from '../../Utils';

export const LEFT_TAB = {
	SENTIMENT: 'SENTIMENT',
	ESG: 'ESG',
};

export const RIGHT_TAB = {
	OVERVIEW: 'OVERVIEW',
	SENTIMENT: 'SENTIMENTS',
	ESG: 'ESG',
};

export const isDesktop = window.innerWidth >= 768;

const SingleReportScreen = (props: IAuthorizationProps) => {
	const [documents, setDocuments] = useState<IDocuments | null>(null);
	const [calls, setCalls] = useState<IDocumentListCompany[] | null>(null);
	const [selectedLeftTab, setSelectedLeftTab] = useState(LEFT_TAB.SENTIMENT);
	const [selectedRightTab, setSelectedRightTab] = useState(RIGHT_TAB.OVERVIEW);

	const [documentListData, setDocumentListData] = useState(null);
	const [companies, setCompanies] = useState(null);
	const [scoresAverage, setScoresAverage] = useState(null);
	const [esg9Average, setEsg9Average] = useState(null);

	const [searchParams] = useSearchParams();
	const countryId = searchParams.get('country_id') || '';
	const documentType = searchParams.get('document_type') || getDefaultCalls();
	const companyId = searchParams.get('company_id') || '';
	const documentId = searchParams.get('document_id') || '';

	const fetchingDocumentList = async () => {
		try {
			const request = servicesRequest();
			const {
				data: {
					data: { data },
				},
			} = await request.get(
				DOCUMENT_LIST_URL +
				`?country_id=${countryId}&document_type=${documentType}&company_id=${companyId}`
			);

			if (data.length > 0) {
				setDocumentListData(data);
			}
		} catch (error) {
			console.error('Something went wrong. Please check and try again.');
		}
	};

	const fetchingDocument = async () => {
		try {
			const request = servicesRequest();
			const {
				data: { data },
			} = await request.get(DOCUMENT_URL + `/${documentId}`);

			setDocuments(data);
		} catch (error) {
			console.error('Something went wrong. Please check and try again.');
		}
	};

	const fetchingCompanies = async () => {
		try {
			const request = servicesRequest();
			const {
				data: { data },
			} = await request.get(
				COMPANIES_URL + `/${companyId}?document_type=${documentType}`
			);

			setCompanies(data);
		} catch (error) {
			console.error('Something went wrong. Please check and try again.');
		}
	};

	const fetchingScoresAverage = async () => {
		try {
			const request = servicesRequest();
			const {
				data: { data },
			} = await request.get(
				SCORES_URL +
				`/average?document_type=${documentType}&company_id=${companyId}`
			);

			setScoresAverage(data);
		} catch (error) {
			console.error('Something went wrong. Please check and try again.');
		}
	};

	const fetchingEsg9Average = async () => {
		try {
			const request = servicesRequest();
			const {
				data: { data },
			} = await request.get(
				ESG9_URL +
				`/average?document_type=${documentType}&company_id=${companyId}`
			);

			if (documentType === 'call') {
				setEsg9Average(data.call);
			}

			if (documentType === 'filing') {
				setEsg9Average(data.filing);
			}
		} catch (error) {
			console.error('Something went wrong. Please check and try again.');
		}
	};

	useEffect(() => {
		fetchingDocumentList().catch(console.error);
		fetchingCompanies().catch(console.error);
	}, [countryId, documentType, companyId]);

	useEffect(() => {
		fetchingScoresAverage().catch(console.error);
		fetchingEsg9Average().catch(console.error);
	}, [companyId, documentType]);

	useEffect(() => {
		fetchingDocument().catch(console.error);
	}, [documentId]);

	const onChangeLeftTab = (value: string) => {
		setSelectedLeftTab(value);
	};

	const onChangeRightTab = (value: string) => {
		setSelectedRightTab(value);
	};

	useEffect(() => {
		props.setMobileTitle(documents?.companyName);
	}, [documents]);

	const defaultProps = () => ({
		selectedLeftTab,
		setSelectedLeftTab,
		selectedRightTab,
		setSelectedRightTab,
		onChangeLeftTab,
		onChangeRightTab,
		documents,
		setDocuments,
		calls,
		setCalls,
		documentListData,
		companies,
		scoresAverage,
		esg9Average,
		documentType,
	});

	if (isDesktop) {
		return (
			<RootContainer>
				<SingleReportDesktop {...defaultProps()} />
			</RootContainer>
		);
	}

	return <SingleReportMobile {...defaultProps()} />;
};

export default SingleReportScreen;
