import React from 'react';
import { Select } from 'antd';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';

import {
	ContainerGearIcon,
	ContainerNavBar,
	GearIcon,
	NavLink,
} from '../NavigationBar.styled';
import { CALL, MARKET_DROPDOWN_SELECT } from '../../../Constants';
import {
	FlexDisplayMediumGap,
	FlexDisplaySmallGap,
	FlexDisplaySpaceBetween,
} from '../../../Screens/Preferences/Preferences.styled';
import { INavigationBar } from '../NavigationBar.types';
import { ROUTES } from '../../../Constants/routes';
import Logo from '../../../Assets/logo.png';
import USAFlag from '../../../Assets/usa-icon.svg';
import HKFlag from '../../../Assets/hk-icon.svg';
import { LogoContainer } from '../NavigationBar.component';
import AutoCompleteComponent from '../../Compare/AutoComplete';

import { Button } from '../../index';

const { Option } = Select;

export const MENU = [
	{
		label: 'Home',
		value: 'home',
		route: ROUTES.ROOT,
	},
	{
		label: 'Reports',
		value: 'reports',
		route: ROUTES.REPORT,
	},
	{
		label: 'Compare',
		value: 'compare',
		route: ROUTES.COMPARE,
	},
	{
		label: 'Companies',
		value: 'companies',
		route: ROUTES.COMPANY,
	},
	{
		label: 'Industries',
		value: 'industries',
		route: ROUTES.INDUSTRIES,
	},
];

export const renderNavigationMenu = (currentRoute: string) => {
	if (currentRoute === '/single-report' || currentRoute === 'single-report') { currentRoute = '/reports' }

	return (
		<React.Fragment>
			{MENU.map((item, index) => (
				<NavLink
					href={`${item.route}`}
					key={index}
					selected={
						`/${item.route}` === currentRoute || item.route === currentRoute
					}>
					{item?.label}
				</NavLink>
			))}
		</React.Fragment>
	);
};

const NavigationDesktopComponent = (props: INavigationBar) => {
	const {
		selectedMarket,
		setIsOpenAuthorization,
		handleChangeMarket,
		isLoggedIn,
		onClickConfirm,
		changeDocumentType,
		selectedCall,
		showDocumentDropdown,
		showMarketDropdown
	} = props;

	const navigate = useNavigate();

	const location = useLocation();
	const currentRoute = location.pathname;

	const handleClick = () => {
		if (isLoggedIn) {
			navigate(`/${ROUTES.PREFERENCES}`);
		} else {
			setIsOpenAuthorization(true);
		}
	};

	return (
		<ContainerNavBar className="py-3 px-5">
			<FlexDisplaySpaceBetween>
				<FlexDisplayMediumGap className="">
					<LogoContainer
						src={Logo}
						alt="FS"
						className="cursor-pointer"
						onClick={() => {
							navigate('/');
						}}
					/>
					<FlexDisplaySmallGap>
						{showMarketDropdown && <Select
							labelInValue
							defaultValue={selectedMarket}
							size="small"
							style={{ width: 120 }}
							onSelect={handleChangeMarket}>
							{MARKET_DROPDOWN_SELECT.map(option => (
								<Option
									key={option.value}
									value={option.value}
									label={option.label}>
									<FlexDisplaySmallGap>
										<div>
											<img
												src={option.value === 1 ? USAFlag : HKFlag}
												alt={option.value.toString()}
											/>
										</div>
										<div>
											<span className="ant-select-selection-item">
												{option.label}
											</span>
										</div>
									</FlexDisplaySmallGap>
								</Option>
							))}
						</Select>}
						{showDocumentDropdown && (
							<Select
								labelInValue
								defaultValue={selectedCall}
								style={{ width: 145 }}
								options={CALL}
								size="small"
								onSelect={(e: any) => {
									changeDocumentType(e);
								}}
							/>
						)}
					</FlexDisplaySmallGap>
				</FlexDisplayMediumGap>
				<FlexDisplaySpaceBetween className="">
					{renderNavigationMenu(currentRoute)}
				</FlexDisplaySpaceBetween>
				<FlexDisplaySpaceBetween className="">
					<AutoCompleteComponent onClickConfirm={onClickConfirm} />
					{isLoggedIn ?
						<ContainerGearIcon style={{ display: 'flex', flexDirection: 'row' }}>
							<GearIcon className="nav-icon" onClick={handleClick} />
						</ContainerGearIcon> :
						<Button
							label="Sign in / Register"
							onClick={handleClick}
							mobilePadding="1.5vw"
						/>}

				</FlexDisplaySpaceBetween>
			</FlexDisplaySpaceBetween>
		</ContainerNavBar>
	);
};

export default NavigationDesktopComponent;
