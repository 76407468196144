import { useState } from 'react';
import _ from 'lodash';
import { Pagination } from 'antd';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import {
	TableData,
	TableHead,
	YearTab,
} from '../../Screens/Company/Company.styled';
import { LineChart } from '../index';
import { FlexDisplaySpaceBetween } from '../../Screens/Preferences/Preferences.styled';
import { LoadingComponent } from '../../Screens/Industries/Industries.desktop.screen';

const KeywordListTableComponent = (props: any) => {
	const {
		keywords,
		documentType,
		getKeywordFrequencies,
		fetchingKeywords,
		selectedWatchlistDetails,
		limit = 10,
		isMobile = false,
		isLoadingKeywords,
		placement
	} = props;
	const [popoverContent, setPopoverContent] = useState(null);
	const [page, setPage] = useState<number>(1);

	const keywordsData = _.get(keywords, documentType, []);
	const keywordMeta = _.get(keywords, 'meta', {});

	const currentPage = _.get(keywordMeta, 'current_page', 1);
	const total = _.get(keywordMeta, 'total', 1);
	const token = localStorage.getItem('token');

	const fetchData = async (word: string) => {
		try {
			const data = await getKeywordFrequencies(word);
			// eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
			const mapping = data.map(
				(item: { date: string | number | Date; count: number }) => {
					return {
						date: new Date(item.date),
						value: Number(item.count),
					};
				}
			);
			setPopoverContent(mapping);
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	};

	const renderPopoverContent = (item: any) => {
		return (
			<Popover id={`popover-${item?.word}`}>
				<Popover.Body>
					<FlexDisplaySpaceBetween>
						<YearTab selected={true}>{item?.word}</YearTab>
						<YearTab>{item?.count}</YearTab>
					</FlexDisplaySpaceBetween>
					{popoverContent !== null && (
						<div className="p-2">
							<LineChart
								data={popoverContent}
								title={''}
								showToolTips={false}
							/>
						</div>
					)}
				</Popover.Body>
			</Popover>
		);
	};

	return (
		<div>
			<div className="table-container bg-white rounded">
				<div className="table-scrollable d-flex">
					<table className={`table table-borderless w-100 p-3 ${!isLoadingKeywords && "table-striped"}`}>
						<TableHead>
							<tr>
								<td className="px-3">Rank</td>
								<td className="px-3">Keyword</td>
								<td className="px-3">Frequency</td>
							</tr>
						</TableHead>
						<tbody>
							{isLoadingKeywords && (
								<tr>
									<td colSpan={3}>
										<div className="m-2">
											<LoadingComponent />
										</div>
									</td>
								</tr>
							)}
							{!isLoadingKeywords && keywordsData?.map((item: any, index: number) => {
								return (
									<OverlayTrigger
										key={index}
										trigger={['hover', 'focus']}
										placement={placement ? placement : isMobile ? 'top' : 'right'}
										overlay={renderPopoverContent(item)}
										onEnter={() => fetchData(item?.word)}>
										<tr className="cursor-pointer">
											<TableData className="px-3">
												{page * limit - limit + index + 1}
											</TableData>
											<TableData className="px-3">{item?.word}</TableData>
											<TableData className="px-3">{item?.count}</TableData>
										</tr>
									</OverlayTrigger>
								);
							})}
						</tbody>
					</table>
				</div>
				{!token && keywordsData.length == 0 && !isLoadingKeywords &&
					<div className="text-center pb-3 px-2">
						Register to view watchlist keywords!
					</div>
				}
				{token && keywordsData.length == 0 && keywords != undefined && !isLoadingKeywords &&
					<div className="text-center pb-3 px-2">
						Add your watchlist to view watchlist keywords.
					</div>
				}
			</div>
			<div className="d-flex justify-content-end margin-top-2 bg-white rounded">
				<Pagination
					size="small"
					showSizeChanger={false}
					defaultCurrent={currentPage}
					total={total}
					pageSize={limit}
					onChange={async (pages: number) => {
						await fetchingKeywords(selectedWatchlistDetails, pages);
						setPage(pages);
					}}
				/>
			</div>
		</div>
	);
};

export default KeywordListTableComponent;
