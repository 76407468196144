// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { DATA_TYPE, SCREEN_SIZE, SORT_DATE } from '../../Constants';
import useGetListData from '../../Hooks/useGetListData';

import CompanyDesktop from './Company.desktop.screen';
import CompanyMobile from './Company.mobile.screen';
import { ROUTES } from '../../Constants/routes';
import { calculateEsgCompaniesIndustries } from '../../Components/SingleReport';
import useResizeScreen from '../../Hooks/useResizeScreen';
import { convertToPercentage, getDefaultCalls, getDefaultMarket } from '../../Utils';
import servicesRequest, { COMPANIES_URL, COMPANY_URL } from '../../Config/services.config';
import { toast } from 'react-toastify';
import { convertValue } from '../../Components/Home/TrendingReportsTable.component';

const { COMPANY } = DATA_TYPE;

const CompanyScreen = () => {
	const screenType = useResizeScreen();
	const [searchParams] = useSearchParams();
	const documentType = searchParams.get('document_type') || getDefaultCalls();
	const id = searchParams.get('company_id') || '';
	const startDateParams =
		searchParams.get('start-date') || SORT_DATE.FIVE_YEARS.gte;
	const endDateParams =
		searchParams.get('end-date') || SORT_DATE.FIVE_YEARS.lte;

	const stateProps = useGetListData({
		dataType: COMPANY,
		documentType,
		id,
		startDateParams,
		endDateParams,
	});

	const [companyData, setCompanyData] = useState(null);

	const navigate = useNavigate();

	const companyIdParams = id || stateProps?.data?.id || '';

	const onDocumentUrlClicked = (documentId: number) => {
		navigate(
			`/${ROUTES.SINGLE_REPORT}?country_id=${getDefaultMarket()}&document_type=${documentType}&document_id=${documentId}&company_id=${companyIdParams}`
		);
	};

	stateProps.onChangeCompanyId = (companyId: string) => {
		navigate(
			`?country_id=${getDefaultMarket()}&document_type=${documentType}&company_id=${companyId}`
		);
	};

	stateProps.onChangeDate = (startDate: string, endDate: string) => {
		navigate(
			`?country_id=${getDefaultMarket()}&document_type=${documentType}&company_id=${id}&start-date=${startDate}&end-date=${endDate}`
		);

		window.location.reload();
	};

	const esgProps = calculateEsgCompaniesIndustries(stateProps.esg9Average);

	const onClickViewMore = () => {
		navigate(
			`/${ROUTES.REPORT}?name=${stateProps?.document?.document?.company?.name}&stock-code=${stateProps?.document?.document?.company?.stock_code}`
		);
	};

	const navigateToCompare = () => {
		const name = _.get(stateProps.documentList, '[0].company.name', '');
		const stockCode = _.get(stateProps.documentList, '[0].company.stock_code', '');

		navigate(
			`/${ROUTES.COMPARE
			}?document-type=${documentType}&company-industry-filter=${encodeURIComponent(
				`${name?.replaceAll(',', '_')},${stockCode}`
			)}&date-start=${stateProps?.startDate}&date-finish=${stateProps?.finishDate
			}&keyword-page=1&all-document-type=false`
		);
	};

	const request = servicesRequest();

	const getCompanySentimentReturn = () => {
		if (companyIdParams) {
			const sentimentUrl =
				stateProps.startDate && stateProps.finishDate
					? `${COMPANIES_URL}/${companyIdParams}?country_id=${getDefaultMarket()}&document_type=${documentType}&document_date.gte=${stateProps.startDate}&document_date.lte=${stateProps.finishDate}`
					: `${COMPANIES_URL}/${companyIdParams}?country_id=${getDefaultMarket()}&document_type=${documentType}`;
			request
				.get(sentimentUrl)
				.then((response: any) => {
					const responseData = _.get(
						response,
						`data.data`,
						'N/A'
					);
					setCompanyData(responseData);
				})
				.catch(() =>
					console.error('Something went wrong. Please refresh the page.')
				);
		}
	};

	useEffect(() => {
		getCompanySentimentReturn()
	}, [companyIdParams])

	const lastStockPrices = _.get(stateProps, `stockPrices[${stateProps?.stockPrices?.length - 1}].c`, 0)
	const firstStockPrices = _.get(stateProps, 'stockPrices[0].c', 0)

	const companyReturn = convertValue(convertToPercentage((lastStockPrices - firstStockPrices) / firstStockPrices)).value

	return (
		<React.Fragment>
			{screenType === SCREEN_SIZE.TABLET ||
				screenType === SCREEN_SIZE.MOBILE ? (
				<CompanyMobile
					{...stateProps}
					onDocumentUrlClicked={onDocumentUrlClicked}
					documentType={documentType}
					esgProps={esgProps}
					onClickViewMore={onClickViewMore}
					navigateToCompare={navigateToCompare}
					companyData={companyData}
					companyReturn={companyReturn}
				/>
			) : (
				<CompanyDesktop
					{...stateProps}
					onDocumentUrlClicked={onDocumentUrlClicked}
					documentType={documentType}
					esgProps={esgProps}
					onClickViewMore={onClickViewMore}
					navigateToCompare={navigateToCompare}
					companyData={companyData}
					companyReturn={companyReturn}
				/>
			)}
		</React.Fragment>
	);
};

export default CompanyScreen;
