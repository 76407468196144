import React, { useState } from 'react';
import { BiTrendingDown, BiTrendingUp } from 'react-icons/bi';
import _ from 'lodash';
import { Pagination, Popconfirm, Select } from 'antd';
import {
	HiOutlinePencil,
	HiOutlinePlusCircle,
	HiOutlineTrash,
} from 'react-icons/hi';
import { FaArrowLeft } from 'react-icons/fa';

import {
	InformationPercentage,
	TableData,
	TableHead,
	WhiteContainer,
	YearTab,
} from '../Company/Company.styled';
import {
	RightTabContainer,
	RightTabItem,
	ShowMobileOnly,
} from '../SingleReport/SingleReport.styled';
import KeywordListTableComponent from '../../Components/Home/KeywordListTable.component';
import {
	FlexDisplay,
	FlexDisplayMediumGap,
	FlexDisplaySmallGap,
	FlexDisplaySpaceBetween,
} from '../Preferences/Preferences.styled';
import { convertToPercentage, trimStringToLength } from '../../Utils';
import CompanyChartListComponent from '../../Components/Home/CompanyChartList.component';
import { LIST_TYPE } from '../Industries/Industries.screen';
import { LIST_TYPE_SECTOR } from './Home.screen';
import { IPaginationMeta, ITrendingReportsData } from './Home.types';
import { renderDisclaimer } from '../../Components/SingleReport';
import SearchCompanyComponent from '../../Components/Home/SearchCompany.component';
import AddWatchlistComponent from '../../Components/Home/AddWatchlist.component';
import EarningCallTable from '../../Components/Company/EarningTable.component';
import { FilterReportsButton } from '../../Components/Compare/SideTab.component';
import { Popover } from '../../Components';


const renderWatchlists = (item: any, index: number, onClickCard: (isCompany: boolean, dataId: number) => void) => {
	const { code, company_id, name } = item;

	const type = item?.type === 'company' ? 'C' : 'I';

	let positive = _.get(item, 'score.positive', null);
	positive = _.isNull(positive) ? '-' : `${convertToPercentage(positive)}%`;

	let negative = _.get(item, 'score.negative', null);
	negative = _.isNull(negative) ? '-' : `${convertToPercentage(negative)}%`;

	let neutral = _.get(item, 'score.neutral', null);
	neutral = _.isNull(neutral) ? '-' : `${convertToPercentage(neutral)}%`;

	let sentiment = _.get(item, 'final_score.sentiment', null);
	sentiment = _.isNull(sentiment) ? '-' : `${convertToPercentage(sentiment)}%`;

	let change = _.get(item, 'final_score.change', null);
	const changeTrend = change === null ? '-' : change > 0;
	change = _.isNull(change) ? '-' : `${convertToPercentage(change)}%`;

	const isCompany = item?.type === 'company';
	// const type = isCompany ? 'C' : 'I';
	const dataId = isCompany ? company_id : code;

	return (
		<div className="px-20 py-2" key={index}>
			<div className="d-flex align-items-center justify-content-between mb-15">
				<div className="d-flex gap-2" onClick={() => onClickCard(isCompany, dataId)}>
					<h3 className="fs-10 m-0 fw-600 text-neutral align-self-center">
						{name}
					</h3>
					<h3 className="fs-10 m-0 fw-600 text-white p-1 bg-darkerblue rounded">
						{code}
					</h3>
				</div>
				<div className="d-flex gap-2">
					{type === 'I' ? (
						<InformationPercentage
							className="bg-blue rounded"
							textStyle="#225FB0">
							{type}
						</InformationPercentage>
					) : (
						<InformationPercentage
							className="bg-darkerblue rounded"
							textStyle="#ffffff">
							{type}
						</InformationPercentage>
					)}
				</div>
			</div>
			<div className="d-flex justify-content-between align-items-center p-2 bg-lightgrey rounded mt-15">
				<div className="d-flex gap-2 align-items-center">
					<h2 className="fs-24 fw-700 m-0">{sentiment}</h2>
					<h2 className="fs-12 fw-600 m-0">
						{changeTrend === true && (
							<>
								<BiTrendingUp className="fs-12" color="#107C10" />{' '}
								<span className="text-green">{change}</span>
							</>
						)}
						{!changeTrend && (
							<>
								<BiTrendingDown className="fs-12" color="#D13438" />
								<span className="text-red">{change}</span>
							</>
						)}
					</h2>
				</div>
				<div className="d-flex gap-1">
					<InformationPercentage
						className="bg-success-100 rounded"
						textStyle="#107C10">
						{positive}
					</InformationPercentage>
					<InformationPercentage
						className="bg-danger-33 rounded"
						textStyle="#D13438">
						{negative}
					</InformationPercentage>
					<InformationPercentage className="bg-light rounded">
						{neutral}
					</InformationPercentage>
				</div>
			</div>
		</div>
	);
};

const HomeMobile = (props: any) => {
	const [isEditWatchlist, setIsEditWatchlist] = useState(false);
	const [isShowAlertModal, setIsShowAlertModal] = useState(false);
	const [watchlistToEdit, setWatchlistToEdit] = useState<any>(null);

	const {
		watchlists,
		setSelectedWatchlist,
		fetchingWatchlistDetail,
		selectedWatchlist,
		watchlistDetails,
		trendingData,
		documentType,
		onChangePage,
		selectedType,
		setSelectedType,
		selectedListType,
		setSelectedListType,
		trendingReports,
		deleteFromWatchlists,
		onChangeWatchlist,
		deleteWatchlist,
		filter,
		setFilter,
		onClickCard,
	} = props;

	const watchlistData = _.get(watchlists, 'data', []);

	const watchlistDetailsData = _.get(watchlistDetails, 'data', []);
	const watchlistDetailsMeta = _.get(watchlistDetails, 'meta', {});

	const currentPage = _.get(watchlistDetailsMeta, 'current_page', 1);
	const total = _.get(watchlistDetailsMeta, 'total', 1);

	const trendingList = _.get(trendingData, 'data', []);
	const trendingMeta = _.get(trendingData, 'meta', {});
	const trendingCurrentPage = _.get(trendingMeta, 'current_page', 1);
	const trendingTotal = _.get(trendingMeta, 'total', 1);
	const token = localStorage.getItem('token');

	const trendingReportsData: ITrendingReportsData[] = _.get(
		trendingReports,
		'data',
		[]
	);

	const trendingReportsMeta: IPaginationMeta = _.get(
		trendingReports,
		'meta',
		{}
	);

	const watchlistDropdownSelect = watchlistData.map(
		(item: { name: string; id: number }) => ({
			label: item.name,
			value: item.id,
			id: item.id,
		})
	);

	const listType = selectedListType === LIST_TYPE_SECTOR.COMPANIES ? 'companies' : 'industries'

	if (isEditWatchlist) {
		return (
			<>
				<div className="py-lg-1 px-3 " style={{ paddingTop: '4.3rem' }}>
					<div
						className="w-20 cursor-pointer mb-2"
						onClick={() => setIsEditWatchlist(false)}>
						<FilterReportsButton className="py-1 px-2">
							<FlexDisplay>
								<FaArrowLeft className="mx-1" />
								<div>Back</div>
							</FlexDisplay>
						</FilterReportsButton>
					</div>
					<div className="d-flex justify-content-between w-100 align-items-center">
						<h5 className="fs-12 fw-500 m-0 w-50 ">Edit Watchlist</h5>
						<div className="w-50">
							<SearchCompanyComponent {...props} />
						</div>
					</div>
					<hr />
					<>
						<FlexDisplaySpaceBetween>
							<FlexDisplayMediumGap>
								<Select
									labelInValue
									defaultValue={watchlistDropdownSelect[0]}
									style={{ width: 145 }}
									options={watchlistDropdownSelect}
									size="small"
									onSelect={async (item: any) => {
										setSelectedWatchlist({ ...item, id: item.value });
										await fetchingWatchlistDetail({ ...item, id: item.value });
									}}
								/>
								<div className="d-flex gap-1 p-1">
									<h3
										className="fs-10 fw-600 m-0 cursor-pointer"
										onClick={() => {
											setIsShowAlertModal(true);
											setWatchlistToEdit(selectedWatchlist);
										}}>
										<HiOutlinePencil className="fs-12 text-darkblue cursor-pointer" />
									</h3>
									<Popconfirm
										title="Delete Watchlist"
										description={`Are you sure to delete ${selectedWatchlist?.label} ?`}
										onConfirm={() => {
											deleteWatchlist(selectedWatchlist);
										}}
										okText="Yes"
										cancelText="No">
										<HiOutlineTrash className="fs-12 text-red cursor-pointer" />
									</Popconfirm>
								</div>
							</FlexDisplayMediumGap>
							<div
								className="fs-12 cursor-pointer"
								onClick={() => {
									setIsShowAlertModal(true);
								}}>
								<FlexDisplaySmallGap className="cursor-pointer">
									<HiOutlinePlusCircle className="fs-12 text-darkblue" />
									<div className="fs-12">Add Watchlist</div>
								</FlexDisplaySmallGap>
							</div>
						</FlexDisplaySpaceBetween>
					</>
					<hr />
					<div className="w-100 mt-2">
						<div className="table-container">
							<div className="table-scrollable">
								<table className="table table-striped table-borderless mb-4">
									<TableHead>
										<tr>
											<td>Symbol</td>
											<td>Name</td>
											<td>Type</td>
											<td></td>
										</tr>
									</TableHead>
									<tbody>
										{watchlistDetailsData.map((item: any, index: number) => {
											const { code, name } = item;

											const type = item?.type === 'company' ? 'C' : 'I';
											return (
												<React.Fragment key={index}>
													<tr>
														<TableData>
															<InformationPercentage
																className="bg-darkerblue rounded"
																textStyle="#ffffff">
																{trimStringToLength(code, 4)}
															</InformationPercentage>
														</TableData>
														<TableData fontWeight="600">{name}</TableData>
														<TableData>
															{type === 'I' ? (
																<InformationPercentage
																	className="bg-blue rounded"
																	textStyle="#225FB0">
																	{type}
																</InformationPercentage>
															) : (
																<InformationPercentage
																	className="bg-darkerblue rounded"
																	textStyle="#ffffff">
																	{type}
																</InformationPercentage>
															)}
														</TableData>
														<td>
															<Popconfirm
																title="Delete Company/Industry"
																description={`Are you sure to delete ${item?.name} ?`}
																onConfirm={() => {
																	deleteFromWatchlists(item);
																}}
																okText="Yes"
																cancelText="No">
																<HiOutlineTrash className="fs-12 text-darkblue cursor-pointer" />
															</Popconfirm>
														</td>
													</tr>
												</React.Fragment>
											);
										})}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
				<AddWatchlistComponent
					{...props}
					isShowAlertModal={isShowAlertModal}
					setIsShowAlertModal={setIsShowAlertModal}
					watchlistToEdit={watchlistToEdit}
					setWatchlistToEdit={setWatchlistToEdit}
				/>
			</>
		);
	}

	return (
		<React.Fragment>
			<div className="bg-grey pt-2">
				<WhiteContainer className="py-2 mb-2 position-relative">
					<div className={`position-absolute end-0 top-0 px-1`}>
						<Popover
							popoverTextHeader="**Edit Watchlist**"
							popoverTextBody={`Click to add or modify your watchlist and track your favorite companies. Click on the company name to navigate to the respective company.`}
						/>
					</div>
					<RightTabContainer className="d-flex justify-content-between px-3 pt-2">
						<div className="d-flex gap-1">
							{watchlistData.length > 0 ?
								watchlistData?.map(
									(item: { name: string }, index: number) => (
										<RightTabItem
											className="p-2"
											onClick={async () => {
												setSelectedWatchlist(item);
												await fetchingWatchlistDetail(item);
											}}
											selected={item === selectedWatchlist}
											key={index}>
											{item?.name}
										</RightTabItem>
									)
								) :
								<RightTabItem selected={true}>
									Watchlist
								</RightTabItem>
							}
						</div>

						{token &&
							<h5
								className="fs-10 fw-600 m-0 cursor-pointer standard-code bg-blue text-darkblue"
								onClick={() => setIsEditWatchlist(true)}>
								Edit Watchlist
							</h5>}
					</RightTabContainer>
					{
						watchlistDetailsData?.length > 0 ?
							watchlistDetailsData.map((item: any, index: number) => {
								return renderWatchlists(item, index, onClickCard);
							}) :
							token ? <div className='text-center pt-3 pb-3'>Add your watchlist by clicking Edit Watchlist.</div>
								: <div className='text-center pt-3 pb-3'>Register now and personalize your watchlist!</div>
					}
					<div className="d-flex justify-content-end pt-3">
						<Pagination
							size="small"
							showSizeChanger={false}
							defaultCurrent={currentPage}
							total={total}
							onChange={async (page: number) => {
								await fetchingWatchlistDetail(selectedWatchlist, page);
							}}
						/>
					</div>
				</WhiteContainer>
				{/* <WhiteContainer className="py-2 my-2">
					<RightTabContainer className="d-flex justify-content-between px-3">
						<RightTabItem onClick={() => { }} selected={true}>
							Top Keywords
							
						</RightTabItem>
					</RightTabContainer>
					<KeywordListTableComponent {...props} isMobile />
				</WhiteContainer> */}
				<WhiteContainer className="my-2 py-2 my-2">
					<div className="m-2 d-flex justify-content-between align-items-center">
						<div className="d-flex gap-1">
							<YearTab
								selected={selectedType === LIST_TYPE.TRENDING}
								onClick={() => setSelectedType(LIST_TYPE.TRENDING)}>
								Trending
								{selectedType === LIST_TYPE.TRENDING && <Popover
									className='ps-1'
									popoverTextHeader="**Trending Companies**"
									popoverTextBody={`Lists ${listType} currently popular based on user clicks in the past 30 days.`}
								/>}
							</YearTab>
							<YearTab
								selected={selectedType === LIST_TYPE.MOVERS_UP}
								onClick={() => setSelectedType(LIST_TYPE.MOVERS_UP)}>
								Movers Up
								{selectedType === LIST_TYPE.MOVERS_UP && <Popover
									className='ps-1'
									popoverTextHeader="**Movers Up**"
									popoverTextBody={`Highlights ${listType} with increasing sentiment scores.`}
								/>}
							</YearTab>
							<YearTab
								selected={selectedType === LIST_TYPE.MOVERS_DOWN}
								onClick={() => setSelectedType(LIST_TYPE.MOVERS_DOWN)}>
								Movers Down
								{selectedType === LIST_TYPE.MOVERS_DOWN && <Popover
									className='ps-1'
									popoverTextHeader="**Movers Down**"
									popoverTextBody={`Highlights ${listType} with decreasing sentiment scores`}
								/>}
							</YearTab>
						</div>
						<div
							className="fs-10 fw-600 px-2 py-1 bg-blue text-darkblue rounded cursor-pointer"
							onClick={() => {
								if (selectedListType === LIST_TYPE_SECTOR.COMPANIES) {
									setSelectedListType(LIST_TYPE_SECTOR.INDUSTRIES);
								} else {
									setSelectedListType(LIST_TYPE_SECTOR.COMPANIES);
								}
							}}>
							{selectedListType === LIST_TYPE_SECTOR.COMPANIES
								? 'Company'
								: 'Industry'}
						</div>
					</div>
					{trendingList?.map((item: any, index: number) => (
						<CompanyChartListComponent
							data={item}
							documentType={documentType}
							key={index}
							onClickCard={onClickCard}
						/>
					))}
					<WhiteContainer className="rounded p-1">
						<center>
							<Pagination
								size="small"
								showSizeChanger={false}
								defaultCurrent={trendingCurrentPage}
								total={trendingTotal}
								onChange={page => {
									onChangePage({ companyPageParam: page });
								}}
							/>
						</center>
					</WhiteContainer>
				</WhiteContainer>
				<WhiteContainer className="py-2 px-2 my-2">
					<EarningCallTable
						pageType='Home'
						componentTitle="Trending Reports"
						documentList={trendingReportsData}
						tablePaginationData={trendingReportsMeta}
						onChangePagination={page =>
							onChangePage({ trendingReportsParam: page })
						}
						filter={filter}
						setFilter={setFilter}
						isMobileVersion={true}
					/>
				</WhiteContainer>
				{renderDisclaimer()}
			</div>
		</React.Fragment>
	);
};

export default HomeMobile;
