import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import {
	HiOutlinePencil,
	HiOutlinePlusCircle,
	HiOutlineTrash,
} from 'react-icons/hi';
import _ from 'lodash';
import { Pagination, Popconfirm } from 'antd';

import {
	InformationPercentage,
	InformationPercentageSpan,
	TableData,
	TableHead,
} from '../../Screens/Company/Company.styled';
import SearchCompanyComponent from './SearchCompany.component';
import { Button } from '../index';
import { FlexDisplaySmallGap } from '../../Screens/Preferences/Preferences.styled';
import AddWatchlistComponent from './AddWatchlist.component';
import { trimStringToLength } from '../../Utils';
import { ColoredLabel } from '../../Screens/Industries/Industries.desktop.screen';

const WatchlistModalComponent = (props: any) => {
	const {
		isShowWatchlistModal,
		setIsShowWatchlistModal,
		watchlists,
		selectedWatchlist,
		onChangeWatchlist,
		watchlistDetails,
		deleteFromWatchlists,
		deleteWatchlist,
		fetchingWatchlistDetail
	} = props;

	const watchlistData = _.get(watchlists, 'data', []);
	const watchlistDetailsData = _.get(watchlistDetails, 'data', []);
	const watchlistDetailsMeta = _.get(watchlistDetails, 'meta', {});

	const currentPage = _.get(watchlistDetailsMeta, 'current_page', 1);
	const total = _.get(watchlistDetailsMeta, 'total', 1);

	const [isShowAlertModal, setIsShowAlertModal] = useState(false);
	const [watchlistToEdit, setWatchlistToEdit] = useState<any>(null);

	return (
		<>
			<Modal
				show={isShowWatchlistModal}
				onHide={() => {
					setIsShowWatchlistModal(false);
				}}
				centered={true}
				size="lg">
				<Modal.Header>
					<div className="d-flex justify-content-between w-100 align-items-center">
						<h5 className="fs-12 fw-500 m-0 w-50">Edit Watchlist</h5>
						<div className="w-50">
							<SearchCompanyComponent {...props} />
						</div>
					</div>
				</Modal.Header>
				<Modal.Body>
					<div className="w-100 d-flex gap-1">
						<div className="w-25">
							<h4 className="fs-12 fw-700 mb-3">Watchlist</h4>
							<div className="d-flex row gap-2 px-2">
								{watchlistData?.map((item: { name: string }, index: number) => {
									return (
										<div
											className={`${item === selectedWatchlist && 'bg-blue'
												} px-2 py-2 hover-blue rounded d-flex justify-content-between`}
											key={index}>
											<h3
												className="fs-10 fw-600 m-0 cursor-pointer w-75"
												onClick={async () => {
													await onChangeWatchlist(item);
												}}>
												{item?.name}
											</h3>
											<div className="d-flex w-25 gap-1">
												<h3
													className="fs-10 fw-600 m-0 cursor-pointer"
													onClick={() => {
														setIsShowAlertModal(true);
														setWatchlistToEdit(item);
													}}>
													<HiOutlinePencil className="fs-12 text-darkblue cursor-pointer" />
												</h3>
												<Popconfirm
													title="Delete Watchlist"
													description={`Are you sure to delete ${item?.name} ?`}
													onConfirm={() => {
														deleteWatchlist(item);
													}}
													okText="Yes"
													cancelText="No">
													<HiOutlineTrash className="fs-12 text-red cursor-pointer" />
												</Popconfirm>
											</div>
										</div>
									);
								})}
							</div>
						</div>
						<div className="w-75 px-2">
							<h4 className="fs-12 fw-700">{selectedWatchlist?.name}</h4>
							<div className="table-container">
								<div className="table-scrollable">
									<table className="table table-striped table-borderless mb-4">
										<TableHead>
											<tr>
												<td>Symbol</td>
												<td>Name</td>
												{/* <td>Type</td> */}
												<td></td>
											</tr>
										</TableHead>
										<tbody>
											{watchlistDetailsData.map((item: any, index: number) => {
												const { code, name } = item;

												const type = item?.type === 'company' ? 'C' : 'I';
												return (
													<React.Fragment key={index}>
														<tr>
															<td className="fs-10 fw-400">
																<ColoredLabel
																	sector={code}
																	className="company-stock-code fw-600 fs-10 mx-1">
																	{code}
																</ColoredLabel>
															</td>
															<td className="fs-10 fw-600">{name}</td>
															<td className="fs-10 fw-400">
																{/* {type === 'I' ? (
																	<InformationPercentageSpan
																		className="bg-blue standard-code"
																		textStyle="#225FB0">
																		{type}
																	</InformationPercentageSpan>
																) : (
																	<InformationPercentageSpan
																		className="bg-darkerblue standard-code"
																		textStyle="#ffffff">
																		{type}
																	</InformationPercentageSpan>
																)} */}
															</td>
															<td>
																<Popconfirm
																	title="Delete Company/Industry"
																	description={`Are you sure to delete ${item?.name} ?`}
																	onConfirm={() => {
																		deleteFromWatchlists(item);
																	}}
																	okText="Yes"
																	cancelText="No">
																	<HiOutlineTrash className="fs-12 text-red cursor-pointer" />
																</Popconfirm>
															</td>
														</tr>
													</React.Fragment>
												);
											})}
										</tbody>
									</table>
								</div>
							</div>
							<div className="d-flex justify-content-end">
								<Pagination
									size="small"
									showSizeChanger={false}
									defaultCurrent={currentPage}
									total={total}
									onChange={async (page: number) => {
										await fetchingWatchlistDetail(selectedWatchlist, page);
									}}
								/>
							</div>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer className="d-flex justify-content-between">
					<div className="fs-12 cursor-pointer">
						<FlexDisplaySmallGap
							className="cursor-pointer"
							onClick={() => {
								setIsShowAlertModal(true);
							}}>
							<HiOutlinePlusCircle className="fs-12 text-darkblue" />
							<div className="fs-12">Add Watchlist</div>
						</FlexDisplaySmallGap>
					</div>
					<div className="w-15 fs-12">
						<Button
							label="Close"
							rounded={false}
							size="small"
							onClick={() => setIsShowWatchlistModal(false)}
						/>
					</div>
				</Modal.Footer>
			</Modal>

			<AddWatchlistComponent
				{...props}
				isShowAlertModal={isShowAlertModal}
				setIsShowAlertModal={setIsShowAlertModal}
				watchlistToEdit={watchlistToEdit}
				setWatchlistToEdit={setWatchlistToEdit}
			/>
		</>
	);
};

export default WatchlistModalComponent;
