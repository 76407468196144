import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { toast } from 'react-toastify';

import {
	Container,
	ContainerMenuTab,
	FlexDisplay,
	FlexDisplaySmallGap,
	IconStyled,
	MenuLeftTab,
	MenuRightTab,
	PreferencesSection,
	SelectedTab,
	SignOutButton,
	SignOutIcon,
	TabDescription,
	TabTitle,
	UsernameText,
	WelcomeText,
} from './Preferences.styled';

import notificationIcon from '../../Assets/Icon/megaphone.svg';
import userSettingIcon from '../../Assets/Icon/user.svg';
import subscriptionIcon from '../../Assets/Icon/money.svg';
import {
	NotificationComponent,
	SubscriptionComponent,
	UserSettingComponent,
} from '../../Components/Preferences';
import { Modal } from '../../Components';
import { BUTTON_TYPE } from '../../Components/Button/Button.component';
import { IAuthorizationProps } from '../../Components/Authorization/Authorization.types';
import servicesRequest, { USER_PROFILE } from '../../Config/services.config';
import { GetProfileResponse } from './Preferences.types';
import { ERROR_CODES, ROLE } from '../../Constants';
import { ErrorResponse } from '../../Components/VerificationModal/VerificationModal.types';
import { LoadingComponent } from '../Industries/Industries.desktop.screen';
import { WhiteContainer } from '../Company/Company.styled';

const TAB_MENU = [
	{
		title: 'User setting',
		description:
			'Update your name, email address, and password, ensuring your account reflects the most accurate and current information.',
		value: 'USER_SETTING',
		Icon: <IconStyled src={userSettingIcon} alt="icon" />,
	},
	{
		title: 'Notification',
		description: 'Never miss important updates or insights that matter to you.',
		value: 'NOTIFICATION',
		Icon: <IconStyled src={notificationIcon} alt="icon" />,
	},
	{
		title: 'Membership',
		description:
			'Change your membership for your access to the exclusive content and features',
		value: 'SUBSCRIPTION',
		Icon: <IconStyled src={subscriptionIcon} alt="icon" />,
	},
];

const NeedHelpButton = styled(SignOutButton)`
	background: rgba(31, 120, 180, 0.14);
	color: #225fb0;
	text-align: center;
`;

const PreferencesScreen = (
	props: IAuthorizationProps
): React.JSX.Element | null => {
	const [selectedTab, setSelectedTab] = useState(TAB_MENU[0].value);
	const [signOutAlert, setSignOutAlert] = useState(false);
	const [fullName, setFullName] = useState('');
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [shouldReload, setShouldReload] = useState(false);

	const navigate = useNavigate();

	const cancelSignOut = () => {
		setSignOutAlert(false);
	};

	const signOut = () => {
		cancelSignOut();
		localStorage.removeItem('token');
		props.setIsOpenAuthorization(true);
		navigate('/');
	};

	const changeShouldReload = () => setShouldReload(!shouldReload);

	const getProfile = (response: GetProfileResponse) => {
		const emailResponse: string = _.get(response, 'data.data.user.email', '');
		const firstNameResponse: string = _.get(
			response,
			'data.data.user.first_name',
			''
		);
		const lastNameResponse: string = _.get(
			response,
			'data.data.user.last_name',
			''
		);
		const fullNameResponse: string = firstNameResponse + ' ' + lastNameResponse;

		setEmail(emailResponse);
		setFirstName(firstNameResponse);
		setLastName(lastNameResponse);
		setFullName(fullNameResponse);
	};

	const handleGetProfile = () => {
		const request = servicesRequest();
		request
			.get(USER_PROFILE)
			.then(response => getProfile(response))
			.catch((error: unknown) => {
				const err = error as ErrorResponse;
				const statusCode = _.get(err, 'response.data.status_code');

				if (statusCode === ERROR_CODES.VERIFICATION_ERROR) {
					navigate('/');
					localStorage.removeItem('token');
				} else {
					toast.error('Something went wrong. Please refresh the page.');
				}
			});
	};

	const token = localStorage.getItem('token');
	const isAdmin = localStorage.getItem('role') !== ROLE.MEMBER;

	useEffect(() => {
		if (!token) {
			navigate('/');
		}
		if (isAdmin) {
			navigate('/admin');
		} else {
			handleGetProfile();
		}
	}, []);

	useEffect(() => {
		if (shouldReload) {
			handleGetProfile();
		}
	}, [shouldReload]);

	if (token && !isAdmin) {
		return (
			<Container>
				<FlexDisplay>
					<div>
						<WelcomeText>Welcome</WelcomeText>
						{(fullName && email) ? (
							<UsernameText>
								<b>{fullName}</b>, {email}
							</UsernameText>) :
							<WhiteContainer>
								<LoadingComponent />
							</WhiteContainer>
						}
					</div>
				</FlexDisplay>

				<ContainerMenuTab>
					<PreferencesSection>
						<MenuLeftTab>
							{TAB_MENU.map((item, index) => (
								<SelectedTab
									onClick={() => {
										setSelectedTab(item.value);
									}}
									key={index}
									selected={item.value === selectedTab}>
									<FlexDisplaySmallGap>
										{item.Icon}
										<TabTitle>{item?.title}</TabTitle>
									</FlexDisplaySmallGap>
									<TabDescription>{item?.description}</TabDescription>
								</SelectedTab>
							))}
						</MenuLeftTab>
						<MenuRightTab>
							{selectedTab === TAB_MENU[0].value && (
								<UserSettingComponent
									fullName={fullName}
									email={email}
									lastName={lastName}
									firstName={firstName}
									changeShouldReload={changeShouldReload}
								/>
							)}
							{selectedTab === TAB_MENU[1].value && <NotificationComponent />}
							{selectedTab === TAB_MENU[2].value && <SubscriptionComponent />}
						</MenuRightTab>
					</PreferencesSection>
					<FlexDisplay>
						<SignOutButton
							onClick={() => {
								setSignOutAlert(true);
							}}>
							<SignOutIcon /> Sign Out
						</SignOutButton>
						<a href='mailto:cbsa@ust.hk' style={{
							width: '100%'
						}}><NeedHelpButton> Need help? </NeedHelpButton></a>
					</FlexDisplay>
				</ContainerMenuTab>
				<Modal
					show={signOutAlert}
					title="Sign out"
					buttonActionType={BUTTON_TYPE.DANGER}
					buttonActionLabel="Sign Out"
					onHide={cancelSignOut}
					onCancel={cancelSignOut}
					onConfirm={signOut}>
					<div>Are you sure you want to sign out?</div>
				</Modal>
			</Container>
		);
	}
	return null;
};

export default PreferencesScreen;
